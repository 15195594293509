import React, { useEffect, useRef, useState } from 'react';
import styles from './Gifs.scss';
import cx from 'classnames';
import i18n from 'src/locales';
import { isFunction, isEmpty, toString, toLower } from 'src/helpers/utils';
import { fromEvent } from 'rxjs';
import { TextInput, SearchIcon } from 'evergreen-ui';

const availableGIFs = [
  {
    link: 'https://c.tenor.com/TfSATNYuuDcAAAAi/text-cute-text.gif',
    key: 'TfSATNYuuDcAAAAi',
    searchKey: 'trust believe you'
  },
  {
    link: 'https://c.tenor.com/FXOWNdp3QR0AAAAi/thankyou.gif',
    key: 'FXOWNdp3QR0AAAAi',
    searchKey: 'thank you'
  },
  {
    link: 'https://c.tenor.com/CcLjjLtP6vAAAAAC/welcome-welcome-gif.gif',
    key: 'CcLjjLtP6vAAAAAC',
    searchKey: 'you are welcome'
  },
  {
    link: 'https://c.tenor.com/Wvkkxkiy2KMAAAAC/alright-team-lets-get-it-the-black-hokage.gif',
    key: 'Wvkkxkiy2KMAAAAC',
    searchKey: 'team get it'
  },
  {
    link: 'https://c.tenor.com/yrXnEqwuEPkAAAAi/mttc-education.gif',
    key: 'yrXnEqwuEPkAAAAi',
    searchKey: 'great well'
  },
  {
    link: 'https://c.tenor.com/NJhHW2zwnqsAAAAC/tomorrow-shawn.gif',
    key: 'NJhHW2zwnqsAAAAC',
    searchKey: 'see tomorrow'
  },
  {
    link: 'https://c.tenor.com/CT3GOSeG8xcAAAAC/doge-doge-coin.gif',
    key: 'doge-doge-coin',
    searchKey: 'to the moon'
  },
  {
    link: 'https://c.tenor.com/-h2Pb5ZgXn0AAAAC/taylor-swift-swiftie.gif',
    key: 'h2Pb5ZgXn0AAAAC',
    searchKey: 'see you tomorrow taylor excited jumping'
  },
  {
    link: 'https://c.tenor.com/uRPVpm67khgAAAAC/doge-dogeintensifies.gif',
    key: 'doge-dogeintensifies',
    searchKey: 'intense doge'
  },
  {
    link: 'https://c.tenor.com/KmO0ZsSmxsEAAAAd/celebration-dance.gif',
    key: 'KmO0ZsSmxsEAAAAd',
    searchKey: 'celebration excited'
  },
  {
    link: 'https://c.tenor.com/Kz34WTnB2SIAAAAC/thats-a-great-idea-perfect.gif',
    key: 'Kz34WTnB2SIAAAAC',
    searchKey: 'great idea'
  },
  {
    link: 'https://c.tenor.com/z9NRK56R4NwAAAAd/shuhua-%E8%88%92%E5%B0%8F%E5%A7%90%E5%96%9D%E8%8C%B6%E5%95%A6.gif',
    key: 'z9NRK56R4NwAAAAd',
    searchKey: 'boss'
  },
  {
    link: 'https://c.tenor.com/TaXc-x0BUa4AAAAC/kittycass-peachcat.gif',
    key: 'TaXc-x0BUa4AAAAC',
    searchKey: 'sad heart'
  },
  {
    link: 'https://c.tenor.com/tqERWt8lBYEAAAAC/calculating-puzzled.gif',
    key: 'tqERWt8lBYEAAAAC',
    searchKey: 'confused calculating calculate thinking'
  },
  {
    link: 'https://c.tenor.com/tpXoBlx6OcwAAAAC/shocked-ohreally.gif',
    key: 'tpXoBlx6OcwAAAAC',
    searchKey: 'shock shocked surprised'
  },
  {
    link: 'https://c.tenor.com/ZLDna2SjEjUAAAAC/joy-park-sooyeong.gif',
    key: 'ZLDna2SjEjUAAAAC',
    searchKey: 'peace cute'
  },
  {
    link: 'https://c.tenor.com/JT4yvDYgX4MAAAAC/love-it.gif',
    key: 'JT4yvDYgX4MAAAAC',
    searchKey: 'love it'
  },
  {
    link: 'https://c.tenor.com/oMSXsOfcGMcAAAAC/please-pretty.gif',
    key: 'oMSXsOfcGMcAAAAC',
    searchKey: 'pretty please'
  },
  {
    link: 'https://c.tenor.com/DI7AfQKhTsQAAAAC/puss-in-boots-cat.gif',
    key: 'DI7AfQKhTsQAAAAC',
    searchKey: 'puss in boots please'
  },
  {
    link: 'https://c.tenor.com/vHW5BfO0j7sAAAAd/seungwanyoungstreet-seungwan-wand.gif',
    key: 'vHW5BfO0j7sAAAAd',
    searchKey: 'confused shocked'
  },
  {
    link: 'https://c.tenor.com/sck9cmGxe84AAAAC/te-amo.gif',
    key: 'sck9cmGxe84AAAAC',
    searchKey: 'love you loveya'
  },
  {
    link: 'https://c.tenor.com/MCF-Pi6yiHEAAAAC/brb-be-right-back.gif',
    key: 'MCF-Pi6yiHEAAAAC',
    searchKey: 'be right back brb'
  },
  {
    link: 'https://c.tenor.com/Bra7UlufCggAAAAC/sad.gif',
    key: 'Bra7UlufCggAAAAC',
    searchKey: 'sad cry'
  },
  {
    link: 'https://c.tenor.com/dZ0ost9JVMsAAAAC/dance-party.gif',
    key: 'dZ0ost9JVMsAAAAC',
    searchKey: 'bear dancing babu'
  }
];

const Gifs = ({ gif = '', onSelect, close, id = '' }) => {
  const uniqueClassName = `comment-input-gif-${id}`;
  const gifListRef = useRef(null);
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    const subscriber = fromEvent(document, 'click').subscribe(evt => {
      if (!evt) {
        return;
      }

      const target = evt.target ? evt.target : evt.srcElement;

      if (
        target &&
        target.classList &&
        target.classList.length &&
        target.classList.contains(uniqueClassName) &&
        isFunction(onSelect)
      ) {
        const selectedKey = `${target.classList[target.classList.length - 1]}`;

        if (selectedKey === uniqueClassName) {
          return;
        }

        if (selectedKey) {
          const find = availableGIFs.filter(
            gif => gif && gif.key === selectedKey
          );
          const gif = find[0];

          if (gif && gif.link) {
            onSelect(gif.link);

            if (isFunction(close)) {
              close();
            }
          }
        }
      } else if (isFunction(close)) {
        close();
      }
    });

    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
  }, [gif, onSelect, close, uniqueClassName, gifListRef]);

  const goSearchGif = (key = '') => {
    if (!key) {
      setFiltered([]);
    }
    const newFiltered = [];
    key = toLower(key);

    for (let i = availableGIFs.length - 1; i >= 0; i--) {
      const gifInfo = availableGIFs[i];
      if (gifInfo && gifInfo.searchKey) {
        const str = `${gifInfo.searchKey}`;

        if (str.includes(key)) {
          newFiltered.push({ ...gifInfo });
        }
      }
    }

    setFiltered(newFiltered);
  };

  return (
    <div className={cx(styles.gifs, uniqueClassName)}>
      <div
        className={cx(styles.search_gif, styles.flex_row_xy, uniqueClassName)}
      >
        <TextInput
          onChange={evt => {
            if (!evt) {
              return;
            }

            const target = evt.target ? evt.target : evt.srcElement;
            if (!target) {
              return;
            }
            const value = toString(target.value);
            setSearch(value);
            goSearchGif(value);
          }}
          value={search}
          className={cx(uniqueClassName)}
          maxLength={40}
        />{' '}
        <div>
          <SearchIcon />
          <div className={cx(styles.cover, uniqueClassName)}></div>
        </div>
      </div>
      {isEmpty(filtered) && !isEmpty(search) && (
        <div className={cx(styles.empty, styles.flex_row_xy)}>
          <h5>{i18n('common_no_results')}</h5>
        </div>
      )}
      <ul ref={gifListRef} className={cx(uniqueClassName)}>
        {(search ? filtered : availableGIFs).map((gif, idx) => (
          <li key={`gif-${idx}-${gif.key}`}>
            <img
              src={gif.link}
              alt={'tenor gif '}
              className={uniqueClassName}
            />
            <div className={cx(styles.cover, uniqueClassName, gif.key)}></div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Gifs;
