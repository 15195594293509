import React, { Component } from 'react';
import cx from 'classnames';
import styles from './ClearTaskBody.scss';
import i18n from 'src/locales';
import CustomButton from 'src/components/button';
import LoadSpinner from 'src/components/load-spinner';
import { isFunction } from 'src/helpers/utils';
import { Button } from 'evergreen-ui';

class ClearTask extends Component {
  state = {
    submitted: false
  };

  onConfirm = () => {
    const { confirm } = this.props;

    if (isFunction(confirm)) {
      this.setState(
        {
          submitted: true
        },
        confirm
      );
    }
  };

  onCancel = () => {
    const { cancel } = this.props;
    if (isFunction(cancel)) {
      cancel();
    }
  };

  render() {
    const { submitted } = this.state;

    return (
      <div className={cx(styles.flex_row_xy, styles.clear_task)}>
        <div className={styles.actual}>
          <h4>{i18n('user_edit_task_clear_body')}</h4>
          <div
            className={cx(
              { [styles.hide_element]: !submitted },
              styles.spinner_wrap
            )}
          >
            <div className={styles.raw}>
              <LoadSpinner className={styles.raw_spin} />
            </div>
          </div>
          <div
            className={cx(styles.flex_row_x, styles.actions, {
              [styles.hide_element]: submitted
            })}
          >
            <CustomButton
              onClick={this.onConfirm}
              label={i18n('common_confirm')}
              variant={'primaryPurple'}
              className={styles.confirm}
            />

            <Button
              className={styles.cancel}
              appearance="minimal"
              onClick={this.onCancel}
            >
              <div className={cx(styles.flex_row_xy, styles.cancel_raw)}>
                <p>{i18n('common_cancel_title')}</p>
              </div>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ClearTask;
