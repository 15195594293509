import React from 'react';
import cx from 'classnames';
import styles from './CustomBadge.scss';

const CustomBadge = props => {
  if (!props.name) {
    return <></>;
  }

  return (
    <div
      className={cx(styles.custom_badge, {
        [props.className]:
          typeof props.className === 'string' && props.className,

        [styles.custom_badge_default]: !props.type,
        [styles.custom_badge_author]: props.type === 'author',
        [styles.custom_badge_subscribed]: props.type === 'subscribed',
        [styles.custom_badge_personal_tag]: props.type === 'personal tag',
        [styles.custom_badge_space_name]: props.type === 'space name'
      })}
    >
      <h5>{props.name || ''} </h5>
    </div>
  );
};

export default CustomBadge;
