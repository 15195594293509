import React, { useState, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './Failed.scss';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import CustomButton from 'src/components/button';
import Store from 'src/lib/store';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, ArrowRightIcon, CrossIcon } from 'evergreen-ui';
import { withUserProfileSettings } from 'src/profile-manager';

const PaymentFailed = props => {
  const verifyCalled = useRef(false);
  const [verifying, setVerifying] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [query, setQuery] = useState(null);
  const [toRedirect, setToRedirect] = useState(false);
  const profileLoaded = props?.profileLoaded;
  const isLoggedIn = props?.isLoggedIn;
  const isThemeDarkMode = AppAPI.isDarkMode();

  const onUpgrade = () => {
    if (tryAgain) {
      return;
    } else {
      setTryAgain(true);

      if (!isLoggedIn || ProfileAPI.SPACES.isUserPremiumWithSpace()) {
        // already premium
        // or not logged in we redirect user
        setToRedirect(true);
      } else {
        ProfileAPI.PAYMENT.createCheckoutSession();
      }
    }
  };

  const onProfileLoaded = useCallback(
    (params = {}) => {
      const { fetching = false } = params || {};
      const refId = query?.ref_id;

      if (!isLoggedIn && profileLoaded && !fetching) {
        if (!refId) {
          setToRedirect(true);
        } else {
          setVerifying(false);
        }
        return;
      }

      if (
        !ProfileAPI.USER_DATA.checkIsFetchingPlan() &&
        !fetching &&
        ProfileAPI.USER_DATA.info()
      ) {
        const verify = async () => {
          if (query) {
            if (!refId) {
              setToRedirect(true);
              return;
            }

            await ProfileAPI.PAYMENT.checkoutFailed(refId);
            setVerifying(false);
          }
        };

        if (!verifyCalled?.current && query) {
          verifyCalled.current = true;
          verify();
        }
      }
    },

    [query, isLoggedIn, profileLoaded, setVerifying, setToRedirect]
  );

  useEffect(() => {
    let subscriber = null;

    if (
      (!ProfileAPI.USER_DATA.checkIsFetchingPlan() &&
        !!ProfileAPI.USER_DATA.info()) ||
      (profileLoaded && !isLoggedIn)
    ) {
      onProfileLoaded({ fetching: false });
    } else {
      subscriber =
        ProfileAPI.USER_DATA.onProfileFetchingPlanStatus(onProfileLoaded);
    }

    return () => {
      if (subscriber && subscriber.unsubscribe) {
        subscriber.unsubscribe();
      }
    };
  }, [
    query,
    profileLoaded,
    isLoggedIn,
    query?.session_id,
    onProfileLoaded,
    setVerifying
  ]);

  useEffect(() => {
    Store.CONFIGS.getQueryJSON();
    setQuery(Store.CONFIGS.getQuery());
  }, []);

  if (toRedirect && isLoggedIn) {
    return <Redirect to={'/user'} />;
  } else if (toRedirect && !isLoggedIn && profileLoaded) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div className={cx(styles.payment_failed, styles.flex_row_xy)}>
      <div className={cx(styles.raw, { [styles.raw_dark]: isThemeDarkMode })}>
        <div className={cx(styles.failed)}>
          {(verifying || !profileLoaded) && (
            <div
              className={cx(styles.loading, styles.flex_row_xy, {
                [styles.loading_dark]: isThemeDarkMode
              })}
            >
              <Spinner />
            </div>
          )}
          {!verifying && profileLoaded && (
            <>
              <div className={cx(styles.failed_ic, styles.flex_row_xy)}>
                <div className={styles.flex_row_xy}>
                  <CrossIcon />
                </div>
              </div>
              <div className={styles.title}>
                <h3>{i18n('payment_failed')}</h3>
              </div>
              <div className={styles.message}>
                <h5>{i18n('payment_failed_message')}</h5>{' '}
              </div>
              <div className={styles.try_again}>
                <CustomButton
                  onClick={onUpgrade}
                  loading={tryAgain}
                  className={styles.try_again_button}
                  variant={'primaryPurple'}
                  label={i18n('payment_failed_try_again')}
                />{' '}
              </div>
              {isLoggedIn && (
                <div className={styles.redirect}>
                  <Link to={'/user/account?section=plan'}>
                    <h5> {i18n('payment_redirect_account_settings')}</h5>
                    <span>
                      <ArrowRightIcon />
                    </span>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(PaymentFailed);
