import React, { Component } from 'react';
import cx from 'classnames';
import styles from './Reminders.scss';
import ProfileAPI from 'src/profile-manager/API';
import k from 'src/constants/k';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import {
  isArray,
  isString,
  isEmpty,
  hoursInStringToHHMMAMPMFormat
} from 'src/helpers/utils';

class Reminders extends Component {
  currentDate = new Date();
  state = {
    fetching: true,
    mounted: false,
    enabled: false,
    currentDate: this.currentDate,
    currentDateUtc: this.currentDate.toISOString()
  };

  constructor() {
    super();

    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    this.setState({
      mounted: false
    });
  }

  onMount = async () => {
    const { taskId, uRef } = this.props;
    const taskIdLowerCase = `${taskId}`.toLowerCase();
    if (taskId) {
      const remindersRes = await ProfileAPI.USER_TASKS.getReminders(
        taskIdLowerCase,
        uRef
      );

      if (
        this.state.mounted &&
        remindersRes &&
        !remindersRes.err &&
        isArray(remindersRes.reminders)
      ) {
        const reminder = remindersRes.reminders[0];

        if (reminder) {
          const due = reminder.dueString;
          const hours = reminder.hours || '00:00';

          await this.setStateAsync({
            hours,
            currentDate: new Date(due),
            currentDateUtc: due,
            fetching: false,
            enabled: remindersRes.enabled
          });
        }
      }
    }
  };

  render() {
    const { fetching, currentDate, enabled, hours } = this.state;
    const canShow =
      enabled && !fetching && ProfileAPI.USER_DATA.checkIsLoggedIn();
    const dateInString = `${
      k.MONTHS_INDEX[currentDate.getMonth()].abv
    } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
    const dateGap =
      Math.ceil(currentDate.getTime() / (1000 * 60 * 60)) -
      Math.ceil(this.currentDate.getTime() / (1000 * 60 * 60));
    const isToday = dateGap < 0 && dateGap > -24;
    const isDateFuture = !isToday && dateGap > 0;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const exactHours =
      isString(hours) && !isEmpty(hours)
        ? `${hoursInStringToHHMMAMPMFormat(hours)}`
        : '';

    if (!canShow) {
      return <></>;
    }

    return (
      <div
        className={cx(styles.reminders, {
          [styles.reminders_dark]: isThemeDarkMode
        })}
      >
        <div>
          <h5>{`${
            isDateFuture
              ? i18n('task_view_reminder_on')
              : isToday
              ? i18n('task_view_reminder_today')
              : i18n('task_view_reminder_last')
          } ${exactHours ? `${exactHours} ` : ''}${dateInString}`}</h5>{' '}
        </div>
      </div>
    );
  }
}

export default Reminders;
