import React, { Component, Fragment, createRef } from 'react';
import cx from 'classnames';
import styles from './Task.scss';
import Store from 'src/lib/store';
import TaskPassword from 'src/modals/task-password';
import AppAPI from 'src/app-manager/API';
import ModalAPI from 'src/modal-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import Logger from 'src/lib/Logger';
import k from 'src/constants/k';
import TaskDescription from './TaskDescription';
import PriorityIcon from 'src/components/priority-icon';
import Header from './Header';
import Footer from './Footer';
import Actions from './actions';
import WithAccess from 'src/pages/task/with-access';
import ImageExpand from 'src/modals/image-expand';
import UserDOM from 'src/lib/UserDOM';
import Exposure from './exposure';
import CreateTask from 'src/modals/create-task';
import CustomBadge from 'src/components/custom-badge';
import CommentsAndActivities from './comments-activities';
import FilesPreview from './files-preview';
import Reminders from './reminders';
import CommentMobile from './comments/comment-mobile';
import i18n, { Markdown } from 'src/locales';
import {
  isEmpty,
  isValidDescriptionFormat,
  isArray,
  isNumber,
  isMobileView,
  isValidObject,
  isIOSMobile,
  isNil
} from 'src/helpers/utils';
import { isAlphaNumeric, isStringNotEmpty } from 'src/lib/UserInputs';
import { withUserProfileSettings } from 'src/profile-manager';
import { timer, fromEvent } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { Redirect, withRouter } from 'react-router-dom';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'src/lib/bodyScrollLock.min';

class TaskView extends Component {
  onLocalProfileLoadedSubscriber = null;
  onTaskInfoSubscriber = null;
  onTaskCopyLinkTimerSubscriber = null;
  onIDInfoSubscriber = null;
  onDocumentClickSubscriber = null;
  onWindowResizeSubscriber = null;
  onTaskInfoTaskInfoSubscriber = null;
  onSubmitResultSubscriber = null;
  onSubmitEditResultSubscriber = null;
  onUserInfoStatusSubscriber = null;
  taskViewWrapRef = null;
  descriptionWrapREF = null;
  avatarImageREF = null;
  getting_started_task = false;
  task_URL = '';
  tryingLastEditedTask = false;
  tryingLastCreatedTask = false;
  taskMoreOptionsUnique = 'task-more-options-unique';
  infoFetched = false;
  triesInCreateTask = 0;
  initialViewportHeight = 0;
  scrollY = 0;

  state = {
    mounted: false,
    invalid: false,
    checked: false,
    copiedLink: false,
    error: false,
    taskID: '',
    taskMore: false,
    universalREF: '',
    requiredPW: false,
    title: '',
    description_delta: {},
    displayName: '',
    priority: -1,
    created: -1,
    exposure: -1,
    author: '',
    author_id: '',
    authorAvatar: false,
    task_state: '',
    pwcode: '',
    isEditor: false,
    isOwner: false,
    isShared: false,
    actions: true,
    personal_tags: [],
    author_tags: [],
    spaceTags: [],
    spaceTagsInfo: {},
    spaceInfo: {},
    hasAccessFetching: true,
    hasAccess: [],
    files: [],
    readOnly: false,
    last_updated: -1,
    redirectEdit: false,
    views: 0,
    viewsValid: false,
    taskInfoFromLocal: false,
    canFetchCommentsAndActivities: false,
    updatingState: false,
    subscribers: [],
    height: window?.visualViewport
      ? window.visualViewport.height
      : window.innerHeight,
    comment: {
      writing: false,
      loading: true,
      allowedView: true,
      allowedWrite: true,
      mode: k.TASK_VIEW_COMMENT_MODE.new.key,
      properties: null
    }
  };

  constructor() {
    super();

    if (!this.initialViewportHeight) {
      this.initialViewportHeight = window.visualViewport
        ? window.visualViewport.height
        : window.innerHeight;
    }

    this.taskViewWrapRef = createRef();
    this.descriptionWrapREF = createRef();
    this.avatarImageREF = createRef();
    this.onCopyLink = this.onCopyLink.bind(this);
    this.onCopyLinkWPasscode = this.onCopyLink.bind(this, true);
    this.onEditTask = this.onEditTask.bind(this);
    this.onAuthorProfileIDInfo = this.onAuthorProfileIDInfo.bind(this);
    this.toggleTaskMore = this.toggleTaskMore.bind(this);
    this.modifyHasAccess = this.modifyHasAccess.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));

    this.onSubmitResultSubscriber = ProfileAPI.USER_TASKS.onCreateResults(
      this.onSubmitCreateResults
    );
    this.onSubmitEditResultSubscriber = ProfileAPI.USER_TASKS.onEditResults(
      this.onSubmitEditResults
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (isMobileView()) {
      ModalAPI.setModalStyle(true);
    }

    this.onDocumentClickSubscriber = fromEvent(document, 'click').subscribe(
      this.onDocumentClick
    );
    this.onSpacesListSubscriber = ProfileAPI.SPACES.onSpacesList(this.onList);
    this.setState(
      {
        mounted: true
      },
      this.onMount
    );

    try {
      document.addEventListener('scroll', this.onScroll);
      window.addEventListener('scroll', this.onScroll, false);

      if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', this.onResize);
      } else {
        window.addEventListener('resize', this.onResize, false);
      }
    } catch {}

    if (isMobileView()) {
      if (this.taskViewWrapRef?.current) {
        disableBodyScroll(this.taskViewWrapRef.current);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { mounted } = this.state;
    const { profileLoaded } = this.props;

    if (
      mounted &&
      !isNil(prevProps) &&
      !prevProps?.profileLoaded &&
      profileLoaded
    ) {
      this.onMount();
    }
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    const html = document.querySelector('html');
    const body = document.body;
    const doms = [root, html, body];

    if (this.taskViewWrapRef?.current) {
      enableBodyScroll(this.taskViewWrapRef.current);
    }

    doms
      .filter(dom => dom)
      .forEach(dom => {
        dom.style.height = '';
        dom.style.maxHeight = '';
        dom.style.overflow = '';
      });

    this.setState({
      mounted: false
    });

    window.scrollTo(0, 0);

    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', this.onResize);
    }

    document.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize, false);
    window.removeEventListener('scroll', this.onScroll, false);

    this.triesInCreateTask = 0;
    this.infoFetched = false;

    if (
      ProfileAPI.SPACES.isUserPremiumWithSpace() &&
      ProfileAPI.SPACES.doneWithInitialFetch()
    ) {
      const space = ProfileAPI.SPACES.getSpaceSelected();
      const spaceId = space ? space.id || space.space_id : '';
      ProfileAPI.SPACES.getTagsList(1, spaceId, true);
    }

    if (this.onUserInfoStatusSubscriber) {
      this.onUserInfoStatusSubscriber.unsubscribe();
    }

    if (this.onSpacesListSubscriber) {
      this.onSpacesListSubscriber.unsubscribe();
    }

    if (this.onSubmitEditResultSubscriber) {
      this.onSubmitEditResultSubscriber.unsubscribe();
    }

    if (this.onSubmitResultSubscriber) {
      this.onSubmitResultSubscriber.unsubscribe();
    }

    if (this.onTaskInfoTaskInfoSubscriber) {
      this.onTaskInfoTaskInfoSubscriber.unsubscribe();
    }

    if (this.onDocumentClickSubscriber) {
      this.onDocumentClickSubscriber.unsubscribe();
    }

    if (this.onIDInfoSubscriber) {
      this.onIDInfoSubscriber.unsubscribe();
    }

    if (this.onLocalProfileLoadedSubscriber) {
      this.onLocalProfileLoadedSubscriber.unsubscribe();
    }

    if (this.onTaskInfoSubscriber) {
      this.onTaskInfoSubscriber.unsubscribe();
    }

    if (this.onTaskCopyLinkTimerSubscriber) {
      this.onTaskCopyLinkTimerSubscriber.unsubscribe();
    }
  }

  allowCommentWrite = () => {
    this.setState({
      comment: { ...this.state.comment, loading: false, allowedWrite: true }
    });
  };

  blockCommentWrite = () => {
    this.setState({
      comment: { ...this.state.comment, loading: false, allowedWrite: false }
    });
  };

  blockCommentView = () => {
    this.setState({
      comment: {
        ...this.state.comment,
        loading: false,
        // if view is not allowed then so is writing new comment
        allowedView: false,
        allowedWrite: false
      }
    });
  };

  updateCommentMode = (mode = '', properties) => {
    this.setState({
      comment: {
        ...this.state.comment,
        mode,
        properties,
        writing:
          mode === k.TASK_VIEW_COMMENT_MODE.new.key ||
          (mode === k.TASK_VIEW_COMMENT_MODE.edit.key &&
            isValidObject(properties))
      }
    });
  };

  checkOverflowWithCommentActive = () => {
    try {
      const root = document.getElementById('root');
      const html = document.querySelector('html');
      const body = document.body;
      const doms = [root, html, body];
      const currentViewportHeight = window?.visualViewport
        ? window.visualViewport.height
        : window.innerHeight;
      const newH = `${currentViewportHeight}px`;

      if (isMobileView()) {
        // html.style.height = 0;
        // html.style.maxHeight = 0;
        body.style.height = 0;
        body.style.maxHeight = 0;
        root.style.height = newH;

        AppAPI.noUIOverflow();
      } else {
        doms
          .filter(dom => dom)
          .forEach(dom => {
            dom.style.height = '';
            dom.style.maxHeight = '';
            dom.style.overflow = '';
          });
      }
    } catch {}
  };

  onResize = () => {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    } else {
      const dom = this.taskViewWrapRef.current;
      const isMobileScren = isMobileView();
      const currentViewportHeight = window.visualViewport
        ? window.visualViewport.height
        : window.innerHeight;

      if (isMobileScren) {
        if (dom) {
          dom.style.height = `${currentViewportHeight}px`;
        }
      } else if (dom && dom.style.height) {
        dom.style.height = '';
      }

      this.checkOverflowWithCommentActive();
    }
  };

  onScroll = () => {
    const { mounted, comment } = this.state;

    if (!mounted) {
      return;
    }

    const isIosMobile = isIOSMobile() || Capacitor.getPlatform() === 'ios';
    const commentIsActive =
      comment?.writing ||
      (!isEmpty(comment?.mode) && isValidObject(comment?.properties));

    if (isMobileView()) {
      if (commentIsActive && isIosMobile) {
        if (this.taskViewWrapRef?.current?.scrollTo) {
          this.taskViewWrapRef.current.scrollTo(0, 0);
        }
      }

      if (window?.scrollY > 0) {
        window.scrollTo(0, 0);
      }
    }
  };

  onList = params => {
    const { mounted, spaceInfo } = this.state;

    if (mounted && spaceInfo && params) {
      const spaceId = spaceInfo ? spaceInfo.id : '';
      const res = ProfileAPI.SPACES.getSpaceById(spaceId);
      const space = res?.space;

      if (!!space) {
        this.setState(
          {
            spaceInfo: { ...spaceInfo, ...space }
          },
          this.spaceSelectedCheck
        );
      }
    }
  };

  spaceSelectedCheck = async () => {
    const { mounted, spaceInfo } = this.state;

    try {
      const spaceId = spaceInfo ? spaceInfo.id || spaceInfo.space_id : '';
      if (spaceId && mounted) {
        // check if space is available
        const { space } = ProfileAPI.SPACES.getSpaceById(spaceId);

        if (!space) {
          return;
        }

        const selectedSpace = ProfileAPI.SPACES.getSpaceSelected();
        const globalConfigSpace = AppAPI.getGlobalConfig('space');

        if (
          globalConfigSpace &&
          (!selectedSpace ||
            !globalConfigSpace.selected ||
            (selectedSpace && selectedSpace.id !== spaceId))
        ) {
          globalConfigSpace.selected = spaceId;
          await ProfileAPI.SPACES.setSpaceSelected(spaceId);
          await AppAPI.setGlobalConfig('space', globalConfigSpace);
        }
      }
    } catch {}
  };

  onMount = async () => {
    Store.CONFIGS.getQueryJSON();
    const query = Store.CONFIGS.query;
    const { profileLoaded } = this.props;
    const { pathname } = window.location;
    const maxIs3 = pathname.indexOf('/user/view') === 0;
    const maxIs2 = pathname.indexOf('/view') === 0;

    if (!profileLoaded) {
      return;
    }

    if (isMobileView()) { 
      this.onResize();
    }

    if (
      (maxIs2 || maxIs3) &&
      typeof query.t === 'string' &&
      query.t &&
      `${query.t}`.trim().length > 0
    ) {
      const pathArr = pathname.trim().split('/');

      if (pathArr[0].length <= 0) {
        pathArr.shift();
      }

      if (
        (pathArr.length !== 2 && maxIs2) ||
        (pathArr.length !== 3 && maxIs3)
      ) {
        return this.setState({
          invalid: true,
          mounted: false,
          checked: true
        });
      }

      const taskID = pathArr[pathArr.length - 1];

      if (!isStringNotEmpty(taskID) || !isAlphaNumeric(taskID)) {
        return this.setState({
          invalid: true,
          mounted: false
        });
      } else {
        this.setState(
          {
            taskID: taskID.toUpperCase(),
            universalREF: query.t
          },
          () => {
            Logger.log(
              `Inspecting task, id: ${this.state.taskID}, uref: ${this.state.universalREF}`
            );

            this.task_URL = `${window.location.origin}${window.location.pathname}?t=${this.state.universalREF}`;
            this.onTaskInfoTaskInfoSubscriber =
              ProfileAPI.PUBLIC.onInspectTaskResult(this.onTaskInfo);

            if (!this.props.profileLoaded) {
              this.onLocalProfileLoadedSubscriber =
                ProfileAPI.PUBLIC.onProfileLoaded(this.onProfileLoaded);
            } else {
              this.onProfileLoaded();
            }
          }
        );
      }
    } else {
      this.setState({
        invalid: true,
        mounted: true,
        checked: true
      });
    }
  };

  onSubmitCreateResults = async ({
    success = false,
    try_again = false,
    uRef = '',
    not_allowed = false,
    not_allowed_plan = false
  }) => {
    // always create mode here

    try {
      const lastCreatedTask = AppAPI.getLocalConfig('lastCreatedTask') || null;
      const userId = ProfileAPI.USER_DATA.getUserId();
      if (try_again) {
        // try again create

        if (
          userId &&
          uRef &&
          typeof lastCreatedTask === 'object' &&
          lastCreatedTask &&
          lastCreatedTask.valid &&
          lastCreatedTask.uRef === uRef &&
          lastCreatedTask.userId === userId
        ) {
          if (this.triesInCreateTask > 2) {
            // 2 tries limit for creating new task for now
            // @todo check if offline, if yes then show offline screen

            Logger.log(`Unsuccessful in creating task, uRef: ${uRef}`);
            this.setState({
              invalid: true,
              checked: true
            });
          } else {
            this.triesInCreateTask += 1;
          }

          await ProfileAPI.USER_TASKS.create(
            lastCreatedTask.title,
            lastCreatedTask.description_delta,
            lastCreatedTask.tags,
            lastCreatedTask.spaceTags,
            lastCreatedTask.priority,
            lastCreatedTask.exposure,
            lastCreatedTask.exposurePasscode,
            lastCreatedTask.subscribers,
            lastCreatedTask.uRef,
            lastCreatedTask.spaceId
          );
          return;
        }
      }

      if (not_allowed || not_allowed_plan) {
        //@todo prompt user to get premium
      } else if (success) {
        // allow view now when proceeded from create task modal
        await ProfileAPI.USER_PROFILE.getLastDraft(true);
        await this.setStateAsync({
          canFetchCommentsAndActivities: true
        });
      }
    } catch (err) {
      Logger.log(
        `Something failed when checking for last task created results then into view: ${err.message}`
      );
    }
  };

  onSubmitEditResults = async ({
    success = false,
    try_again = false,
    universalID = '',
    not_allowed = false,
    not_allowed_plan = false
  }) => {
    try {
      const { mounted } = this.state;
      if (!mounted) {
        return;
      } else {
        // if edit mode then just let em comment since comments section is already established
        await this.setStateAsync({
          canFetchCommentsAndActivities: true
        });
      }

      const lastEditedTask = AppAPI.getLocalConfig('lastEditedTask') || null;
      const userId = ProfileAPI.USER_DATA.getUserId();
      const uRef = universalID;

      if (try_again) {
        if (
          userId &&
          uRef &&
          typeof lastEditedTask === 'object' &&
          lastEditedTask &&
          lastEditedTask.valid &&
          lastEditedTask.uRef === uRef &&
          lastEditedTask.userId === userId
        ) {
          if (this.tryingLastEditedTask) {
            return;
          } else {
            this.tryingLastEditedTask = true;
          }

          if (this.triesInCreateTask > 5) {
            // 5 ries limit for editing task for now
            // @todo check if offline, if yes then show offline screen
            Logger.log(`Unsuccessful in editing task, uRef: ${uRef}`);
          } else {
            this.triesInCreateTask += 1;
          }

          await ProfileAPI.USER_TASKS.edit(
            lastEditedTask.taskId,
            uRef,
            lastEditedTask.title,
            lastEditedTask.description_delta,
            lastEditedTask.tags,
            lastEditedTask.spaceTags,
            lastEditedTask.priority,
            lastEditedTask.exposure,
            lastEditedTask.exposurePasscode,
            lastEditedTask.taskState,
            lastEditedTask.newSubscribers,
            lastEditedTask.removeSubscribers
          );

          this.tryingLastEditedTask = false;
          return;
        }
      }

      if (not_allowed || not_allowed_plan) {
        //@todo prompt user to get premium
      } else if (success) {
        // allow view now when proceeded from create task modal
        await ProfileAPI.USER_PROFILE.getLastDraft(true);
      }
    } catch (err) {
      Logger.log(
        `Something failed when checking for last task edited results then into view: ${err.message}`
      );
    }
  };

  onImagePreviewClose = () => {
    ModalAPI.goDeactivate();

    const timeoutId = setTimeout(() => {
      if (this.scrollY && isNumber(this.scrollY)) {
        window.scrollTo(0, this.scrollY);
      }

      clearTimeout(timeoutId);
    }, 300);
  };

  onDocumentClick = evt => {
    if (!evt) {
      return;
    }

    const target = evt.target
      ? evt.target
      : evt.srcElement
      ? evt.srcElement
      : null;
    const { mounted, taskMore } = this.state;

    if (target && mounted) {
      try {
        if (
          target &&
          UserDOM.isImage(target) &&
          target.hasAttribute &&
          target.hasAttribute('src') &&
          !ModalAPI.isActive()
        ) {
          const imgAlt = `${target.getAttribute('alt')}`;

          if (taskMore) {
            this.setState({
              taskMore: false
            });
          }

          if (
            (target.parentElement &&
              target.parentElement.classList.contains(styles.author_avatar)) ||
            (imgAlt &&
              (imgAlt === 'Author avatar' ||
                imgAlt === 'user avatar' ||
                imgAlt.includes('gif from comment input') ||
                imgAlt.includes('emoji')))
          ) {
            return;
          }

          const src = target.getAttribute('src');
          this.scrollY = window.scrollY;

          if (src) {
            this.setState(
              {
                comment: {
                  ...this.state.comment,
                  writing: false
                }
              },
              () => {
                ModalAPI.setDOM(
                  <ImageExpand image={src} close={this.onImagePreviewClose} />,
                  true,
                  'TaskImageExpand'
                );
                ModalAPI.goActivate();
              }
            );
          }
        } else if (
          target &&
          target.classList &&
          target.classList.contains &&
          !target.classList.contains(this.taskMoreOptionsUnique) &&
          taskMore
        ) {
          this.setState({
            taskMore: false
          });

          return;
        } else if (
          target &&
          target.classList &&
          target.classList.contains &&
          target.classList.contains(this.taskMoreOptionsUnique) &&
          target.classList.length > 0 &&
          taskMore
        ) {
          const cn = target.classList[target.classList.length - 1];

          if (cn) {
            const cnArr = cn.split('-');
            if (isArray(cnArr) && cnArr.length > 1) {
              const type = cnArr[cnArr.length - 1];

              if (type === 'copy') {
                this.setState(
                  {
                    taskMore: false
                  },
                  this.onCopyLink
                );
              } else if (type === 'edit') {
                this.setState(
                  {
                    taskMore: false
                  },
                  this.onEditTask
                );
              } else if (type === 'copywpw') {
                this.setState(
                  {
                    taskMore: false
                  },
                  this.onCopyLinkWPasscode
                );
              } else if (type === 'newstate') {
                this.setState({
                  taskMore: false
                });
              }
            }
          }
        }
      } catch {}
    }
  };

  onProfileLoaded = async () => {
    Logger.log(`Checking inspect task on cloud`);
    /**
     * We check on API if task's public if user is not logged in or user owns this task
     */
    const { isLoggedIn } = this.props;
    const { mounted, taskID: taskId, universalREF, checked } = this.state;
    const lastEdited = AppAPI.getGlobalConfig('lastEdited');

    if (!mounted) {
      return;
    }

    if (isLoggedIn) {
      const { firstName, lastName } = ProfileAPI.USER_DATA.info();
      const displayName = `${firstName} ${lastName}`;
      await this.setStateAsync({
        displayName
      });
    }

    const cached = ProfileAPI.TASKS.getCachedTaskById(`${taskId}`);
    const canUseCached = Boolean(
      cached &&
        !cached.deleted &&
        cached.latest &&
        isValidDescriptionFormat(cached.latest.description_delta)
    );

    if (
      mounted &&
      lastEdited &&
      lastEdited.valid &&
      (lastEdited.taskId === taskId || lastEdited.source === 'create') &&
      lastEdited.title &&
      !checked
    ) {
      await this.setStateAsync({
        taskInfoFromLocal: true
      });

      this.confirmTaskLoad({
        title: lastEdited.title,
        description_delta: lastEdited.description,
        author: lastEdited.author,
        created: lastEdited.created,
        last_updated: lastEdited.lastUpdated,
        isOwner: lastEdited.owner,
        personal_tags: lastEdited.tags,
        getting_started_task: false,
        exposure: lastEdited.exposure,
        priority: lastEdited.priority,
        task_state: lastEdited.taskState,
        pwcode: lastEdited.exposurePasscode,
        author_id: lastEdited.author_id || '',
        author_tags: [],
        shared: lastEdited.subscriberEditing ? true : false,
        views: lastEdited.views,
        views_valid: lastEdited.viewsValid,
        subs_editing: lastEdited.subscriberEditing,
        files: lastEdited.files,
        subscribers: lastEdited.subscribers,
        source: lastEdited.source,
        space_info: lastEdited.spaceInfo,
        space_tags: lastEdited.spaceTags,
        space_tags_info: lastEdited.spaceTagsInfo
      });
    } else if (mounted) {
      if (canUseCached) {
        this.confirmTaskLoad(cached.latest);
      } else if (!checked) {
        if (this.infoFetched) {
          return;
        } else {
          this.infoFetched = true;
        }

        await ProfileAPI.PUBLIC.sendInspectTask(taskId, universalREF);
      }
    }
  };

  onTaskInfo = ({
    success = false,
    not_exists = false,
    requiredPW = false
  }) => {
    const { mounted, checked, taskID } = this.state;
    const { isLoggedIn } = this.props;

    if (!mounted || checked) return;
    else if (not_exists) {
      this.setState({
        invalid: true,
        checked: true
      });
    } else if (requiredPW) {
      this.setState(
        {
          requiredPW: true
        },
        () => {
          ModalAPI.setDOM(
            <TaskPassword
              taskID={this.state.taskID}
              universalREF={this.state.universalREF}
              confirm={this.confirmTaskLoad}
              isLoggedIn={!!isLoggedIn}
            />
          );
          ModalAPI.goActivate();
        }
      );
    } else if (success) {
      const respond = Object.assign(
        {},
        ProfileAPI.PUBLIC.VARS.inspectTaskMap[taskID]
      );

      if (respond && respond.title) {
        delete ProfileAPI.PUBLIC.VARS.inspectTaskMap[taskID];

        this.confirmTaskLoad(respond);
      } else {
        this.setState({
          invalid: true,
          checked: true
        });
      }
    } else {
      this.setState({
        invalid: true,
        checked: true
      });
    }
  };

  async onCopyLink(wPasscode = false, evt) {
    const { mounted, checked, exposure, pwcode } = this.state;

    if (!mounted || !checked) return;
    else {
      try {
        let valueSTR = '';

        if (exposure === k.USER_TASK_PRIVACY_PUBLIC_W_PW && wPasscode) {
          valueSTR = `${this.task_URL} \n\n Passcode: ${pwcode}`;
        } else {
          valueSTR = `${this.task_URL}`;
        }

        const copyRES = await AppAPI.copyTextToClipboard(valueSTR);

        if (copyRES && !copyRES.err) {
          this.setState({
            copiedLink: true
          });

          this.onTaskCopyLinkTimerSubscriber = timer(1500).subscribe(() => {
            if (!this.state.mounted) {
              return;
            } else {
              this.setState({
                copiedLink: false
              });

              if (this.onTaskCopyLinkTimerSubscriber) {
                this.onTaskCopyLinkTimerSubscriber.unsubscribe();
                this.onTaskCopyLinkTimerSubscriber = null;
              }
            }
          });
        }
      } catch (err) {
        if (err) {
          Logger.log(err.message);

          if (err.stack) {
            Logger.log(err.stack);
          }
        }
      }
    }
  }

  async onEditTask() {
    const {
      mounted,
      invalid,
      title,
      description_delta,
      exposure,
      priority,
      personal_tags,
      created,
      task_state,
      universalREF,
      taskID
    } = this.state;
    const { profileLoaded, isLoggedIn } = this.props;
    if (!mounted || !profileLoaded || !isLoggedIn) {
      return;
    } else {
      const globalTaskToEdit = {
        valid: true,
        title: '',
        desc: {},
        tags: [],
        exposure: 0,
        priority: 0,
        created: -1,
        task_state: ''
      };
      if (exposure <= 0 || priority <= 0) {
        return this.setState({
          invalid: true
        });
      }

      if (invalid) {
        globalTaskToEdit.valid = false;
      } else {
        globalTaskToEdit.priority = priority;
        globalTaskToEdit.exposure = exposure;
        globalTaskToEdit.title = title;
        globalTaskToEdit.tags = [...personal_tags];
        globalTaskToEdit.desc = description_delta;
        globalTaskToEdit.created = created;
        globalTaskToEdit.task_state = task_state;

        if (
          typeof description_delta !== 'object' ||
          typeof globalTaskToEdit.desc.ops === 'undefined'
        ) {
          globalTaskToEdit.desc = { ops: [] };
        }
      }

      await AppAPI.setGlobalConfig('taskToEditProps', globalTaskToEdit);
      // @todo use this as cached
      await this.setStateAsync({
        mounted: false,
        invalid: false
      });

      try {
        AppAPI.appendQueryURL('/user', {
          action: 'edit_task',
          id: taskID,
          t: universalREF
        });

        ModalAPI.goDeactivate();
        ModalAPI.setDOM(<CreateTask />, false, 'CreateTask');
        ModalAPI.goActivate();
      } catch {}
    }
  }

  confirmTaskLoad = (respond = {}) => {
    const {
      title,
      description_delta = '',
      author,
      created,
      isOwner = false,
      personal_tags = [],
      author_tags = [],
      getting_started_task = false,
      exposure,
      priority,
      task_state,
      last_updated,
      pwcode = '',
      author_id = '',
      shared = false,
      views = 0,
      views_valid = false,
      subs_editing = false,
      files = [],
      subscribers = [],
      space_info = {},
      space_tags = [],
      space_tags_info = {},
      read_only = false
    } = respond;

    try {
      const titleDOM = document.querySelector('title');
      const lastEdited = AppAPI.getGlobalConfig('lastEdited');
      const { invalid, checked } = this.state;

      if (titleDOM) AppAPI.setMetaTitle(`${title}`);

      if (!invalid && checked) {
        // this case it's just recently created

        this.setState({
          title,
          subscribers
        });
      } else {
        this.getting_started_task = getting_started_task;
        this.setState(
          {
            title,
            description_delta:
              description_delta.length > 0
                ? JSON.parse(description_delta)
                : { ops: [] },
            author,
            author_id: author_id,
            created,
            exposure,
            priority,
            files,
            subscribers,
            last_updated:
              typeof last_updated === 'number' && !!last_updated
                ? last_updated
                : -1,
            task_state,
            checked: true,
            isOwner: isOwner && !getting_started_task,
            isEditor: (isOwner || subs_editing) && !getting_started_task,
            isShared: shared,
            personal_tags:
              personal_tags.length > 0 && personal_tags[0] === 'none'
                ? []
                : [...personal_tags],
            author_tags: author_tags.length <= 0 ? [] : [...author_tags],
            pwcode: typeof pwcode === 'string' ? pwcode : '',
            views,
            readOnly: read_only,
            viewsValid: views_valid,
            ...(lastEdited &&
              lastEdited.noApiRequest && {
                canFetchCommentsAndActivities: true
              }),
            spaceInfo: space_info,
            spaceTags: space_tags,
            spaceTagsInfo: space_tags_info
          },
          async () => {
            const {
              description_delta,
              taskInfoFromLocal,
              canFetchCommentsAndActivities
            } = this.state;

            if (this.descriptionWrapREF && this.descriptionWrapREF.current) {
              TaskDescription.initQuill(
                this.descriptionWrapREF.current,
                description_delta
              );
              TaskDescription.cleanEditor(this.descriptionWrapREF.current);
              ModalAPI.goDeactivate();
            }

            /**
             * We fetch author profile ID info
             */
            await this.authorProfileID();
            await this.getWAccess();

            if (lastEdited) {
              lastEdited.valid = false;
              lastEdited.noApiRequest = false;
              lastEdited.description = '';
              lastEdited.spaceInfo = null;
              await AppAPI.setGlobalConfig('lastEdited', lastEdited);
            }

            if (!taskInfoFromLocal && !canFetchCommentsAndActivities) {
              await this.setStateAsync({
                canFetchCommentsAndActivities: true
              });
            }

            this.spaceSelectedCheck();
          }
        );
      }
    } catch (err) {
      this.setState({
        error: true,
        checked: true,
        invalid: false
      });

      Logger.log(`Failed in finalizing task info: ${err.message}`);
    }
  };

  onAuthorProfileIDInfo({
    valid = false,
    has_image = false,
    image = '',
    id = ''
  }) {
    const { mounted, invalid, author_id } = this.state;

    if (!mounted || invalid) {
      return;
    } else if (
      valid &&
      this.avatarImageREF &&
      this.avatarImageREF.current &&
      has_image &&
      typeof image === 'string' &&
      image.length > 0 &&
      author_id === id
    ) {
      const getting_started_task = this.getting_started_task;

      if (!getting_started_task) {
        const setState = this.setState.bind(this);

        this.avatarImageREF.current.onload = function () {
          setState({
            authorAvatar: true
          });
        };

        this.avatarImageREF.current.src = image;
      }
    }

    if (this.onIDInfoSubscriber) {
      this.onIDInfoSubscriber.unsubscribe();
      this.onIDInfoSubscriber = null;
    }
  }

  onUserInfoStatusChange = async params => {
    const { mounted } = this.state;

    if (mounted && !params?.fetching) {
      if (this.onUserInfoStatusSubscriber) {
        this.onUserInfoStatusSubscriber.unsubscribe();
      }

      await this.authorProfileID();
    }
  };

  authorProfileID = async () => {
    const { mounted, invalid, author_id = '', isOwner } = this.state;
    let userAuthorId = author_id;

    if (
      !mounted ||
      invalid ||
      ((typeof author_id !== 'string' || author_id.length <= 0) && !isOwner)
    ) {
      if (!author_id) {
        Logger.log(`Blank author id`);
      }
      return;
    } else if (isOwner) {
      const checkingInfo = ProfileAPI.USER_DATA.checkIsFetchingInfo();

      if (checkingInfo) {
        this.onUserInfoStatusSubscriber =
          ProfileAPI.USER_DATA.onProfileFetchingInfoStatus(
            this.onUserInfoStatusChange
          );
        return;
      }

      const user = ProfileAPI.USER_DATA.info();
      const { dp, profileID = '' } = user;
      const hasDP =
        typeof dp === 'string' && dp.length > 0 && dp.indexOf('data:') === 0;

      if (user) {
        const { firstName, lastName } = user;
        const displayName = `${firstName} ${lastName}`;
        await this.setStateAsync({
          displayName
        });
      }

      userAuthorId = profileID;
      await this.setStateAsync({
        author_id: user.profileID
      });

      if (user && hasDP) {
        this.onAuthorProfileIDInfo({
          valid: true,
          has_image: true,
          image: dp,
          id: profileID
        });

        return;
      }
    }

    Logger.log(`Fetching author ID info: ${author_id}`);

    if (userAuthorId && userAuthorId.length > 0) {
      this.onIDInfoSubscriber = ProfileAPI.USER_PROFILE.onIDInfo(
        this.onAuthorProfileIDInfo
      );
      await ProfileAPI.USER_PROFILE.IDInfo(userAuthorId);
    }
  };

  async getWAccess() {
    const {
      mounted,
      taskID,
      universalREF,
      invalid,
      checked,
      hasAccess,
      isOwner,
      isShared,
      subscribers: storedSubscribers
    } = this.state;
    const { profileLoaded, isLoggedIn } = this.props;
    const loaded = profileLoaded && checked;

    if (!mounted || !checked || invalid) {
      return;
    } else {
      if ((!isOwner && !isShared) || (loaded && !isLoggedIn)) {
        await this.setStateAsync({
          hasAccessFetching: false
        });

        return;
      }

      let subscribersProfileId = [];

      if (!this.state.mounted) {
        return;
      } else if (isArray(storedSubscribers) && storedSubscribers.length > 0) {
        subscribersProfileId = storedSubscribers;
      } else {
        subscribersProfileId = await ProfileAPI.USER_TASKS.getSubscribers(
          taskID,
          universalREF
        );
      }

      if (this.state.mounted) {
        if (isArray(subscribersProfileId)) {
          for (const profileId of subscribersProfileId) {
            if (
              profileId &&
              typeof profileId === 'string' &&
              profileId.length > 0
            ) {
              const wAccessSub = {
                id: profileId,
                loading: true,
                type: k.USER_TASK_ACCESS_SUBSCRIBER,
                has_image: false,
                image: '',
                initials: '',
                firstName: '',
                lastName: ''
              };

              hasAccess.push(wAccessSub);
            }
          }
        }

        await this.setStateAsync({
          hasAccessFetching: false,
          hasAccess
        });
      }
    }
  }

  modifyHasAccess(hasAccess = []) {
    const { mounted, invalid, checked } = this.state;

    if (!mounted || !checked || invalid) {
      return;
    }

    if (isArray(hasAccess)) {
      this.setState({
        hasAccess
      });
    }
  }

  toggleTaskMore() {
    const { mounted, taskMore, copiedLink } = this.state;

    if (!mounted) {
      return;
    }

    this.setState({
      taskMore: !taskMore,
      copiedLink: copiedLink && !taskMore ? false : copiedLink
    });
  }

  onUpdateState = async () => {
    const {
      task_state: taskState,
      taskID: taskId,
      universalREF: uRef,
      updatingState
    } = this.state;
    const activeToInactive =
      `${taskState}`.toLowerCase() === k.TASK_STATE_ACTIVE;
    const newState = activeToInactive
      ? k.TASK_STATE_INACTIVE
      : k.TASK_STATE_ACTIVE;

    if (updatingState) {
      return;
    }

    try {
      await this.setStateAsync({
        updatingState: true
      });
      const res = await ProfileAPI.USER_TASKS.updateState(
        newState,
        taskId,
        uRef
      );

      if (res && res.success) {
        // update
        await this.setStateAsync({
          task_state: newState
        });
      }

      await this.setStateAsync({
        updatingState: false
      });
    } catch (err) {
      Logger.log(`onUpdateState error: ${err && err.message}`);
    }
  };

  render() {
    const {
      comment,
      copiedLink,
      invalid,
      checked,
      error,
      title,
      author_id,
      author,
      authorAvatar,
      created,
      actions,
      isOwner,
      isEditor,
      isShared,
      priority,
      exposure,
      personal_tags,
      redirectEdit,
      taskID,
      task_state,
      taskMore,
      last_updated,
      universalREF,
      pwcode = '',
      hasAccess,
      hasAccessFetching,
      views,
      viewsValid,
      taskInfoFromLocal,
      canFetchCommentsAndActivities,
      updatingState,
      files,
      spaceInfo,
      spaceTags,
      spaceTagsInfo,
      readOnly,
      displayName
    } = this.state;

    const { profileLoaded, isLoggedIn } = this.props;
    const loaded = profileLoaded && checked;
    const authorDisplayName = !isOwner ? author : displayName;
    const showWithAccess =
      loaded && !invalid && !hasAccessFetching && (isShared || isOwner);
    let createdSTR = '';
    let lastUpdatedSTR = '';

    if (invalid) {
      return (
        <Redirect
          to={`/not-found${
            !isLoggedIn
              ? `?taskViewRedirect=${taskID}-ref-${universalREF}`
              : `?prevPath=taskView${universalREF}`
          }`}
        />
      );
    } else if (redirectEdit) {
      const _path = !invalid
        ? `/user/edit/${taskID}?t=${universalREF}`
        : '/user';
      return <Redirect to={_path} />;
    } else {
      const utcDate = new Date(Math.floor(created * 1000));
      createdSTR = `${
        k.MONTHS_INDEX[utcDate.getMonth()].abv
      } ${utcDate.getDate()}, ${utcDate.getFullYear()}`;
    }

    if (last_updated > 1) {
      const utcDate = new Date(Math.floor(last_updated * 1000));
      lastUpdatedSTR = `${
        k.MONTHS_INDEX[utcDate.getMonth()].abv
      } ${utcDate.getDate()}, ${utcDate.getFullYear()}`;
    }

    const noTags =
      (!personal_tags || !personal_tags.length) &&
      (!spaceTags || !spaceTags.length);
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div
        className={cx(styles.task_view_wrap, {
          [styles.task_view_wrap_dark]: isThemeDarkMode
        })}
        ref={this.taskViewWrapRef}
      >
        <Header
          toggleTaskMore={this.toggleTaskMore}
          onCopyLink={this.onCopyLink}
          onUpdateState={this.onUpdateState}
          updatingState={updatingState}
          exposure={exposure}
          loaded={loaded}
          copiedLink={copiedLink}
          taskMore={taskMore}
          isLoggedIn={isLoggedIn}
          isOwner={isOwner}
          isEditor={isEditor}
          isShared={isShared}
          taskState={task_state}
          profileLoaded={profileLoaded}
          spaceInfo={spaceInfo}
          readOnly={readOnly}
        />
        <div className={styles.task_view_wrap_raw}>
          <div
            className={cx(styles.flex_row_x, styles.content, {
              [styles.hide_element]: error || invalid
            })}
          >
            <div className={cx(styles.first, styles.flex_column_x)}>
              <div
                className={cx(styles.status, {
                  [styles.hide_element]: !loaded
                })}
              >
                <div className={styles.status_priority}>
                  <PriorityIcon
                    priority_key={
                      k.TASK_PRIORITIES[priority - 1] &&
                      k.TASK_PRIORITIES[priority - 1].key
                    }
                  />
                </div>
                <div
                  className={cx(styles.status_actual, {
                    [styles.status_actual_dark]: isThemeDarkMode
                  })}
                >
                  <h5>
                    {i18n(
                      k.TASKS_STATES_OBJ[`${task_state}`]
                        ? `${k.TASKS_STATES_OBJ[`${task_state}`].i18n}`
                        : ''
                    )}
                  </h5>
                </div>
                <div
                  className={cx(styles.status_relation_access, {
                    [styles.status_relation_access_dark]: isThemeDarkMode,
                    [styles.hide_element]: !isShared
                  })}
                >
                  <h5>{i18n('task_view_is_subscribed')}</h5>
                </div>
                <div
                  className={cx(styles.status_views, {
                    [styles.hide_element]: !viewsValid
                  })}
                >
                  <h5>{`${views} ${
                    views !== 1
                      ? i18n('common_views_title')
                      : i18n('common_view_title')
                  }`}</h5>
                </div>
              </div>
              <div className={cx(styles.flex_row_y, styles.title)}>
                <div
                  className={cx(styles.title_actual, {
                    [styles.hide_element]: !loaded
                  })}
                >
                  <h2>{`${title}`}</h2>
                  <div
                    className={cx(styles.last_updated, {
                      [styles.last_updated_dark]: isThemeDarkMode
                    })}
                  >
                    {Markdown('task_view_last_updated', {
                      date1: lastUpdatedSTR || createdSTR
                    })}
                  </div>
                  {loaded && !invalid && (
                    <Exposure
                      className={styles.exposure_info}
                      pwcode={pwcode}
                      exposure={exposure}
                      isShared={isShared}
                      isOwner={isOwner || isEditor}
                    />
                  )}
                </div>
                <div
                  className={cx(styles.title_placeholder, {
                    [styles.hide_element]: loaded,
                    [styles.title_placeholder_dark]: isThemeDarkMode
                  })}
                >
                  <div></div>
                </div>
              </div>
              <div
                className={cx(styles.flex_row_y, styles.author, {
                  [styles.author_dark]: isThemeDarkMode
                })}
              >
                <div
                  className={cx(styles.flex_row_y, styles.author_actual, {
                    [styles.hide_element]: !loaded
                  })}
                >
                  <div className={cx(styles.flex_row_xy, styles.author_avatar)}>
                    <h3
                      className={cx({ [styles.hide_element]: authorAvatar })}
                    >{`${authorDisplayName.charAt(0)}`}</h3>
                    <img
                      alt={'Author avatar'}
                      ref={this.avatarImageREF}
                      className={cx(styles.author_avatar_img, {
                        [styles.author_avatar_img_hide]: !authorAvatar,
                        [styles.author_avatar_img_show]: authorAvatar
                      })}
                    />
                  </div>
                  <p>{`${authorDisplayName}`}</p>
                </div>
                <div
                  className={cx(styles.author_placeholder, {
                    [styles.hide_element]: loaded,
                    [styles.author_placeholder_dark]: isThemeDarkMode
                  })}
                ></div>
                {showWithAccess && (
                  <WithAccess
                    taskID={taskID}
                    universalREF={universalREF}
                    hasAccess={hasAccess}
                    hasAccessFetching={hasAccessFetching}
                    modifyHasAccess={this.modifyHasAccess}
                  />
                )}
              </div>
              <div
                className={cx(styles.task_tags, {
                  [styles.hide_element]:
                    !(profileLoaded && isLoggedIn) || !loaded || noTags
                })}
              >
                <div className={styles.list}>
                  <ul
                    className={cx({
                      [styles.hide_element]: noTags
                    })}
                  >
                    {personal_tags
                      .filter(tag => tag)
                      .map(tag => {
                        return (
                          <li key={`task-view-tag${tag}`}>
                            <CustomBadge
                              type={'personal tag'}
                              name={`${tag}`.split('_').join(' ')}
                            />
                          </li>
                        );
                      })}
                    {spaceTags
                      .filter(
                        tagId => tagId && spaceTagsInfo && spaceTagsInfo[tagId]
                      )
                      .map(tagId => {
                        const tagInfo = spaceTagsInfo[tagId];

                        return (
                          <li key={`task-view-tag${tagId}`}>
                            <CustomBadge
                              type={'personal tag'}
                              name={`${tagInfo.tag}${
                                spaceInfo ? ` (${spaceInfo.name})` : ''
                              }`}
                            />
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              {taskID &&
                universalREF &&
                profileLoaded &&
                isLoggedIn &&
                loaded && <Reminders taskId={taskID} uRef={universalREF} />}
              {
                /**
                 * Description here below
                 */
                <Fragment />
              }
              <div
                className={cx(styles.description_wrap_placeholder, {
                  [styles.hide_element]: loaded,
                  [styles.description_wrap_placeholder_dark]: isThemeDarkMode
                })}
              >
                <ul>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <div
                ref={this.descriptionWrapREF}
                className={styles.description_wrap}
              >
                <div
                  id={'cha-task-view-des-editor'}
                  className={cx(styles.description_input, {
                    [styles.description_input_dark]: isThemeDarkMode
                  })}
                ></div>
              </div>
            </div>
            <div
              className={cx(styles.second, {
                [styles.hide_element]: !actions,
                [styles.second_has_actions]: actions
              })}
            >
              <Actions
                personal_tags={personal_tags}
                toggleTaskMore={this.toggleTaskMore}
                modifyHasAccess={this.modifyHasAccess}
                loaded={loaded}
                copiedLink={copiedLink}
                taskMore={taskMore}
                pwcode={pwcode}
                isLoggedIn={isLoggedIn}
                isOwner={isOwner}
                isEditor={isEditor}
                isShared={isShared}
                invalid={invalid}
                exposure={exposure}
                profileLoaded={profileLoaded}
                universalREF={universalREF}
                taskID={taskID}
                hasAccess={hasAccess}
                hasAccessFetching={hasAccessFetching}
              />
            </div>
          </div>

          {loaded && !invalid && files && !this.getting_started_task ? (
            <FilesPreview files={files} />
          ) : (
            <></>
          )}
          {/**
           * Containing comments and activities for the task
           */}
          {loaded &&
            canFetchCommentsAndActivities &&
            !invalid &&
            !this.getting_started_task &&
            !readOnly && (
              <CommentsAndActivities
                taskInfoFromLocal={taskInfoFromLocal}
                loaded={loaded && !invalid}
                taskId={taskID}
                universalREF={universalREF}
                isLoggedIn={isLoggedIn}
                authorProfileId={author_id}
                hasAccess={hasAccess}
                showWithAccess={showWithAccess}
                files={files}
                comment={comment}
                updateCommentMode={this.updateCommentMode}
                blockCommentWrite={this.blockCommentWrite}
                blockCommentView={this.blockCommentView}
                allowCommentWrite={this.allowCommentWrite}
              />
            )}
          <div
            className={cx(styles.flex_row_xy, styles.invalid_view, {
              [styles.hide_element]: error || !invalid
            })}
          >
            <div className={cx(styles.flex_row_xy, styles.msg)}>
              <div className={styles.ic}>
                <span></span>
              </div>
              <h4>{i18n('task_view_invalid_page')}</h4>
            </div>
          </div>
          <div
            className={cx(styles.something_wrong, styles.flex_row_xy, {
              [styles.hide_element]: !error || invalid
            })}
          >
            <div className={cx(styles.flex_row_xy, styles.msg)}>
              <div className={styles.ic}>
                <span></span>
              </div>
              <h4>{i18n('task_view_went_wrong')}</h4>
            </div>
          </div>
        </div>
        {loaded && !invalid && spaceInfo ? (
          <Footer readOnly={readOnly} />
        ) : (
          <></>
        )}
        {loaded &&
          isMobileView() &&
          isLoggedIn &&
          !readOnly &&
          canFetchCommentsAndActivities &&
          !invalid &&
          !this.getting_started_task && (
            <CommentMobile
              uRef={universalREF}
              taskId={taskID}
              comment={comment}
              updateCommentMode={this.updateCommentMode}
            />
          )}
      </div>
    );
  }
}

export default withUserProfileSettings(withRouter(TaskView));
