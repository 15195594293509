import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './Subscribers.scss';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import UserAvatar from 'src/components/user-avatar';
import Logger from 'src/lib/Logger';
import k from 'src/constants/k';
import {
  isArray,
  isEmpty,
  isFunction,
  isMobileView,
  last,
  filter
} from 'src/helpers/utils';
import { fromEvent, Subject } from 'rxjs';
import { isCorrectEmailFormat } from 'src/lib/UserInputs';
import { debounceTime } from 'rxjs/operators';
import { CrossIcon, IconButton, Spinner } from 'evergreen-ui';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'src/lib/bodyScrollLock.min';

class Subscribers extends Component {
  inputRef = null;
  documentClickSubscriber = null;
  searchOBSSubscriber = null;
  onSavedUsersResultsSubscriber = null;
  windowBlurSubscriber = null;
  onSpacesListSubscriber = null;
  onIDInfoSubscriber = null;
  blockUpdateSave = false;
  currentPage = 1;
  cached = {
    // id: meta
  };
  searchQueue = [];
  listRef = null;

  state = {
    fetchedSubscribersInfo: 0,
    mounted: false,
    subscriberInput: '',
    subs: [
      /*  {
        id: 'asdasdasd',
        loading: true,
        initials: '',
        firstName: '',
        lastName: '',
        has_image: false,
        image: '',
        type: ''
      }*/
    ],
    savedUsers: [],
    spaceMembers: [],
    savingUser: false,
    searching: false,
    results: [], // for search results
    focused: false,
    loading: true
  };

  constructor() {
    super();

    this.listRef = createRef();
    this.inputRef = createRef();
    this.searchOBS = new Subject();
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    const { hasAccess } = this.props;

    this.setState(
      {
        mounted: true,
        ...(isArray(hasAccess) && { subs: [...hasAccess] })
      },
      this.onMount
    );

    this.onSpacesListSubscriber = ProfileAPI.SPACES.onSpacesList(
      this.onSpaceMembersList
    );
  }

  componentWillUnmount() {
    this.setState({
      mounted: false
    });

    this.clearCache();

    if (this.listRef?.current && isMobileView()) {
      enableBodyScroll(this.listRef.current);
    }

    if (this.onSpacesListSubscriber) {
      this.onSpacesListSubscriber.unsubscribe();
    }

    if (this.documentClickSubscriber) {
      this.documentClickSubscriber.unsubscribe();
    }

    if (this.searchOBSSubscriber) {
      this.searchOBSSubscriber.unsubscribe();
    }

    if (this.onIDInfoSubscriber) {
      this.onIDInfoSubscriber.unsubscribe();
    }

    if (this.onSavedUsersResultsSubscriber) {
      this.onSavedUsersResultsSubscriber.unsubscribe();
    }

    if (this.windowBlurSubscriber) {
      this.windowBlurSubscriber.unsubscribe();
    }
  }

  componentDidUpdate(prevProps) {
    const { subs, mounted } = this.state;
    const { loaded, spaceId, hasAccess } = this.props;

    try {
      if (
        (!prevProps.loaded && loaded) ||
        (isArray(subs) && !isEmpty(subs.length) && loaded)
      ) {
        const find = subs.filter(
          subscriber => subscriber && !subscriber.firstName
        );

        if (find && !isEmpty(find)) {
          this.fetchSubscribersInfo();
        }
      }
    } catch {}

    try {
      if (
        prevProps &&
        ((spaceId && prevProps.spaceId !== spaceId) ||
          (!spaceId && prevProps.spaceId))
      ) {
        this.onSpaceMembersList();
      }
    } catch {}

    try {
      if (prevProps.loaded && loaded && mounted && isArray(hasAccess)) {
        const subscriberIds = filter(
          subs.map(
            subscriber =>
              subscriber && (subscriber?.id || subscriber?.profile_id)
          ),
          id => !isEmpty(id)
        );
        let hasChange = false;

        if (this.blockUpdateSave) {
          return;
        }

        if (isArray(subs) && hasAccess.length < subs.length) {
          this.setState({
            subs: hasAccess
          });
          return;
        }

        for (let i = 0; i < hasAccess.length; i++) {
          const user = hasAccess[i];
          const currentUserId = user?.id || user?.profile_id;

          if (
            !isEmpty(currentUserId) &&
            !subscriberIds.includes(currentUserId)
          ) {
            const cachedUser =
              this.cached[currentUserId] ||
              ProfileAPI.USER_PROFILE.getUserCache(currentUserId);

            if (cachedUser) {
              subs.push({
                ...cachedUser,
                id: currentUserId,
                type: k.USER_TASK_ACCESS_SUBSCRIBER
              });
              hasChange = true;
            }
          }
        }

        if (hasChange) {
          this.setState({
            subs
          });
        }
      }
    } catch {}
  }

  onMount = async () => {
    this.windowBlurSubscriber = fromEvent(window, 'blur').subscribe(
      this.onWindowBlur
    );

    this.onIDInfoSubscriber = ProfileAPI.USER_PROFILE.onIDInfo(this.onIdInfo);

    this.documentClickSubscriber = fromEvent(document, 'click').subscribe(
      this.onDocumentClick
    );

    this.searchOBSSubscriber = this.searchOBS
      .pipe(debounceTime(200))
      .subscribe(this.search);

    if (ProfileAPI.USER_TASKS.checkSavedUsersPage(1) > -1) {
      await this.checkSavedUsers({ fetching: false });
    }

    this.onSavedUsersResultsSubscriber =
      ProfileAPI.USER_TASKS.onSaveUsersResults(this.checkSavedUsers);

    this.fetchSubscribersInfo();
    this.onSpaceMembersList();
  };

  onSpaceMembersList = async () => {
    const { spaceId, updateConnectedUsers } = this.props;
    const {
      mounted,
      savedUsers,
      spaceMembers: currentSpaceMembers
    } = this.state;
    const members = [];
    const off = [];

    try {
      if (mounted) {
        if (spaceId) {
          const savedUserIds = savedUsers
            .filter(user => user?.id || user?.profile_id)
            .map(user => user?.id || user?.profile_id);
          const { space } = ProfileAPI.SPACES.getSpaceById(spaceId) || {};
          const spaceMembers = space?.members;
          const noMembers =
            !space ||
            !space.members ||
            typeof space.members !== 'object' ||
            !space.membersTotal;
          const currentUserId = ProfileAPI.USER_DATA.info().profileID;

          if (!noMembers) {
            for (const page in spaceMembers) {
              const currentPageMembers = spaceMembers[`${page}`];

              if (currentPageMembers && currentPageMembers.map) {
                for (const member of currentPageMembers) {
                  const memberId = member.profile_id;

                  if (
                    member &&
                    memberId !== currentUserId &&
                    !savedUserIds.includes(memberId)
                  ) {
                    members.push({ ...member, id: memberId, spaceId }); // new instance for us to populate
                  }
                }
              }
            }
          }

          await this.setStateAsync({
            spaceMembers: members
          });

          if (isFunction(updateConnectedUsers)) {
            updateConnectedUsers([...members, ...savedUsers]);
          }

          for (const member of members) {
            const userId = member?.profile_id;
            if (!isEmpty(userId) && userId !== currentUserId) {
              off.push(ProfileAPI.USER_PROFILE.IDInfo(userId));
            }
          }
        } else {
          if (currentSpaceMembers?.length) {
            for (const member of currentSpaceMembers) {
              const userId = member?.id || member?.profile_id;
              if (userId) {
                this.remove(userId);
              }
            }
          }

          this.setState({
            spaceMembers: []
          });

          if (isFunction(updateConnectedUsers)) {
            updateConnectedUsers(savedUsers);
          }
        }

        try {
          if (off?.length) {
            await Promise.all(off);
          }
        } catch {}
      }
    } catch {}
  };

  fetchSubscribersInfo = async () => {
    const { loaded } = this.props;
    const { mounted, subs, fetchedSubscribersInfo } = this.state;

    if (mounted && loaded && fetchedSubscribersInfo < 2) {
      // fetch initial info of subscribers
      const getInfoArrAsync = [];
      for (const subscriber of subs) {
        if (subscriber && (subscriber.id || subscriber.profile_id)) {
          try {
            const id = subscriber.id || subscriber.profile_id;
            if (id) {
              getInfoArrAsync.push(
                ProfileAPI.USER_PROFILE.IDInfo(
                  subscriber.id || subscriber.profile_id
                )
              );
            }
          } catch {}
        }
      }

      if (getInfoArrAsync && getInfoArrAsync.length > 0) {
        Logger.log(`Fetching info for subscribers users list `);
        await Promise.all(getInfoArrAsync);
        await this.setStateAsync({
          fetchedSubscribersInfo: fetchedSubscribersInfo + 1
        });
      }
    }
  };

  cacheUser = (user = {}) => {
    if (user && (user.id || user.profile_id)) {
      if (!user.id) {
        user.id = user.profile_id;
      }

      if (
        !this.cached[user.id] ||
        !this.cached[user.id].firstName ||
        !this.cached[user.id].lastName ||
        this.cached[user.id].image !== user.image
      ) {
        this.cached[user.id] = user;
      }
    }
  };

  clearCache() {
    delete this.cached;
  }

  onWindowBlur = () => {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    } else if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.blur();

      this.onInputBlur();
    }
  };

  checkSavedUsers = async params => {
    if (!params) {
      return;
    }

    const { updateConnectedUsers } = this.props;
    const { mounted, loading, searching, spaceMembers } = this.state;
    const fetching = Boolean(params.fetching);

    if (!mounted) {
      return;
    }

    if (loading !== fetching && !searching) {
      await this.setStateAsync({
        loading: fetching
      });
    }

    if (!fetching) {
      const maxPage = ProfileAPI.USER_TASKS.getSaveUsersMaxPage();
      const savedUsers = ProfileAPI.USER_TASKS.getSavedUsers(1);
      // const toFetchInfo = maxPage === this.currentPage;

      if (maxPage > 1) {
        this.currentPage += 1;
        const currentPage = this.currentPage;

        Logger.log(`Fetching saved users page: ${currentPage}`);
        await ProfileAPI.USER_TASKS.savedUsers(currentPage);
      }

      if (savedUsers && savedUsers.length) {
        for (const user of savedUsers) {
          if (user?.firstName || user?.lastName) {
            if (!user.id && user.profile_id) {
              user.id = user.profile_id;
            }

            if (!user.has_image) {
              user.has_image = false;
              user.image = '';
            }

            this.cacheUser(user);
          }
        }

        await this.setStateAsync({ savedUsers });

        if (isFunction(updateConnectedUsers)) {
          updateConnectedUsers([...spaceMembers, ...savedUsers]);
        }
      }
    }
  };

  onIdInfo = async params => {
    if (!params || !this.state.mounted) {
      return;
    }

    const {
      id: profileID,
      valid = false,
      has_image = false,
      image = '',
      firstName = '',
      lastName = '',
      email = ''
    } = params;
    const { spaceId, updateConnectedUsers } = this.props;
    const { mounted, spaceMembers, searching, results, savedUsers } =
      this.state;

    if (!mounted) {
      return;
    }

    const user = {
      has_image,
      image,
      id: profileID,
      profile_id: profileID,
      firstName,
      lastName,
      loading: false,
      initials: `${firstName.charAt(0)}${lastName.charAt(0)}`.toLowerCase(),
      type: k.USER_TASK_ACCESS_SUBSCRIBER,
      email: email || ''
    };

    if (mounted && valid && !searching && spaceId) {
      let hasChange = false;
      for (let i = 0; i < spaceMembers.length; i++) {
        const member = spaceMembers[i];
        const userId = member?.profile_id;

        if (profileID === userId) {
          spaceMembers[i] = {
            ...member,
            ...user
          };
          hasChange = true;
          break;
        }
      }

      if (hasChange) {
        await this.setStateAsync({
          spaceMembers
        });

        if (isFunction(updateConnectedUsers)) {
          updateConnectedUsers([...savedUsers, ...spaceMembers]);
        }
      }
    }

    if (mounted && valid) {
      // add

      if (searching) {
        // save user

        const inResults = results.filter(
          res => res && (res.id === profileID || res.profile_id === profileID)
        );
        const alreadySaved = this.alreadySaved(profileID);

        if (!inResults || inResults.length < 1) {
          results.push(user);
        }

        this.cacheUser(user);

        await this.setStateAsync({
          results,
          savingUser: !!alreadySaved
        });

        if (!alreadySaved) {
          Logger.log(`Saving user: ${profileID}`);

          savedUsers.push(user);
          await this.setStateAsync({
            savedUsers
          });
          await ProfileAPI.USER_TASKS.saveUser(profileID);
        }

        if (savedUsers.length < 2) {
          await ProfileAPI.USER_TASKS.savedUsers(1);
        }

        await this.setStateAsync({ searching: false });
      } else {
        Logger.log(`Saving user info: ${profileID}`);
        this.saveInfo(user);
      }
    } else if (mounted && searching) {
      await this.setStateAsync({
        searching: false,
        results: []
      });
    }

    if (searching) {
      this.checkForSearchQueue();
    }
  };

  checkForSearchQueue = async () => {
    if (this.searchQueue.length > 0) {
      await this.setStateAsync({
        searching: false,
        subscriberInput: last(this.searchQueue)
      });
      this.search();
      this.searchQueue = [];
    }
  };

  saveInfo(user = {}) {
    const { subs, mounted, savedUsers } = this.state;
    let hasChange = false;

    if (!user && !mounted) {
      return;
    }

    if (user && !user.id && user.profile_id) {
      user['id'] = user.profile_id;
    }

    for (let i = 0; i < subs.length; i++) {
      const subscriber = subs[i];

      if (
        subscriber &&
        (subscriber.id === user.id ||
          subscriber.profile_id === user.profile_id) &&
        (!subscriber.firstName || !subscriber.lastName)
      ) {
        subscriber.image = user.image;
        subscriber.has_image = user.has_image;
        subscriber.firstName = user.firstName;
        subscriber.lastName = user.lastName;
        subscriber.loading = false;
        subscriber.initials = user.initials;

        hasChange = true;

        break;
      }
    }

    for (let i = 0; i < savedUsers.length; i++) {
      const savedUser = savedUsers[i];

      if (
        savedUser &&
        (user.id === savedUser.id || user.profile_id === savedUser.profile_id)
      ) {
        savedUser.image = user.image;
        savedUser.image = user.image;
        savedUser.has_image = user.has_image;
        savedUser.firstName = user.firstName;
        savedUser.lastName = user.lastName;
        savedUser.loading = false;
        savedUser.initials = user.initials;

        hasChange = true;

        break;
      }
    }

    if (hasChange) {
      this.setState({
        subs,
        savedUsers
      });

      this.cacheUser(user);
    }
  }

  onSubscriberInput = evt => {
    if (!evt) {
      return;
    }

    const target = evt.target
      ? evt.target
      : evt.srcElement
      ? evt.srcElement
      : null;

    if (target) {
      const { results = [] } = this.state;
      const value = `${
        target.value === ProfileAPI.USER_DATA.info().profileID
          ? ''
          : target.value
      }`;

      if (!value) {
        results.length = 0;
      }

      this.setState(
        {
          subscriberInput: `${value}`,
          results,
          focused: true
        },
        () => {
          this.searchOBS.next();
          this.toggleDisableBodyScroll();
        }
      );
    }
  };

  toggleDisableBodyScroll = () => {
    const { focused } = this.state;

    if (!isMobileView()) {
      return;
    }

    if (this.listRef?.current) {
      if (focused) {
        disableBodyScroll(this.listRef.current);
      } else {
        enableBodyScroll(this.listRef.current);
      }
    }
  };

  onInputFocus = () => {
    const { mounted } = this.state;

    if (mounted) {
      this.setState(
        {
          focused: true
        },
        this.toggleDisableBodyScroll
      );
    }
  };

  onInputBlur = () => {
    const { mounted } = this.state;

    if (mounted) {
      this.setState(
        {
          focused: false
        },
        this.toggleDisableBodyScroll
      );
    }
  };

  onDocumentClick = evt => {
    const { mounted, subs } = this.state;
    const { modify, spaceId } = this.props;

    if (!mounted || !evt) {
      return;
    }

    if (evt && mounted) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (target) {
        if (
          target.classList.length &&
          (target.classList.contains('add-as-subscriber') ||
            target.classList.contains(styles.subs_input_raw))
        ) {
          const value = `${target.classList[target.classList.length - 1]}`;

          if (!isEmpty(value)) {
            if (!this.alreadySubscriber(value)) {
              // save as sub
              const userId = value;
              const user =
                this.cached[userId] ||
                ProfileAPI.USER_PROFILE.getUserCache(userId);

              if (user) {
                user.type = k.USER_TASK_ACCESS_SUBSCRIBER;
                subs.push(user);

                this.blockUpdateSave = true;
                this.setState(
                  {
                    subscriberInput: '',
                    subs
                  },
                  () => {
                    if (isFunction(modify)) {
                      modify(subs);
                    }

                    this.onInputBlur();
                    this.blockUpdateSave = false;
                  }
                );
              }
            } else {
              this.onInputBlur();
            }
          }

          return;
        } else if (
          target.classList &&
          target.classList.contains(styles.remove_selected) &&
          target.classList.length
        ) {
          // remove a selected tag
          const id = `${target.classList[target.classList.length - 1]}`;
          const user =
            this.cached[id] ||
            (spaceId ? ProfileAPI.USER_PROFILE.getUserCache(id) : null);

          if (id && user) {
            this.remove(id);
            return;
          }
        }
      }
    }

    this.onInputBlur();
  };

  remove = (id = '') => {
    const { modify } = this.props;
    const { subs } = this.state;
    const user = this.cached[id] || ProfileAPI.USER_PROFILE.getUserCache(id);

    if (id && user) {
      for (let i = 0; i < subs.length; i++) {
        const subscriber = subs[i];
        if (
          subscriber &&
          (subscriber.profile_id === id || subscriber.id === id)
        ) {
          // remove from list

          try {
            subs.splice(i, 1);
            this.blockUpdateSave = true;
            this.setState(
              {
                subs
              },
              () => {
                this.blockUpdateSave = false;
              }
            );
            if (isFunction(modify)) {
              modify(subs);
            }
          } catch {}
          break;
        }
      }
    }
  };

  search = async () => {
    // search for saved subs
    // stagingroes2 ChXzPkEK609otKjWuvlglttr8umn1
    const {
      subscriberInput: sensitiveInput,
      savedUsers,
      subs,
      searching
    } = this.state;
    const subscriberInput = `${sensitiveInput}`.toLowerCase();
    const results = [];
    const resultsId = [];

    if (searching) {
      this.searchQueue.push(sensitiveInput);
      return;
    }

    if (savedUsers.length) {
      // search match

      for (let s = 0; s < savedUsers.length; s++) {
        const user = savedUsers[s];

        if (user) {
          const str = `${user.id || user.profile_id} ${user.firstName} ${
            user.lastName
          } ${user.email}`.toLowerCase();

          if (str.includes(subscriberInput)) {
            results.push(user);
            resultsId.push(user.id || user.profile_id);
          }
        }
      }
    }

    if (subs.length) {
      // search match

      for (let s = 0; s < subs.length; s++) {
        const user = savedUsers[s];

        if (user) {
          const str = `${user.id || user.profile_id} ${user.firstName} ${
            user.lastName
          } ${user.email || ''}`.toLowerCase();

          if (
            str.includes(subscriberInput) &&
            !resultsId.includes(user.id || user.profile_id)
          ) {
            results.push(user);
            resultsId.push(user.id || user.profile_id);
          }
        }
      }
    }

    if (subscriberInput === ProfileAPI.USER_DATA.info().profileID) {
      this.setState({
        subscriberInput: ''
      });
    } else if (isCorrectEmailFormat(subscriberInput)) {
      // get email info
      const email = subscriberInput;
      const user =
        this.alreadySaved(email) || this.alreadySubscriber(email, 'email');

      if (!user) {
        Logger.log(`Searching email: ${email}`);
        await this.setStateAsync({ searching: true });
        const res = await ProfileAPI.USER_PROFILE.getEmailInfo(email);

        if (!this.state.mounted) {
          return;
        } else if (
          res &&
          res.valid &&
          res.user &&
          (res.user.profile_id || res.user.id)
        ) {
          await this.onIdInfo({
            ...res.user,
            valid: true,
            email,
            id: res.user.profile_id || res.user.id
          });
          return;
        } else {
          this.checkForSearchQueue();
        }
      } else {
        await this.setStateAsync({ searching: false });
        if (!resultsId.includes(user.id || user.profile_id)) {
          results.push(user);
        }
      }
    } else if (ProfileAPI.userIdIsValid(subscriberInput)) {
      // locate from already subs first
      // locate from saved users first
      // if not then send API request
      const id = subscriberInput;
      const user = this.alreadySaved(id) || this.alreadySubscriber(id);

      if (user) {
        if (!resultsId.includes(user.id || user.profile_id)) {
          results.push(user);
        }
      } else {
        // do search
        await this.setStateAsync({ searching: true, results });
        await ProfileAPI.USER_PROFILE.IDInfo(id);
        return;
      }
    }

    await this.setStateAsync({
      results,
      searching: false
    });
  };

  alreadySubscriber = (id = '', type = 'id') => {
    const { subs } = this.state;

    for (let i = 0; i < subs.length; i++) {
      const subscriber = subs[i];

      if (
        subscriber &&
        (subscriber.id === id || subscriber.profile_id === id) &&
        type === 'id'
      ) {
        if (!subscriber.id) {
          subscriber.id = subscriber.profile_id;
        }

        return subscriber;
      } else if (
        subscriber &&
        subscriber.email &&
        subscriber.email === id &&
        type === 'email'
      ) {
        if (!subscriber.id) {
          subscriber.id = subscriber.profile_id;
        }

        return subscriber;
      }
    }

    return false;
  };

  alreadySaved = (id = '') => {
    const { savedUsers } = this.state;

    for (let i = 0; i < savedUsers.length; i++) {
      const user = savedUsers[i];

      if (
        user &&
        (user.id === id || user.profile_id === id || user.email === id)
      ) {
        if (!user.id) {
          user.id = user.profile_id;
        }

        return user;
      }
    }

    return false;
  };

  alreadyInResults(id = '') {
    const { results } = this.state;

    for (let i = 0; i < results.length; i++) {
      const user = results[i];

      if (user && (user.id === id || user.profile_id === id)) {
        return true;
      }
    }

    return false;
  }

  unsaveUserFromList = async (profileId = '') => {
    if (!profileId) {
      return;
    }
    const { updateConnectedUsers } = this.props;
    const { mounted, spaceMembers } = this.state;
    const res = await ProfileAPI.USER_TASKS.unsaveUser(profileId);

    if (res && !res.err && mounted) {
      const { savedUsers } = this.state;
      const newSavedUsers = savedUsers.filter(
        user => user.id !== profileId && user.profile_id !== profileId
      );

      this.setState({
        savedUsers: newSavedUsers
      });

      if (isFunction(updateConnectedUsers)) {
        updateConnectedUsers([...spaceMembers, ...newSavedUsers]);
      }
    }
  };

  render() {
    const {
      subscriberInput,
      focused,
      subs,
      spaceMembers,
      savedUsers,
      results,
      searching,
      loading
    } = this.state;
    const saveUsersWithMembers = [...spaceMembers, ...savedUsers];
    const searchMode = subscriberInput.length > 0;
    const emptySearch = searchMode && results.length < 1;
    const emptySavedList = !searchMode && saveUsersWithMembers.length < 1;
    const maxPreview = 5;
    const preview = subs.slice(0, maxPreview);
    const hasPreview = preview.length > 0 && subs.length >= maxPreview;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div className={styles.task_subs}>
        <div
          className={cx(styles.title, { [styles.title_dark]: isThemeDarkMode })}
        >
          <p>{i18n('user_create_task_subscribers_title')}</p>
        </div>
        <div
          className={cx(styles.subs_preview, {
            [styles.hide_element]: !hasPreview
          })}
        >
          <ul>
            {preview
              .filter(user => user?.firstName)
              .map((user, idx) => {
                const {
                  id = '',
                  profile_id = '',
                  firstName,
                  lastName,
                  has_image,
                  image = '',
                  initials
                } = user;

                return (
                  <li
                    key={`user-create-task-sub-preview-${idx}-${id}-${profile_id}`}
                  >
                    <UserAvatar
                      className={styles.subs_preview_avatar}
                      toFetch={false}
                      firstName={firstName}
                      lastName={lastName}
                      initials={
                        initials ||
                        `${firstName}`.charAt(0) + `${lastName}`.charAt(0)
                      }
                      image={has_image ? image : ''}
                      has_image={has_image}
                      source={'subscribers'}
                      noHover
                    />
                  </li>
                );
              })}
          </ul>
          {subs &&
            preview &&
            typeof subs.length === 'number' &&
            typeof preview.length === 'number' &&
            subs.length > preview.length && (
              <div className={styles.others}>
                <h5>{`+${subs.length - preview.length} ${i18n(
                  'common_others_title'
                )}`}</h5>
              </div>
            )}
        </div>
        <div className={cx(styles.subs_input)}>
          <input
            ref={this.inputRef}
            type="text"
            className={styles.subs_input_raw}
            onChange={this.onSubscriberInput}
            onFocus={this.onInputFocus}
            value={subscriberInput}
            placeholder={i18n('user_modify_task_access_id_placeholder')}
          />
          <div
            className={cx(styles.task_subs_drop, {
              [styles.task_subs_drop_hide]: !focused //&& false
            })}
          >
            <div
              className={cx(styles.spinner_wrap, styles.flex_row_xy, {
                [styles.hide_element]: !loading && !searching
              })}
            >
              <div className={styles.raw}>
                <Spinner height={16} width={16} />
              </div>
            </div>
            <div
              className={cx(styles.empty, {
                [styles.hide_element]:
                  (!emptySavedList && !emptySearch) || loading
              })}
            >
              {emptySavedList && (
                <h5>{i18n('user_create_task_no_saved_subscribers')}</h5>
              )}
              {emptySearch && !searching && (
                <h5>{i18n('user_create_task_new_subscribers_empty_search')}</h5>
              )}
            </div>

            <div
              className={cx(styles.list, {
                [styles.hide_element]:
                  searching ||
                  loading ||
                  (emptySavedList && !searchMode) ||
                  (emptySearch && searchMode)
              })}
              ref={this.listRef}
            >
              <ul>
                {(searchMode ? results : saveUsersWithMembers)
                  .filter(user => user && (user.profile_id || user.id))
                  .map((user, idx) => {
                    const {
                      id = '',
                      profile_id = '',
                      firstName,
                      lastName,
                      has_image,
                      image = '',
                      initials,
                      spaceId
                    } = user;
                    const profileId = profile_id || id;
                    const key = `task-subscribers-${
                      searchMode ? 'search' : 'saved'
                    }-${idx}-${profileId}`;
                    const noInfo = !firstName || !lastName;

                    return (
                      <li
                        key={key}
                        className={cx({ [styles.hide_element]: noInfo })}
                      >
                        <div className={styles.list_item_avatar}>
                          <UserAvatar
                            toFetch={false}
                            firstName={firstName}
                            lastName={lastName}
                            initials={
                              initials ||
                              `${firstName}`.charAt(0) + `${lastName}`.charAt(0)
                            }
                            image={has_image ? image : ''}
                            has_image={has_image}
                            noHover={true}
                          />
                        </div>
                        <h5>{`${firstName} ${lastName}`}</h5>
                        <div
                          className={cx(
                            styles.cover,
                            'add-as-subscriber',
                            `${id || profile_id}`
                          )}
                        ></div>{' '}
                        {!searchMode && !spaceId && (
                          <IconButton
                            appearance="minimal"
                            icon={CrossIcon}
                            className={styles.unsave_user}
                            onClick={() => this.unsaveUserFromList(profileId)}
                          />
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <div
            className={cx(styles.subs_list, {
              [styles.hide_element]: subs.length < 1
            })}
          >
            <ul>
              {subs
                .filter(subscriber => subscriber?.firstName)
                .map((subscriber, idx) => {
                  const {
                    firstName,
                    lastName,
                    id = '',
                    profile_id = '',
                    initials = '',
                    has_image = false,
                    image = ''
                  } = subscriber || {};

                  return (
                    <li key={`user-create-task-sub-${idx}-${id || profile_id}`}>
                      <div className={styles.avatar}>
                        <UserAvatar
                          toFetch={false}
                          firstName={firstName}
                          lastName={lastName}
                          initials={
                            initials ||
                            `${firstName}`.charAt(0) + `${lastName}`.charAt(0)
                          }
                          image={has_image ? image : ''}
                          has_image={has_image}
                          noHover={true}
                        />
                      </div>
                      <h5>{`${firstName} ${lastName}`}</h5>
                      <div
                        className={cx(
                          styles.remove_selected,
                          `${id || profile_id}`
                        )}
                      ></div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Subscribers;
