import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './Success.scss';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import CustomButton from 'src/components/button';
import Store from 'src/lib/store';
import ProfileAPI from 'src/profile-manager/API';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, ArrowRightIcon, TickCircleIcon } from 'evergreen-ui';
import { withUserProfileSettings } from 'src/profile-manager';

const PaymentSuccess = props => {
  const isThemeDarkMode = AppAPI.isDarkMode();
  const verifyCalled = useRef(false);
  const [query, setQuery] = useState(null);
  const [verifying, setVerifying] = useState(true);
  const [toRedirect, setToRedirect] = useState(false);
  const profileLoaded = props?.profileLoaded;
  const isLoggedIn = props?.isLoggedIn;

  const onProfileLoaded = useCallback(
    (params = {}) => {
      const { fetching = false } = params || {};

      if (!fetching && !isLoggedIn && profileLoaded) {
        setToRedirect(true);
        return;
      }

      if (
        !ProfileAPI.USER_DATA.checkIsFetchingPlan() &&
        !fetching &&
        ProfileAPI.USER_DATA.info()
      ) {
        const verify = async () => {
          if (query) {
            const refId = query?.ref_id;
            const sessionId = query?.session_id;

            if (!refId || !sessionId) {
              setToRedirect(true);
              return;
            }

            const res = await ProfileAPI.PAYMENT.checkoutSuccess(
              refId,
              sessionId
            );

            if (res?.err) {
              setToRedirect(true);
            } else {
              // refresh user plan info
              await ProfileAPI.USER_DATA.getUserPlan();
              await ProfileAPI.USER_DATA.storeUserStorage();
              setVerifying(false);
            }
          }
        };

        if (!verifyCalled?.current && query) {
          verifyCalled.current = true;
          verify();
        }
      } else if (
        !fetching &&
        !ProfileAPI.USER_DATA.checkIsLoggedIn() &&
        !ProfileAPI.USER_DATA.checkIsFetchingPlan()
      ) {
        // not logged in
        setToRedirect(true);
      }
    },

    [query, profileLoaded, isLoggedIn, setVerifying, setToRedirect]
  );

  useEffect(() => {
    Store.CONFIGS.getQueryJSON();
    setQuery(Store.CONFIGS.getQuery());
  }, []);

  useEffect(() => {
    let subscriber = null;

    if (
      (!ProfileAPI.USER_DATA.checkIsFetchingPlan() &&
        !!ProfileAPI.USER_DATA.info()) ||
      (profileLoaded && !isLoggedIn)
    ) {
      onProfileLoaded({ fetching: false });
    } else {
      subscriber =
        ProfileAPI.USER_DATA.onProfileFetchingPlanStatus(onProfileLoaded);
    }

    return () => {
      if (subscriber && subscriber.unsubscribe) {
        subscriber.unsubscribe();
      }
    };
  }, [
    query,
    profileLoaded,
    isLoggedIn,
    query?.session_id,
    onProfileLoaded,
    setToRedirect,
    setVerifying
  ]);

  if (toRedirect) {
    return <Redirect to={'/user'} />;
  } else if (toRedirect && !isLoggedIn) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div className={cx(styles.payment_success, styles.flex_row_xy)}>
      <div className={cx(styles.raw, { [styles.raw_dark]: isThemeDarkMode })}>
        <div className={styles.success}>
          {(verifying || !profileLoaded) && (
            <div
              className={cx(styles.loading, styles.flex_row_xy, {
                [styles.loading_dark]: isThemeDarkMode
              })}
            >
              <div>
                <Spinner />
              </div>
            </div>
          )}
          {!verifying && profileLoaded && (
            <>
              <div className={cx(styles.success_ic, styles.flex_row_xy)}>
                <TickCircleIcon color="success" />
              </div>
              <div className={styles.title}>
                <h3>{i18n('payment_successful')}</h3>
              </div>
              <div className={styles.message}>
                <h5>{i18n('payment_success_message')}</h5>{' '}
              </div>

              <div className={styles.to_dashboard}>
                <CustomButton
                  onClick={() => setToRedirect(true)}
                  className={styles.to_dashboard_button}
                  variant={'primaryPurple'}
                  label={i18n('payment_succes_to_dashboard')}
                />{' '}
              </div>

              <div className={styles.redirect}>
                <Link to={'/user/account?section=plan'}>
                  <h5> {i18n('payment_redirect_account_settings')}</h5>
                  <span>
                    <ArrowRightIcon />
                  </span>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(PaymentSuccess);
