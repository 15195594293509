import styles from './AtInfo.scss';
import axios from 'axios';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import { toString, isEmpty, isMobileView } from 'src/helpers/utils';
import { getApiDashboardUrlWhitelistedLocation } from 'src/helpers/urls';

const Quill = window.Quill;
const Base = Quill.import('blots/embed');

export default function RegisterAtInfo() {
  class AtInfo extends Base {
    static blotName = 'atinfo';
    static tagName = 'div';

    static create(value) {
      super.create(value);
      const {
        userId = '',
        text = '',
        name = '',
        image = '',
        description = '',
        edit = true
      } = value || {};
      const id = `AtInfo${Date.now()}`;
      const div = document.createElement('div');
      const divHover = document.createElement('div');
      const divAvatar = document.createElement('div');
      const divTexts = document.createElement('div');
      const domTitle = document.createElement('h6');
      const domDescription = document.createElement('h6');
      const img = document.createElement('img');

      const onDivClick = evt => {
        if (evt) {
          let target = evt.target;

          if (!target) {
            return;
          }

          const x = evt.clientX;
          const y = evt.clientY;
          const parentElement = target?.parentElement;

          if (
            parentElement &&
            parentElement.dataset &&
            parentElement.dataset.userid &&
            parentElement.id === id
          ) {
            target = parentElement;
          }

          if (isMobileView()) {
            if (
              target &&
              target.dataset &&
              target.dataset.userid === userId &&
              target.classList
            ) {
              const timeoutId = setTimeout(() => {
                divHover.classList.add(styles.at_info_show);

                if (y < 250) {
                  divHover.style.top = 'calc(50% + 16px)';
                  divHover.style.bottom = 'unset';
                } else {
                  divHover.style.top = 'unset';
                  divHover.style.bottom = 'calc(50% + 16px)';
                }

                if (x > window.innerWidth - 250) {
                  divHover.style.right = '5%';
                } else if (x < 200) {
                  divHover.style.right = '';
                  divHover.style.left = '0';
                } else {
                  divHover.style.left = '25%';
                }

                clearTimeout(timeoutId);
              }, 100);
            } else if (
              target &&
              divHover.classList &&
              divHover.classList.contains(styles.at_info_show)
            ) {
              divHover.classList.remove(styles.at_info_show);
              divHover.style.right = '';
              divHover.style.left = '';
            }
          }
        }
      };

      divTexts.append(domTitle);
      divTexts.append(domDescription);

      // hover children
      divHover.classList.add(styles.at_info_hover);
      divHover.append(divAvatar);
      divHover.append(divTexts);

      if (!isMobileView() && !edit) {
        divHover.classList.add(styles.at_info_hover_noedit);
      }

      // avatar
      divAvatar.append(img);
      divAvatar.classList.add(styles.at_info_hover_avatar);

      // texts
      domTitle.textContent = text;
      domDescription.textContent = description;
      divTexts.classList.add(styles.at_info_hover_texts);

      if (!description) {
        domDescription.classList.add(styles.hide_element);
        divTexts.classList.add(styles.at_info_hover_texts_center);
      }

      div.classList.add(styles.at_info);

      if (!isMobileView()) {
        div.classList.add(styles.at_info_onhover);
      }

      if (!edit) {
        div.classList.add(styles.at_info_noedit);
      }

      div.textContent = text;
      div.setAttribute('spellcheck', 'false');
      div.setAttribute('autocomplete', 'off');
      div.setAttribute('autocorrect', 'off');
      div.setAttribute('autocapitalize', 'off');
      // store data
      div.setAttribute('data-text', text);
      div.setAttribute('data-name', name);
      div.setAttribute('data-userId', userId);
      div.setAttribute('data-description', description);
      div.setAttribute('id', id);
      div.append(divHover);
      document.addEventListener('click', onDivClick);

      if (!image || !name) {
        // fetch info
        // replace/update after request

        const currentUserCached = ProfileAPI.USER_PROFILE.getUserCache(id);
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);

        if (
          currentUserCached &&
          !currentUserCached.fetching &&
          currentUserCached.firstName
        ) {
          const withImage =
            currentUserCached.has_image && currentUserCached.image;
          const firstName = currentUserCached.firstName;
          const lastName = currentUserCached.lastName;
          const updatedText = `@${firstName} ${lastName}`;

          if (!withImage) {
            const divDefault = document.createElement('div');
            const domCharacter = document.createElement('h6');
            divAvatar.removeChild(img);
            divDefault.append(domCharacter);
            divAvatar.append(divDefault);

            domCharacter.textContent =
              `${firstName}`.charAt(0) || `${lastName}`.charAt(0);
            divDefault.classList.add(styles.at_info_hover_avatar_default);
          } else {
            img.alt = `avatar for ${updatedText}`;
            img.src = image;
          }
        } else {
          axios({
            baseURL,
            method: 'POST',
            data: {
              id: userId
            },
            url: `/v1/task/pid`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          }).then(res => {
            if (res?.data?.valid) {
              const withImage =
                !isEmpty(res?.data?.image) && res?.data?.has_image;
              const image = res?.data?.image;
              const firstName = res?.data?.firstName;
              const lastName = res?.data?.lastName;
              const updatedText = `@${firstName} ${lastName}`;
              const user = {
                firstName,
                lastName,
                image,
                id: userId,
                profileId: userId,
                profile_id: userId,
                fetching: false,
                has_image: withImage
              };
              ProfileAPI.USER_PROFILE.setUserCache(id, {
                ...currentUserCached,
                ...user
              });

              if (!withImage) {
                const divDefault = document.createElement('div');
                const domCharacter = document.createElement('h6');
                divAvatar.removeChild(img);
                divDefault.append(domCharacter);
                divAvatar.append(divDefault);

                domCharacter.textContent =
                  `${firstName}`.charAt(0) || `${lastName}`.charAt(0);
                divDefault.classList.add(styles.at_info_hover_avatar_default);
              } else {
                img.alt = `avatar for ${updatedText}`;
                img.src = image;
              }
            }
          });
        }
      } else {
        img.alt = `avatar for ${text}`;
        img.src = image;
      }

      return div;
    }

    static value(domNode) {
      if (domNode && domNode.dataset) {
        const text = domNode.dataset?.text || '';
        const name = domNode.dataset?.name || '';
        const userId = domNode.dataset?.userId || domNode.dataset?.userid;
        const description = domNode.dataset?.description || '';
        const isUserInfo = !isEmpty(userId);

        // support user mention for now
        if (isUserInfo) {
          return {
            text,
            name,
            description,
            userId
          };
        }
      }

      return {};
    }

    update(mutations, context) {
      // see issue https://github.com/quilljs/quill/issues/1172
      // `childList` mutations are not handled on Quill
      // see `update` implementation on:
      // https://github.com/quilljs/quill/blob/master/blots/embed.js

      for (const mutation of mutations) {
        if (!mutation) continue;
        if (mutation.type !== 'childList') continue;
        if (toString(mutation.removedNodes.length) === '0') continue;

        const nodeType = mutation.removedNodes[0].nodeType;
        setTimeout(() => {
          if (!this?._remove) return;
          this._remove(nodeType);
        }, 0);
        return;
      }

      const unhandledMutations = mutations.filter(m => m.type !== 'childList');
      super.update(unhandledMutations, context);
    }

    static formats() {
      return true;
    }
  }

  AtInfo.blotName = 'atinfo';
  AtInfo.className = 'ql-atinfo';
  AtInfo.tagName = 'div';

  Quill.register('formats/atinfo', AtInfo, true);
}
