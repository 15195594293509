import React from 'react';
import cx from 'classnames';
import styles from './LogoLoad.scss';
import AppLogo from 'src/components/app-logo';
import AppAPI from 'src/app-manager/API';

const LogoLoad = props => {
  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      className={cx(styles.logo_load, styles.flex_column_xy, {
        [styles.logo_load_dark]: isThemeDarkMode
      })}
    >
      <AppLogo />
      <div
        className={cx({ [styles.hide_element]: !props.message }, styles.msg, {
          [styles.msg_dark]: isThemeDarkMode
        })}
      >
        <h3>{props.message}</h3>
      </div>
    </div>
  );
};

export default LogoLoad;
