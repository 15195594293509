import Logger from 'src/lib/Logger';
import TwitterBlot from 'src/lib/formats/twitter';
import YoutubeBlot from 'src/lib/formats/YoutubeBlot';
import FilePreview from 'src/lib/formats/file-preview';
import BetterList from 'src/lib/formats/better-list';
import BetterImage from 'src/lib/formats/better-image';
import WebPreview from 'src/lib/formats/web-preview';
import BetterVideo from 'src/lib/formats/better-video';
import AtInfo from 'src/lib/formats/at-info';
import VideoPlaceholder from 'src/lib/formats/placeholder/video-placeholder';
import 'src/lib/formats/YoutubeBlot.css';
import { isDev, isFunction } from 'src/helpers/utils';
import { v4 } from 'uuid';

export function init() {
  try {
    console.log(`Initializing Quill`);

    const Quill = window.Quill;
    const colorCN = Quill.import('attributors/class/color');
    const colorStyle = Quill.import('attributors/style/color');
    const sizeCN = Quill.import('attributors/class/size');
    const devMode = isDev();

    /**
     * Initialise all custom formats here please
     */
    if (YoutubeBlot()) {
      Quill.register(YoutubeBlot());
    }

    if (VideoPlaceholder) {
      VideoPlaceholder();
    }

    if (BetterList) {
      BetterList();
    }

    if (AtInfo) {
      AtInfo();
    }

    if (BetterVideo) {
      BetterVideo();
    }

    if (BetterImage) {
      BetterImage();
    }

    if (FilePreview) {
      FilePreview();
    }

    if (WebPreview()) {
      Quill.register(WebPreview());
    }

    if (TwitterBlot()) {
      Quill.register(TwitterBlot());
    }

    Quill.register(colorCN, true);
    Quill.register(colorStyle, true);
    Quill.register(sizeCN, true);
    Quill.debug('error');

    if (!devMode) {
      Quill.register('suppressWarning');
    }
  } catch {}
}

export default class QuillTemplate {
  domId = '';
  toolbarId = '';
  delta = {
    ops: []
  };
  editor = null;
  editorChangeCallback = [];
  selectionChangeCallback = [];
  name = '';
  unique = '';
  enabled = true;

  constructor(domId = '') {
    if (domId) {
      this.domId = domId;
    }

    this.unique = v4();
    this.selectionChange = this.selectionChange.bind(this);
    this.editorChange = this.editorChange.bind(this);
    this.establishListener = this.establishListener.bind(this);
    this.removeListener = this.removeListener.bind(this);
  }

  disable() {
    if (this.editor) {
      this.enabled = false;
      this.editor.enable(false);
    }
  }

  isDisabled() {
    return !this.enabled;
  }

  enable() {
    if (this.editor) {
      if (!this.enabled) {
        this.enabled = true;
        this.editor.enable(true);
      }
    }
  }

  get() {
    // return the editor instance/object
    return this.editor;
  }

  getDelta() {
    try {
      this.delta = this.editor.getContents();
    } catch {}
    return this.delta;
  }

  getDeltaCopy() {
    try {
      return JSON.parse(JSON.stringify(this.delta));
    } catch {
      return { ops: [] };
    }
  }

  setNotAllowedHTMLs(notAllowed = []) {
    if (notAllowed && notAllowed.length && notAllowed[0]) {
      if (!this.editor || !window.Quill) {
        return;
      }

      for (const n of notAllowed) {
        this.editor.clipboard.addMatcher(n, function () {
          const Delta = window.Quill.import('delta');
          return new Delta().insert('');
        });
      }
    }
  }

  setName(name = '') {
    if (name !== this.name) {
      this.name = name;
    }
  }

  getName() {
    return this.name;
  }

  getUnique() {
    return this.unique;
  }

  create(domId = '', toolbarId = '', props = {}, limitedFormats = []) {
    try {
      const Quill = window.Quill;

      if (domId && typeof domId === 'string') {
        this.domId = domId;
      } else if (this.domId) {
        domId = this.domId;
      }

      if (toolbarId && typeof toolbarId === 'string') {
        this.toolbarId = toolbarId;
      }

      const quillDOM = document.getElementById(domId);
      const toolbarDOM = document.getElementById(toolbarId);

      if (quillDOM) {
        // check if custom toolbar exists
        const placeholder =
          props && props['placeholder'] ? props['placeholder'] : '';
        const hasModules = props && typeof props['modules'] === 'object';

        if (hasModules) {
          delete props['modules']['handlers'];
        }

        this.editor = new Quill(quillDOM, {
          modules: {
            toolbar: {
              ...(toolbarDOM && { container: `#${toolbarId}` })
            },
            ...(hasModules && props['modules'])
          },
          // set placeholder
          ...(placeholder &&
            typeof placeholder === 'string' && { placeholder }),

          // set limited formats
          ...(limitedFormats &&
            limitedFormats.length &&
            typeof limitedFormats.length === 'number' && {
              formats: limitedFormats
            })
        });

        if (
          this.editor &&
          props &&
          props['handlers'] &&
          typeof props['handlers'] === 'object' &&
          typeof props['handlers']['image'] === 'function'
        ) {
          this.editor
            .getModule('toolbar')
            .addHandler('image', props.handlers.image);
        }

        if (this.editor) {
          this.establishListener();
        }
      } else {
        throw new Error('Invalid dom container');
      }
    } catch (err) {
      if (err) {
        Logger.log(
          `Something went wrong when instantiating new Quill due error: ${err.message}`
        );
      }
    }
  }

  removeListener() {
    this.editor.off('selection-change', this.selectionChange);
    this.editor.off('editor-change', this.editorChange);
  }

  establishListener() {
    this.editor.on('selection-change', this.selectionChange);
    this.editor.on('editor-change', this.editorChange);
  }

  unsubscribeSelectionChange(cb) {
    if (cb) {
      const idx = this.selectionChangeCallback.indexOf(cb);

      if (idx > -1) {
        this.selectionChangeCallback.splice(idx, 1);
      }
    }
  }

  onSelectionChange(cb) {
    if (cb && isFunction(cb)) {
      const idx = this.selectionChangeCallback.indexOf(cb);

      if (idx < 0) {
        this.selectionChangeCallback.push(cb);
      }
    }
  }

  unsubscribeEditorChange(cb) {
    if (cb) {
      const idx = this.editorChangeCallback.indexOf(cb);

      if (idx > -1) {
        this.editorChangeCallback.splice(idx, 1);
      }
    }
  }

  onEditorChange(cb) {
    if (cb && isFunction(cb)) {
      const idx = this.editorChangeCallback.indexOf(cb);

      if (idx < 0) {
        this.editorChangeCallback.push(cb);
      }
    }
  }

  editorChange(...args) {
    for (const cb of this.editorChangeCallback) {
      cb(...args);
    }
  }

  selectionChange(...args) {
    for (const cb of this.selectionChangeCallback) {
      cb(...args);
    }
  }

  destroy() {
    if (this.editor) {
      try {
        this.selectionChangeCallback = [];
        this.editorChangeCallback = [];
        this.removeListener();
      } catch {}
    }
  }
}
