import Store from 'src/lib/store';
import Logger from 'src/lib/Logger';
import k from 'src/constants/k';
import axios from 'axios';
import ProfileAPI from './API';
import { isFunction, isDev, isDebugMode, isMobileApp } from 'src/helpers/utils';
import { v4 } from 'uuid';
import { isCorrectEmailFormat } from 'src/lib/UserInputs';

class Flow {
  static profile_context = null;
  initialFetchEncryptionCode = false;

  constructor(ctx) {
    this.profile_context = ctx;
  }

  checkAuthKeysField(obj = {}) {
    return !!(
      obj !== null &&
      typeof obj === 'object' &&
      typeof obj['k'] === 'string' &&
      typeof obj['idx'] === 'number' &&
      typeof obj['uuid'] === 'string'
    );
  }

  async placeholderTest() {
    // for testing purposes only
    const { setErrorMessage } = this.profile_context.props;
    const response = await fetch(
      'https://jsonplaceholder.typicode.com/todos/1'
    );
    const resJSON = await response.json();

    if (resJSON && setErrorMessage) {
      setErrorMessage(`placeholderTest:${JSON.stringify(resJSON)}`);
    }
  }

  async getEC() {
    const devMode = isDev();
    const canPromptError = devMode || isDebugMode() || isMobileApp();
    let baseURL = '';

    try {
      if (this.initialFetchEncryptionCode) {
        return;
      }

      const { setErrorMessage } = this.profile_context.props;
      baseURL = k.API_DASHBOARD_URLS[devMode ? 'development' : 'production'];
      this.initialFetchEncryptionCode = true;
      const [resL, resS] = await Promise.all([
        axios({
          baseURL,
          method: 'GET',
          url: `/v1/pub/scr/client-login?uuid=${v4()}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        }),
        axios({
          baseURL,
          method: 'GET',
          url: `/v1/pub/scr/client-signup?uuid=${v4()}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        })
      ]);

      if (resL && resL.status === 200 && resL.data && resL.data.valid) {
        this.profile_context.encVAL['login'] = { err: false, ...resL.data.v };
      } else {
        if (isFunction(setErrorMessage) && canPromptError) {
          setErrorMessage(
            `respondError:${window?.location?.origin} ${baseURL} ${resL?.data?.message}` ||
              `${resL.status} ${resL.statusText}`
          );
        }

        Logger.log(`Failed to get user security for login`);
      }

      if (resS && resS.status === 200 && resS.data && resS.data.valid) {
        this.profile_context.encVAL['signup'] = { err: false, ...resS.data.v };
      } else {
        Logger.log(`Failed to get user security for signup`);
      }
    } catch (err) {
      console.error(err.message);

      try {
        const { setErrorMessage } = this.profile_context.props;

        if (canPromptError && err && isFunction(setErrorMessage)) {
          setErrorMessage(
            `getEC:baseURL: ${baseURL} origin: ${
              window?.location?.origin
            } error:${err?.message} err:${err} \
            res:  ${err?.response?.data || err?.response?.data?.error}
            `
          );
        }
      } catch {}
    }
  }

  async start() {
    /**
     * See if localStorage has already user loggedin
     */

    await this.profile_context.setStateAsync({ mounted: true });
    await this.checkFlags();
    await ProfileAPI.start();

    if (Store.CONFIGS.HAS_LOCAL_STORAGE_AVAILABLE) {
      /**
       * Not in incognito mode
       */
      const storageUserAuthKeys = [
        '__ch__',
        '__aa__',
        '__mu__',
        '__meta__',
        '__in__'
      ];
      const storedParsed = [];

      try {
        for (let i of storageUserAuthKeys) {
          const c = localStorage.getItem(`${i}`);
          const cparsed = JSON.parse(c);
          storedParsed.push(cparsed);
        }

        if (storedParsed.length >= 4) {
          /**
           * Formatting to verify user
           * - For first 3  ('__ch__', '__aa__', '__mu__')
           *  {
           *    k: str
           *    idx: num
           *    uuid: uuid
           *  }
           * - For __meta__
           *  {
           *    lb: str
           *    n: num
           *    t_id: str
           *    u_id: str
           *    username_map: str
           *    uuid: uuid
           *  }
           */

          const __ch__ = storedParsed[0];
          const __aa__ = storedParsed[1];
          const __mu__ = storedParsed[2];
          const __meta__ = storedParsed[3];
          const __in__ = storedParsed[4];

          if (
            this.checkAuthKeysField(__ch__) &&
            this.checkAuthKeysField(__aa__) &&
            this.checkAuthKeysField(__mu__) &&
            __meta__ !== null &&
            typeof __meta__ === 'object' &&
            typeof __meta__['lb'] === 'string' &&
            typeof __meta__['n'] === 'number' &&
            typeof __meta__['username_map'] === 'string' &&
            typeof __meta__['uuid'] === 'string'
          ) {
            const auth_token = `${__ch__.k}${__aa__.k}${__mu__.k}`;
            const auth_token_id = __meta__.t_id;
            const lb = __meta__.lb;
            const n = __meta__.n;
            const profileID = __meta__.u_id;
            const username_ref = __meta__.username_map;
            const { user } = this.profile_context.state;

            if (__in__ && __in__.in) {
              user.initials = __in__.in;

              if (__in__.dp) {
                user.dp = __in__.dp;
              }

              if (__in__.isPremiumTeams) {
                user.plan.isPremiumTeams = Boolean(__in__.isPremiumTeams);
                user.plan.isExpired = Boolean(__in__.isExpired);
              }

              if (__in__.email && isCorrectEmailFormat(__in__.email)) {
                user.email = __in__.email;
              }
            }

            user.profileID = profileID;
            user.auth = {
              token: auth_token,
              id: auth_token_id,
              username_ref,
              lb,
              n
            };

            await this.profile_context.setStateAsync({
              user
            });

            if (__in__ && __in__.in) {
              await this.profile_context.setStateAsync({
                profileLoaded: true,
                isLoggedIn: true
              });
            }

            await Promise.all([
              this.getEC(),
              ProfileAPI.USER_DATA.verifyGET(),
              ProfileAPI.USER_TASKS.getUserPersonalTags(),
              ProfileAPI.USER_DATA.basicInfoGET(true)
            ]);

            if (
              !!this.profile_context.state &&
              this.profile_context.state.isLoggedIn
            ) {
              if (!this.profile_context.state.profileLoaded) {
                await this.profile_context.setStateAsync({
                  profileLoaded: true
                });
              }

              if (window.location.pathname.indexOf('/view') === 0) {
                // to load task view fast
                this.profile_context.userProfileLoadedOBS.next();
              }

              /**
               * We fetch user basic info, plan/subscription, and created personal tags
               */
              try {
                await Promise.all([
                  ProfileAPI.USER_PROFILE.getLastDraft(true),
                  ProfileAPI.USER_TASKS.savedUsers(1)
                ]);

                if (!ProfileAPI.SPACES.doneWithInitialFetch()) {
                  await ProfileAPI.SPACES.getJoinedSpaces();
                }
              } catch {}
            } else if (!this.profile_context.state.isOffline) {
              await this.profile_context.removeUserSession();
              await this.profile_context.confirmUserNotLoggedIn();
              this.profile_context.userProfileLoadedOBS.next();
            } else {
              /**
               * Show error page
               */

              await this.profile_context.confirmUserNotLoggedIn();
              this.profile_context.userProfileLoadedOBS.next();
            }
          } else {
            throw new Error('Invalid auth key parameters');
          }
        } else {
          await this.getEC();
          await this.profile_context.removeUserSession();
          await this.profile_context.confirmUserNotLoggedIn();
          this.profile_context.userProfileLoadedOBS.next();
        }
      } catch (err) {
        /**
         * Failed user is not logged in
         */

        if (err && err.stack) {
          Logger.log(err.stack);
        }

        Logger.log(`Failed in getting stored user auth keys: ${err.message}`);
        await this.getEC();
        await this.profile_context.removeUserSession();
        this.profile_context.userProfileLoadedOBS.next();
      }
    } else {
      await this.getEC();
      await this.profile_context.removeUserSession();
      await this.profile_context.confirmUserNotLoggedIn();
      this.profile_context.userProfileLoadedOBS.next();
    }

    this.profile_context.userProfileLoadedOBS.next();
  }

  async checkFlags() {
    if (window.location.pathname !== '/user') {
      ProfileAPI.PUBLIC.VARS.hideSearch = true;
    }

    return;
  }
}

export default Flow;
