import React, { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './CommentEntity.scss';
import UserAvatar from 'src/components/user-avatar';
import k from 'src/constants/k';
import ButtonClick from 'src/components/button-click';
import CustomBadge from 'src/components/custom-badge';
import AppAPI from 'src/app-manager/API';
import CommentInput from '../comment-input';
import ProfileAPI from 'src/profile-manager/API';
import i18n, { Markdown } from 'src/locales';
import { daysToSeconds, getHHMMAMPMFormat } from 'src/helpers/utils';
import { Spinner } from 'evergreen-ui';

export function CommentDateFormat(created = 0) {
  const createdUTC = new Date(created * 1000);
  const localUTC = new Date();
  const timestamp_now = Math.ceil(Date.now() / 1000);
  const currentYear = localUTC.getFullYear();
  const isSameYear = createdUTC.getFullYear() === currentYear;

  if (isSameYear && timestamp_now > created) {
    const isSameMonth = createdUTC.getMonth() === localUTC.getMonth();
    const isYesterday =
      isSameMonth && createdUTC.getDate() + 1 === localUTC.getDate();

    if (isYesterday) {
      return i18n('common_yesterday_title');
    } else if (
      isSameMonth &&
      localUTC.getDate() > createdUTC.getDate() &&
      localUTC.getDate() - createdUTC.getDate() < 7
    ) {
      return Markdown('task_view_comments_date_days_ago', {
        day1: `${localUTC.getDate() - createdUTC.getDate()}`
      });
    }

    const isSameDay =
      isSameMonth && createdUTC.getDate() === localUTC.getDate();
    const diff = timestamp_now - created;
    const isLessThan12Hrs =
      isSameDay && timestamp_now > created && diff < daysToSeconds(0.5);

    if (isLessThan12Hrs) {
      if (diff < 3600) {
        let min = Math.ceil(diff * (1 / 60));
        min = min < 2 ? 1 : min;

        if (min === 60) {
          return Markdown('task_view_comments_date_hr_ago', {
            hr1: '1'
          });
        }

        return Markdown(
          min < 2
            ? 'task_view_comments_date_min'
            : 'task_view_comments_date_mins',
          {
            min1: `${min}`
          }
        );
      } else {
        let hr = Math.round(Number((diff * (1 / 60) * (1 / 60)).toFixed(2)));
        hr = hr < 2 ? 1 : hr;

        return hr < 2
          ? Markdown('task_view_comments_date_hr_ago', {
              hr1: `${hr}`
            })
          : Markdown('task_view_comments_date_hrs_ago', {
              hr1: `${hr}`
            });
      }
    } else if (isSameDay) {
      return getHHMMAMPMFormat(createdUTC.getHours(), createdUTC.getMinutes());
    }
  }

  return `${
    k.MONTHS_INDEX[createdUTC.getMonth()].abv
  } ${createdUTC.getDate()}, ${createdUTC.getFullYear()}`;
}

const CommentEntity = ({
  users = {},
  profile_id = '',
  text = '',
  firstName = '',
  lastName = '',
  created = 0,
  ref_id = '',
  opts = false,
  onUserInfo = function () {},
  onSave = async function () {},
  onCancel = function () {},
  setEditingId = function () {},
  editingId = '',
  loadAction = false,
  toEdit = false,
  idx = -1,
  isAuthor = false,
  image = '',
  has_image = false,
  edited = false,
  deleted = false,
  spaceMember = false,
  text_raw = false,
  delta = '',
  showEdit = false,
  hideEditButton = false,
  taskId = '',
  uRef = '',
  gif = ''
}) => {
  const [editedText, EditText] = useState(
    (typeof text === 'string' && text) || ''
  );
  // const commentOptionsActionsUnique = 'comment-option-actions-unique';
  const textRef = useRef(); // old text if edited
  const isThemeDarkMode = AppAPI.isDarkMode();

  const onCancelEdit = useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel();
      EditText(textRef.current);
    }
  }, [EditText, onCancel, textRef]);

  const onSaveEdit = useCallback(async () => {
    if (typeof onSave === 'function') {
      await onSave(ref_id, idx, editedText, textRef.current);
      textRef.current = editedText;
    } else EditText(textRef.current);
  }, [ref_id, idx, EditText, onSave, editedText, textRef]);

  const user = ProfileAPI.USER_DATA.info();
  const userId = user.profileID;
  const profileId = profile_id;
  const isLoggedIn = user && ProfileAPI.USER_DATA.checkIsLoggedIn();
  const isUser = !!(profileId && userId === profileId);

  return (
    <div className={styles.comment_entity}>
      <UserAvatar
        noHover={true}
        profile_id={profile_id}
        toFetch={users && profile_id && users[profile_id] ? false : true}
        {...(users[profile_id] && {
          firstName: users[profile_id].firstName,
          lastName: users[profile_id].lastName,
          has_image: users[profile_id].has_image,
          image: users[profile_id].image
        })}
        {...((!users || !users[profile_id]) && {
          firstName: firstName,
          lastName: lastName,
          image: image ? image : '',
          has_image: !!has_image
        })}
        className={styles.comment_avatar}
        onInfo={onUserInfo}
      />
      <div className={styles.content}>
        <div className={styles.name}>
          <h5>{`${firstName} ${lastName}`}</h5>{' '}
          {isAuthor && (
            <div>
              <CustomBadge name={i18n('common_author_title')} type={'author'} />
            </div>
          )}{' '}
          {spaceMember && (
            <div>
              <CustomBadge
                name={i18n('common_space_member_title')}
                type={'author'}
              />
            </div>
          )}
        </div>
        <h5 className={styles.created}>{CommentDateFormat(created)}</h5>
        <h5 className={cx(styles.edited, { [styles.hide_element]: deleted })}>
          <i>{`${edited ? `(${i18n('common_edited_title')})` : ''}`}</i>
        </h5>

        {(text || delta || gif) && !deleted && (
          <CommentInput
            hideEditButton={hideEditButton}
            showEdit={isLoggedIn && isUser && showEdit}
            text={text}
            version={text_raw ? 'v1' : 'v2'}
            delta={gif ? '' : delta}
            mode={'edit'}
            className={styles.comment_input}
            taskId={taskId}
            uRef={uRef}
            refId={ref_id}
            editingId={editingId}
            gif={gif}
            setEditingId={setEditingId}
          />
        )}
        <div
          className={cx(styles.deleted, { [styles.hide_element]: !deleted })}
        >
          <div>
            <h5>{i18n('task_view_comments_one_deleted')}</h5>
          </div>
        </div>
        <div
          className={cx(styles.actions, {
            [styles.hide_element]: !toEdit || loadAction
          })}
        >
          <div className={styles.actions_actual}>
            <ButtonClick
              className={cx(styles.cancel, {
                [styles.cancel_dark]: isThemeDarkMode
              })}
              onClick={onCancelEdit}
            >
              <h4>{i18n('common_cancel_title')}</h4>
            </ButtonClick>
            <ButtonClick
              className={cx(styles.save, {
                [styles.cancel_dark]: isThemeDarkMode
              })}
              onClick={onSaveEdit}
            >
              <h4>{i18n('common_save_title')}</h4>
            </ButtonClick>
          </div>
        </div>
      </div>
      <div
        className={cx(styles.spinner_wrap, {
          [styles.spinner_wrap_dark]: isThemeDarkMode,
          [styles.hide_element]: !loadAction
        })}
      >
        <div className={styles.raw}>
          <Spinner size={25} />
        </div>
      </div>
    </div>
  );
};

export default CommentEntity;
