import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './CreateTask.scss';
import AppAPI from 'src/app-manager/API';
import ModalAPI from 'src/modal-manager/API';
import CreateTaskV2 from 'src/pages/user/create-task2';
import Store from 'src/lib/store';
import k from 'src/constants/k';
import i18n from 'src/locales';
import MessageHover from 'src/components/message-hover';
import ProfileAPI from 'src/profile-manager/API';
import { Capacitor } from '@capacitor/core';
import { isNil, isString, isEmpty, isIOSMobile } from 'src/helpers/utils';
import { IconButton, CrossIcon } from 'evergreen-ui';
import { withRouter } from 'react-router-dom';

class CreateTask extends Component {
  rawRef = null;
  wrapRef = null;

  state = {
    mounted: false,
    showProps: true,
    containerHeight: 0,
    valid: false,
    maximizeEditor: false,
    mode: '',
    team: [
      // {
      //   id: '',
      //   name: ''
      // }
    ],
    showClose: true
  };

  constructor() {
    super();

    this.rawRef = createRef(null);
    this.wrapRef = createRef(null);
    this.close = this.close.bind(this);
    this.toggleProps = this.toggleProps.bind(this);
    this.toggleMaximizeEditor = this.toggleMaximizeEditor.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  close() {
    const { mode } = this.state;

    AppAPI.appendQueryURL('', {
      action: '',
      ...(mode === k.CREATE_TASK_MODE.EDIT && { id: '', t: '' })
    });

    if (this.onScrollSubscriber) {
      this.onScrollSubscriber.unsubscribe();
    }

    ModalAPI.goDeactivate();
  }

  componentDidMount() {
    // verify if there's a team in link
    // verify if team is valid
    Store.CONFIGS.getQueryJSON();
    this.setState(
      {
        mounted: true
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    this.setState({ mounted: false, team: [] });
  }

  onMount = async () => {
    try {
      const query = Store.CONFIGS.getQuery();

      await this.setTeam();
      await this.setStateAsync({
        valid: true,
        mode:
          query && query.action === 'edit_task' ? k.CREATE_TASK_MODE.EDIT : ''
      });
    } catch {}
  };

  setTeam = async (spaceId = '', spaceInfo = null) => {
    if (!spaceId) {
      spaceId = ProfileAPI.SPACES.getSpaceSelectedForCreate();
    }
    const team = [];

    if (!isNil(spaceInfo) && isString(spaceId) && !isEmpty(spaceId)) {
      team.push({ id: spaceId, name: spaceInfo.name });
    } else if (spaceId !== 'personal' && spaceId) {
      const { space: spaceInfo } =
        ProfileAPI.SPACES.getSpaceById(spaceId) || {};

      if (spaceInfo) {
        team.push({
          id: spaceId,
          name: spaceInfo.name
        });
      }
    }

    await this.setStateAsync({
      team
    });
  };

  toggleProps() {
    const { showProps } = this.state;

    this.setState({
      showProps: !showProps
    });
  }

  toggleMaximizeEditor(flag) {
    if (window.innerWidth < 901) {
      return;
    }

    const maximizeEditor =
      typeof flag === 'boolean' ? flag : !this.state.maximizeEditor;

    this.setState({
      maximizeEditor,
      showProps: !maximizeEditor
    });
  }

  hideCloseButton = () => {
    this.setState({
      showClose: false
    });
  };

  showCloseButton = () => {
    this.setState({
      showClose: true
    });
  };

  render() {
    const { showProps, showClose, valid, team, maximizeEditor } = this.state;
    const isTeam = team && team.length > 0;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const isIosPlatform = Capacitor.getPlatform() === 'ios'; // mobile app
    const isIosMobile = isIOSMobile() || isIosPlatform;

    return (
      <div
        className={cx(styles.create_task_modal_wrap, {
          [styles.create_task_modal_wrap_ios]: isIosMobile
        })}
        ref={this.wrapRef}
      >
        <div
          ref={this.rawRef}
          className={cx(styles.raw, {
            [styles.raw_dark]: isThemeDarkMode,
            [styles.raw_default]: !maximizeEditor,
            [styles.raw_w_prop]: showProps && !maximizeEditor,
            [styles.raw_no_prop]: !showProps && !maximizeEditor,
            [styles.raw_expand]: maximizeEditor,
            [styles.raw_expand_dark]: maximizeEditor && isThemeDarkMode,
            [styles.raw_ios]: isIosPlatform
          })}
        >
          {valid && (
            <CreateTaskV2
              toggleMaximizeEditor={this.toggleMaximizeEditor}
              maximizeEditor={maximizeEditor}
              toggleProps={this.toggleProps}
              showProps={showProps}
              isTeam={isTeam}
              team={team}
              setTeam={this.setTeam}
              close={this.close}
              showCloseButton={this.showCloseButton}
              hideCloseButton={this.hideCloseButton}
            />
          )}
          <div
            className={cx(styles.close, {
              [styles.close_dark]: isThemeDarkMode,
              [styles.hide_element]: maximizeEditor || !showClose
            })}
          >
            <IconButton
              className={styles.close_button}
              appearance="minimal"
              icon={CrossIcon}
              onClick={this.close}
            />

            <MessageHover
              message={i18n('common_cancel_title')}
              className={styles.close_tip}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateTask);
