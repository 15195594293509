import axios from 'axios';
import k from 'src/constants/k';
import Store from 'src/lib/store';
import Logger from 'src/lib/Logger';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import i18n from 'src/locales';
import {
  hrsToMilliseconds,
  hrsToSeconds,
  getAuthHeaders
} from 'src/helpers/utils';
import {
  getApiOpenBaseUrl,
  getApiDashboardUrlWhitelistedLocation
} from 'src/helpers/urls';
import { interval, Subject } from 'rxjs';

class UserData {
  userNewNotifsSubscriber = null;
  userNotificationsOnUnreadOBS = null;
  userUpdatePasswordOBS = null;
  userNewNotifsIntervalMS = 30000;

  user_notifications_accumulated = [];
  user_notifications_first_page = [];
  user_notifications_has_next = false;
  user_notifications_page = 1;
  user_highlighted_notifications = [];

  VARS = {
    markReadNotifs: false,
    gettingNotifs: true,
    gettingHighlightedNotifs: false,
    notifsFirstGET: false,
    chekingNewNotifs: false,
    blockNewNotificationsUI: false // we use this flag, set to true, whenever we in process of marking batch of notifications as read
  };

  start() {
    this.onUserLogoutSubscriber = ProfileAPI.onLogout(this.onLogout.bind(this));
  }

  release() {
    if (this.userNotificationsOnUnreadOBS) {
      this.userNotificationsOnUnreadOBS.unsubscribe();
    }

    if (this.userUpdatePasswordOBS) {
      this.userUpdatePasswordOBS.unsubscribe();
    }
  }

  resetVARS() {
    this.VARS = {
      markReadNotifs: false,
      gettingNotifs: true,
      notifsFirstGET: false,
      blockNewNotificationsUI: false
    };

    this.user_notifications_accumulated = [];
    this.user_notifications_first_page = [];
    this.user_notifications_has_next = false;
    this.user_notifications_page = 1;
  }

  constructor() {
    this.userNotificationsOnUnreadOBS = new Subject();
    this.userUpdatePasswordOBS = new Subject();
  }

  onLogout() {
    this.resetVARS();
  }

  encResetPWValid() {
    return !!(
      this.profile_context &&
      this.profile_context.encVAL &&
      this.profile_context.encVAL.resetpw &&
      !this.profile_context.encVAL.resetpw.err
    );
  }

  encLoginValid() {
    return !!(
      !!this.profile_context &&
      !!this.profile_context.encVAL &&
      !!this.profile_context.encVAL.login &&
      !this.profile_context.encVAL.login.err
    );
  }

  encSignupValid() {
    return !!(
      !!this.profile_context &&
      !!this.profile_context.encVAL &&
      !!this.profile_context.encVAL.signup &&
      !this.profile_context.encVAL.signup.err
    );
  }

  encLogin() {
    const x = this.profile_context.encVAL.login.id.split('-');

    return {
      arr1: this.profile_context.encVAL.login.k1,
      arr2p: x[0],
      arr2u: x[x.length - 1]
    };
  }

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  async setUserAuth({ auth_token_id, auth_token, username_ref, n, lb }) {
    if (this.profile_context) {
      const { user } = this.profile_context.state;
      user.plan = {
        isPremium: false,
        isPremiumTeams: false,
        isExpired: false,
        isLifetime: false,
        maxTags: 0,
        tasksMonth: 0,
        type: i18n('user_plan_free_title'),
        end: 0,
        started: 0
      };

      user.auth = {
        token: auth_token,
        id: auth_token_id,
        username_ref,
        lb,
        n
      };

      await this.profile_context.setStateAsync({
        user
      });
    }
  }

  onProfileFetchingInfoStatus(fn) {
    if (this.profile_context) {
      return this.profile_context.userProfileFetchingInfo.subscribe({
        next: fn
      });
    } else return null;
  }

  onProfileFetchingPlanStatus(fn) {
    if (this.profile_context) {
      return this.profile_context.userProfileFetchinPlan.subscribe({
        next: fn
      });
    } else return null;
  }

  onProfileNewNotifs(fn) {
    if (this.profile_context) {
      return this.profile_context.userProfileFetchingNewNotifOBS.subscribe({
        next: fn
      });
    } else return null;
  }

  onProfileFetchingNotifications(fn) {
    if (this.profile_context) {
      return this.profile_context.userProfileFetchingNotificationsOBS.subscribe(
        {
          next: fn
        }
      );
    } else {
      return null;
    }
  }

  onUpdatePassword(fn) {
    if (this.profile_context) {
      return this.userUpdatePasswordOBS.subscribe({ next: fn });
    }

    return null;
  }

  checkIsProfileLoaded() {
    return Boolean(this.profile_context?.state?.profileLoaded);
  }

  checkIsFetchingInfo() {
    return !!(
      !!this.profile_context &&
      !!this.profile_context.state &&
      this.profile_context.state.isFetchingInfo
    );
  }

  checkIsFetchingPlan() {
    return !!(
      !!this.profile_context &&
      !!this.profile_context.state &&
      this.profile_context.state.isFetchingPlan
    );
  }

  checkIsLoggedIn() {
    return !!(
      this.profile_context &&
      this.profile_context.state &&
      this.profile_context.state.isLoggedIn
    );
  }

  checkJustLoggedIn() {
    return !!(
      this.profile_context &&
      this.profile_context.state &&
      this.profile_context.state.justLoggedIn
    );
  }

  info() {
    if (this.profile_context) {
      return this.profile_context.state.user;
    }

    return null;
  }

  getUserId() {
    if (this.profile_context) {
      return this.profile_context.state.user
        ? this.profile_context.state.user.profileID
        : '';
    }

    return '';
  }

  isOffline() {
    return !!(
      this.profile_context &&
      this.profile_context.state &&
      this.profile_context.state.isOffline
    );
  }

  async verfiyRefresh(is_expired = false) {
    if (this.profile_context) {
      /**
       * Refresh user auth
       */

      try {
        const { user, isLoggedIn = false } = this.profile_context.state;
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'GET',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/user/refresh?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.valid) {
          const { new_auth_token, new_auth_token_id } = res.data;
          user.auth.token = new_auth_token;
          user.auth.id = new_auth_token_id;

          await this.profile_context.setStateAsync({
            user
          });

          if (!isLoggedIn) {
            await this.profile_context.setStateAsync({
              isLoggedIn: true
            });
          }
        } else if (is_expired) {
          /**
           * If it was really from an expired auth token, we logout; other case maybe we just want to refresh user on a schedule basis
           */

          return await ProfileAPI.sendLogout();
        }
      } catch (err) {
        Logger.log(`Failed to refresh user: ${err.message}`);
      }
    }
  }

  async verifyGET() {
    if (this.profile_context) {
      try {
        const { user } = this.profile_context.state;
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'GET',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/user/verify?auth_token_id=${id}&lb=${lb}&n=${n}&refresh=true`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (!res) {
          throw new Error('Invalid object response from verifyGET');
        } else if (res.status === 200 && res.data && res.data.valid) {
          if (res.data.token && res.data.token_id) {
            user.auth.id = res.data.token_id;
            user.auth.token = res.data.token;
          }

          await this.profile_context.setStateAsync({
            isLoggedIn: true
          });
        } else if (res.data && res.data.auth_expired) {
          return await this.verfiyRefresh(true);
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          return await ProfileAPI.sendLogout();
        } else {
          throw new Error('Unexpected respond from verifyGET');
        }
      } catch (err) {
        Logger.log(`Failed to fetch very user session: ${err.message}`);

        if (!Store.OS.USER_ONLINE) {
          await this.profile_context.setStateAsync({
            isOffline: true
          });
        }
      }
    }
  }

  async basicInfoGET(doLogin = false, fromLoginPage = false) {
    if (this.profile_context) {
      const { user } = this.profile_context.state;

      try {
        await this.profile_context.setStateAsync({
          isFetchingInfo: true
        });
        this.profile_context.userProfileFetchingInfo.next({ fetching: true });
        if (doLogin) {
          await this.profile_context.confirmUserLoggedIn();
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const res = await axios({
          baseURL,
          method: 'GET',
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/user/basic-info?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          const {
            access = [],
            firstName = '',
            lastName = '',
            email,
            profile_display,
            profile_id,
            new_notifs,
            createdSeconds,
            created,
            display_pic = '',
            dp_limit_reached = false,
            pw_last_updated = 0,
            pw_update_limit_reached = false,
            onboarding_welcome = false,
            activated = false,
            last_email_verify_sent: lastEmailVerifySent,
            notification_settings = null
          } = res.data;
          const onboarding = AppAPI.getLocalConfig('onboarding');
          const timestampNowSeconds = Math.ceil(Date.now() / 1000);

          user.access = access || [];
          user.firstName = firstName;
          user.lastName = lastName;
          user.email = email;
          user.profileID = profile_id;
          user.profileImage = profile_display;
          user.newNotifs = new_notifs;
          user.dp = display_pic;
          user.dpLimit = dp_limit_reached;
          user.pwLastUpdated = pw_last_updated;
          user.pwLimit = pw_update_limit_reached;
          user.activated = activated;

          if (createdSeconds && typeof createdSeconds === 'number') {
            user.created = createdSeconds * 1000;
          } else {
            user.created = created;
          }

          if (
            !user.initials ||
            user.initials.charAt(0) !== `${firstName}`.charAt(0) ||
            user.initials.charAt(1) !== `${lastName}`.charAt(0)
          ) {
            user.initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
          }

          if (
            (onboarding.welcome && !onboarding_welcome) ||
            (!onboarding.welcome && onboarding_welcome)
          ) {
            onboarding.welcome = onboarding_welcome;
            await AppAPI.setLocalConfigs('onboarding', onboarding);
          }

          if (!activated) {
            Logger.log(`User's email is not yet verified`);
          }

          try {
            if (
              !activated &&
              (fromLoginPage ||
                Number.isNaN(lastEmailVerifySent) ||
                !lastEmailVerifySent ||
                lastEmailVerifySent < 1 ||
                (lastEmailVerifySent > 0 &&
                  !Number.isNaN(lastEmailVerifySent) &&
                  timestampNowSeconds - lastEmailVerifySent > hrsToSeconds(12)))
            ) {
              // if user's email is not activate and last time we sent is 24 hours ago, we auto send an email for them to verify their emails
              // but need to check if it's login from signup success
              this.sendEmailForVerification();
            }
          } catch {}

          if (
            notification_settings &&
            typeof notification_settings === 'object'
          ) {
            user.notificationSettings = {
              email: {
                allowEmailOnCommentPersonal:
                  !!notification_settings.allowEmailOnCommentPersonal,
                allowEmailOnTaskSubscribed:
                  !!notification_settings.allowEmailOnTaskSubscribed,
                allowEmailOnNewTaskCustom:
                  !!notification_settings.allowEmailOnNewTaskCustom,
                allowEmailOnTag: notification_settings.allowEmailOnTag,
                allowEmailOnCommentFromCreatedCustom:
                  !!notification_settings.allowEmailOnCommentFromCreatedCustom,
                allowEmailOnTaskSubscribedCustom:
                  !!notification_settings.allowEmailOnTaskSubscribedCustom,
                unsubscribeToAllEmailCustom:
                  !!notification_settings.unsubscribeToAllEmailCustom
              }
            };
          }

          await this.profile_context.setStateAsync({
            isFetchingInfo: false
          });
          await this.getUserHighlightedNotifications();
        } else if (res.data && res.data.auth_expired) {
          /**
           * Logout for now, @todo refresh
           */
          return await ProfileAPI.sendLogout();
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          return await ProfileAPI.sendLogout();
        }
      } catch (err) {
        Logger.log(`Failed to fetch user basic info: ${err.message}`);
      }

      await this.profile_context.setStateAsync({
        user
      });

      if (doLogin && !this.checkIsFetchingInfo()) {
        await this.getUserPlan();
      }

      this.profile_context.storeUserStorage();
      this.profile_context.userProfileFetchingInfo.next({
        fetching: false
      });
    }
  }

  storeUserStorage() {
    if (this.profile_context) {
      this.profile_context.storeUserStorage();
    }
  }

  async sendEmailForVerification() {
    if (this.profile_context) {
      Logger.log(`Sending email verification`);
      try {
        const { user = {}, isLoggedIn = false } = this.profile_context.state;

        if (!isLoggedIn) {
          throw new Error('Not logged in');
        }

        const { id, lb, n } = user.auth;
        const headers = getAuthHeaders(user);
        const baseURL = getApiOpenBaseUrl();

        await axios({
          baseURL,
          headers,
          method: 'POST',
          data: {
            email: user.email
          },
          url: `/v1/user/verify-email?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });
      } catch (err) {
        if (err)
          Logger.log(
            `Failed to send user email verification due to error: ${err.message}`
          );
      }
    }
  }

  async confirmEmailVerification(token) {
    const result = { err: true, is_expired: false, email: '' };
    if (this.profile_context) {
      Logger.log(`Confirming email verification`);

      try {
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          data: {
            token
          },
          url: `/v1/user/confirm-verify-email`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.is_expired = res.data.is_expired;
          result.err = !(res.data.success || res.data.already_verified);
          result.email = res.data.email;

          if (!result.err) {
            const { user } = this.profile_context.state;
            user.activated = true;
            await this.profile_context.setStateAsync({
              user
            });
          }
        }
      } catch (err) {
        if (err)
          Logger.log(
            `Failed to confirm email verification due to error: ${err.message}`
          );
      }
    }

    return result;
  }

  startCheckNewNotifs() {
    /**
     * To call whenever header is ready to listen for notifs change, also we need to block this incase user is on /notifications
     * and we're reading the new notifications
     */

    if (Store.CONFIGS.query && Store.CONFIGS.query.no_notif_check) {
      Logger.log(`No notif check it is :)`);
      return;
    }

    if (!this.userNewNotifsSubscriber) {
      Logger.log('Start to check for user new notifs');
      this.userNewNotifsSubscriber = interval(20000).subscribe(async () => {
        if (!this.VARS.blockNewNotificationsUI) {
          if (this.VARS.chekingNewNotifs) {
            return;
          }

          this.VARS.chekingNewNotifs = true;
          await this.checkNewNotifsGET();
          this.VARS.chekingNewNotifs = false;
        } else Logger.log(`Checking new notifs currently blocked`);
      });
    }
  }

  endCheckNewNotifs() {
    if (this.userNewNotifsSubscriber) {
      this.userNewNotifsSubscriber.unsubscribe();
    }
  }

  async markNotificationsRead(ids = []) {
    if (this.profile_context) {
      this.VARS.blockNewNotificationsUI = true;
      this.profile_context.userProfileFetchingNewNotifOBS.next({
        user: this.profile_context.state.user,
        mark_read: true
      });

      if (!ids || typeof ids.length !== 'number') {
        ids = [];
      }

      const { user, isLoggedIn } = this.profile_context.state;
      const { token, id, lb, n, username_ref } = user.auth;

      if (!isLoggedIn) {
        this.VARS.blockNewNotificationsUI = false;
        return;
      } else {
        this.VARS.markReadNotifs = true;
      }

      try {
        if (ids && ids.length) {
          let i = 0;

          while (i < this.user_highlighted_notifications.length) {
            const highlighted = this.user_highlighted_notifications[i];

            if (
              highlighted &&
              highlighted.ref_id &&
              ids.includes(highlighted.ref_id)
            ) {
              this.user_highlighted_notifications.splice(i, 1);
              continue;
            }

            i += 1;
          }
        }

        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          data: {
            ids
          },
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/user/notifications/mark-read?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          const { has_unread } = res.data;
          user.newNotifs = !!has_unread;

          await this.profile_context.setStateAsync({
            user
          });
          this.VARS.blockNewNotificationsUI = false;
          this.profile_context.userProfileFetchingNewNotifOBS.next({
            user: this.profile_context.state.user
          });
          this.VARS.markReadNotifs = false;
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          this.VARS.blockNewNotificationsUI = false;
          this.VARS.markReadNotifs = false;
          return await ProfileAPI.sendLogout();
        } else {
          throw new Error(
            'Unexpected block if-else block in mark read notifications'
          );
        }
      } catch (err) {
        Logger.log(`Failed to mark notifications read: ${err.message}`);
        this.VARS.blockNewNotificationsUI = false;
        this.VARS.markReadNotifs = false;
      }
    }
  }

  markAccumulatedNotificationsRead(ids = []) {
    if (ids.length <= 0 || !Array.isArray(ids)) {
      return;
    }

    for (let i = 0; i < this.user_notifications_accumulated.length; i++) {
      const n = this.user_notifications_accumulated[i];

      if (
        n &&
        n.unread &&
        typeof n.ref_id === 'string' &&
        ids.indexOf(n.ref_id) >= 0
      ) {
        this.user_notifications_accumulated[i].unread = false;
      }
    }
  }

  resetNotifications() {
    this.user_notifications_accumulated.length = 0;
    this.user_notifications_first_page.length = 0;
    this.user_notifications_has_next = false;
    this.user_notifications_page = 1;
    this.VARS.notifsFirstGET = false;
    this.VARS.gettingNotifs = false;
  }

  async checkNewNotifsGET() {
    if (this.profile_context) {
      const { user, isLoggedIn } = this.profile_context.state;

      if (
        isLoggedIn &&
        !user.isFetchingInfo &&
        !this.VARS.blockNewNotificationsUI
      ) {
        try {
          /**
           * Note this endpoint repond
           * also comes to inform if user has new tasks,
           * so we can fetch and inform there's an assigned or him/her as a subscriber to a task
           */
          Logger.log(`Checking user notifs`);

          const { token, id, lb, n, username_ref } = user.auth;
          const res = await axios({
            method: 'GET',
            baseURL:
              k.API_DASHBOARD_URLS[
                Store.CONFIGS.IS_DEV ? 'development' : 'production'
              ],
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            },
            url: `/v1/user/i/notif?auth_token_id=${id}&lb=${lb}&n=${n}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.status === 200 && res.data) {
            const { new_notifs, new_tasks } = res.data;
            const newNotifs = new_notifs === 1 ? true : false;
            const newTasks = new_tasks === 1 ? true : false;

            user.newNotifs = newNotifs;
            user.newTasks = newTasks;

            if (newNotifs) {
              Logger.log(`User has new notifications`);
            }

            await this.profile_context.setStateAsync({ user });

            this.profile_context.userProfileFetchingNewNotifOBS.next({
              user: this.profile_context.state.user
            });
          } else if (res.data.auth_expired) {
            /**
             * We refresh user, logout for now
             */
            return await ProfileAPI.sendLogout();
          } else if (
            res.status === 401 ||
            res.status === 404 ||
            (res.data && res.data.removed)
          ) {
            return await ProfileAPI.sendLogout();
          }
        } catch (err) {
          Logger.log(
            `Failed to fetch user new notifs,tasks info: ${err.message}`
          );
        }
      }
    }
  }

  isUserAdmin() {
    try {
      return Boolean(
        this.profile_context &&
          this.profile_context.state &&
          this.profile_context.state.user &&
          this.profile_context.state.user.access &&
          this.profile_context.state.user.access.length &&
          this.profile_context.state.user.access.includes &&
          this.profile_context.state.user.access.includes('admin')
      );
    } catch {
      return false;
    }
  }

  isUserPlanExpired() {
    try {
      return Boolean(
        this.profile_context &&
          this.profile_context.state &&
          this.profile_context.state.user &&
          this.profile_context.state.user.plan &&
          !this.profile_context.state.user.plan.isLifetime &&
          this.profile_context.state.user.plan.isExpired
      );
    } catch {
      return false;
    }
  }

  isUserPremiumFromStripePay() {
    if (this.profile_context) {
      const { user } = this.profile_context.state;
      const plan = user.plan;

      return !!plan.stripePaid;
    }

    return false;
  }

  isUserPremium() {
    if (this.profile_context) {
      const { user } = this.profile_context.state;
      const plan = user.plan;
      return plan.isPremiumTeams;
    }

    return false;
  }

  isUserPersonalPlan() {
    if (this.profile_context) {
      const { user } = this.profile_context.state;
      const plan = user.plan;
      return !plan.isPremiumTeams;
    }

    return false;
  }

  isUserAbleToCreateTask() {
    try {
      if (this.profile_context) {
        const { user, isFetchingInfo } = this.profile_context.state;
        const plan = user.plan;
        const isPremium = plan.isPremium || plan.isPremiumTeams;
        const tasksMonth = plan.tasksMonth;
        const end = plan.end;
        const now = Date.now();

        if (isFetchingInfo) {
          return false;
        }

        if (!isPremium) {
          // is free, check task per month

          if (Date.now() === end) {
            return true;
          } else if (tasksMonth + 1 <= plan.tasksCreatedMonth) {
            return false;
          } else return true;
        } else {
          // check if expire

          if (plan.isPremium && now >= hrsToMilliseconds(24) + end) {
            // now expire + 1day allowance
            return false;
          } else if (isPremium && now + hrsToMilliseconds(1) > end) {
            // an hour in advance check
            return false;
          }

          return true;
        }
      }

      return false;
    } catch {
      return false;
    }
  }

  async getUserPlan() {
    if (this.profile_context) {
      const { user } = this.profile_context.state;

      try {
        await this.profile_context.setStateAsync({
          isFetchingPlan: true
        });

        this.profile_context.userProfileFetchinPlan.next({ fetching: true });

        const { id, lb, n } = user.auth;
        const headers = getAuthHeaders(user);
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const res = await axios({
          headers,
          baseURL,
          method: 'GET',
          url: `/v1/user/plan?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });
        const onboarding = AppAPI.getLocalConfig('onboarding');

        if (res && res.status === 200 && res.data && res.data.success) {
          // current_count_created_month

          user.plan.stripePaid = res.data.stripe;
          user.plan.isPremium =
            res.data.is_premium || res.data.is_premium_w_group;
          user.plan.isLifetime =
            (res.data.is_premium || res.data.is_premium_w_group) &&
            res.data.is_lifetime;
          // lifetime license should only apply to premium individuals/teams
          user.plan.isPremiumTeams = res.data.is_premium_w_group;
          user.plan.maxTags = res.data.tags_max;
          user.plan.tasksMonth = res.data.task_month;
          user.plan.tasksCreatedMonth = res.data.current_count_created_month;
          user.plan.started = res.data.is_seconds
            ? res.data.start_month * 1000
            : res.data.start_month;
          user.plan.end = res.data.is_seconds
            ? res.data.end_month * 1000
            : res.data.end_month;
          user.plan.isExpired =
            !user.plan.isLifetime && Boolean(res.data.is_expired);
          if (res.data.is_premium_w_group) {
            user.plan.type = i18n('user_plan_premium_teams_title');
          } else if (res.data.is_premium) {
            user.plan.type = i18n('user_plan_premium_title');
          }

          if (
            (onboarding.free1MonthPremium &&
              !res.data.onboard_free_one_month_claimed) ||
            (!onboarding.free1MonthPremium &&
              res.data.onboard_free_one_month_claimed)
          ) {
            // if true then show modal onboarding
            // since it's claimed now
            onboarding.free1MonthPremium =
              res.data.onboard_free_one_month_claimed;
            // prompt user with onboarding modal
            await AppAPI.setLocalConfigs('onboarding', onboarding);
          }

          await this.profile_context.setStateAsync({
            isFetchingPlan: false
          });
        } else if (res.data.auth_expired) {
          /**
           * We refresh user, logout for now
           */
          return await ProfileAPI.sendLogout();
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          return await ProfileAPI.sendLogout();
        }
      } catch (err) {
        Logger.log(`Failed to fetch user plan info: ${err.message}`);
      }

      await this.profile_context.setStateAsync({
        user
      });

      this.profile_context.userProfileFetchinPlan.next({ fetching: false });
    }
  }

  async increaseCreatedTaskInMonthUI() {
    if (this.profile_context) {
      const { user } = this.profile_context.state;

      const { isFetchingPlan } = this.profile_context.state;

      if (isFetchingPlan) {
        return;
      } else {
        user.plan.tasksCreatedMonth += 1;

        await this.profile_context.setStateAsync({
          user
        });
      }
    }
  }

  seUsertNotificationsPage(p = 1) {
    if (p <= 0) return;

    this.user_notifications_page = p;
  }

  seUsertNotificationsNextPage(followFetch = false) {
    if (this.user_notifications_has_next) {
      this.user_notifications_page += 1;

      if (followFetch) {
        return this.userNotificationsGET();
      }
    }
  }

  async userNotificationsGET() {
    if (this.profile_context) {
      try {
        const { user, isLoggedIn } = this.profile_context.state;
        if (!isLoggedIn) return;
        const { id, lb, n } = user.auth;
        this.profile_context.userProfileFetchingNotificationsOBS.next({
          fetching: true
        });
        this.VARS.gettingNotifs = true;

        const headers = getAuthHeaders(user);
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const res = await axios({
          headers,
          baseURL,
          method: 'GET',
          url: `/v1/user/notifications?auth_token_id=${id}&lb=${lb}&n=${n}&p=${this.user_notifications_page}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          /**
           * Store in accumulated
           */

          if (!this.VARS.notifsFirstGET) {
            this.VARS.notifsFirstGET = true;
          }

          if (this.user_notifications_accumulated.length <= 0) {
            this.user_notifications_first_page = [...res.data.value];
          }

          this.user_notifications_has_next = res.data.has_next;
          this.user_notifications_accumulated = [
            ...this.user_notifications_accumulated,
            ...res.data.value
          ];
          this.profile_context.userProfileFetchingNotificationsOBS.next({
            fetching: false,
            success: true
          });
          this.VARS.gettingNotifs = false;
        } else if (
          res &&
          (res.status === 401 ||
            res.status === 404 ||
            (res.data && res.data.removed))
        ) {
          return await ProfileAPI.sendLogout();
        } else {
          throw new Error(
            'Unexpected status from getting user notifs on cloud'
          );
        }
      } catch (err) {
        Logger.log(`Failed to fetch user notifications: ${err.message}`);
        /**
         * @todo we check if it's offline
         */
        this.VARS.gettingNotifs = false;
      }
    }
  }

  getStoredHighlightedNotifications() {
    return this.user_highlighted_notifications;
  }

  async getUserHighlightedNotifications() {
    // 10 most recent unread notifs
    if (this.profile_context) {
      try {
        const { user, isLoggedIn } = this.profile_context.state;
        if (!isLoggedIn) return;

        if (this.VARS.gettingHighlightedNotifs) {
          return;
        } else {
          this.VARS.gettingHighlightedNotifs = true;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'GET',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/user/notifications/highlighted?auth_token_id=${id}&lb=${lb}&n=${n}&p=${this.user_notifications_page}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.valid) {
          this.VARS.gettingHighlightedNotifs = false;
          this.user_highlighted_notifications = res.data.notifs;

          return this.user_highlighted_notifications;
        } else {
          this.VARS.gettingHighlightedNotifs = false;
          return [];
        }
      } catch (err) {
        Logger.log(
          `Failed to fetch user highlighted notifications: ${err.message}`
        );

        this.VARS.gettingHighlightedNotifs = false;

        return [];
      }
    }

    return [];
  }

  async updateUserPassword(new_password = '') {
    if (this.profile_context && ProfileAPI) {
      try {
        const { user, isLoggedIn } = this.profile_context.state;
        if (!isLoggedIn) return;

        const x = this.profile_context.encVAL.pwonly.id.split('-');
        const arr1 = this.profile_context.encVAL.pwonly.k1;
        const arr2p = x[0];
        const pRES = ProfileAPI.PUBLIC.enc(new_password, arr1, arr2p);
        const { token, id, lb, n, username_ref } = user.auth;

        if (pRES && (!pRES.value || pRES.err)) {
          this.userUpdatePasswordOBS.next({ success: false });
          return;
        }

        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            password: pRES.value
          },
          url: `/v1/user/update/pw?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          this.userUpdatePasswordOBS.next({ success: true });
        } else if (res && res.data && res.data.limit_reached) {
          this.userUpdatePasswordOBS.next({
            limit_reached: true,
            last_updated: res.data.last_updated,
            success: false
          });
        } else {
          this.userUpdatePasswordOBS.next({ success: false });
        }
      } catch (err) {
        Logger.log(`Failed to update user password: ${err.message}`);
        this.userUpdatePasswordOBS.next({ success: false });
      }
    }
  }

  async updateUserStats(key = '', value) {
    const result = { err: true };
    if (this.profile_context && ProfileAPI) {
      const { user, isLoggedIn } = this.profile_context.state;

      try {
        if (!isLoggedIn) return result;
        else if (key) {
          const { token, id, lb, n, username_ref } = user.auth;
          const opt = {
            [key]: value
          };

          const res = await axios({
            method: 'POST',
            baseURL:
              k.API_DASHBOARD_URLS[
                Store.CONFIGS.IS_DEV ? 'development' : 'production'
              ],
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            },
            data: {
              opt
            },
            url: `/v1/user/update/stats?auth_token_id=${id}&lb=${lb}&n=${n}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data && res.data.success) {
            result.err = false;
          }
        }
      } catch (err) {
        Logger.log(
          `Failed to update user stat: ${key} w error: ${err.message}`
        );
      }
    }

    return result;
  }

  async updateUserSettings(props = {}) {
    const result = { err: true };

    if (this.profile_context) {
      const { user, isLoggedIn } = this.profile_context.state;

      if (isLoggedIn) {
        try {
          if (!props) {
            return result;
          }

          const { token, id, lb, n, username_ref } = user.auth;
          const res = await axios({
            method: 'POST',
            baseURL:
              k.API_DASHBOARD_URLS[
                Store.CONFIGS.IS_DEV ? 'development' : 'production'
              ],
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            },
            data: {
              props: {
                ...props
              }
            },
            url: `/v1/user/update/settings?auth_token_id=${id}&lb=${lb}&n=${n}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data && res.data.success) {
            result.err = false;
          }
        } catch (err) {
          Logger.log(
            `Failed to update user settings new props`,
            props,
            ` w error: ${err.message}`
          );
        }
      }
    }

    return result;
  }
}

export default UserData;
