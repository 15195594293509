import UserData from './UserData';
import Public from './Public';
import Spaces from './Spaces';
import UserTasks from './UserTasks';
import Tasks from './Tasks';
import UserProfile from './UserProfile';
import Admin from './Admin';
import Search from './Search';
import Payment from './Payment';
import axios from 'axios';
import k from 'src/constants/k';
import Store from 'src/lib/store';
import Logger from 'src/lib/Logger';
import AppAPI from 'src/app-manager/API';
import { Subject } from 'rxjs';
import { isAlphaNumeric } from 'src/lib/UserInputs';

class ProfileAPI {
  static PUBLIC = null;
  static USER_DATA = null;
  static USER_PROFILE = null;
  static USER_TASKS = null;
  static SPACES = null;
  static onUserLogoutOBS = null;
  static TASKS = null;
  static ADMIN = null;
  static SEARCH = null;
  static PAYMENT = null;

  setContext(profile_context) {
    this.profile_context = profile_context;
    this.USER_DATA.setContext(profile_context);
    this.USER_TASKS.setContext(profile_context);
    this.USER_PROFILE.setContext(profile_context);
    this.PUBLIC.setContext(profile_context);
    this.SPACES.setContext(profile_context);
    this.TASKS.setContext(profile_context);
    this.ADMIN.setContext(profile_context);
    this.SEARCH.setContext(profile_context);
    this.PAYMENT.setContext(profile_context);
  }

  constructor() {
    this.PUBLIC = new Public();
    this.SPACES = new Spaces();
    this.USER_DATA = new UserData();
    this.USER_TASKS = new UserTasks();
    this.TASKS = new Tasks();
    this.USER_PROFILE = new UserProfile();
    this.ADMIN = new Admin();
    this.SEARCH = new Search();
    this.PAYMENT = new Payment();
    this.onUserLogoutOBS = new Subject();
    this.sendLogout = this.sendLogout.bind(this);
  }

  release() {
    this.USER_TASKS.release();
    delete this.USER_TASKS;
  }

  async start() {
    /**
     * Initialisation
     */
    this.USER_TASKS.start();
    this.USER_DATA.start();
  }

  onLogout(fn) {
    if (this.onUserLogoutOBS) {
      return this.onUserLogoutOBS.subscribe({ next: fn });
    }

    return null;
  }

  async sendLogout() {
    /**
     * We logout user we send request on DB to invalidate auth token
     */

    if (this.profile_context) {
      try {
        const { user, isLoggedIn } = this.profile_context.state;
        if (!isLoggedIn) {
          return;
        } else {
          this.onUserLogoutOBS.next();
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const auth_token_id = id;
        const auth_token = token;
        const letters_batch = lb;
        const batch_num = n;
        const username_map = username_ref;
        const taskDraft = AppAPI.getLocalConfig('taskDraft');

        taskDraft.valid = false;
        taskDraft.title = '';
        taskDraft.desc = '';

        await AppAPI.setLocalConfigs('taskDraft', taskDraft);
        await AppAPI.setGlobalConfig('taskViewColumn', 0);
        await AppAPI.setLocalConfigs('tasks', {
          selectedTags: [],
          selectedState: []
        });

        await this.profile_context.removeUserSession();

        if (this.TASKS && this.TASKS.clearCached) {
          this.TASKS.clearCached();
        }

        if (this.USER_TASKS && this.USER_TASKS.resetVARS) {
          this.USER_TASKS.resetVARS();
        }

        if (this.SPACES) {
          this.SPACES.reset();
        }

        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${auth_token}`,
            'x-custom-user-session': `${username_map}`
          },
          url: `/v1/user/logout?auth_token_id=${auth_token_id}&lb=${letters_batch}&n=${batch_num}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200) {
          Logger.log(`Successfully logged out user on cloud`);
        }
      } catch (err) {
        Logger.log(`Failed to logout user on cloud: ${err.message}`);
      }
    }
  }

  userIdIsValid(id = '') {
    return (
      typeof id === 'string' &&
      id.length > 8 &&
      id.includes('lttr') &&
      id.includes('mn') &&
      isAlphaNumeric(id)
    );
  }

  getEditTaskURL(id = '', uref = '') {
    try {
      const pathname = window.location.pathname;
      const query = Store.CONFIGS.getQuery();

      if (!query['action']) {
        query['action'] = 'edit_task';
      }

      query['id'] = `${id || ''}`.toUpperCase();
      query['t'] = uref || '';

      const search = Object.keys(query)
        .map((k, idx) => {
          if (
            query[k] === '' ||
            (k === 'err' && typeof query[k] === 'boolean')
          ) {
            return '';
          } else if (k === 'action' && query[k] !== 'edit_task') {
            query[k] = 'edit_task';
          }

          return `${idx > 0 ? '&' : '?'}${k}=${query[k]}`;
        })
        .join('');

      const basePath = `${
        pathname.charAt(0) !== '/' ? '/' + pathname : `${pathname}`
      }`;

      return `${basePath}${search ? search : ''}`;
    } catch {
      return '';
    }
  }
}

export default new ProfileAPI();
