import React, { Component } from 'react';
import cx from 'classnames';
import styles from './TaskPassword.scss';
import CustomButton from 'src/components/button';
import k from 'src/constants/k';
import ProfileAPI from 'src/profile-manager/API';
import ModalAPI from 'src/modal-manager/API';
import AppAPI from 'src/app-manager/API';
import AppLogo from 'src/components/app-logo';
import i18n, { Markdown } from 'src/locales';
import { isAlphaNumeric, isStringNotEmpty } from 'src/lib/UserInputs';
import { fromEvent } from 'rxjs';
import { Redirect, Link } from 'react-router-dom';
import { TextInputField } from 'evergreen-ui';

class TaskPassword extends Component {
  onInspectResultSubscriber = null;
  onKeydownSubscriber = null;
  onDocumentClickSubscriber = null;

  state = {
    submitted: false,
    mounted: false,
    password: '',
    passwordInfo: { err: false, msg: '' },
    status: '',
    redirectDashboard: false,
    tries: 0
  };

  constructor() {
    super();

    this.onPasscodeInput = this.onPasscodeInput.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInspectResult = this.onInspectResult.bind(this);
    this.onDocumentClicked = this.onDocumentClicked.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.onInspectResultSubscriber = ProfileAPI.PUBLIC.onInspectTaskPWResult(
      this.onInspectResult
    );

    this.onDocumentClickSubscriber = fromEvent(document, 'click').subscribe(
      this.onDocumentClicked
    );

    this.onKeydownSubscriber = fromEvent(
      document ? document : window,
      'keydown'
    ).subscribe(this.onKeydown.bind(this));

    this.setState({
      mounted: true
    });
  }

  componentWillUnmount() {
    this.setState({
      mounted: false
    });

    if (this.onDocumentClickSubscriber) {
      this.onDocumentClickSubscriber.unsubscribe();
    }

    if (this.onInspectResultSubscriber) {
      this.onInspectResultSubscriber.unsubscribe();
    }

    if (this.onKeydownSubscriber) {
      this.onKeydownSubscriber.unsubscribe();
    }

    delete this.onDocumentClicked;
    ModalAPI.goDeactivate();
  }

  async onKeydown(evt) {
    if (
      evt.keyCode === 13 &&
      this.state.mounted &&
      !this.state.passwordInfo.err
    ) {
      await this.onSubmit();
    }
  }

  onDocumentClicked(evt) {
    if (evt) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (
        target &&
        target.hasAttribute &&
        target.hasAttribute('href') &&
        (target.getAttribute('href') === '/sign-up' ||
          target.getAttribute('href') === '/login' ||
          target.getAttribute('href') === '/user')
      ) {
        if (this.state.mounted) {
          this.setState(
            {
              mounted: false
            },
            ModalAPI.goDeactivate
          );
        }
      }
    }
  }

  onInspectResult({ success = false, respond = null }) {
    const { mounted, passwordInfo, submitted, tries } = this.state;

    if (!mounted) {
      return;
    } else if (tries >= k.USER_TASK_PASSWORD_MAX_TRIES) {
      if (ProfileAPI.USER_DATA.checkIsLoggedIn()) {
        this.setState({
          redirectDashboard: true
        });
      } else {
        window.location.href = 'https://getchamu.com';
      }
    } else if (!success || !respond) {
      passwordInfo.err = true;
      passwordInfo.msg = i18n(
        'task_view_required_passcode_status_invalid_passcode'
      );

      this.setState({
        passwordInfo,
        submitted: false
      });
    } else if (submitted) {
      this.setState(
        {
          submitted: false,
          mounted: false
        },
        this.props.confirm({ ...respond })
      );
    }
  }

  onPasscodeInput(evt) {
    if (!evt || !evt?.target) {
      return;
    }

    const { mounted, passwordInfo } = this.state;
    const target = evt?.target || evt?.srcElement;
    const value = target?.value || '';

    if (!mounted) {
      return;
    }

    if (passwordInfo.err) {
      passwordInfo.err = false;
      passwordInfo.msg = '';

      this.setState({
        passwordInfo,
        password: value
      });
    } else {
      this.setState({
        password: value
      });
    }
  }

  onInputFocus() {
    const { passwordInfo, mounted, submitted } = this.state;
    if (!mounted || submitted) {
      return;
    }

    if (passwordInfo.err) {
      passwordInfo.err = false;
      passwordInfo.msg = '';

      this.setState({
        passwordInfo
      });
    }
  }

  onSubmit = async () => {
    const { password, mounted, submitted, passwordInfo, tries } = this.state;
    if (!mounted || submitted || passwordInfo.err) {
      return;
    }

    if (
      password.length < k.USER_TASK_PRIVACY_PW_PROPERTIES.minLength ||
      password.length > k.USER_TASK_PRIVACY_PW_PROPERTIES.maxLength ||
      !isAlphaNumeric(password)
    ) {
      passwordInfo.err = true;
      passwordInfo.msg = i18n(
        'task_view_required_passcode_status_invalid_passcode'
      );

      return await this.setStateAsync({
        passwordInfo,
        tries: tries + 1
      });
    } else {
      await this.setStateAsync({
        submitted: true,
        tries: tries + 1
      });

      await ProfileAPI.PUBLIC.sendInpsectTaskPW(
        this.props.taskID,
        this.props.universalREF,
        password
      );
    }
  };

  render() {
    const {
      password,
      passwordInfo,
      submitted,
      status,
      redirectDashboard,
      tries
    } = this.state;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const remaining = k.USER_TASK_PASSWORD_MAX_TRIES - tries;
    //http://localhost:3001/view/A000185?t=YAOeg8udKkmYX2CVFBZl
    const isLoggedIn = ProfileAPI.USER_DATA.checkIsLoggedIn();

    if (redirectDashboard) {
      return <Redirect to={'/user'} />;
    }

    return (
      <div className={cx(styles.flex_row_xy, styles.task_password)}>
        <div
          className={cx(styles.task_password_raw, {
            [styles.task_password_raw_dark]: isThemeDarkMode
          })}
        >
          <div className={styles.logo_wrap}>
            <AppLogo className={styles.logo} />
            <div className={styles.cover}>
              <Link to={isLoggedIn ? '/user' : '/login'} />
            </div>
          </div>
          <div
            className={cx(styles.message, {
              [styles.message_dark]: isThemeDarkMode
            })}
          >
            <h5>{i18n('task_view_required_passcode_message')}</h5>
          </div>
          <div
            className={cx(styles.tries, {
              [styles.tries_dark]: isThemeDarkMode,
              [styles.hide_element]: submitted || remaining < 1
            })}
          >
            <div>
              <h5>
                {Markdown('task_view_required_passcode_tries_left', {
                  num1: `${remaining}`
                })}
              </h5>
            </div>
          </div>
          <div
            className={cx(styles.task_password_raw_input, {
              [styles.task_password_raw_input_dark]: isThemeDarkMode
            })}
          >
            <TextInputField
              label={''}
              placeholder={i18n('task_view_required_passcode_placeholder')}
              onFocus={this.onInputFocus}
              onChange={this.onPasscodeInput}
              value={password}
              type={'password'}
              maxLength={k.USER_TASK_PRIVACY_PW_PROPERTIES.maxLength}
              className={styles.password}
              validationMessage={passwordInfo.err && passwordInfo.msg}
            />
          </div>
          <div
            className={cx(styles.status_wrap, {
              [styles.hide_element]: !isStringNotEmpty(status)
            })}
          >
            <h5>{status}</h5>
          </div>
          <div className={cx(styles.actions, styles.flex_row_xy)}>
            <CustomButton
              onClick={this.onSubmit}
              loading={submitted}
              disabled={submitted}
              className={styles.submit_button}
              variant={'primaryPurple'}
              label={i18n('task_view_required_passcode_submit_title')}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TaskPassword;
