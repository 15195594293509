import React from 'react';
import cx from 'classnames';
import styles from './MessageHover.scss';

const MessageHover = props => {
  return (
    <div
      className={cx(styles.message_hover, {
        [`${props.className}`]:
          typeof props.className === 'string' && !!props.className,
        [styles.hide_element]: !props.message
      })}
    >
      <div>
        <h5>{props.message || ''}</h5>
      </div>
    </div>
  );
};

export default MessageHover;
