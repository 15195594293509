import UserDOM from 'src/lib/UserDOM';
import { isNumber, isValidObject } from 'src/helpers/utils';
import 'src/lib/formats/YoutubeBlot.css';

const TASK_DESCRIPTION_VARS = new (class {
  QUILL = null;
  TARGET_DOM = null;
  CHECKING_FORMAT_FLAG = false;
})();

function initQuill(wrap, description_delta = { ops: [] }) {
  if (wrap) {
    const Quill = window.Quill;
    const target = wrap.querySelector('#cha-task-view-des-editor');

    if (target && Quill) {
      TASK_DESCRIPTION_VARS.QUILL = new Quill(target, {
        modules: {
          syntax: true
        }
      });

      const parsed = { ...description_delta };

      if (isNumber(parsed?.ops?.length)) {
        for (let i = 0; i < parsed.ops.length; i++) {
          const currentOp = parsed.ops[i];
          if (isValidObject(currentOp?.insert?.file)) {
            currentOp.insert.file.edit = false;
          } else if (isValidObject(currentOp?.insert?.atinfo)) {
            currentOp.insert.atinfo.edit = false;
          }
        }
      }

      TASK_DESCRIPTION_VARS.QUILL.setContents(parsed.ops);
      TASK_DESCRIPTION_VARS.QUILL.enable(false);
    }
  }
}

function cleanEditor(target) {
  if (UserDOM.isElement(target)) {
    if (
      target.children &&
      typeof target.children.length === 'number' &&
      target.children.length > 0
    ) {
      let i = 0;

      while (target.hasChildNodes() && i < target.children.length) {
        const child = target.children[i];

        if (!child || child.nodeType !== 1) {
          break;
        }

        if (child.hasChildNodes() && child.children.length > 0) {
          cleanEditor(child);
        }

        if (child.style && !!child.removeAttribute) {
          /**
           * Default text for now
           */

          child.removeAttribute('style');
        } else if (child.style) {
          child.style = '';
        }

        if (child.nodeType === 1 && UserDOM.notAllowed(child)) {
          child.remove();
          continue;
        } else if (
          child.nodeType === 1 &&
          (child.nodeName.toLowerCase() === 'h3' ||
            child.nodeName.toLowerCase() === 'h4' ||
            child.nodeName.toLowerCase() === 'h5')
        ) {
          if (TASK_DESCRIPTION_VARS.QUILL) {
            child.remove();
            continue;
          }
        }

        i += 1;
      }
    }
  }

  return true;
}

// eslint-disable-next-line
export default {
  initQuill,
  // eslint-disable-next-line
  cleanEditor
};
