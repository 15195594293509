import Logger from 'src/lib/Logger';
import Store from 'src/lib/store';
import k from 'src/constants/k';

export function timeout(ms) {
  return new Promise(resolve => {
    const _timeout = setTimeout(
      () => {
        clearTimeout(_timeout);
        return resolve();
      },
      ms ? ms : 50
    );
  });
}

export function getHHMMAMPMFormat(hh, mm) {
  let isPM = false;
  let finalhh = hh;

  if (hh >= 12 && hh <= 24) {
    isPM = true;
  }

  if (isPM) {
    finalhh = hh - 12;
    finalhh = finalhh <= 0 ? 12 : finalhh;

    if (hh === 24) {
      isPM = false;
    }
  }

  if (Number(hh) === 0 || hh === 0) {
    finalhh = 12;
    isPM = false;
  }

  if (`${finalhh}`.length <= 1) {
    finalhh = `0${finalhh}`;
  }

  if (`${mm}`.length <= 1) {
    mm = `0${mm}`;
  }

  return `${finalhh}:${mm} ${isPM ? 'PM' : 'AM'}`;
}

export function daysToSeconds(days) {
  return days * 24 * 60 * 60;
}

export function hrsToSeconds(hrs) {
  return hrs * 60 * 60;
}

export function hrsToMilliseconds(hrs = 0) {
  return hrsToSeconds(hrs) * 1000;
}

export function isYTURL(str = '') {
  // eslint-disable-next-line
  const match =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

  return typeof str === 'string' && str.length > 0 && match.test(str);
}

export function isTwitterStatusURL(str = '') {
  //eslint-disable-next-line
  const match = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)$/;

  return typeof str === 'string' && str.length && match.test(str);
}

export function getStringSizeKB(str = '') {
  if (typeof str !== 'string') {
    return 0;
  } else {
    return Number((new Blob([`${str}`]).size / 1024).toFixed(2));
  }
}

export function isImageURL(url = '') {
  const match = /\.(jpeg|jpg|gif|png)$/;

  return typeof url === 'string' && url.length > 0 && match.test(url);
}

export function isWebURL(url = '') {
  try {
    if (url && typeof url === 'string' && url.length > 0) {
      const _url = new URL(url);
      if (_url.protocol === 'http://' || _url.protocol === 'https://') {
        return {
          err: false,
          url: encodeURI(url)
        };
      }

      return {
        err: false,
        url
      };
    }
  } catch (err) {
    if (err.stack) {
      Logger.log(err.stack);
    }
  }

  return { err: true, url: '' };
}

export function getSpaceNameInitials(name = '') {
  name = `${name}`.trim();
  const arr = name.split(' ');

  if (arr.length) {
    const firstName = arr[0];
    const lastName = arr[1];

    if (firstName && lastName) {
      return `${firstName.charAt(0)}${
        lastName.charAt(0) ? ` ${lastName.charAt(0)}` : ''
      }`.toUpperCase();
    } else if (firstName) {
      return `${firstName.charAt(0)}${
        firstName.charAt(1) ? ` ${firstName.charAt(1)}` : ''
      }`.toUpperCase();
    }
  }

  return '';
}

export function isValidDescriptionFormat(desc = '') {
  try {
    return Boolean(desc && desc.length && JSON.parse(desc).ops);
  } catch {
    return false;
  }
}

export function isFunction(fn) {
  return !!(
    fn &&
    (typeof fn === 'function' ||
      (fn &&
        typeof fn === 'object' &&
        fn.then &&
        typeof fn.then === 'function'))
  );
}

export function isString(str) {
  return !!(typeof str === 'string');
}

export function toNumber(num = 0) {
  return Number(num);
}

export function isNumber(num) {
  if (typeof num === 'number' || !Number.isNaN(num)) {
    return true;
  }

  if (typeof num !== 'string') {
    return;
  }

  // cast
  num = Number(num);

  return typeof num === 'number' && !Number.isNaN(num);
}

export function isNil(obj) {
  return typeof obj === 'undefined' || obj === null;
}

export function isValidObject(obj) {
  return Boolean(obj && !isNil(obj));
}

export function isArray(arr) {
  return !!(
    arr &&
    ((window.Array && Array.isArray(arr)) ||
      (typeof arr === 'object' && typeof arr.length === 'number'))
  );
}

export function formatSpaceTags(space = null) {
  if (!space) {
    return [];
  }

  try {
    let tags = [];
    for (const page of space.tagsPageFetched) {
      try {
        const currentPageKey = `page${page}`;
        const currentPageTags = space.tags[currentPageKey];

        if (!isNil(currentPageTags) && isArray(currentPageTags)) {
          tags = [...tags, ...currentPageTags];
        }
      } catch {}
    }

    return tags;
  } catch {
    return [];
  }
}

export function isFileAllowed(fileName = '') {
  if (!fileName) {
    return false;
  }

  fileName = `${fileName || ''}`;

  const lastDotIdx = fileName.lastIndexOf('.');
  const extensionString = fileName.substring(
    lastDotIdx > 0 ? lastDotIdx - 1 : 0,
    fileName.length
  );
  const notAllowed = ['.exe', '.sh', '.bash', '.cmd', '.git', '.app', '.msi'];
  const find = notAllowed.filter(ext => extensionString.includes(ext));

  if (!find?.length || find.length < 1) {
    return true;
  }

  return false;
}

export function isBoolean(bool) {
  return typeof bool === 'boolean';
}

export function head(arr) {
  if (isArray(arr)) {
    return arr[0];
  }

  return undefined;
}

export function isDev() {
  try {
    return Store && Store.CONFIGS ? Store.CONFIGS.isDev() : false;
  } catch {
    return false;
  }
}

export function filter(arr = [], matchFn = null) {
  const filtered = [];
  if (arr?.length) {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (matchFn && isFunction(matchFn) && !!matchFn(item)) {
        filtered.push(item);
      }
    }
  }

  return filtered;
}

export function size(collection) {
  if (isArray(collection) || isString(collection)) {
    return collection.length;
  } else if (collection && isNumber(collection?.length)) {
    return collection.length;
  }

  return 0;
}

export function toString(val) {
  return `${val || ''}`;
}

export function isMobileView() {
  return window && isNumber(window?.innerWidth) && window.innerWidth < 900;
}
export function isIOS() {
  const osName = `${Store.OS.getOSName()}`.toLowerCase();
  const deviceManufacturer =
    window && window.platform
      ? `${window.platform?.manufacturer}`.toLowerCase()
      : '';

  return (
    deviceManufacturer.includes('apple') ||
    deviceManufacturer.includes('mac') ||
    osName.includes('ios') ||
    osName.includes('apple')
  );
}

export function isIOSMobile() {
  const osName = `${Store.OS.getOSName()}`.toLowerCase();
  const isMobile = isMobileView();
  return isMobile && osName && isIOS();
}

export function compareStringSortAscending(a, b) {
  if (a && b) {
    const nameA = `${a.name || a.tag}`.toLowerCase();
    const nameB = `${b.name || b.tag}`.toLowerCase();
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    }
  }

  return 0;
}

export function includes(elem, match = '') {
  if (isString(elem)) {
    const cast = `${elem}`;
    return cast.indexOf(`${match}`) > -1;
  } else if (isArray(elem)) {
    return elem.indexOf(match) > -1;
  } else if (isValidObject(elem)) {
    return Boolean(elem[match]);
  }

  return false;
}

export function isEmpty(elem) {
  if (isNumber(elem?.length)) {
    return elem?.length < 1;
  }

  if (isArray(elem) || isString(elem)) {
    return !elem?.length || elem.length < 0;
  } else if (isValidObject(elem)) {
    const keys = Object.keys(elem);
    return !keys?.length || keys.length < 0;
  }

  return !elem?.length;
}

export function last(elem) {
  if (elem && isArray(elem) && elem?.length) {
    return elem[elem.length - 1];
  }

  return undefined;
}

export function isCordova() {
  try {
    return !!window.cordova;
  } catch {
    return false;
  }
}

export function isMobileApp() {
  try {
    return !!process.env.REACT_APP_MOBILE;
  } catch {
    return false;
  }
}

export function getAuthHeaders(user) {
  try {
    const { token, username_ref } = user.auth || {};

    return {
      authorization: `BEARER ${token}`,
      'x-custom-user-session': `${username_ref}`
    };
  } catch (err) {
    return {};
  }
}

export function toUpper(val) {
  return `${val}`.toUpperCase();
}

export function toLower(val) {
  return `${val || ''}`.toLowerCase();
}

export function isDebugMode() {
  try {
    return Store && Store.CONFIGS ? Store.CONFIGS.isDebugMode() : false;
  } catch {
    return false;
  }
}

export function isWhitespace(str = '') {
  return Boolean(/\s|\n/.test(str));
}

export function correctHHMMAFormat(time = '') {
  const [hh, mm] = `${time}`.split(':');
  return !!(hh < 23 && hh > -1 && mm < 59 && mm > -1);
}

export function hoursToHHMMAFormat(time = 0) {
  if (time < 1 || time >= 24 || !isNumber(time)) {
    return '00:00';
  } else {
    const hh = Math.ceil(time);

    if (hh > time) {
      return `${hh}:30`;
    } else {
      return `${hh}:00`;
    }
  }
}

export function hoursInStringToHHMMAMPMFormat(hours = '') {
  if (!hours || !includes(hours, ':')) {
    return '';
  }

  const [hh, mm] = hours.split(':').map(n => toNumber(n));
  return getHHMMAMPMFormat(hh, mm);
}

export function getHoursAndMinutesFromHHMMA(hoursInStr = '') {
  if (!hoursInStr || !hoursInStr.includes(':')) {
    return [];
  }

  return hoursInStr.split(':').map(n => toNumber(n));
}

export function isSupportedVideoFileFormat(format = '') {
  // hevc is supported windows 10 and above
  const supported = [
    'video/mp4',
    'video/Ogg',
    'video/WebM',
    'video/hevc',
    'video/HEVC'
  ];

  return supported.includes(format);
}

export function isValidFileLink(link = '') {
  const ALLOWED_LINK_STORAGE = k.ALLOWED_LINK_STORAGE;

  for (let i = 0; i < ALLOWED_LINK_STORAGE.length; i++) {
    const whiteListedLink = ALLOWED_LINK_STORAGE[i];
    const isHttps = `${link}`.includes('https');

    if (link?.length > 0 && isHttps && link.includes(whiteListedLink)) {
      return true;
    }
  }

  return false;
}

export function descriptionTextContents(description = { ops: [] }) {
  if (!description?.ops?.length) {
    return '';
  }

  return description.ops.reduce((text, op) => {
    if (typeof op.insert === 'string') {
      return text + op.insert;
    } else {
      return text + '\n';
    }
  }, '');
}
