import React, { Component } from 'react';
import cx from 'classnames';
import styles from './SignUpV2.scss';
import AppLogoIcon from 'src/components/app-logo-icon';
import AppAPI from 'src/app-manager/API';
import Button from 'src/components/button';
import k from 'src/constants/k';
import ProfileAPI from 'src/profile-manager/API';
import ModalAPI from 'src/modal-manager/API';
import InitUserLoading from 'src/components/init-user';
import Logger from 'src/lib/Logger';
import Store from 'src/lib/store';
import i18n, { URLMarkdown, Markdown } from 'src/locales';
import {
  Checkbox,
  TextInputField,
  TickCircleIcon,
  Spinner
} from 'evergreen-ui';
import UserInputs, {
  isCorrectEmailFormat,
  isStringNotEmpty
} from 'src/lib/UserInputs';
import { withUserProfileSettings } from 'src/profile-manager';
import { AdjustableAbsolutWrap } from 'src/layout/commons';
import { timer, fromEvent } from 'rxjs';
import { Redirect, withRouter } from 'react-router-dom';

class SignUpV2 extends Component {
  resultFromSubmitSignupSubscriber = null;
  lastSignupPayload = null;
  redirectTimerSubscriber = null;
  localDocumentOnKeyDownSubscriber = null;
  tries = 0;
  inviteCode = '';
  countryCode = '';

  state = {
    agreeToTerms: false,
    status: '',
    submitted: false,
    username: '',
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    usernameInfo: { err: false, msg: '' },
    passwordInfo: { err: false, msg: '' },
    emailInfo: { err: false, msg: '' },
    firstNameInfo: { err: false, msg: '' },
    lastNameInfo: { err: false, msg: '' },
    earlyEmailInfo: { err: false, msg: '' },
    successSignup: false,
    successRedirect: false,
    successEarlyAccess: false,
    allowSignup: false,
    mounted: false,
    loaded: false
  };

  constructor() {
    super();

    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.toggleAgreeToTerms = this.toggleAgreeToTerms.bind(this);
    this.componentOnSignupResult = this.componentOnSignupResult.bind(this);
    this.inputsOnFocus = this.inputsOnFocus.bind(this);
    this.usernameOnInput = this.inputTextChange.bind(this, 'username');
    this.passwordOnInput = this.inputTextChange.bind(this, 'password');
    this.emailOnInput = this.inputTextChange.bind(this, 'email');
    this.firstNameOnInput = this.inputTextChange.bind(this, 'firstName');
    this.lastNameOnInput = this.inputTextChange.bind(this, 'lastName');
  }

  componentDidMount() {
    let allowSignup = false;
    let countryCode = '';
    try {
      Store.CONFIGS.getQueryJSON();
    } catch {}

    try {
      countryCode = `${AppAPI.getGlobalConfig('country')}`.toLowerCase();
    } catch {}

    if (
      AppAPI.getGlobalConfig('successProbe') &&
      k.COUNTRIES_WHITELIST.indexOf(countryCode) > -1
    ) {
      this.inviteCode = 'CHAMUPHL';
      allowSignup = true;
    } else {
      // allow signup but international needs code
      allowSignup = true;
    }

    this.countryCode = countryCode;
    this.localDocumentOnKeyDownSubscriber = fromEvent(
      document ? document : window,
      'keydown'
    ).subscribe(e => {
      const { mounted, submitted } = this.state;

      if (e.keyCode === 13 && mounted && !submitted) {
        this.submit();
      }
    });

    this.resultFromSubmitSignupSubscriber = ProfileAPI.PUBLIC.onSignupResult(
      this.componentOnSignupResult
    );

    this.setState(
      {
        mounted: true,
        allowSignup
      },
      this.onMount.bind(this)
    );
  }

  componentWillUnmount() {
    if (this.resultFromSubmitSignupSubscriber) {
      this.resultFromSubmitSignupSubscriber.unsubscribe();
    }

    if (this.localDocumentOnKeyDownSubscriber) {
      this.localDocumentOnKeyDownSubscriber.unsubscribe();
    }

    if (this.redirectTimerSubscriber) {
      this.redirectTimerSubscriber.unsubscribe();
    }

    this.resetVARS();
    this.setState({
      mounted: false,
      loaded: false
    });
    this.lastSignupPayload = null;
  }

  async onMount() {
    try {
      if (Store.CONFIGS.query.invite) {
        const inviteC = Store.CONFIGS.query.invite;

        if (typeof inviteC === 'string' && inviteC.length > 1) {
          // verify on server

          Logger.log(`Verifying signup invite code`);

          const res = await ProfileAPI.PUBLIC.sendRedeemSignupInvite(inviteC);

          if (this.state.mounted) {
            if (res && !res.err) {
              this.inviteCode = inviteC;

              await this.setStateAsync({
                loaded: true,
                allowSignup: true
              });
            } else if (this.props.history) {
              await this.setStateAsync({
                loaded: true
              });

              this.props.history.push('/sign-up');
            } else {
              await this.setStateAsync({
                loaded: true
              });
            }
          }
        } else if (this.props.history) {
          await this.setStateAsync({
            loaded: true
          });

          this.props.history.push('/sign-up');
        }
      } else {
        await this.setStateAsync({
          loaded: true
        });
      }
    } catch (err) {
      console.error(err);

      await this.setStateAsync({
        loaded: true
      });
    }
  }

  inputsOnFocus() {
    const {
      usernameInfo,
      passwordInfo,
      emailInfo,
      earlyEmailInfo,
      firstNameInfo,
      lastNameInfo,
      submitted,
      status = '',
      allowSignup
    } = this.state;

    if (!submitted) {
      if (
        usernameInfo.err ||
        passwordInfo.err ||
        emailInfo.err ||
        earlyEmailInfo.err ||
        firstNameInfo.err ||
        lastNameInfo.err
      ) {
        usernameInfo.err = false;
        usernameInfo.msg = '';
        passwordInfo.err = false;
        passwordInfo.msg = '';
        emailInfo.err = false;
        emailInfo.msg = '';
        firstNameInfo.err = false;
        firstNameInfo.msg = '';
        lastNameInfo.err = false;
        lastNameInfo.msg = '';
        earlyEmailInfo.err = true;
        earlyEmailInfo.msg = '';

        this.setState({
          usernameInfo,
          passwordInfo,
          emailInfo,
          earlyEmailInfo,
          firstNameInfo,
          lastNameInfo,
          submitted: false,
          status: ''
        });
      }

      if (status.length > 0 && !allowSignup) {
        this.setState({
          status: ''
        });
      }
    }
  }

  onRedirectTimer = () => {
    if (this.redirectTimerSubscriber) {
      this.redirectTimerSubscriber.unsubscribe();
    }

    if (this.state.mounted) {
      this.setState({
        successRedirect: true,
        mounted: false,
        submitted: false
      });

      delete this.redirectTimerSubscriber;
    }
  };

  async componentOnSignupResult({
    success = false,
    respond,
    username_taken,
    email_taken,
    try_again
  }) {
    const {
      mounted,
      emailInfo,
      usernameInfo,
      passwordInfo,
      username,
      password,
      submitted
    } = this.state;

    if (!mounted) {
      return;
    }

    if (try_again) {
      if (this.lastSignupPayload && this.tries < 4) {
        const {
          username = '',
          password = '',
          email = '',
          firstName = '',
          lastName = ''
        } = this.lastSignupPayload;

        if (!submitted) {
          await this.setStateAsync({
            submitted: true
          });
        }

        Logger.log('Trying signup again');

        this.tries += 1;
        await ProfileAPI.PUBLIC.sendSignup(
          username,
          password,
          email,
          firstName,
          lastName,
          // for temporary
          this.inviteCode,
          this.countryCode
        );
      } else {
        this.tries = 0;

        await this.resetVARS();
        await this.setStateAsync({
          status: i18n('common_something_wrong_w_try'),
          submitted: false
        });
      }
    } else if (success) {
      await AppAPI.setGlobalConfig('toLoginUser', {
        pw: password,
        valid: true,
        username
      });

      await this.setStateAsync({
        successSignup: true,
        submitted: true,
        email: '',
        firstName: '',
        lastName: ''
      });

      this.redirectTimerSubscriber = timer(2000).subscribe(
        this.onRedirectTimer
      );
    } else if (email_taken) {
      emailInfo.err = true;
      emailInfo.msg = i18n('sign_up_status_email_taken');

      await this.setStateAsync({
        status: '',
        submitted: false,
        emailInfo
      });

      ModalAPI.toaster('warning', i18n('sign_up_status_email_taken'), 3500);
      return;
    } else if (username_taken) {
      usernameInfo.err = true;
      usernameInfo.msg = i18n('sign_up_status_username_taken');

      await this.setStateAsync({
        status: '',
        submitted: false,
        usernameInfo
      });
      ModalAPI.toaster('warning', i18n('sign_up_status_username_taken'), 3500);

      return;
    } else if (
      !success &&
      respond.invalids &&
      Array.isArray(respond.invalids) &&
      respond.invalids.length > 0
    ) {
      if (respond.invalids.includes('username')) {
        usernameInfo.err = true;
        usernameInfo.msg = i18n('sign_up_status_invalid_username');

        await this.setStateAsync({
          status: '',
          submitted: false,
          usernameInfo
        });
        ModalAPI.toaster(
          'warning',
          i18n('sign_up_status_invalid_username'),
          3500
        );
      } else if (respond.invalids.includes('email')) {
        emailInfo.err = true;
        emailInfo.msg = i18n('sign_up_status_invalid_email');

        await this.setStateAsync({
          status: '',
          submitted: false,
          emailInfo
        });

        ModalAPI.toaster('warning', i18n('sign_up_status_invalid_email'), 3500);
      } else if (respond.invalids.includes('password')) {
        passwordInfo.err = true;
        passwordInfo.msg = i18n('common_invalid_password');

        await this.setStateAsync({
          status: '',
          submitted: false,
          passwordInfo
        });
        ModalAPI.toaster('warning', i18n('common_invalid_password'), 3500);
      } else {
        await this.resetVARS();
        await this.setStateAsync({
          status: '',
          submitted: false
        });
        ModalAPI.toaster('warning', i18n('common_something_wrong_w_try'), 3500);
      }
    } else {
      await this.resetVARS();
      await this.setStateAsync({
        status: i18n('common_something_wrong_w_try'),
        submitted: false
      });
      ModalAPI.toaster('warning', i18n('common_something_wrong_w_try'), 4500);
    }
  }

  async toggleAgreeToTerms() {
    const { status = '', agreeToTerms } = this.state;

    await this.setStateAsync({
      agreeToTerms: !this.state.agreeToTerms
    });

    if (!agreeToTerms && status === i18n('sign_up_status_need_to_agree')) {
      this.setState({
        status: ''
      });
    }
  }

  async resetVARS() {
    const {
      usernameInfo,
      passwordInfo,
      emailInfo,
      firstNameInfo,
      lastNameInfo
    } = this.state;

    usernameInfo.err = false;
    usernameInfo.msg = '';
    passwordInfo.err = false;
    passwordInfo.msg = '';
    emailInfo.err = false;
    emailInfo.msg = '';
    firstNameInfo.err = false;
    firstNameInfo.msg = '';
    lastNameInfo.err = false;
    lastNameInfo.msg = '';

    await this.setStateAsync({
      username: '',
      password: '',
      email: '',
      firstName: '',
      lastName: '',
      successEarlyAccess: false,
      successRedirect: false,
      successSignup: false,
      usernameInfo,
      passwordInfo,
      emailInfo,
      firstNameInfo,
      lastNameInfo
    });
  }

  submit = async () => {
    const {
      mounted,
      agreeToTerms,
      allowSignup,
      username,
      usernameInfo,
      password,
      passwordInfo,
      email,
      emailInfo,
      firstName,
      firstNameInfo,
      lastName,
      lastNameInfo,
      submitted
    } = this.state;

    if (
      !mounted ||
      submitted ||
      passwordInfo.err ||
      usernameInfo.err ||
      firstName.err ||
      lastNameInfo.err ||
      emailInfo.err
    ) {
      return;
    }

    if (allowSignup) {
      // username check
      if (username.indexOf('.') >= 0) {
        usernameInfo.err = true;
        usernameInfo.msg = i18n('sign_up_status_invalid_no_dots');

        await this.setStateAsync({
          status: '',
          submitted: false,
          usernameInfo
        });
      } else if (username.indexOf('/') >= 0) {
        usernameInfo.err = true;
        usernameInfo.msg = i18n('sign_up_status_invalid_no_forward_slash');

        await this.setStateAsync({
          status: '',
          submitted: false,
          usernameInfo
        });
      } else if (!UserInputs.validUsername(username)) {
        usernameInfo.err = true;
        usernameInfo.msg = i18n(
          username.length < k.USERNAME_PROPERTIES.minLength
            ? 'sign_up_status_invalid_at_least_8_chars'
            : 'sign_up_username_tip_err'
        );

        await this.setStateAsync({
          status: '',
          submitted: false,
          usernameInfo
        });
      }

      // pw check
      if (password.indexOf('/') >= 0) {
        passwordInfo.err = true;
        passwordInfo.msg = i18n('sign_up_status_invalid_no_forward_slash');

        await this.setStateAsync({
          status: '',
          submitted: false,
          passwordInfo
        });
      } else if (password.indexOf('.') >= 0) {
        passwordInfo.err = true;
        passwordInfo.msg = i18n('sign_up_status_invalid_no_dots');

        await this.setStateAsync({
          status: '',
          submitted: false,
          passwordInfo
        });
      } else if (!UserInputs.validPassword(password)) {
        passwordInfo.err = true;
        passwordInfo.msg = i18n(
          password.length < k.PASSWORD_PROPERTIES.minLength
            ? 'sign_up_status_invalid_at_least_8_chars'
            : 'sign_up_password_tip_err'
        );
        await this.setStateAsync({
          status: '',
          submitted: false,
          passwordInfo
        });
      }

      // firstname check
      if (
        !(
          isStringNotEmpty(firstName) &&
          firstName.length >= k.FIRSTNAME_PROPERTIES.minLength &&
          firstName.length <= k.FIRSTNAME_PROPERTIES.maxLength
        )
      ) {
        firstNameInfo.err = true;
        firstNameInfo.msg = i18n('sign_up_status_invalid_firstname');

        await this.setStateAsync({
          status: '',
          submitted: false,
          firstNameInfo
        });
      }

      // lastname check
      if (
        !(
          isStringNotEmpty(lastName) &&
          lastName.length >= k.LASTNAME_PROPERTIES.minLength &&
          lastName.length <= k.LASTNAME_PROPERTIES.maxLength
        )
      ) {
        lastNameInfo.err = true;
        lastNameInfo.msg = i18n('sign_up_status_invalid_lastname');

        await this.setStateAsync({
          status: '',
          submitted: false,
          lastNameInfo
        });
      }

      // email check
      if (
        !(
          email.length <= k.EMAIL_PROPERTIES.maxLength &&
          email.length >= k.EMAIL_PROPERTIES.minLength &&
          isCorrectEmailFormat(email)
        )
      ) {
        emailInfo.err = true;
        emailInfo.msg = i18n('sign_up_status_invalid_email');

        await this.setStateAsync({
          status: '',
          submitted: false,
          emailInfo
        });
      }

      if (!ProfileAPI.USER_DATA.encSignupValid()) {
        await this.setStateAsync({
          status: i18n('sign_up_status_wrong_refresh'),
          submitted: false
        });

        return;
      }

      // not block any country for now
      // but not PHL will require code
      // } else if (
      //   !(
      //     AppAPI.getGlobalConfig('successProbe') &&
      //     k.COUNTRIES_WHITELIST.indexOf(
      //       `${AppAPI.getGlobalConfig('country')}`.toLowerCase()
      //     ) >= 0
      //   )
      // ) {
      //   await this.resetVARS();
      //   await this.setStateAsync({
      //     allowSignup: false,
      //     submitted: false,
      //     status: i18n('sign_up_status_unavailable_region')
      //   });

      //   return;
      // }

      if (!agreeToTerms) {
        ModalAPI.toaster('danger', i18n('sign_up_status_need_to_agree'), 4500);

        // await this.setStateAsync({
        //   status: i18n('sign_up_status_need_to_agree'),
        //   submitted: false
        // });

        return;
      }

      if (
        usernameInfo.err ||
        passwordInfo.err ||
        emailInfo.err ||
        firstNameInfo.err ||
        lastNameInfo.err
      ) {
        return;
      }

      await this.setStateAsync({
        submitted: true,
        status: ''
      });

      this.lastSignupPayload = {
        username,
        password,
        email,
        firstName,
        lastName
      };

      await ProfileAPI.PUBLIC.sendSignup(
        username,
        password,
        email,
        firstName,
        lastName,
        // for temporary
        this.inviteCode,
        this.countryCode
      );
    } else {
      if (emailInfo.err || submitted) {
        return;
      } else if (
        !(
          email.length <= k.EMAIL_PROPERTIES.maxLength &&
          email.length >= k.EMAIL_PROPERTIES.minLength &&
          isCorrectEmailFormat(email)
        )
      ) {
        emailInfo.err = true;
        emailInfo.msg = i18n('sign_up_status_invalid_email');

        await this.setStateAsync({
          emailInfo
        });

        return;
      } else if (!agreeToTerms) {
        ModalAPI.toaster('danger', i18n('sign_up_status_need_to_agree'), 4500);
        return;
      } else {
        await this.setStateAsync({
          submitted: true,
          status: ''
        });

        if (this.state.mounted) {
          const res = await ProfileAPI.PUBLIC.sendEarlyAccess(email);

          if (!this.state.mounted) {
            return;
          }

          if (res && res.success) {
            await this.setStateAsync({
              submitted: false,
              status: '',
              successEarlyAccess: true
            });
          } else if (res && res.taken) {
            emailInfo.err = true;
            emailInfo.msg = i18n('sign_up_early_email_taken');

            await this.setStateAsync({
              submitted: false,
              successEarlyAccess: false,
              emailInfo,
              status: ''
            });
          } else {
            await this.setStateAsync({
              submitted: false,
              successEarlyAccess: false,
              status: i18n('common_something_wrong_w_try')
            });
          }
        }
      }
    }
  };

  inputTextChange(name, value) {
    if (typeof value === 'string') {
      this.setState({
        [name]: value
      });
      this.inputsOnFocus();
    } else {
      const evt = value;

      if (evt) {
        const target = evt.target
          ? evt.target
          : evt.srcElement
          ? evt.srcElement
          : null;

        if (target) {
          const value = `${evt.target.value}`;

          this.setState({
            [name]: value
          });

          this.inputsOnFocus();
        }
      }
    }
  }

  render() {
    const {
      agreeToTerms = false,
      status = '',
      submitted = false,
      allowSignup,
      username,
      usernameInfo,
      password,
      passwordInfo,
      firstName,
      firstNameInfo,
      lastName,
      lastNameInfo,
      email,
      emailInfo,
      successSignup,
      successEarlyAccess = false,
      successRedirect,
      loaded
    } = this.state;
    const { profileLoaded, isLoggedIn } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const query = Store.CONFIGS.getQuery();

    if (!profileLoaded || !loaded) {
      return <InitUserLoading />;
    } else if (isLoggedIn) {
      // make sure to check premium pay from pricing page
      return (
        <Redirect to={`/user${query?.pay ? '?pay=premiumWithSpaces' : ''}`} />
      );
    } else if (successRedirect) {
      return (
        <Redirect
          to={`/login?redirect=sign_up_success${
            query?.pay ? '&pay=premiumWithSpaces' : ''
          }`}
        />
      );
    }

    return (
      <AdjustableAbsolutWrap>
        <div
          className={cx(styles.flex_column_y, styles.sign_upv2, {
            [styles.sign_upv2_dark]: isThemeDarkMode
          })}
        >
          <div className={styles.actual}>
            <div className={styles.logo}>
              <div className={styles.logo_wrap}>
                <AppLogoIcon />
              </div>
            </div>
            <div
              className={cx(styles.ic_w_title, {
                [styles.hide_element]: successSignup
              })}
            >
              <div
                className={cx(styles.ic_only, {
                  [styles.ic_only_dark]: isThemeDarkMode
                })}
              >
                <div></div>
              </div>
              <div
                className={cx(styles.title, {
                  [styles.title_dark]: isThemeDarkMode
                })}
              >
                <h2>{i18n('sign_up_welcome')}</h2>
                <h5>
                  {i18n(allowSignup ? 'sign_up_title' : 'sign_up_early_access')}
                </h5>
              </div>
            </div>
            <div
              className={cx(styles.success_signup, {
                [styles.hide_element]: !successSignup,
                [styles.success_signup_dark]: isThemeDarkMode
              })}
            >
              <div>
                <TickCircleIcon color="success" />{' '}
              </div>
              <h4>{i18n('sign_up_success_redirect_title')}</h4>
              <h5>{i18n('sign_up_success_redirect_message')}</h5>
            </div>
            <div
              className={cx(styles.early_access, {
                [styles.hide_element]: allowSignup || successSignup
              })}
            >
              <div
                className={cx({ [styles.hide_element]: successEarlyAccess })}
              >
                <h5>{i18n('sign_up_early_access_message')}</h5>
              </div>
              <div
                className={cx(
                  styles.early_access_success,

                  {
                    [styles.hide_element]: !successEarlyAccess
                  }
                )}
              >
                <div></div>
                <h5>
                  {Markdown('sign_up_early_success_msg', {
                    email1: email
                  })}
                </h5>
              </div>
            </div>
            <div
              className={cx(
                styles.username_and_password,

                {
                  [styles.hide_element]:
                    !allowSignup || successEarlyAccess || successSignup
                }
              )}
            >
              <div
                className={cx(styles.input_two, {
                  [styles.input_two_dark]: isThemeDarkMode
                })}
              >
                <TextInputField
                  placeholder={i18n('common_username_placeholder')}
                  label={i18n('username_title')}
                  onChange={this.usernameOnInput}
                  value={username}
                  validationMessage={usernameInfo.err && usernameInfo.msg}
                />
              </div>
              <div
                className={cx(styles.input_two, {
                  [styles.input_two_dark]: isThemeDarkMode
                })}
              >
                <TextInputField
                  placeholder={i18n('sign_in_input_pw_placeholder')}
                  label={i18n('password_title')}
                  onChange={this.passwordOnInput}
                  value={password}
                  type={'password'}
                  validationMessage={passwordInfo.err && passwordInfo.msg}
                />
              </div>
            </div>
            <div
              className={cx(styles.firstname_and_lastname, {
                [styles.hide_element]:
                  !allowSignup || successEarlyAccess || successSignup
              })}
            >
              <div
                className={cx(styles.input_two, {
                  [styles.input_two_dark]: isThemeDarkMode
                })}
              >
                <TextInputField
                  placeholder={i18n('sign_up_firstname_placeholder')}
                  label={i18n('sign_up_firstname_title')}
                  onChange={this.firstNameOnInput}
                  value={firstName}
                  validationMessage={firstNameInfo.err && firstNameInfo.msg}
                  autoComplete="false"
                  spellCheck={false}
                />
              </div>
              <div
                className={cx(styles.input_two, {
                  [styles.input_two_dark]: isThemeDarkMode
                })}
              >
                <TextInputField
                  placeholder={i18n('sign_up_lasstname_placeholder')}
                  label={i18n('sign_up_lastname_title')}
                  onChange={this.lastNameOnInput}
                  value={lastName}
                  validationMessage={lastNameInfo.err && lastNameInfo.msg}
                  autoComplete="false"
                  spellCheck={false}
                />
              </div>
            </div>
            <div
              className={cx(styles.email, {
                [styles.hide_element]: successEarlyAccess || successSignup
              })}
            >
              <div
                className={cx(styles.input_one, {
                  [styles.input_one_dark]: isThemeDarkMode
                })}
              >
                <TextInputField
                  placeholder={i18n('sign_up_input_email_placeholder')}
                  label={i18n('sign_up_email_address_title')}
                  onChange={this.emailOnInput}
                  value={email}
                  validationMessage={emailInfo.err && emailInfo.msg}
                />
              </div>
            </div>
            <div className={styles.actions}>
              <div
                className={cx(styles.agree, {
                  [styles.hide_element]: successEarlyAccess || successSignup,
                  [styles.agree_dark]: isThemeDarkMode,
                  [styles.agree_active_dark]: agreeToTerms && isThemeDarkMode
                })}
              >
                <Checkbox
                  checked={agreeToTerms}
                  label={URLMarkdown(
                    'sign_up_agree_terms_policy',
                    {
                      url1: { link: '/docs/terms-service' },
                      url2: { link: '/docs/privacy-policy' }
                    },
                    '',
                    false
                  )}
                  onChange={this.toggleAgreeToTerms}
                />
              </div>

              <div
                className={cx(styles.spinner_wrap, {
                  [styles.hide_element]: !submitted,
                  [styles.spinner_wrap_dark]: isThemeDarkMode
                })}
              >
                <div className={styles.raw}>
                  <Spinner size={26} />
                </div>
              </div>
              <div
                className={cx(styles.status, {
                  [styles.hide_element]: status.length < 1
                })}
              >
                <div>
                  <h5>{status}</h5>
                </div>
              </div>
              <div
                className={cx(styles.confirm, {
                  [styles.hide_element]: successEarlyAccess || successSignup
                })}
              >
                <Button
                  label={i18n('common_confirm')}
                  variant={'primaryPurple'}
                  onClick={this.submit}
                  disabled={submitted}
                  className={cx(styles.confirm_button, {
                    [styles.hide_element]: submitted,
                    [styles.confirm_wno_status]: status.length < 1,
                    [styles.confirm_w_status]: status.length > 0
                  })}
                />
              </div>
              <div
                className={cx(styles.redirect_login, {
                  [styles.hide_element]: successSignup || submitted
                })}
              >
                <h5>
                  {URLMarkdown(
                    'sign_up_or_login',
                    {
                      url1: { link: '/login' }
                    },
                    '',
                    false
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </AdjustableAbsolutWrap>
    );
  }
}

export default withUserProfileSettings(withRouter(SignUpV2));
