import React from 'react';
import cx from 'classnames';
import styles from './AppLogo.scss';
import AppAPI from 'src/app-manager/API';

const AppLogo = props => {
  const isThemeDarkMode = AppAPI.isDarkMode();
  return (
    <div
      className={cx(styles.flex_row_xy, styles.app_logo, {
        [props.className]: !!props.className
      })}
    >
      <div
        className={cx(styles.flex_row_xy, styles.fav_icon, {
          [styles.fav_icon_dark]: isThemeDarkMode
        })}
      >
        <div className={styles.logo}> </div>
      </div>
      <p className={cx(styles.word, { [styles.word_dark]: isThemeDarkMode })}>
        {'chamu'}
      </p>
      <div
        className={cx(styles.beta_title, {
          [styles.hide_element]: !props.showBeta
        })}
      >
        <h5>{'Beta'}</h5>
      </div>
    </div>
  );
};

export default AppLogo;
