import axios from 'axios';
import Logger from 'src/lib/Logger';
import AppAPI from 'src/app-manager/API';
import { Subject } from 'rxjs';
import { getAuthHeaders } from 'src/helpers/utils';
import {
  getApiOpenBaseUrl,
  getApiDashboardUrlWhitelistedLocation
} from 'src/helpers/urls';

class UserProfile {
  onUpdateDPOBS = null;
  onNewDPOBS = null;
  onProfileIDInfoOBS = null;
  onEmailInfoOBS = null;
  onSaveDraftOBS = null;
  onGetLastDraftOBS = null;
  profile_context = null;
  users = {};

  constructor() {
    this.onUpdateDPResultOBS = new Subject();
    this.onNewDPOBS = new Subject();
    this.onProfileIDInfoOBS = new Subject();
    this.onSaveDraftOBS = new Subject();
    this.onGetLastDraftOBS = new Subject();
    this.onEmailInfoOBS = new Subject();
  }

  getUserCache(profileIdOrEmail = '') {
    if (!profileIdOrEmail) {
      return null;
    }

    return this.users[`${profileIdOrEmail}`];
  }

  setUserCache(profileIdOrEmail = '', payload = {}) {
    if (!payload) {
      return;
    }

    this.users[`${profileIdOrEmail}`] = payload;
  }

  removeUserCache(profileIdOrEmail = '') {
    if (this.users[`${profileIdOrEmail}`]) {
      delete this.users[`${profileIdOrEmail}`];
    }
  }

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  confirmJustLoggedIn() {
    if (this.profile_context) {
      this.profile_context.setState({
        justLoggedIn: true
      });
    }
  }

  onUpdateDPResult(fn) {
    if (this.onUpdateDPResultOBS) {
      return this.onUpdateDPResultOBS.subscribe({ next: fn });
    }

    return null;
  }

  onNewDP(fn) {
    if (this.onNewDPOBS) {
      return this.onNewDPOBS.subscribe({ next: fn });
    }

    return null;
  }

  async updateDP(base64 = '', key = '') {
    if (
      !this.profile_context ||
      base64.length <= 0 ||
      base64.indexOf('data:image') !== 0
    ) {
      return;
    }

    try {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const countryCode = AppAPI.getGlobalConfig('country');
      const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
      const res = await axios({
        baseURL,
        method: 'PUT',
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          base64
        },
        url: `/v1/user/update/dp?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      // const formData = new FormData();
      // formData.append('file', blob, `${v4()}${key ? `_${key}` : ''}`);

      // const res = await axios({
      //   method: 'POST',
      //   baseURL:
      //     k.API_OPEN_URLS[Store.CONFIGS.IS_DEV ? 'development' : 'production'],
      //   headers: {
      //     authorization: `BEARER ${token}`,
      //     'x-custom-user-session': `${username_ref}`,
      //     'content-type': 'multipart/form-data'
      //   },
      //   data: formData,
      //   url: `/v1/file/upload/image/user?auth_token_id=${id}&lb=${lb}&n=${n}&ref_id=${spaceRefId}&space_id=${spaceId}&key=${key}`,
      //   validateStatus: function (status) {
      //     return status >= 200 && status <= 500;
      //   }
      // });

      if (res && res.status === 200 && res.data && res.data.success) {
        user.dp = base64;

        await this.profile_context.setStateAsync({
          user
        });

        this.onUpdateDPResultOBS.next({
          success: true,
          base64,
          limit_reached: false,
          limit_next: res.data.limit_next
        });

        this.onNewDPOBS.next({
          updated: true
        });
      } else if (res.data && res.data.limit_reached) {
        this.onUpdateDPResultOBS.next({
          success: false,
          limit_reached: true,
          limit_next: res.data.limit_next
        });
      } else {
        this.onUpdateDPResultOBS.next({
          success: false
        });
      }
    } catch (err) {
      Logger.log(`Failed to update user DP: ${err.message}`);

      this.onUpdateDPResultOBS.next({ success: false });
    }
  }

  onIDInfo(fn) {
    if (this.onProfileIDInfoOBS) {
      return this.onProfileIDInfoOBS.subscribe({ next: fn });
    }

    return null;
  }

  onEmailInfo(fn) {
    if (this.onEmailInfoOBS) {
      return this.onEmailInfoOBS.subscribe({ next: fn });
    }

    return null;
  }

  async getEmailInfo(email = '') {
    if (!this.profile_context) {
      if (this.onEmailInfoOBS) {
        this.onEmailInfoOBS.next();
      }

      return null;
    }

    try {
      const { user = {}, isLoggedIn = false } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { token, id, lb, n, username_ref = '' } = user.auth;
      const baseURL = getApiOpenBaseUrl();
      const res = await axios({
        baseURL,
        method: 'POST',
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          email
        },
        url: `/v1/user/info-mail?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data) {
        if (this.onEmailInfoOBS && typeof res.data === 'object' && res.data) {
          this.onEmailInfoOBS.next({ ...res.data });

          if (res.data?.user) {
            const user = res.data.user;
            const userId = user.profile_id;
            const has_image = user?.has_image || user?.image;
            const currentUserCached = this.getUserCache(userId);

            if (userId) {
              this.setUserCache(userId, {
                ...currentUserCached,
                ...user,
                has_image,
                fetching: false
              });
            }
          }
        }
        return res.data;
      } else {
        throw new Error(`${res.statusText} ${res.status}`);
      }
    } catch (err) {
      if (err) {
        Logger.log(
          `Failed in getEmailInfo email: ${email} error: ${err.message}`
        );
      }

      if (this.onEmailInfoOBS) {
        this.onEmailInfoOBS.next();
      }

      return null;
    }
  }

  async IDInfo(id = '') {
    if (!this.profile_context) {
      return;
    }

    try {
      const user = this.getUserCache(`${id}`);
      if (!id) {
        throw new Error('Empty id');
      } else if (user && user.fetching) {
        return user;
      } else if (user && user.id) {
        this.onProfileIDInfoOBS.next({
          valid: true,
          ...user
        });

        return user;
      } else {
        this.setUserCache(id, { fetching: true });
      }

      const countryCode = AppAPI.getGlobalConfig('country');
      const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
      const res = await axios({
        baseURL,
        method: 'POST',
        data: {
          id
        },
        url: `/v1/task/pid`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.status === 200 && res.data && res.data.valid) {
        const user = {
          id,
          profileId: id,
          profile_id: id,
          fetching: false,
          has_image: res.data.has_image,
          image: res.data.image,
          firstName: res.data.firstName,
          lastName: res.data.lastName
        };
        const currentUserCached = this.getUserCache(id);
        this.setUserCache(id, { ...currentUserCached, ...user });
        this.onProfileIDInfoOBS.next({
          valid: true,
          ...user
        });

        return user;
      } else {
        this.onProfileIDInfoOBS.next({
          id,
          valid: false,
          image: '',
          has_image: false
        });
        this.removeUserCache(id);

        return null;
      }
    } catch (err) {
      if (err && err.stack) {
        Logger.log(err.stack);
      }

      Logger.log(`Failed to post info w/ ID: ${id}: ${err.message}`);
      this.onProfileIDInfoOBS.next({
        id,
        valid: false,
        image: '',
        has_image: false
      });

      return null;
    }
  }

  onSaveDraftResult(fn) {
    if (this.onSaveDraftOBS) {
      return this.onSaveDraftOBS.subscribe({ next: fn });
    }

    return null;
  }

  async saveDraft(title = '', description_delta = '') {
    try {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const countryCode = AppAPI.getGlobalConfig('country');
      const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
      const res = await axios({
        baseURL,
        method: 'PUT',
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          title,
          description_delta,
          subscribers: []
        },
        url: `/v1/task/save-draft?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && typeof res.data === 'object') {
        this.onSaveDraftOBS.next({ ...res.data });
      }
    } catch (err) {
      if (err && err.stack) {
        Logger.log(err.stack);
      }

      if (`${err?.message}`.toLowerCase().includes('network error')) {
        // user is offline
      }

      Logger.log(`Failed saving user's last task draft: ${err.message}`);
    }
  }

  onGetLastDraftResult(fn) {
    if (this.onGetLastDraftOBS) {
      return this.onGetLastDraftOBS.subscribe({ next: fn });
    }

    return null;
  }

  getDraftTimestamp() {
    return Math.ceil(Date.now() / (10 * 1000));
  }

  clearLocalCopyLastDraft() {
    try {
      if (!this.profile_context) {
        return;
      }

      const { user = {} } = this.profile_context.state;

      if (user && user.lastDraft) {
        user.lastDraft = {
          valid: false,
          title: '',
          description: '',
          created: 0,
          priority: -1,
          taskId: '',
          uRef: ''
        };

        this.profile_context.setState({
          user
        });
      }
    } catch {}
  }

  getLocalCopyDraft() {
    try {
      if (!this.profile_context) {
        return null;
      }

      const { user = {} } = this.profile_context.state;

      return user ? user.lastDraft : null;
    } catch {
      return null;
    }
  }

  async updateLocalCopyDraft(params) {
    if (!this.profile_context) {
      return;
    }

    if (params && typeof params === 'object') {
      try {
        const { user = {} } = this.profile_context.state;

        user.lastDraft = {
          ...user.lastDraft,
          ...params,
          valid: true
        };

        await this.profile_context.setStateAsync({
          user
        });
      } catch {
        return null;
      }
    }
  }

  async getLastDraft(save = false) {
    try {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { lastDraft = {}, auth } = user;
      const { id, lb, n } = auth || {};
      const countryCode = AppAPI.getGlobalConfig('country');
      const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
      const headers = getAuthHeaders(user);
      const res = await axios({
        baseURL,
        headers,
        method: 'GET',
        url: `/v1/task/last-draft?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.status === 200 && res.data.valid) {
        if (save) {
          if (lastDraft) {
            lastDraft.valid = true;
            lastDraft.title = res.data.title;
            lastDraft.description = res.data.description_delta;
            lastDraft.created = res.data.created;
            lastDraft.taskId = res.data.task_id;
            lastDraft.uRef = res.data.u_ref;
            lastDraft.files = res.data.files;

            await this.profile_context.setStateAsync({
              user
            });
          }
        }

        this.onGetLastDraftOBS.next({
          valid: true,
          title: res.data.title,
          created: res.data.created,
          u_ref: res.data.u_ref,
          task_id: res.data.task_id,
          description_delta: res.data.description_delta,
          files: res.data.files
        });
      } else {
        this.onGetLastDraftOBS.next({
          valid: false
        });
      }
    } catch (err) {
      Logger.log(err.stack || '');
      Logger.log(err.message);

      this.onGetLastDraftOBS.next({
        valid: false
      });
    }
  }

  async report(opt = [], comment = '') {
    if (this.profile_context) {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn || !Array.isArray(opt)) {
        return null;
      }

      try {
        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          baseURL,
          headers,
          method: 'POST',
          data: {
            opt,
            comment
          },
          url: `/v1/pub/report?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        Logger.log(
          `Received status: ${res.status} from sending feedback report from user`
        );

        if (res && res.data) {
          return res.data;
        } else {
          return null;
        }
      } catch (err) {
        Logger.log(err.stack || '');
        Logger.log(err.message);

        return null;
      }
    }
  }
}

export default UserProfile;
