import React, { Component, lazy, Suspense } from 'react';
import cx from 'classnames';
import styles from './Pages.scss';
import LogoLoad from 'src/components/logo-load';
import ForgotPassword from './forgot-password';
import Docs from './docs';
import AppAPI from 'src/app-manager/API';
import { withGlobalSettings } from 'src/app-manager';
import { Switch, Route } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

import TaskView from './task';
import NotFound from './user/not-found';
import Login from './loginv2';
import Signup from './sign-upv2';
import Payment from './payment';
import LinkMigrate from './link-migrate';

const Admin = lazy(() => import('./admin'));
const User = lazy(() => import('./user'));
const Default = lazy(() => import('./default'));
const ResetPassword = lazy(() => import('./reset-password'));
const BetaInvite = lazy(() => import('./beta-invite'));
const VerifyEmail = lazy(() => import('./verify-email'));

class DefaultEntry extends Component {
  render() {
    const isThemeDarkMode = AppAPI.isDarkMode();
    const isIosMobile = Capacitor.getPlatform() === 'ios';

    return (
      <div
        className={cx(styles.pages, {
          [styles.darkmode_text]: isThemeDarkMode,
          [styles.darkmode_background]: isThemeDarkMode,
          [styles.pages_padding]: isIosMobile
        })}
      >
        <LinkMigrate />
        {/* <Suspense fallback={<LogoLoad />}>
          <Switch>
            <Route path={'/verify-email'}>
              <VerifyEmail />
            </Route>
            <Route path={'/login'}>
              <Login />
            </Route>
            <Route path={'/sign-up'}>
              <Signup />
            </Route>
            <Route path={'/docs'}>
              <Docs />
            </Route>
            <Route path={'/forgot-password'}>
              <ForgotPassword />
            </Route>
            <Route path={'/reset-password'}>
              <ResetPassword />
            </Route>
            <Route path={'/view'}>
              <TaskView />
            </Route>
            <Route path={'/payment'}>
              <Payment />
            </Route>
            <Route path={'/user/beta'}>
              <BetaInvite />
            </Route>
            <Route path={['/admin']}>
              <Admin />
            </Route>
            <Route path={['/user']}>
              <User />
            </Route>
            <Route path={'/not-found'}>
              <NotFound />
            </Route>
            <Route exact path={['/', '/index', '/home']}>
              <Default />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Suspense> */}
      </div>
    );
  }
}

export default withGlobalSettings(DefaultEntry);
