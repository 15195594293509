import React, { Component } from 'react';
import cx from 'classnames';
import styles from './More.scss';
import MessageHover from 'src/components/message-hover';
import CopiedPrompt from 'src/components/copied-prompt';
import k from 'src/constants/k';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import { isFunction } from 'src/helpers/utils';
import { Button, LinkIcon, EditIcon, TickIcon, Spinner } from 'evergreen-ui';

class More extends Component {
  taskMoreOptionsUnique = 'task-more-options-unique';

  onUpdateState = () => {
    const { onUpdateState, updatingState } = this.props;

    if (updatingState) {
      return;
    }

    if (isFunction(onUpdateState)) {
      onUpdateState();
    }
  };

  render() {
    const {
      copiedLink,
      loaded,
      exposure,
      taskMore = false,
      profileLoaded = false,
      isLoggedIn = false,
      isOwner = false,
      isShared = false,
      isEditor = false,
      taskState = '',
      updatingState
    } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const copyLinkWithPasscode =
      exposure === k.USER_TASK_PRIVACY_PUBLIC_W_PW && (isShared || isOwner);
    const copyOnly =
      ((isShared && isLoggedIn && !isEditor) || (!isOwner && !isEditor)) &&
      !copyLinkWithPasscode;

    return (
      <div
        className={cx(styles.more_actions, {
          [styles.more_actions_hidden]: !loaded || !isLoggedIn || copyOnly
        })}
      >
        <div
          className={cx(
            this.taskMoreOptionsUnique,
            styles.more_actions_ic_wrap
          )}
        >
          <Button
            className={cx(
              { [styles.more_actions_ic_wrap_dark]: isThemeDarkMode },
              this.taskMoreOptionsUnique
            )}
            {...(this.props.toggleTaskMore &&
              typeof this.props.toggleTaskMore === 'function' && {
                onClick: this.props.toggleTaskMore
              })}
            appearance="minimal"
          >
            <div
              className={cx(
                styles.more_actions_ic,
                { [styles.more_actions_ic_dark]: isThemeDarkMode },
                this.taskMoreOptionsUnique
              )}
            >
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div
                className={cx(styles.cover, this.taskMoreOptionsUnique)}
              ></div>
            </div>
          </Button>
          {!copiedLink && (
            <MessageHover
              className={styles.more_actions_hover}
              message={i18n('task_view_more_actions')}
            />
          )}
          <CopiedPrompt
            className={cx(styles.copied_link, {
              [styles.hide_element]: !copiedLink
            })}
          />
        </div>
        <div
          className={cx(styles.more_actions_opts, this.taskMoreOptionsUnique, {
            [styles.hide_element]: !taskMore,
            [styles.more_actions_opts_dark]: isThemeDarkMode
          })}
        >
          <ul
            className={cx(this.taskMoreOptionsUnique, {
              [styles.multiple_opts]: profileLoaded && (isOwner || isEditor)
            })}
          >
            <li
              className={cx({
                [styles.hide_element]:
                  (profileLoaded && !isLoggedIn) || (!isOwner && !isEditor)
              })}
            >
              <div className={styles.to_edit}>
                <Button appearance="minimal">
                  <div>
                    <div>
                      <EditIcon />{' '}
                    </div>
                    <h5>{i18n('common_edit_title')}</h5>
                  </div>{' '}
                  <div
                    className={cx(
                      styles.cover,
                      this.taskMoreOptionsUnique,
                      `${this.taskMoreOptionsUnique}-edit`
                    )}
                  ></div>
                </Button>
              </div>
            </li>
            {(isOwner || isEditor) && (
              <li className={cx(this.taskMoreOptionsUnique, 'newstate')}>
                <div
                  className={cx(styles.update_state, {
                    [styles.update_state_loading]: updatingState,
                    [styles.update_state_dark]: isThemeDarkMode
                  })}
                >
                  <Button
                    appearance="minimal"
                    className={cx(this.taskMoreOptionsUnique, 'newstate')}
                    onClick={this.onUpdateState}
                  >
                    <div>
                      <div>
                        {!updatingState ? (
                          <TickIcon />
                        ) : (
                          <Spinner height={16} width={16} />
                        )}
                      </div>
                      <h5>
                        {i18n(
                          taskState === k.TASK_STATE_ACTIVE
                            ? 'user_task_view_more_opt_set_to_inactive'
                            : 'user_task_view_more_opt_set_to_active'
                        )}{' '}
                      </h5>
                    </div>
                    <div
                      className={cx(
                        styles.cover,
                        this.taskMoreOptionsUnique,
                        `${this.taskMoreOptionsUnique}-newstate`
                      )}
                    ></div>
                  </Button>
                </div>
              </li>
            )}

            <li>
              <div className={styles.copy_link}>
                <Button appearance="minimal">
                  <div>
                    <div>
                      <LinkIcon />
                    </div>
                    <h5>{i18n('task_view_copy_link')}</h5>
                  </div>
                  <div
                    className={cx(
                      styles.cover,
                      this.taskMoreOptionsUnique,
                      `${this.taskMoreOptionsUnique}-copy`
                    )}
                  ></div>
                </Button>
              </div>
            </li>
            <li
              className={cx({
                [styles.hide_element]: !copyLinkWithPasscode
              })}
            >
              <div className={styles.copy_link_w_pw}>
                <Button appearance="minimal">
                  <div>
                    <div>
                      {' '}
                      <LinkIcon />{' '}
                    </div>
                    <h5>{i18n('task_view_copy_link_w_passcode')}</h5>
                  </div>
                  <div
                    className={cx(
                      styles.cover,
                      this.taskMoreOptionsUnique,
                      `${this.taskMoreOptionsUnique}-copywpw`
                    )}
                  ></div>
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default More;
