import React, { Component, createRef } from 'react';
import cx from 'classnames';
import i18n from 'src/locales';
import styles from './TaskFormats.scss';
import AppAPI from 'src/app-manager/API';
import TaskProperties from 'src/pages/user/create-task2/TaskProperties';
import Images from './Images';
import ClearTaskBody from 'src/modals/clear-task-body';
import ModalAPI from 'src/modal-manager/API';
// import ProfileAPI from 'src/profile-manager/API';
import MessageHover from 'src/components/message-hover';
import { isFunction, isMobileView } from 'src/helpers/utils';
import { H2BlockFormat, CodeBlockFormat, JustifyTextFormat } from './more';
import { fromEvent } from 'rxjs';
import {
  MaximizeIcon,
  IconButton,
  MinimizeIcon,
  Button,
  PaperclipIcon,
  MenuClosedIcon,
  MenuOpenIcon
} from 'evergreen-ui';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'src/lib/bodyScrollLock.min';

const ImagePaste = props => {
  const isThemeDarkMode = AppAPI.isDarkMode();
  return (
    <div
      className={cx(styles.format_image_paste, {
        [props.className]:
          typeof props.className === 'string' &&
          `${props.className}`.length > 0,
        [styles.format_image_paste_active]: props.inputFocus,
        [styles.format_image_paste_inactive]: !props.inputFocus,
        [styles.format_image_paste_dark]: !props.inputFocus && isThemeDarkMode,
        [styles.format_image_paste_dark_inactive]:
          !props.inputFocus && isThemeDarkMode,
        [styles.format_image_paste_dark_active]:
          props.inputFocus && isThemeDarkMode
      })}
    >
      <button type="button" className="ql-image">
        <div>
          <div></div>
          <div></div>
        </div>
      </button>
      <MessageHover
        className={styles.tip}
        message={i18n('common_image_file')}
      />
    </div>
  );
};

class TaskFormats extends Component {
  ImagesHelper = null;
  taskFormatDOMRef = null;
  taskFormatParentDOMRef = null;
  taskDescriptionClearDOMRef = null;
  taskDescriptionClickSubscriber = null;
  onEditorChangeSubscriber = null;
  onEditorClickSubscriber = null;
  onKeydownSubscriber = null;
  skipFormat = false;
  QuillEditor = null;
  classNames = {
    bold: 'ql-bold',
    italic: 'ql-italic',
    strike: 'ql-strike',
    ulist: 'ql-list-bullet',
    olist: 'ql-list-ordered',
    chlist: 'ql-check-list',
    header1: 'ql-header-1',
    header2: 'ql-header-2',
    codeBlock: 'ql-code-block'
  };

  flags = {
    bold: false,
    italic: false,
    strike: false,
    ulist: false,
    olist: false,
    chlist: false,
    header1: false,
    header2: false,
    codeBlock: false
  };

  state = {
    isMaximized: false,
    moreSelected: 'header-2',
    formats: {},
    mounted: false,
    bodyScrollDisabled: false
  };

  constructor() {
    super();

    this.ImagesHelper = Images;
    this.taskFormatParentDOMRef = createRef();
    this.taskFormatDOMRef = createRef();
    this.taskDescriptionClearDOMRef = createRef();
    this.onClear = this.onClear.bind(this);
    this.checkFormats = this.checkFormats.bind(this);
    this.promptError = this.promptError.bind(this);
    this.onBold = this.onFormatClick.bind(this, 'bold');
    this.onItalic = this.onFormatClick.bind(this, 'italic');
    this.onStrike = this.onFormatClick.bind(this, 'strike');
    this.onUList = this.onFormatClick.bind(this, 'ulist');
    this.onOList = this.onFormatClick.bind(this, 'olist');
    this.onChList = this.onFormatClick.bind(this, 'chlist');
    this.onHeader1 = this.onFormatClick.bind(this, 'header1');
    this.onHeader2 = this.onFormatClick.bind(this, 'header2');
    this.onCodeBlock = this.onFormatClick.bind(this, 'codeBlock');
    this.onEditorClick = this.onEditorClick.bind(this);
    this.onUnload = this.onUnload.bind(this);
    this.goMaximize = this.goMaximize.bind(this);
    this.goMinimze = this.goMinimze.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
    this.clearConfirm = this.clearConfirm.bind(this);
    this.setQuillEditor = this.setQuillEditor.bind(this);
    AppAPI.onAppUnload(this.onUnload);
  }

  onUnload() {
    if (this.ImagesHelper) {
      delete this.ImagesHelper;
    }
  }

  onKeydown(evt) {
    const { maximizeEditor } = this.props;

    if (evt && evt.keyCode === 27 && maximizeEditor) {
      this.goMinimze();
    }
  }

  componentDidUpdate(prevProps) {
    const { ready, inputFocus } = this.props;
    const { mounted, bodyScrollDisabled } = this.state;

    if (!mounted || !ready || !this.taskFormatDOMRef?.current) {
      return;
    }

    if (
      isMobileView() &&
      ((!prevProps?.inputFocus && inputFocus) ||
        (prevProps?.inputFocus && !inputFocus) ||
        !bodyScrollDisabled) &&
      this.taskFormatDOMRef?.current
    ) {
      this.setState(
        {
          bodyScrollDisabled: true
        },
        () => disableBodyScroll(this.taskFormatDOMRef.current)
      );
    }
  }

  componentDidMount() {
    this.onEditorChangeSubscriber = this.props.onEditorChange(
      this.checkFormats
    );

    this.onKeydownSubscriber = fromEvent(document, 'keyup').subscribe(
      this.onKeydown
    );

    this.onEditorClickSubscriber = this.props.onEditableClicked(
      this.onEditorClick
    );

    if (typeof this.props.confirmTaskFormats === 'function') {
      this.props.confirmTaskFormats(
        this.ImagesHelper.imageHandler,
        this.ImagesHelper,
        this.setQuillEditor
      );
    }

    this.setState({
      mounted: true
    });
  }

  setQuillEditor() {
    if (!this.QuillEditor) {
      this.QuillEditor = this.props.getQuillEditor();
      this.ImagesHelper.setQuillEditor(this.QuillEditor);
      this.ImagesHelper.setDescriptionDOM(this.props.getDescriptionDOM());
    }
  }

  componentWillUnmount() {
    AppAPI.removeOnAppUnload(this.onUnload);
    this.setState({
      mounted: false
    });

    if (this.taskFormatDOMRef?.current) {
      enableBodyScroll(this.taskFormatDOMRef.current);
    }

    if (this.onKeydownSubscriber) {
      this.onKeydownSubscriber.unsubscribe();
    }

    if (this.onEditorClickSubscriber) {
      this.onEditorClickSubscriber.unsubscribe();
    }

    if (this.ImagesHelper) {
      this.ImagesHelper.destroy();
    }

    delete this.QuillEditor;
    delete this.ImagesHelper;
  }

  goMinimze() {
    const { toggleMaximizeEditor } = this.props;

    if (typeof toggleMaximizeEditor === 'function') {
      toggleMaximizeEditor(false);

      this.setState(
        {
          isMaximized: false
        },
        () => this.goFocusEditor()
      );
    }
  }

  goMaximize() {
    const { toggleMaximizeEditor } = this.props;

    if (typeof toggleMaximizeEditor === 'function') {
      toggleMaximizeEditor(true);

      this.setState(
        {
          isMaximized: true
        },
        () => this.goFocusEditor()
      );
    }
  }

  goFocusEditor = () => {
    const { getQuillEditor } = this.props;
    if (!getQuillEditor) {
      return;
    }

    this.QuillEditor = getQuillEditor();

    if (this.QuillEditor && !this.QuillEditor.hasFocus()) {
      this.QuillEditor.focus();
    }
  };

  checkFormats({ name, args }) {
    if (!this.QuillEditor) {
      return;
    } else if (
      this.skipFormat ||
      ['text-change', 'selection-change'].indexOf(name) < 0
    ) {
      if ((name === 'selection-change' && args[0]) || name === 'text-change') {
        this.skipFormat = false;
      }

      return;
    }

    let f = null;

    if (name === 'selection-change' && args && Array.isArray(args)) {
      if (args[0] && args[0] !== null) {
        f = this.QuillEditor.getFormat(args[0]);
      }
    } else if (name === 'text-change') {
      const range = this.QuillEditor.getSelection();

      if (range) {
        f = this.QuillEditor.getFormat(range);

        if (args[0] && args[0].ops && args[0].ops.length > 0) {
          if (
            (args[0].ops[0] && args[0].ops[0].insert === '\n') ||
            (args[0].ops[1] && args[0].ops[1].insert === '\n')
          ) {
            this.skipFormat = true;

            return;
          }
        }
      }
    }

    this.applyFormat(f);
  }

  onEditorClick() {}

  onFormatClick(name) {
    if (!this.QuillEditor) {
      this.QuillEditor = this.props.getQuillEditor();
    }

    if (!this.QuillEditor || !this.QuillEditor.hasFocus()) {
      return;
    } else this.skipFormat = true;

    const f = this.QuillEditor.getFormat();
    const cn = this.classNames[name];
    const parent = this.taskFormatParentDOMRef.current;
    const { moreSelected } = this.state;

    if (cn && parent) {
      this.applyFormat(f);
    }

    if (name === 'header2' && moreSelected !== 'header-2') {
      this.setState({
        moreSelected: 'header-2'
      });
    } else if (name === 'codeBlock' && moreSelected !== 'code-block') {
      this.setState({
        moreSelected: 'code-block'
      });
    }
  }

  applyFormat(f = {}) {
    if (!f) {
      this.skipFormat = false;
      return;
    }

    const parent = this.taskFormatParentDOMRef.current;
    const boldDOM = parent.querySelector(`div .${this.classNames.bold}`);
    const italicDOM = parent.querySelector(`div .${this.classNames.italic}`);
    const strikeDOM = parent.querySelector(`div .${this.classNames.strike}`);
    const ulistDOM = parent.querySelector(`div .${this.classNames.ulist}`);
    const olistDOM = parent.querySelector(`div .${this.classNames.olist}`);
    const header1DOM = parent.querySelector(`div .${this.classNames.header1}`);
    const chListDOM = parent.querySelector(`div .${this.classNames.chlist}`);

    if (f && f.bold) {
      boldDOM.classList.add(styles.format_active);
      this.flags.bold = true;
    } else {
      this.flags.bold = false;
      boldDOM.classList.remove(styles.format_active);
    }

    if (f.italic) {
      this.flags.italic = true;
      italicDOM.classList.add(styles.format_active);
    } else {
      this.flags.italic = false;
      italicDOM.classList.remove(styles.format_active);
    }

    if (f.strike) {
      this.flags.strike = true;
      strikeDOM.classList.add(styles.format_active);
    } else {
      this.flags.strike = false;
      strikeDOM.classList.remove(styles.format_active);
    }

    if (f.list === 'bullet') {
      this.flags.ulist = true;
      ulistDOM.classList.add(styles.format_active);
    } else {
      this.flags.ulist = false;
      ulistDOM.classList.remove(styles.format_active);
    }

    if (f.list === 'ordered') {
      this.flags.olist = true;
      olistDOM.classList.add(styles.format_active);
    } else {
      this.flags.olist = false;
      olistDOM.classList.remove(styles.format_active);
    }

    if (f.header === 1) {
      this.flags.header1 = true;
      this.flags.header2 = false;
      header1DOM.classList.add(styles.format_active);
    } else {
      this.flags.header1 = false;
      this.flags.header2 = false;
      header1DOM.classList.remove(styles.format_active);

      if (f.header === 2) {
        this.flags.header2 = true;
      }
    }

    if (f['code-block']) {
      this.flags.codeBlock = true;
    } else {
      this.flags.codeBlock = false;
    }

    if (f && f.list && (f.list === 'unchecked' || f.list === 'checked')) {
      this.flags.chlist = true;

      chListDOM.classList.add(styles.format_active);
    } else {
      this.flags.chlist = false;
      chListDOM.classList.remove(styles.format_active);
    }

    this.skipFormat = false;
    this.setState({
      formats: f
    });
  }

  clearConfirm() {
    ModalAPI.hideDOM2();
    this.props.clearDescription();
  }

  onClear(evt) {
    if (evt) {
      evt.stopPropagation();
    }

    if (typeof this.props.clearDescription === 'function') {
      ModalAPI.setDOM2(
        <ClearTaskBody confirm={this.clearConfirm} cancel={ModalAPI.hideDOM2} />
      );
    }
  }

  promptError = message => {
    if (typeof this.props.promptError === 'function') {
      this.props.promptError(message);
    }
  };

  onAttachFile = () => {
    const input = document.createElement('input');
    const attachfileInputDOMId = 'ATTACH_FILE_INPUT_DOM_ID';

    if (document && document.querySelector(`#${attachfileInputDOMId}`)) {
      const wasCreatedInput = document.querySelector(
        `#${attachfileInputDOMId}`
      );

      if (wasCreatedInput) {
        wasCreatedInput.remove();
      }
    }

    try {
      const promptError = this.promptError;
      const { handleFileUpload } = this.props;
      input.style.visibility = 'hidden';
      input.style.position = 'fix';
      input.style.top = '-20px';
      input.style.right = '-20px';
      input.setAttribute('id', `${attachfileInputDOMId}`);
      input.setAttribute('type', 'file');
      input.setAttribute('name', 'file');
      document.body.appendChild(input);

      input.onchange = function (evt) {
        if (this.files.length > 0) {
          if (isFunction(handleFileUpload)) {
            handleFileUpload(this.files);
          }
        }
      };

      input.onerror = function () {
        promptError(i18n('user_create_task_file_upload_status_invalid_file'));
        input.remove();
      };
      input.click();
    } finally {
      if (input) {
        input.remove();
      }
    }
  };

  render() {
    const { isMaximized } = this.state;
    const { inputFocus = false, submitted = false } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div
        ref={this.taskFormatParentDOMRef}
        className={styles.task_formats_wrap}
        id={'editor-task-formats'}
      >
        <div
          className={styles.toolbar}
          ref={this.taskFormatDOMRef}
          id={TaskProperties.taskDescriptionToolbarID}
        >
          <div
            className={cx(styles.bold, 'ql-bold', {
              [styles.bold_active]: inputFocus && !isThemeDarkMode,
              [styles.bold_dark]: isThemeDarkMode,
              [styles.format_active]: this.flags.bold && inputFocus,
              [styles.format_active_nofocus]: this.flags.bold && !inputFocus,
              [styles.bold_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button type="button" onClick={this.onBold} className="ql-bold">
              {'B'}
            </button>
            <MessageHover
              className={styles.tip}
              message={i18n('common_bold')}
            />
          </div>
          <div
            className={cx(styles.italic, 'ql-italic', {
              [styles.italic_active]: inputFocus && !isThemeDarkMode,
              [styles.italic_dark]: isThemeDarkMode,
              [styles.format_active]: this.flags.italic && inputFocus,
              [styles.format_active_nofocus]: this.flags.italic && !inputFocus,
              [styles.italic_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button onClick={this.onItalic} className="ql-italic">
              <em>{'I'}</em>
            </button>
            <MessageHover
              className={styles.tip}
              message={i18n('common_italic')}
            />
          </div>
          <div
            className={cx(styles.strike, 'ql-strike', {
              [styles.strike_active]: inputFocus && !isThemeDarkMode,
              [styles.strike_dark]: isThemeDarkMode,
              [styles.format_active]: this.flags.strike && inputFocus,
              [styles.format_active_nofocus]: this.flags.strike && !inputFocus,
              [styles.strike_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button onClick={this.onStrike} className="ql-strike">
              {'S'}
              <div></div>
            </button>
            <MessageHover
              className={styles.tip}
              message={i18n('common_strike_through')}
            />
          </div>
          <div
            className={cx(styles.ulist, 'ql-list-bullet', {
              [styles.ulist_active]: inputFocus,
              [styles.format_active]: this.flags.ulist && inputFocus,
              [styles.ulist_dark]: !inputFocus && isThemeDarkMode,
              [styles.ulist_dark_active]: inputFocus && isThemeDarkMode,
              [styles.format_active_nofocus]: this.flags.ulist && !inputFocus
            })}
          >
            <button onClick={this.onUList} className="ql-list" value="bullet">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </button>

            <MessageHover
              className={styles.tip}
              message={i18n('common_unordered_list')}
            />
          </div>
          <div
            className={cx(styles.olist, 'ql-list-ordered', {
              [styles.olist_active]: inputFocus,
              [styles.format_active]: this.flags.olist && inputFocus,
              [styles.format_active_nofocus]: this.flags.olist && !inputFocus,
              [styles.olist_dark]: !inputFocus && isThemeDarkMode,
              [styles.olist_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button onClick={this.onOList} className="ql-list" value="ordered">
              <ol>
                <li></li>
                <li></li>
                <li></li>
              </ol>
            </button>

            <MessageHover
              className={styles.tip}
              message={i18n('common_ordered_list')}
            />
          </div>
          <div
            className={cx(styles.chlist, 'ql-check-list', {
              [styles.chlist_active]: inputFocus,
              [styles.chlist_inactive]: !inputFocus,
              [styles.format_active]: this.flags.chlist && inputFocus,
              [styles.format_active_nofocus]: this.flags.chlist && !inputFocus,
              [styles.chlist_dark]: !inputFocus && isThemeDarkMode,
              [styles.chlist_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button
              className="ql-list"
              onClick={this.onChList}
              value="unchecked"
            >
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </button>
            <MessageHover
              className={styles.tip}
              message={i18n('common_check_list')}
            />
          </div>
          <div
            className={cx(styles.heading1, 'ql-header-1', {
              [styles.heading1_active]: inputFocus,
              [styles.format_active]: this.flags.header1 && inputFocus,
              [styles.format_active_nofocus]: this.flags.header1 && !inputFocus,
              [styles.heading1_dark]: !inputFocus && isThemeDarkMode,
              [styles.heading1_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button onClick={this.onHeader1} className="ql-header" value="1">
              <h1>{'H1'}</h1>
            </button>
            <MessageHover
              className={styles.tip}
              message={i18n('common_heading_1')}
            />
          </div>

          <H2BlockFormat
            className={styles.heading2}
            inFocus={inputFocus}
            active={this.flags.header2}
            onClick={this.onHeader2}
          />

          <div
            className={cx(styles.indent, styles.indent_minus, {
              [styles.indent_active]: inputFocus && !isThemeDarkMode,
              [styles.indent_dark]: !inputFocus && isThemeDarkMode,
              [styles.indent_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button type="button" className="ql-indent" value="-1">
              <MenuClosedIcon />
            </button>
            <MessageHover
              className={styles.tip}
              message={i18n('common_indent_decrease')}
            />
          </div>

          <div
            className={cx(styles.indent, styles.indent_plus, {
              [styles.indent_active]: inputFocus && !isThemeDarkMode,
              [styles.indent_dark]: !inputFocus && isThemeDarkMode,
              [styles.indent_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <button type="button" className="ql-indent" value="+1">
              <MenuOpenIcon />
            </button>
            <MessageHover
              className={styles.tip}
              message={i18n('common_indent_increase')}
            />
          </div>

          <JustifyTextFormat
            onClick={this.goFocusEditor}
            className={styles.justify}
            inFocus={inputFocus}
          />

          <CodeBlockFormat
            className={styles.codeblock}
            inFocus={inputFocus}
            active={this.flags.codeBlock}
            onClick={this.onCodeBlock}
          />
          <ImagePaste inputFocus={inputFocus} className={styles.image_inline} />

          <div
            className={cx(styles.attach_file, {
              [styles.attach_file_active]: inputFocus,
              [styles.attach_file_inactive]: !inputFocus,
              [styles.attach_file_dark]: isThemeDarkMode,
              [styles.attach_file_dark_active]: inputFocus && isThemeDarkMode,
              [styles.attach_file_dark_inactive]: !inputFocus && isThemeDarkMode
            })}
          >
            <IconButton icon={PaperclipIcon} onClick={this.onAttachFile} />{' '}
            <MessageHover
              className={styles.tip}
              message={i18n('user_create_task_attach_file')}
            />
          </div>

          {!isMaximized && (
            <div
              className={cx(styles.maximize, {
                [styles.maximize_active]: inputFocus,
                [styles.maximize_inactive]: !inputFocus,
                [styles.maximize_dark]: isThemeDarkMode,
                [styles.maximize_dark_active]: inputFocus && isThemeDarkMode,
                [styles.maximize_dark_inactive]: !inputFocus && isThemeDarkMode
              })}
            >
              <IconButton icon={MaximizeIcon} onClick={this.goMaximize} />
              <MessageHover
                className={styles.tip}
                message={i18n('common_expand')}
              />
            </div>
          )}
          {isMaximized && (
            <div
              className={cx(styles.minimize, {
                [styles.minimize_active]: inputFocus,
                [styles.minimize_inactive]: !inputFocus,
                [styles.minimize_dark]: isThemeDarkMode,
                [styles.minimize_dark_active]: inputFocus && isThemeDarkMode,
                [styles.minimize_dark_inactive]: !inputFocus && isThemeDarkMode
              })}
            >
              <IconButton icon={MinimizeIcon} onClick={this.goMinimze} />
              <MessageHover
                className={styles.tip}
                message={i18n('common_minimize')}
              />
            </div>
          )}

          <div
            className={cx(styles.clear_all, {
              [styles.hide_element]: submitted,
              [styles.clear_all_dark]: isThemeDarkMode
            })}
            ref={this.taskDescriptionClearDOMRef}
          >
            <Button appearance="minimal" onClick={this.onClear}>
              <p>{i18n('user_create_task_description_clear')}</p>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskFormats;
