import React from 'react';
import cx from 'classnames';
import styles from './Button.scss';
import AppAPI from 'src/app-manager/API';
import { isString, isFunction } from 'src/helpers/utils';
import { Button, Spinner } from 'evergreen-ui';

/**
 * Custom text button
 * @param {*} param0
 * @returns
 */
const CustomButtonWrapper = ({
  variant = '',
  label = '',
  loading = false,
  disabled = false,
  className = '',
  uniqueClassName = '',
  children,
  onClick
}) => {
  // TODO: need to customize for other variants
  const isThemeDarkMode = AppAPI.isDarkMode();
  const isLoading = loading;
  const isPrimaryPurple = variant === 'primaryPurple';
  const isPrimaryDiscard = variant === 'primaryDiscard';

  return (
    <Button
      className={cx(styles.custom_button, styles.flex_row_xy, {
        [`${className}`]: isString(className) && className,
        [styles.custom_button_loading]: isLoading || disabled,
        [styles.custom_button_disabled]: disabled,
        [styles.custom_button_primaryPurple]: isPrimaryPurple,
        [styles.custom_button_primaryDiscard]: isPrimaryDiscard,
        [styles.custom_button_primaryPurple_dark]:
          isThemeDarkMode && isPrimaryPurple,
        [uniqueClassName]: isString(uniqueClassName) && uniqueClassName
      })}
      appearance="minimal"
      onClick={(...args) => {
        if (isFunction(onClick) && !isLoading && !disabled) {
          onClick(...args);
        }
      }}
    >
      {children ? children : <></>}
      {!children && !isLoading ? (
        <p
          className={cx(styles.text, {
            [styles.text_primaryPurple]: isPrimaryPurple,
            [styles.text_primaryDiscard]: isPrimaryDiscard,
            [uniqueClassName]: isString(uniqueClassName) && uniqueClassName
          })}
        >
          {label}
        </p>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div
          className={cx(styles.loading, {
            [styles.loading_primaryPurple]: isPrimaryPurple || isPrimaryDiscard,
            [uniqueClassName]: isString(uniqueClassName) && uniqueClassName
          })}
        >
          <Spinner />
        </div>
      ) : (
        <></>
      )}
    </Button>
  );
};

export default CustomButtonWrapper;
