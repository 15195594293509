import React, { Component, createContext, forwardRef } from 'react';
import ProfileFlow from './Flow';
import ProfileAPI from './API';
import AppAPI from 'src/app-manager/API';
import Logger from 'src/lib/Logger';
import i18n from 'src/locales';
import Store from 'src/lib/store';
import UserLog from 'src/lib/UserLog';
import { v4 } from 'uuid';
import { Subject } from 'rxjs';
import { withErrorManagerSettings } from 'src/error-manager';

const { Consumer, Provider } = createContext();

export function withUserProfileSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {value => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class ProfileManager extends Component {
  PROFILE_FLOW = null;
  userProfileLoadedOBS = null;
  userProfileFetchingInfo = null;
  userProfileFetchinPlan = null;
  userProfileFetchingNewNotifOBS = null;
  userProfileFetchingNotificationsOBS = null;

  encVAL = {
    login: { err: true },
    signup: { err: true },
    resetpw: { err: true },
    pwonly: { err: true }
  };

  state = {
    user: {
      access: [],
      activated: false,
      firstName: '',
      lastName: '',
      email: '',
      created: 0,
      initials: '',
      profileID: '',
      profileImage: '', // URL
      newNotifs: false,
      newTasks: false,
      dp: '',
      dpLimit: false,
      pwLimit: false,
      pwLastUpdated: 0,
      auth: {
        token: '',
        id: '',
        username_ref: '',
        lb: '',
        n: 0
      },
      plan: {
        stripePaid: false,
        isPremium: false,
        isPremiumTeams: false,
        isExpired: false,
        isLifetime: false,
        maxTags: 0,
        tasksMonth: 0,
        type: i18n('user_plan_free_title'),
        end: 0,
        started: 0
      },
      notificationSettings: {
        email: {
          allowEmailOnCommentPersonal: true,
          allowEmailOnTaskSubscribed: true,
          allowEmailOnNewTaskCustom: false,
          allowEmailOnTag: false,
          allowEmailOnCommentFromCreatedCustom: false,
          allowEmailOnTaskSubscribedCustom: true,
          unsubscribeToAllEmailCustom: false
        }
      },
      lastDraft: {
        valid: false,
        title: '',
        description: '',
        created: 0,
        priority: -1,
        taskId: '',
        uRef: ''
      }
    },
    isFetchingInfo: true,
    isFetchingPlan: true,
    justLoggedIn: false,
    isLoggedIn: false,
    isOffline: false,
    profileLoaded: false,
    mounted: false
  };

  constructor() {
    super();

    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.userProfileFetchingInfo = new Subject();
    this.userProfileFetchinPlan = new Subject();
    this.userProfileFetchingNewNotifOBS = new Subject();
    this.userProfileLoadedOBS = new Subject();
    this.userProfileFetchingNotificationsOBS = new Subject();
    ProfileAPI.setContext(this);
    this.PROFILE_FLOW = new ProfileFlow(this);
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    ProfileAPI.release();
    ProfileAPI.resetVARS();
  }

  async start() {
    await this.PROFILE_FLOW.start();
  }

  async confirmUserLoggedIn() {
    const { isLoggedIn, mounted } = this.state;
    Logger.log(`Confirming user login`);

    if (mounted && !isLoggedIn) {
      await this.setStateAsync({
        isLoggedIn: true,
        profileLoaded: true
      });
    }

    return this.storeUserStorage();
  }

  storeUserStorage() {
    if (!AppAPI.getLocalConfig('rememberLogin')) {
      this.clearLS();

      return;
    }

    const storageUserAuthKeys = [
      '__ch__',
      '__aa__',
      '__mu__',
      '__meta__',
      '__in__'
    ];
    const { user, mounted, isLoggedIn } = this.state;
    const { dp = '', lastName = '', firstName = '', plan, email } = user;
    const { token, id, lb, n, username_ref } = user.auth;

    if (!mounted || !isLoggedIn || !user) return;

    try {
      const tokenTL = `${token}`.length;
      const baseTL = Math.floor(Number(tokenTL) / 3);
      const tokenArr = token.split('');

      if (lastName && firstName) {
        localStorage.setItem(
          storageUserAuthKeys[storageUserAuthKeys.length - 1],
          JSON.stringify({
            in: `${firstName.charAt(0)}${lastName.charAt(0)}`,
            dp: `${dp}`,
            isPremiumTeams: plan.isPremiumTeams,
            isExpired: !plan.isLifetime && plan.isExpired,
            isLifetime: plan.isLifetime,
            email: email
          })
        );
      }

      localStorage.setItem(
        storageUserAuthKeys[0],
        JSON.stringify({
          k: tokenArr.slice(0, baseTL).join(''),
          idx: 0,
          uuid: v4()
        })
      );

      localStorage.setItem(
        storageUserAuthKeys[1],
        JSON.stringify({
          k: tokenArr.slice(baseTL, baseTL * 2).join(''),
          idx: baseTL,
          uuid: v4()
        })
      );

      localStorage.setItem(
        storageUserAuthKeys[2],
        JSON.stringify({
          k: tokenArr.slice(baseTL * 2, tokenTL).join(''),
          idx: tokenTL,
          uuid: v4()
        })
      );

      localStorage.setItem(
        storageUserAuthKeys[3],
        JSON.stringify({
          lb,
          n,
          t_id: id,
          u_id: user.profileID,
          username_map: username_ref,
          uuid: v4()
        })
      );
    } catch (err) {
      UserLog.post('dashboard_erorr_login_store', 'Critical', {
        message: `Unable to store user login session in dashboard FE: ${Store.CONFIGS.app.clientVersion}, err: ${err.message} `
      });

      Logger.log(`Failed to store user session: ${err.message}`);
    }
  }

  async confirmUserNotLoggedIn() {
    if (!this.state.mounted) {
      await this.setStateAsync({
        mounted: true
      });
    }

    Logger.log(`Confirmed user wasn't logged in`);
    this.clearUserStorage();

    await this.setStateAsync({
      profileLoaded: true,
      isLoggedIn: false
    });
  }

  clearUserStorage() {
    if (ProfileAPI.USER_DATA) {
      ProfileAPI.USER_DATA.resetVARS();
    }

    if (ProfileAPI.USER_TASKS) {
      ProfileAPI.USER_TASKS.resetVARS();
    }

    if (ProfileAPI.PUBLIC) {
      ProfileAPI.PUBLIC.resetVARS();
    }

    this.clearLS();
  }

  clearLS() {
    if (!Store.CONFIGS.HAS_LOCAL_STORAGE_AVAILABLE) {
      return;
    }

    const storageUserAuthKeys = [
      '__ch__',
      '__aa__',
      '__mu__',
      '__meta__',
      '__in__'
    ];
    try {
      for (let i of storageUserAuthKeys) {
        localStorage.removeItem(`${i}`);
      }
    } catch (err) {
      Logger.log(
        `Failed to clear user storage with keys:`,
        storageUserAuthKeys
      );
    }
  }

  async removeUserSession() {
    await this.confirmUserNotLoggedIn();
    await this.setStateAsync({
      user: {
        access: [],
        firstName: '',
        lastName: '',
        email: '',
        created: 0,
        initials: '',
        profileID: '',
        profileImage: '', // URL
        newNotifs: false,
        newTasks: false,
        dp: '',
        dpLimit: false,
        auth: {
          token: '',
          id: '',
          username_ref: '',
          lb: '',
          n: 0
        },
        plan: {
          isPremium: false,
          isPremiumTeams: false,
          isExpired: false,
          isLifetime: false,
          maxTags: 0,
          tasksMonth: 0,
          type: i18n('user_plan_free_title'),
          end: 0,
          started: 0
        },
        notificationSettings: {
          email: {
            allowEmailOnCommentPersonal: true,
            allowEmailOnTaskSubscribed: true,
            allowEmailOnNewTaskCustom: false,
            allowEmailOnTag: false,
            allowEmailOnTaskSubscribedCustom: true,
            unsubscribeToAllEmailCustom: false
          }
        },
        lastDraft: {
          valid: false,
          title: '',
          description: '',
          created: 0,
          priority: -1,
          taskId: '',
          uRef: '',
          files: []
        }
      },
      isFetchingInfo: false,
      isLoggedIn: false
    });

    return this.clearUserStorage();
  }

  render() {
    const { profileLoaded, isLoggedIn, mounted, isOffline } = this.state;

    return (
      <Provider value={{ profileLoaded, isLoggedIn, isOffline }}>
        {mounted && this.props.children}
      </Provider>
    );
  }
}

export default withErrorManagerSettings(ProfileManager);
