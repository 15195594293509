import React from 'react';
import styles from './FilesPreview.scss';
import FilePreview from 'src/components/file-preview';
import { isString, isEmpty } from 'src/helpers/utils';

const FilesPreview = ({ files = [] }) => {
  if (!files || !files.length) {
    return <></>;
  }

  files = files.length > 0 ? files.filter(file => file && !file.inline) : [];

  return (
    <div className={styles.files_preview}>
      <ul>
        {files
          .filter(
            file =>
              file &&
              file.id &&
              isString(file.fileName) &&
              !isEmpty(file.fileName)
          )
          .map((file, idx) => {
            const name = file.metadata.fileName;
            const type = file.type;
            const sizeInKb = file.size;
            const sizeInBytes = file.sizeBytes;
            const url = file.url;

            return (
              <li key={`file-preview-${idx}-${file.id}`}>
                <FilePreview
                  name={name}
                  type={type}
                  url={url}
                  sizeInKb={sizeInKb}
                  sizeInBytes={sizeInBytes}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default FilesPreview;
