import { isNumber, isNil } from 'src/helpers/utils';
import { Capacitor } from '@capacitor/core';
import { isMobileView, isIOSMobile } from 'src/helpers/utils';
class ModalAPI {
  static context = null;
  static closeOnNewMount = false;

  constructor() {
    this.closeOnNewMount = false;
    this.goDeactivate = this.goDeactivate.bind(this);
    this.goActivate = this.goActivate.bind(this);
    this.hideDOM2 = this.hideDOM2.bind(this);
  }

  setContext(ctx) {
    this.context = ctx;
  }

  noEscape() {
    if (this.context) {
      this.context.setState({
        noEscape: true
      });
    }
  }

  hasEscape() {
    if (this.context) {
      this.context.setState({
        noEscape: false
      });
    }
  }

  hasActiveModal() {
    return !!(
      this.context &&
      this.context.state &&
      (this.context.state.localDOMREF || this.context.state.active)
    );
  }

  getSource() {
    return this.context && this.context.state && this.context.state.source;
  }

  setDOM(dom = null, active = false, source = '') {
    if (this.context && dom) {
      this.context.setState(
        {
          source,
          localDOMREF: dom,
          active: !!dom
        },
        () => this.context.setModalStyle(true)
      );
    }
  }

  setDOM2(dom = null) {
    if (
      this.context &&
      this.context.state &&
      this.context.state.active &&
      dom
    ) {
      this.context.setState({
        localDOMREF2: dom
      });
    }
  }

  hideDOM2() {
    if (this.context) {
      this.context.setState({
        localDOMREF2: null
      });
    }
  }

  toCloseOnNewMount() {
    return !!this.closeOnNewMount;
  }

  setCloseOnNewMount() {
    this.closeOnNewMount = true;
  }

  goActivate() {
    if (this.context) {
      this.context.setState(
        {
          active: true
        },
        () => this.setModalStyle(true)
      );
    }
  }

  setModalStyle(force = false) {
    if (this.context) {
      this.context.setModalStyle(!!force);
    }
  }

  modalOnScroll() {
    if (this.context) {
      this.context.onScroll();
    }
  }

  goDeactivate() {
    if (this.context && this.context.state && this.context.state.active) {
      this.context.setState(
        {
          noEscape: false,
          active: false,
          source: '',
          localDOMREF: null,
          localDOMREF2: null
        },
        () => {
          const isIOSPlatform = Capacitor.getPlatform() === 'ios';
          const isIosPhoneDevice = isIOSMobile();

          if (isMobileView() && (isIOSPlatform || isIosPhoneDevice)) {
            window.scrollTo(0, 0);
          }

          this.setModalStyle(true);
        }
      );
    }
  }

  isActive() {
    return !!(this.context && this.context.state && this.context.state.active);
  }

  toaster(type = '', message = '', timeInMilliseconds = 1000) {
    if (!this.context || !type || !message) {
      return;
    }

    if (
      ['success', 'info', 'warning', 'danger'].includes(type) &&
      !isNil(message) &&
      isNumber(timeInMilliseconds) &&
      timeInMilliseconds > 0
    ) {
      this.context.setState({
        toaster: true,
        toasterType: type,
        toasterMessage: message
      });

      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        this.context.setState({
          toaster: false,
          toasterType: '',
          toasterMessage: ''
        });
      }, timeInMilliseconds);
    }
  }

  closeToaster() {
    if (!this.context) {
      return;
    }

    this.context.setState({
      toaster: false,
      toasterType: '',
      toasterMessage: ''
    });
  }
}

export default new ModalAPI();
