import React, { Component, Fragment } from 'react';
import ModalAPI from './API';
import CustomToaster from 'src/components/custom-toaster';
import { withErrorManagerSettings } from 'src/error-manager';
import { Capacitor } from '@capacitor/core';
import { isMobileView, isIOSMobile } from 'src/helpers/utils';
import { fromEvent } from 'rxjs';

class ModalManager extends Component {
  onDocumentKeydownSubscriber = null;
  onResizeSubscriber = null;
  onScrollSubscriber = null;
  onDocumentScrollSubscriber = null;
  initialViewportHeight = 0;
  adjusting = false;

  state = {
    active: false,
    source: '',
    localDOMREF: null,
    localDOMREF2: null,
    mounted: false,
    noEscape: false,
    toaster: false,
    toasterMessage: '',
    toasterType: 'info',
    currentTotalHeight: window?.visualViewport
      ? Math.floor(window.visualViewport.height)
      : window.innerHeight,
    coverStyle: {
      position: 'absolute',
      minHeight: '100vh',
      width: '100vw',
      left: '0',
      top: '0',
      right: '0',
      boxSizing: 'border-box'
      // border: '10px solid red'
    }
  };

  constructor() {
    super();

    if (!this.initialViewportHeight) {
      this.initialViewportHeight = Math.floor(window.visualViewport.height);
    }
    ModalAPI.setContext(this);
  }

  componentDidMount() {
    const isIOSPlatform = Capacitor.getPlatform() === 'ios';
    const isIosPhoneDevice = isIOSMobile();

    this.onDocumentKeydownSubscriber = fromEvent(window, 'keydown').subscribe(
      this.onKeydown
    );
    this.setState(
      {
        mounted: true
      },
      this.onMount
    );

    try {
      document.addEventListener('scroll', this.onScroll);
      window.addEventListener('scroll', this.onScroll, false);

      if (!(isIOSPlatform || isIosPhoneDevice) || !window.visualViewport) {
        window.addEventListener('resize', this.onResize, false);
      } else if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', () => {
          this.onScroll();
          this.onResize();
        });
      }
    } catch {}
  }

  componentWillUnmount() {
    this.setState({
      mounted: false
    });

    try {
      window.removeEventListener('scroll', this.onScroll, false);
      window.removeEventListener('resize', this.onResize, false);
      document.removeEventListener('scroll', this.onScroll);
      window.visualViewport.removeEventListener('resize', this.onResize);
    } catch {}

    if (this.onResizeSubscriber) {
      this.onResizeSubscriber.unsubscribe();
    }

    if (this.onScrollSubscriber) {
      this.onScrollSubscriber.unsubscribe();
    }

    if (this.onDocumentScrollSubscriber) {
      this.onDocumentScrollSubscriber.unsubscribe();
    }

    if (this.onDocumentKeydownSubscriber) {
      this.onDocumentKeydownSubscriber.unsubscribe();
    }
  }

  onMount = () => {
    this.onScroll();
    this.setModalStyle(true);
  };

  onResize = () => {
    const currentTotalHeight = Math.floor(
      window?.visualViewport ? window.visualViewport.height : window.innerHeight
    );

    this.setModalStyle(true);
    this.setState({ currentTotalHeight });
  };

  onScroll = () => {
    const { mounted, active, source } = this.state;
    const isIOSPlatform = Capacitor.getPlatform() === 'ios';
    const isIosPhoneDevice = isIOSMobile();
    const html = document.querySelector('html');

    if (!mounted) {
      return;
    } else if (
      active &&
      source === 'CreateTask' &&
      (isIosPhoneDevice || isIOSPlatform)
    ) {
      window.scrollTo(0, 0);
    } else if (!active && (isIosPhoneDevice || isIOSPlatform)) {
      if (html.style?.minHeight) {
        ModalAPI.setModalStyle(true);
      }
    }
  };

  setModalStyle = (force = false) => {
    try {
      const { coverStyle, mounted, localDOMREF, active } = this.state;
      const isIOSPlatform = Capacitor.getPlatform() === 'ios';
      const isMobile = isIOSPlatform || isMobileView();
      const isIosPhoneDevice = isIOSMobile();
      const root = document.getElementById('root');
      const isActive = !!localDOMREF || active;

      if (!mounted || (this.adjusting && !force)) {
        return;
      } else if (!this.adjusting) {
        this.adjusting = true;
      }

      if (isMobile) {
        const currentViewportHeight = Math.floor(window.visualViewport.height);
        const newH =
          isActive && (isIosPhoneDevice || isIOSPlatform)
            ? `${currentViewportHeight}px`
            : isIosPhoneDevice || isIOSPlatform
            ? '100%' // '100vh'
            : '100%';

        if (!isActive) {
          if (isIosPhoneDevice || isIOSPlatform) {
            coverStyle.minHeight = newH;
            coverStyle.maxHeight = newH;
          } else {
            coverStyle.minHeight = '100%';
            coverStyle.maxHeight = '100%';
            coverStyle.height = '100%';
          }
        } else {
          coverStyle.minHeight = newH;
          coverStyle.maxHeight = newH;
        }

        coverStyle.position = 'absolute';
        coverStyle.top = '0';
        coverStyle.left = '0';
        coverStyle.right = '0';
        coverStyle.bottom =
          isActive && (isIosPhoneDevice || isIOSPlatform)
            ? `${this.initialViewportHeight - currentViewportHeight + 8}px`
            : '0';

        if (root && active && (isIosPhoneDevice || isIOSPlatform)) {
          root.style.height = newH;
          document.body.style.height = newH;
          root.style.overflow = 'hidden';
        } else if (root && (isIosPhoneDevice || isIOSPlatform)) {
          root.style.overflow = '';
          root.style.height = '';
          document.body.style.height = '';
        }
      } else {
        coverStyle.position = 'absolute';
        coverStyle.minHeight = '100vh';

        if (active) {
          coverStyle.maxHeight = '100vh';
          coverStyle.overflow = 'hidden';
        } else {
          coverStyle.maxHeight = 'unset';
          coverStyle.overflow = 'unset';
        }
      }

      coverStyle.width = '100%';

      this.setState({
        coverStyle: { ...coverStyle }
      });
    } finally {
      this.adjusting = false;
    }
  };

  onKeydown = evt => {
    if (evt && evt.keyCode === 27) {
      const { mounted, active, noEscape } = this.state;

      if (noEscape) {
        return;
      } else if (active && mounted) {
        this.setState(
          {
            active: false,
            localDOMREF: null,
            localDOMREF2: null
          },
          this.setModalStyle
        );
      }
    }
  };

  render() {
    const {
      active,
      localDOMREF,
      localDOMREF2,
      coverStyle,
      toaster,
      toasterMessage,
      toasterType,
      source
    } = this.state;
    const isIOSPlatform = Capacitor.getPlatform() === 'ios';
    const isMobile = isIOSPlatform || isMobileView();
    const isIosPhoneDevice = isIOSPlatform || isIOSMobile();
    const withOverflow = ['TaskImageExpand'].includes(source);

    return (
      <Fragment>
        <div
          id="rootmodal"
          style={{
            ...coverStyle,
            ...(active && {
              filter: `blur(3px)`,
              msUserSelect: 'none',
              userSelect: 'none',
              MozUserSelect: '-moz-none',
              display: 'inline-block',
              ...(!withOverflow && { overflow: 'hidden' })
            }),
            boxSizing: 'border-box',
            padding: '0',
            width: '100%'
          }}
        >
          {this.props.children}
        </div>
        {(active && (
          <Fragment>
            <div
              style={{
                ...coverStyle,
                ...(localDOMREF2 && {
                  filter: `blur(3px)`,
                  overflow: 'hidden'
                }),
                ...(active && {
                  position: 'fixed',
                  top: '0',
                  background: 'transparent',
                  zIndex: 5
                }),
                ...(active &&
                  isMobile &&
                  isIosPhoneDevice && {
                    position: 'absolute',
                    top: '0',
                    overflow: 'hidden'
                  }),
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {localDOMREF}
            </div>
            {localDOMREF2 && (
              <div
                style={{
                  ...coverStyle,
                  ...this.centerAllStyle,
                  position: 'fixed',
                  top: '0',
                  background: 'transparent',
                  zIndex: 6,
                  ...(active &&
                    isMobile &&
                    isIosPhoneDevice && {
                      position: 'absolute',
                      top: '0',
                      overflow: 'hidden'
                    }),
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {localDOMREF2}
              </div>
            )}
          </Fragment>
        )) || <Fragment />}
        {toaster && toasterMessage && (
          <CustomToaster message={toasterMessage} type={toasterType} />
        )}
      </Fragment>
    );
  }
}

export default withErrorManagerSettings(ModalManager);
