import k from 'src/constants/k';

let initialHeight = 0;
let initialWidth = 250;

function onInput(evt) {
  if (
    this.titleHiddenDOMRef &&
    this.titleShownDOMRef &&
    this.titleHiddenDOMRef.current &&
    this.titleShownDOMRef.current &&
    evt
  ) {
    const target = evt.target
      ? evt.target
      : evt.srcElement
      ? evt.srcElement
      : null;

    if (!target) {
      return;
    }

    setTitleHeight.call(this, target);

    if (this && this.state) {
      const { loaded = false, taskDescriptionInit = false } = this.state;

      if (loaded && taskDescriptionInit) {
        if (this.descriptionEditorSaveDraftOBS) {
          this.descriptionEditorSaveDraftOBS.next();
        }
      }
    }
  }
}

function setTitleHeight(target) {
  if (initialHeight <= 0) {
    initialHeight = 40;
  }

  const { value = '' } = target;
  const { title = '', titleInfo, mounted } = this.state;

  if (!mounted) {
    return;
  }

  let newH = 0;
  let newX = 0;
  let finalValue = value;

  if (value.length > 0 && titleInfo.err) {
    titleInfo.err = false;
    titleInfo.msg = '';

    this.setState({
      titleInfo
    });
  } else if (value.length >= k.USER_TASK_TITLE_PROPERTIES.maxLength) {
    finalValue = `${value}`
      .trim()
      .replace(/\n/g, '')
      .split('')
      .slice(0, k.USER_TASK_TITLE_PROPERTIES.maxLength)
      .join('')
      .trim();
  }

  this.titleHiddenDOMRef.current.value = finalValue;
  this.titleShownDOMRef.current.parentElement.style.height = '0';
  newH = this.titleHiddenDOMRef.current.scrollHeight;
  newX = this.titleHiddenDOMRef.current.scrollWidth;

  if (newH < initialHeight || typeof newH !== 'number') {
    newH = initialHeight;
  }

  if (
    typeof newX !== 'number' ||
    newX < initialWidth ||
    !finalValue ||
    finalValue.length < 2
  ) {
    newX = initialWidth;
  }

  this.titleShownDOMRef.current.parentElement.style.height = `${newH}px`;
  // not set width, we maxed it out
  // this.titleShownDOMRef.current.parentElement.style.width = `${newX}px`;

  if (newH > initialHeight) {
    this.titleShownDOMRef.current.parentElement.style.paddingBottom = '7px';
  } else {
    this.titleShownDOMRef.current.parentElement.style.paddingBottom = '3px';
  }

  if (finalValue !== title) {
    this.setState({
      title: finalValue
    });
  }
}

function onFocus() {
  const { mounted } = this.state;

  if (!mounted) {
    return;
  }

  this.setState({
    titleFocus: true
  });
}

function onBlur() {
  const { mounted } = this.state;

  if (!mounted) {
    return;
  }

  this.setState({
    titleFocus: false
  });
}

// eslint-disable-next-line
export default {
  onInput,
  onFocus,
  onBlur,
  setTitleHeight
};
