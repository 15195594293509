import React, { Component } from 'react';
import cx from 'classnames';
import styles from './DeleteTask.scss';
import i18n from 'src/locales';
import CommonButton from 'src/components/common-button';
import AppAPI from 'src/app-manager/API';
import { Button, Spinner } from 'evergreen-ui';

class DeleteTask extends Component {
  state = {
    submitted: false
  };

  constructor() {
    super();

    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm() {
    if (typeof this.props.confirm === 'function') {
      this.setState(
        {
          submitted: true
        },
        this.props.confirm
      );
    }
  }

  onCancel() {
    if (typeof this.props.cancel === 'function') {
      this.props.cancel();
    }
  }

  render() {
    const { submitted } = this.state;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div
        className={cx(styles.flex_row_xy, styles.delete_task, {
          [styles.delete_task_dark]: isThemeDarkMode
        })}
      >
        <div className={styles.actual}>
          <h4>{i18n('user_edit_task_delete_msg')}</h4>
          <div
            className={cx(styles.spinner_wrap, {
              [styles.hide_element]: !submitted,
              [styles.spinner_wrap_dark]: isThemeDarkMode
            })}
          >
            <div className={cx(styles.raw, styles.flex_row_xy)}>
              <Spinner size={24} />
            </div>
          </div>
          <div
            className={cx(styles.flex_row_x, styles.actions, {
              [styles.hide_element]: submitted
            })}
          >
            <CommonButton
              onClick={this.onConfirm}
              text={i18n('common_confirm')}
              className={styles.confirm}
            />
            <Button className={styles.cancel} onClick={this.onCancel}>
              <div className={cx(styles.flex_row_xy, styles.cancel_raw)}>
                <p>{i18n('user_edit_task_delete_cancel')}</p>
              </div>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteTask;
