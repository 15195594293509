import React, { Component } from 'react';
import cx from 'classnames';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import styles from './AdvancedOptions.scss';
import k from 'src/constants/k';
import ButtonClick from 'src/components/button-click';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Checkbox, ChevronDownIcon } from 'evergreen-ui';

class AdvancedOptions extends Component {
  applyAdvanceOptionsOBS = null;
  applyAdvanceOptionsSubscriber = null;

  state = {
    fetching: false,
    mounted: false,
    expand: false,
    allowComments: true,
    allowSubsEdit: false
  };

  constructor(props) {
    super(props);

    this.applyAdvanceOptionsOBS = new Subject();
    this.state.allowComments = props.advancedOptions.allowComments;
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.toggleAllowComments = this.toggleAllowComments.bind(this);
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.toggleAllowSubsEditor = this.toggleAllowSubsEditor.bind(this);
    this.applyAdvancedOptions = this.applyAdvancedOptions.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true
      },
      this.onMount.bind(this)
    );
  }

  componentWillUnmount() {
    if (this.applyAdvanceOptionsSubscriber) {
      this.applyAdvanceOptionsSubscriber.unsubscribe();
      this.applyAdvanceOptionsSubscriber = null;
    }

    this.setState({
      mounted: false
    });
  }

  async onMount() {
    const {
      mode = '',
      taskID = '',
      universalRefID = '',
      modifyAdvancedOptions
    } = this.props;

    if (mode !== k.CREATE_TASK_MODE.CREATE) {
      await this.setStateAsync({
        fetching: true
      });

      const res = await ProfileAPI.USER_TASKS.advancedOptions(
        taskID,
        universalRefID
      );

      if (res && res.success) {
        this.applyAdvanceOptionsSubscriber = this.applyAdvanceOptionsOBS
          .pipe(debounceTime(400))
          .subscribe(this.applyAdvancedOptions);

        modifyAdvancedOptions('allowComments', res.allowComments);
        modifyAdvancedOptions('allowSubsEdit', res.allowSubsEdit);

        await this.setStateAsync({
          allowComments: res.allowComments,
          allowSubsEdit: res.allowSubsEdit,
          fetching: false
        });
      }
    }
  }

  async toggleAllowComments() {
    const { fetching, mounted, allowComments } = this.state;

    if (!mounted || fetching) {
      return;
    } else {
      await this.setStateAsync({
        allowComments: !allowComments
      });

      this.props.modifyAdvancedOptions('allowComments', !allowComments);
      this.applyAdvanceOptionsOBS.next();
    }
  }

  async toggleAllowSubsEditor() {
    const { allowSubsEdit, fetching, mounted } = this.state;

    if (!mounted || fetching) {
      return;
    }

    await this.setStateAsync({
      allowSubsEdit: !allowSubsEdit
    });

    this.props.modifyAdvancedOptions('allowSubsEdit', !allowSubsEdit);
    this.applyAdvanceOptionsOBS.next();
  }

  async applyAdvancedOptions() {
    const { mounted, allowComments, allowSubsEdit } = this.state;
    const { taskID = '', universalRefID = '', mode = '' } = this.props;
    if (!mounted) {
      return;
    } else if (mode && mode !== k.CREATE_TASK_MODE.CREATE) {
      await ProfileAPI.USER_TASKS.applyAdvancedOptions(
        taskID,
        universalRefID,
        allowComments,
        allowSubsEdit
      );
    }
  }

  toggleAdvancedOptions() {
    const { mounted, fetching, expand } = this.state;

    if (!mounted || fetching) {
      return;
    } else {
      this.setState({
        expand: !expand
      });
    }
  }

  render() {
    const { fetching, expand, allowComments, allowSubsEdit } = this.state;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div className={styles.advanced_options}>
        <div
          className={cx(styles.advanced_options_title, {
            [styles.hide_element]: fetching,
            [styles.advanced_options_title_expand]: expand,
            [styles.advanced_options_title_dark]: isThemeDarkMode
          })}
        >
          <ButtonClick onClick={this.toggleAdvancedOptions}>
            <h4> {i18n('user_create_task_advanced_options')} </h4>
            <div>
              <ChevronDownIcon />
            </div>
          </ButtonClick>
        </div>
        <div
          className={cx(styles.advanced_options_content, {
            [styles.hide_element]: fetching,
            [styles.advanced_options_content_hide]: !expand,
            [styles.advanced_options_content_show]: expand
          })}
        >
          <div className={styles.allow_comments}>
            <div
              className={cx(styles.cb, {
                [styles.cb_dark]: isThemeDarkMode,
                [styles.cb_active_dark]: isThemeDarkMode && allowComments
              })}
            >
              <Checkbox
                label={i18n('user_create_task_advanced_options_allow_comments')}
                className={styles.raw}
                checked={allowComments}
                onChange={this.toggleAllowComments}
              />
            </div>
          </div>
          <div className={styles.allow_subs_edit}>
            <div
              className={cx(styles.cb, {
                [styles.cb_dark]: isThemeDarkMode,
                [styles.cb_active_dark]: isThemeDarkMode && allowSubsEdit
              })}
            >
              {' '}
              <Checkbox
                label={i18n(
                  'user_create_task_advanced_options_allow_subs_to_edit'
                )}
                className={styles.raw}
                checked={allowSubsEdit}
                onChange={this.toggleAllowSubsEditor}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.advanced_options_placeholder, {
            [styles.hide_element]: !fetching
          })}
        >
          <ul>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default AdvancedOptions;
