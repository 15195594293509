import k from 'src/constants/k';
import Logger from 'src/lib/Logger';
import i18n from 'src/locales';
import { isValidFileLink, isString } from 'src/helpers/utils';

export class TaskPropertiesTemplate {
  taskContext = null;
  taskPriorityUnique = 'CREATE_TASK_V2_UNIQUE';
  taskPrivacyUnique = 'create-task-privacy';
  taskAddedTagsUnique = 'create-task-add-tags';
  taskStatesUnique = 'TASKS_STATES_UNIQUE';
  taskDescriptionToolbarID = 'create-task-description-toolbar-id';
  cleanDescription = { ops: [] };
  active = false;

  NOT_ALLOWED_HTMLS = [
    'IFRAME',
    // 'iframe',
    'PICTURE',
    'picture',
    'SOURCE',
    'source',
    'H3',
    'h3',
    'H4',
    'h4',
    'H5',
    'h5',
    'HTML',
    'html',
    'BODY',
    'body'
  ];

  constructor() {
    this.onPrioritySelect = this.onPrioritySelect.bind(this);
    this.onTogglePrivacy = this.onTogglePrivacy.bind(this);
    this.isContextMounted = this.isContextMounted.bind(this);
    this.onExposurePWChange = this.onExposurePWChange.bind(this);
    this.onSecureWithPWToggle = this.onSecureWithPWToggle.bind(this);
    this.onStateSelect = this.onStateSelect.bind(this);
  }

  setInactive() {
    this.active = false;
  }

  setActive() {
    this.active = true;
  }

  setContext(ctx) {
    this.taskContext = ctx;
  }

  isConfirmLoaded() {
    return (
      this.taskContext &&
      this.taskContext.state &&
      this.taskContext.state.loaded &&
      this.active
    );
  }

  isContextMounted() {
    return (
      this.taskContext &&
      this.taskContext.state &&
      this.taskContext.state.mounted &&
      this.active
    );
  }

  async setContextState(props) {
    if (
      this.taskContext &&
      props &&
      typeof props === 'object' &&
      this.isContextMounted()
    ) {
      await this.taskContext.setStateAsync({ ...props });
    }
  }

  onStateSelect(evt) {
    if (
      this.taskContext &&
      this.taskContext.state &&
      this.taskContext.state.mounted &&
      evt &&
      this.isContextMounted()
    ) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;
      const { task_state } = this.taskContext.state;

      if (target && target.value) {
        const value = target.value;

        if (
          k.TASKS_STATES_OBJ[value] &&
          (!task_state || task_state.key !== value)
        ) {
          this.taskContext.setState({
            task_state: { ...k.TASKS_STATES_OBJ[value], valid: true }
          });
        }
      }
    }
  }

  onPrioritySelect(evt) {
    if (
      this.taskContext &&
      this.taskContext.state &&
      this.taskContext.state.mounted &&
      evt &&
      this.isContextMounted()
    ) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;
      const { priority } = this.taskContext.state;

      if (target && target.value) {
        const value = target.value;

        if (
          k.TASK_PRIORITIES_OBJ[value] &&
          (!priority || priority.key !== value)
        ) {
          this.taskContext.setState({
            priority: { ...k.TASK_PRIORITIES_OBJ[value] }
          });
        }
      }
    }
  }

  onTogglePrivacy(evt) {
    if (!evt || !this.isContextMounted()) return;
    else {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (target && target.value) {
        const privacy_key = target.value;

        if (['private', 'public'].indexOf(privacy_key) >= 0) {
          const { exposure, mode, exposurePasscode } = this.taskContext.state;

          if (privacy_key === 'private') {
            if (k.USER_TASK_PRIVACY_PRIVATE !== exposure) {
              this.setContextState({
                exposure: k.USER_TASK_PRIVACY_PRIVATE,
                exposureWPasscode: false,
                exposurePasscode:
                  mode === k.CREATE_TASK_MODE.EDIT ? exposurePasscode : ''
              });
            }
          } else if (privacy_key === 'public') {
            if (k.USER_TASK_PRIVACY_PUBLIC_NO_PW !== exposure) {
              this.setContextState({
                exposure: k.USER_TASK_PRIVACY_PUBLIC_NO_PW,
                exposureWPasscode: false,
                exposurePasscode:
                  mode === k.CREATE_TASK_MODE.EDIT ? exposurePasscode : ''
              });
            }
          }
        }
      }
    }
  }

  onSecureWithPWToggle() {
    if (!this.isContextMounted()) {
      return;
    }

    const { exposurePasscodeInfo } = this.taskContext.state;

    if (exposurePasscodeInfo.err) {
      exposurePasscodeInfo.err = false;
      exposurePasscodeInfo.msg = '';
    }

    this.taskContext.setState({
      exposure: k.USER_TASK_PRIVACY_PUBLIC_W_PW,
      exposureWPasscode: true,
      exposurePasscodeInfo: exposurePasscodeInfo
    });
  }

  onExposurePWChange(evt) {
    if (!evt || !this.isContextMounted()) {
      return;
    }

    const target = evt.target
      ? evt.target
      : evt.srcElement
      ? evt.srcElement
      : null;

    if (!target) {
      return;
    }

    const value = `${target.value}`;
    const { exposurePasscodeInfo, exposurePasscode } = this.taskContext.state;

    if (
      value.length < k.USER_TASK_PRIVACY_PW_PROPERTIES.minLength &&
      exposurePasscodeInfo.msg !==
        i18n('user_create_task_status_min_privacy_pw')
    ) {
      exposurePasscodeInfo.err = true;
      exposurePasscodeInfo.msg = i18n('user_create_task_status_min_privacy_pw');
    } else if (
      value.length >= k.USER_TASK_PRIVACY_PW_PROPERTIES.minLength &&
      exposurePasscodeInfo.err
    ) {
      exposurePasscodeInfo.err = false;
      exposurePasscodeInfo.msg = '';
    }

    this.taskContext.setState({
      exposurePasscode:
        value.length > k.USER_TASK_PRIVACY_PW_PROPERTIES.maxLength
          ? exposurePasscode
          : value,
      exposurePasscodeInfo: exposurePasscodeInfo
    });
  }

  setDescriptionProperties(description_delta) {
    if (!this.isContextMounted() || !this.taskContext) {
      return;
    } else if (!Array.isArray(description_delta.ops)) {
      return;
    }

    Logger.log(`Applying description props`);

    this.taskContext.QuillEditor.setContents(description_delta.ops);
  }

  sanitizeDescription(description_delta = { ops: [] }) {
    if (!description_delta || !Array.isArray(description_delta.ops)) {
      return this.cleanDescription;
    }

    let i = 0;

    while (description_delta.ops.length > i) {
      if (
        !description_delta.ops[i] ||
        typeof description_delta.ops[i] === 'number'
      ) {
        description_delta.ops.splice(i, 1);

        if (description_delta.ops.length >= i) {
          break;
        } else {
          i += 1;
          continue;
        }
      }

      const op = description_delta.ops[i];

      if (op && op.insert && typeof op.insert === 'object') {
        if (
          // if unallowed videos from chamu storage
          isString(op.insert?.video) &&
          !isValidFileLink(op.insert.video)
        ) {
          op.insert = '\n';
        }

        // remove placeholders
        if (op.insert?.placeholderVideo || op.insert?.placeholderImage) {
          op.insert = '\n';
        }

        if (isString(op.insert?.image) && !isValidFileLink(op.insert.image)) {
          op.insert = '\n';
        }
      }

      i += 1;
    }

    return description_delta;
  }
}

export default new TaskPropertiesTemplate();
