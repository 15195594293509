import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './Files.scss';
import LoadSpinner from 'src/components/load-spinner';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import MessageHover from 'src/components/message-hover';
import { isFunction } from 'src/helpers/utils';
import { TickCircleIcon, CrossIcon, Button } from 'evergreen-ui';

const StoredFile = ({
  name = '',
  removeFile,
  id = '',
  refId = '',
  url = '',
  loading = false,
  moreView = false
}) => {
  if (!name) {
    return <></>;
  }

  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      className={cx(styles.stored_file, styles.flex_row_y, {
        [styles.stored_file_more_view]: moreView,
        [styles.stored_file_dark]: isThemeDarkMode
      })}
    >
      <div className={styles.icon}>
        {!loading && <TickCircleIcon color="success" />}
        {loading && (
          <div className={styles.spinner}>
            <LoadSpinner />
          </div>
        )}
      </div>
      <h5>
        {name}
        {url && (
          <a
            className={styles.file_link}
            target={'_blank'}
            rel="noreferrer"
            href={url}
          >
            <p></p>
          </a>
        )}
      </h5>
      <div
        className={cx(styles.remove, { [styles.remove_more_view]: moreView })}
        onClick={() => {
          if (isFunction(removeFile) && !loading && refId) {
            removeFile(id, refId);
          }
        }}
      >
        <CrossIcon />
      </div>{' '}
      {name && !moreView && (
        <MessageHover message={name} className={cx(styles.filename_tip)} />
      )}
    </div>
  );
};

const Files = ({ files = [], handleRemoveFileUpload, isEditMode = false }) => {
  const [showMoreFiles, setShowMoreFiles] = useState(false);

  const removeFile = (id = '', refId = '') => {
    if (id && isFunction(handleRemoveFileUpload)) {
      handleRemoveFileUpload(id, refId);
    }
  };
  files = files.filter(file => file && !file.inline);
  const max = window.innerWidth < 901 || isEditMode ? 3 : 4;
  const seeMore = files && files.length > max;
  const preview = seeMore ? files.slice(0, max) : files;

  useEffect(() => {
    if (showMoreFiles && !seeMore) {
      setShowMoreFiles(false);
    }
  }, [seeMore, showMoreFiles]);

  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div className={styles.files}>
      <ul>
        {preview.map((currentFile, idx) => {
          if (!currentFile) {
            return <></>;
          }

          return (
            <li key={`task-file-${currentFile.id}-${idx}`}>
              <StoredFile
                name={
                  currentFile && currentFile.metadata
                    ? currentFile.metadata.fileName
                    : currentFile.fileName
                }
                id={currentFile.id}
                refId={currentFile.ref_id || currentFile.refId}
                removeFile={removeFile}
                loading={currentFile.loading}
                url={currentFile.url}
              />
            </li>
          );
        })}
        {seeMore && (
          <li
            className={cx(styles.see_more, {
              [styles.see_more_dark]: isThemeDarkMode
            })}
          >
            <Button
              appearance="minimal"
              onClick={() => setShowMoreFiles(!showMoreFiles)}
            >
              <h5>
                {showMoreFiles ? ' — ' : '. . .' || i18n('common_see_more')}
              </h5>
            </Button>
          </li>
        )}
      </ul>
      {showMoreFiles && (
        <div
          className={cx(styles.show_more, {
            [styles.show_more_dark]: isThemeDarkMode
          })}
        >
          <ul>
            {files.map((currentFile, idx) => {
              return (
                <li key={`task-file-${currentFile.id}-${idx}`}>
                  <StoredFile
                    name={
                      currentFile && currentFile.metadata
                        ? currentFile.metadata.fileName
                        : currentFile.fileName
                    }
                    id={currentFile.id}
                    refId={currentFile.ref_id || currentFile.refId}
                    removeFile={removeFile}
                    loading={currentFile.loading}
                    moreView={true}
                    url={currentFile.url}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Files;
