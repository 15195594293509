import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './ImageExpand.scss'; 
import ModalAPI from 'src/modal-manager/API';
import { isFunction } from 'src/helpers/utils';
import { CrossIcon, IconButton } from 'evergreen-ui';

const ImageExpand = props => {
  const imageRef = useRef();
  const imageDOMRef = useRef();

  const applyImage = useCallback(() => {
    if (imageRef && imageRef.current && imageDOMRef && imageDOMRef.current) {
      if (typeof imageRef.current === 'string') {
        imageDOMRef.current.setAttribute('src', imageRef.current);
      }
    }
  }, [imageRef]);

  const close = () => {
    if (isFunction(props.close)) {
      props.close();
    } else {
      ModalAPI.goDeactivate();
    }
  };

  useEffect(() => {
    if (props.image && imageRef) {
      imageRef.current = props.image;

      applyImage();
    }
    // eslint-disable-next-line
  }, [props.image, imageRef]);

  return (
    <div className={cx(styles.cover, styles.image_expand)}>
      <img alt="click-display-expanded" ref={imageDOMRef} />
      <div className={styles.close}>
        <IconButton
          icon={CrossIcon}
          appearance={'minimal'}
          onClick={() => {
            if (isFunction(close)) {
              close();
            }
          }}
        />
      </div>
    </div>
  );
};

export default ImageExpand;
