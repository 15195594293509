import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './LoginV2.scss';
import AppLogoIcon from 'src/components/app-logo-icon';
import Button from 'src/components/button';
import InitUserLoading from 'src/components/init-user';
import ProfileAPI from 'src/profile-manager/API';
import Store from 'src/lib/store';
import AppAPI from 'src/app-manager/API';
import UserInputs, { isCorrectEmailFormat } from 'src/lib/UserInputs';
import ModalAPI from 'src/modal-manager/API';
import ButtonClick from 'src/components/button-click';
import i18n, { URLMarkdown } from 'src/locales';
import { withUserProfileSettings } from 'src/profile-manager';
import { AdjustableAbsolutWrap } from 'src/layout/commons';
import { Redirect, withRouter } from 'react-router-dom';
import { fromEvent } from 'rxjs';
import {
  Checkbox,
  TextInputField,
  EyeOpenIcon,
  EyeOffIcon
} from 'evergreen-ui';

class LoginV2 extends Component {
  resultsFromSubmitLoginSubscriber = null;
  localWrapOnClickSubscriber = null;
  localWrapOnBlurSubscriber = null;
  localDocumentOnKeyDownSubscriber = null;
  wrapREF = null;
  usernameInputREF = null;
  passwordInputREF = null;
  password = '';
  outBlur = false;

  state = {
    mounted: false,
    submitted: false,
    status: '',
    username: '',
    usernameInfo: { err: false, msg: '' },
    password: '',
    passwordInfo: { err: false, msg: '' },
    passwordShow: false,
    rememberLoginLocal: false,
    auto: false,
    userBeta: false
  };

  constructor() {
    super();

    this.wrapREF = createRef();
    this.usernameInputREF = createRef();
    this.passwordInputREF = createRef();
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.state.rememberLoginLocal = AppAPI.getLocalConfig('rememberLogin');

    this.submit = this.submit.bind(this);
    this.onWrapBlur = this.onWrapBlur.bind(this);
    this.onWrapClick = this.onWrapClick.bind(this);
    this.inputsOnFocus = this.inputsOnFocus.bind(this);
    this.toggleRememberLogin = this.toggleRememberLogin.bind(this);
    this.usernameInput = this.inputTextChange.bind(this, 'username');
    this.passwordInput = this.inputTextChange.bind(this, 'password');
  }

  componentDidMount() {
    this.resultsFromSubmitLoginSubscriber = ProfileAPI.PUBLIC.onLoginResult(
      this.componentOnLoginResult
    );

    if (this.wrapREF && this.wrapREF.current) {
      this.localWrapOnClickSubscriber = fromEvent(
        this.wrapREF.current,
        'click'
      ).subscribe(this.onWrapClick);

      this.localWrapOnBlurSubscriber = fromEvent(
        this.wrapREF.current,
        'blur'
      ).subscribe(this.onWrapBlur);
    }

    this.localDocumentOnKeyDownSubscriber = fromEvent(
      document ? document : window,
      'keydown'
    ).subscribe(e => {
      const { mounted, submitted } = this.state;

      if (e.keyCode === 13 && !this.outBlur && mounted && !submitted) {
        this.submit();
      }
    });

    Store.CONFIGS.getQueryJSON();
    this.setState(
      {
        mounted: true
      },
      this.onMount.bind(this)
    );
  }

  componentWillUnmount() {
    if (this.resultsFromSubmitLoginSubscriber) {
      this.resultsFromSubmitLoginSubscriber.unsubscribe();
    }

    if (this.localWrapOnClickSubscriber) {
      this.localWrapOnClickSubscriber.unsubscribe();
    }

    if (this.localWrapOnBlurSubscriber) {
      this.localWrapOnBlurSubscriber.unsubscribe();
    }

    if (this.localDocumentOnKeyDownSubscriber) {
      this.localDocumentOnKeyDownSubscriber.unsubscribe();
    }

    this.setState({
      mounted: false,
      submitted: false
    });
    this.password = '';
  }

  async onMount() {
    const toLoginUser = AppAPI.getGlobalConfig('toLoginUser');

    if (
      toLoginUser &&
      toLoginUser.valid &&
      Store.CONFIGS.query &&
      (Store.CONFIGS.query.redirect === 'sign_up_success' ||
        Store.CONFIGS.query.redirect === 'invite_code_success')
    ) {
      const { username = '', pw = '' } = toLoginUser;

      this.password = pw;

      await this.setStateAsync({
        username,
        password: pw,
        status: '',
        auto: true,
        submitted: true
      });

      await this.submit();
    } else if (
      Store.CONFIGS.query.redirect === 'sign_up_success' ||
      Store.CONFIGS.query.redirect === 'invite_code_success'
    ) {
      if (this.props.history) {
        this.props.history.push('/login');
      }
    }

    await AppAPI.setGlobalConfig('toLoginUser', {
      username: '',
      pw: '',
      valid: false
    });
  }

  inputTextChange(name, value = '') {
    const { usernameInfo, passwordInfo } = this.state;

    if (value && typeof value !== 'string') {
      const evt = value;
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (target) {
        const { value = '' } = target;

        this.setState({
          [name]: value
        });

        if (usernameInfo.err && value) {
          usernameInfo.err = false;
          usernameInfo.msg = '';

          this.setState({
            usernameInfo
          });
        }

        if (passwordInfo.err && value) {
          passwordInfo.err = false;
          passwordInfo.msg = '';

          this.setState({
            passwordInfo
          });
        }

        this.inputsOnFocus();
      }
    } else if (typeof value === 'string') {
      this.setState({
        [name]: value
      });
    }
  }

  inputsOnFocus() {
    const { submitted } = this.state;

    if (!submitted) {
      this.setState({
        usernameInfo: { err: false, msg: '' },
        passwordInfo: { err: false, msg: '' },
        status: ''
      });
    }
  }

  componentOnLoginResult = async ({
    success = false,
    respond,
    notApproved = false
  }) => {
    const { mounted, usernameInfo, passwordInfo, username } = this.state;

    if (notApproved && mounted) {
      usernameInfo.err = false;
      usernameInfo.msg = '';
      passwordInfo.err = false;
      passwordInfo.msg = '';

      await AppAPI.setGlobalConfig('toUserBeta', {
        username,
        pw: this.password,
        valid: true,
        lb: respond.lb,
        n: respond.n
      });

      this.password = '';
      await this.setStateAsync({
        username: '',
        password: '',
        usernameInfo,
        passwordInfo,
        status: '',
        mounted: false,
        submitted: false,
        auto: false,
        userBeta: true
      });
    } else if (success && mounted) {
      this.password = '';
      ModalAPI.closeToaster();

      await this.setStateAsync({
        status: i18n('sign_in_status_success'),
        submitted: false,
        auto: false,
        username: '',
        password: ''
      });

      await ProfileAPI.USER_DATA.setUserAuth({
        auth_token_id: respond.auth_token_id,
        auth_token: respond.auth_token,
        username_ref: respond.username,
        n: respond.n,
        lb: respond.lb
      });

      ProfileAPI.USER_DATA.resetVARS();
      ProfileAPI.USER_TASKS.resetVARS();
      ProfileAPI.PUBLIC.resetVARS();
      ProfileAPI.USER_PROFILE.confirmJustLoggedIn();

      await ProfileAPI.USER_DATA.basicInfoGET(true, true);

      await Promise.all([
        ProfileAPI.USER_TASKS.getUserPersonalTags(),
        ProfileAPI.USER_PROFILE.getLastDraft(true),
        ProfileAPI.USER_TASKS.savedUsers(1)
      ]);

      await ProfileAPI.SPACES.getJoinedSpaces();
    } else if (mounted) {
      usernameInfo.err = false;
      usernameInfo.msg = '';
      passwordInfo.err = false;
      passwordInfo.msg = '';
      this.password = '';

      await this.setStateAsync({
        password: '',
        usernameInfo,
        passwordInfo,
        status: '',
        submitted: false,
        auto: false
      });

      ModalAPI.toaster('danger', i18n('sign_in_status_invalid'), 6500);
      if (this.passwordInputREF && this.passwordInputREF.current) {
        const input = this.passwordInputREF.current.querySelector(`input`);

        if (input) {
          input.value = '';
          input.setAttribute('value', '');
        }
      }
    }
  };

  async submit() {
    const {
      mounted,
      usernameInfo,
      passwordInfo,
      username = '',
      password
    } = this.state;

    if (!mounted || passwordInfo.err || usernameInfo.err) {
      return;
    }

    if (this.usernameInputREF && this.usernameInputREF.current) {
      const input = this.usernameInputREF.current.querySelector(`input`);

      if (input) {
        input.blur();
      }
    }

    if (this.passwordInputREF && this.passwordInputREF.current) {
      const input = this.passwordInputREF.current.querySelector(`input`);

      if (input) {
        input.blur();
      }
    }

    const isEmail = isCorrectEmailFormat(username);

    if (
      (!UserInputs.validUsername(username) ||
        !UserInputs.validPassword(password)) &&
      !isEmail
    ) {
      await this.setStateAsync({
        status: '',
        usernameInfo: {
          err: true,
          msg: i18n('sign_in_status_invalid_username')
        },
        passwordInfo: {
          err: true,
          msg: i18n('common_invalid_password')
        },
        submitted: false
      });

      if (password) {
        return;
      }
    }

    if (!UserInputs.validPassword(password)) {
      await this.setStateAsync({
        status: '',
        passwordInfo: {
          err: true,
          msg: i18n('common_invalid_password')
        },
        submitted: false
      });

      return;
    }

    if (!ProfileAPI.USER_DATA.encLoginValid()) {
      await this.setStateAsync({
        status: i18n('sign_in_status_wrong_refresh'),
        submitted: false
      });
    } else {
      await this.setStateAsync({
        submitted: true,
        status: ''
      });

      this.password = password;
      await ProfileAPI.PUBLIC.sendLogin(username, password, isEmail);
    }
  }

  async toggleRememberLogin() {
    if (!Store.CONFIGS.HAS_LOCAL_STORAGE_AVAILABLE) {
      return;
    }

    const { rememberLoginLocal, submitted } = this.state;
    const _rememberLoginLocal = !rememberLoginLocal;

    if (submitted) {
      return;
    }

    await this.setStateAsync({
      rememberLoginLocal: _rememberLoginLocal
    });
    await AppAPI.setLocalConfigs('rememberLogin', _rememberLoginLocal);
  }

  onWrapBlur() {
    this.outBlur = true;
  }

  onWrapClick() {
    this.outBlur = false;
  }

  render() {
    const {
      submitted = false,
      status = '',
      userBeta,
      usernameInfo,
      passwordInfo,
      username,
      password,
      passwordShow,
      rememberLoginLocal
    } = this.state;
    const { profileLoaded, isLoggedIn } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const query = Store.CONFIGS.getQuery();

    if (!profileLoaded) {
      return <InitUserLoading />;
    } else if (isLoggedIn) {
      return (
        <Redirect to={`/user${query?.pay ? '?pay=premiumWithSpaces' : ''}`} />
      );
    } else if (userBeta) {
      return <Redirect to={'/user/beta'} />;
    }

    return (
      <AdjustableAbsolutWrap paddingBottom={0}>
        <div
          className={cx(styles.flex_column_y, styles.login_v2, {
            [styles.login_v2_dark]: isThemeDarkMode
          })}
          ref={this.wrapREF}
        >
          <div className={styles.actual}>
            <div className={styles.logo}>
              <div className={styles.logo_wrap}>
                <AppLogoIcon />
              </div>
            </div>
            <div className={styles.ic_w_title}>
              <div
                className={cx(styles.ic_only, {
                  [styles.ic_only_dark]: isThemeDarkMode
                })}
              >
                <div></div>
              </div>
              <div
                className={cx(styles.title, {
                  [styles.title_dark]: isThemeDarkMode
                })}
              >
                <h2>{i18n('sign_in_welcome')}</h2>
                <h5>{i18n('sign_in_title')}</h5>
              </div>
            </div>
            <div
              className={cx(styles.username, {
                [styles.username_dark]: isThemeDarkMode
              })}
            >
              <div className={styles.input} ref={this.usernameInputREF}>
                <TextInputField
                  placeholder={i18n('common_username_or_email_placeholder')}
                  label={i18n('sign_in_email_or_username')}
                  onChange={this.usernameInput}
                  value={username}
                  validationMessage={usernameInfo.err && usernameInfo.msg}
                />
              </div>
            </div>
            <div
              className={cx(styles.password, {
                [styles.password_dark]: isThemeDarkMode
              })}
            >
              <div className={styles.input} ref={this.passwordInputREF}>
                <TextInputField
                  placeholder={i18n('sign_in_input_pw_placeholder')}
                  label={i18n('password_title')}
                  onChange={this.passwordInput}
                  value={password}
                  type={passwordShow ? 'text' : 'password'}
                  validationMessage={passwordInfo.err && passwordInfo.msg}
                />
                <div
                  className={cx(styles.password_show, {
                    [styles.password_show_dark]: isThemeDarkMode
                  })}
                >
                  <ButtonClick
                    onClick={() => {
                      this.setState({
                        passwordShow: !passwordShow
                      });
                    }}
                  >
                    {passwordShow && <EyeOffIcon />}{' '}
                    {!passwordShow && <EyeOpenIcon />}
                  </ButtonClick>
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              <div
                className={cx(styles.remember_login, {
                  [styles.remember_login_dark]: isThemeDarkMode,
                  [styles.remember_login_active_dark]:
                    isThemeDarkMode && rememberLoginLocal
                })}
              >
                <Checkbox
                  label={i18n('sign_in_remember_login')}
                  checked={rememberLoginLocal}
                  onChange={this.toggleRememberLogin}
                />
              </div>
              <div
                className={cx(styles.status, {
                  [styles.hide_element]: status.length < 1
                })}
              >
                <div>
                  <h5>{status}</h5>
                </div>
              </div>
              <Button
                label={i18n('sign_in_confirm_button')}
                variant={'primaryPurple'}
                onClick={this.submit}
                loading={submitted}
                disabled={submitted}
                className={cx(styles.confirm, {
                  [styles.confirm_wno_status]: status.length < 1,
                  [styles.confirm_w_status]: status.length > 0
                })}
              />
              <div className={styles.forgot_pw}>
                {URLMarkdown(
                  'sign_in_forgot_password',
                  {
                    url1: { link: '/forgot-password' }
                  },
                  '',
                  false
                )}
              </div>
              <div className={styles.redirect_signup}>
                <h5>
                  {URLMarkdown(
                    'sign_in_or_create',
                    {
                      url1: { link: '/sign-up' }
                    },
                    '',
                    false
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </AdjustableAbsolutWrap>
    );
  }
}

export default withRouter(withUserProfileSettings(LoginV2));
