import k from 'src/constants/k';
import { isDev, toLower } from './utils';

export function isValidUrl(value = '') {
  if (
    !value ||
    !value.length ||
    value?.length > 150 ||
    typeof value !== 'string'
  ) {
    return false;
  }

  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

export function getMatchWhitelistUrls(value = '') {
  let matches = [];
  if (
    !value ||
    typeof value !== 'string' ||
    !value.length ||
    value.length > 150
  ) {
    return [];
  }

  const urls = [
    {
      regex: /google\.co[^\s]+$/
    },
    {
      regex: /gmail\.co[^\s]+$/
    },
    {
      regex: /googleapis\.co[^\s]+$/
    },
    {
      regex: /googlefonts\.co[^\s]+$/
    },
    {
      regex: /gogle\.co[^\s]+$/
    },
    {
      regex: /youtube\.co[^\s]+$/
    },
    {
      regex: /facebook\.co[^\s]+$/
    },
    {
      regex: /fb\.co[^\s]+$/
    },
    {
      regex: /instagram\.co[^\s]+$/
    },
    {
      regex: /ig\.co[^\s]+$/
    },
    {
      regex: /getchamu\.co[^\s]+$/
    },
    {
      regex: /app.getchamu\.co[^\s]+$/
    },
    {
      regex: /twitter\.co[^\s]+$/
    },
    {
      regex: /netflix\.co[^\s]+$/
    },
    {
      regex: /amazon\.co[^\s]+$/
    },
    {
      regex: /tumblr\.co[^\s]+$/
    },

    {
      regex: /steam\.co[^\s]+$/
    },
    {
      regex: /moment\.c[^\s]+$/
    },
    {
      regex: /momenthouse\.c[^\s]+$/
    },
    {
      regex: /momenthouse\.c[^\s]+$/
    },
    {
      regex: /figma\.c[^\s]+$/
    },
    {
      regex: /fig\.c[^\s]+$/
    },
    {
      regex: /gitlab\.co[^\s]+$/
    },
    {
      regex: /github\.co[^\s]+$/
    },
    {
      regex: /scribd\.co[^\s]+$/
    },
    {
      regex: /stripe\.co[^\s]+$/
    },
    {
      regex: /coursehero\.co[^\s]+$/
    },
    {
      regex: /cebupacificair\.co[^\s]+$/
    },
    {
      regex: /quilljs\.co[^\s]+$/
    },
    {
      regex: /messenger\.co[^\s]+$/
    },
    {
      regex: /linkedin\.co[^\s]+$/
    },
    {
      regex: /traze\.co[^\s]+$/
    },
    {
      regex: /hbogo\.co[^\s]+$/
    },
    {
      regex: /hbogoasia\.co[^\s]+$/
    },
    {
      regex: /hbomax\.co[^\s]+$/
    },
    {
      regex: /ionicframework\.co[^\s]+$/
    },
    {
      regex: /shopify\.co[^\s]+$/
    },
    {
      regex: /spotify\.co[^\s]+$/
    },
    {
      regex: /tiktok\.co[^\s]+$/
    },
    {
      regex: /canva\.co[^\s]+$/
    },
    {
      regex: /shopee\.co[^\s]+$/
    },
    {
      regex: /you\.co[^\s]+$/
    },
    {
      regex: /lazada\.co[^\s]+$/
    },
    {
      regex: /paymongo\.co[^\s]+$/
    },
    {
      regex: /myspace\.co[^\s]+$/
    },
    {
      regex: /texts\.co[^\s]+$/
    },
    {
      regex: /http?:\/\/[^\s]+$/
    },
    {
      regex: /telegram\.co[^\s]+$/
    },
    {
      regex: /signal\.co[^\s]+$/
    },
    {
      regex: /signal\.app[^\s]+$/
    },
    {
      regex: /airbnb\.co[^\s]+$/
    },
    {
      regex: /abnb\.co[^\s]+$/
    },
    {
      regex: /agoda\.co[^\s]+$/
    },
    {
      regex: /slack\.co[^\s]+$/
    },
    {
      regex: /snap\.co[^\s]+$/
    },
    {
      regex: /snapchat\.co[^\s]+$/
    },
    {
      regex: /wikipedia\.co[^\s]+$/
    },
    {
      regex: /discord\.co[^\s]+$/
    },
    {
      regex: /discord\.app[^\s]+$/
    },
    {
      regex: /bing\.co[^\s]+$/
    },
    {
      regex: /apple\.co[^\s]+$/
    },
    {
      regex: /microsoft\.co[^\s]+$/
    },
    {
      regex: /msn\.co[^\s]+$/
    },
    {
      regex: /whatsapp\.co[^\s]+$/
    },
    {
      regex: /pinterest\.co[^\s]+$/
    },
    {
      regex: /pin\.co[^\s]+$/
    },
    {
      regex: /paymaya\.co[^\s]+$/
    },
    {
      regex: /gcash\.co[^\s]+$/
    },
    {
      regex: /reddit\.co[^\s]+$/
    },
    {
      regex: /i.reddit\.co[^\s]+$/
    },
    {
      regex: /reddit\.ap[^\s]+$/
    },
    {
      regex: /notion\.co[^\s]+$/
    },
    {
      regex: /tenor\.co[^\s]+$/
    },
    {
      regex: /rappler\.co[^\s]+$/
    },
    {
      regex: /ismis\.usc[^\s]+$/
    },
    {
      regex: /gdrive\.c[^\s]+$/
    },
    {
      regex: /dropbox\.co[^\s]+$/
    },
    {
      regex: /box\.co[^\s]+$/
    },
    {
      regex: /paypal\.co[^\s]+$/
    },
    {
      regex: /twitch\.co[^\s]+$/
    },
    {
      regex: /twitch\.tv[^\s]+$/
    },
    {
      regex: /ftp?:\/\/[^\s]+$/
    }
  ];

  for (let i = 0; i < urls.length; i++) {
    try {
      const { regex } = urls[i];

      if (regex) {
        const currentMatches = value.match(regex);
        matches = [...currentMatches];
      }
    } catch {}
  }

  return matches;
}

export function getApiOpenBaseUrl() {
  const devMmode = isDev();
  return k.API_OPEN_URLS[devMmode ? 'development' : 'production'];
}

export function getApiDashboardBaseUrl() {
  const devMmode = isDev();
  return k.API_DASHBOARD_URLS[devMmode ? 'development' : 'production'];
}

export function getApiDashboardInSeaBaseUrl() {
  const devMmode = isDev();
  return devMmode ? k.API_DASHBOARD_URLS['development'] : k.API_DASHBOARD_SEA;
}

export function getApiDashboardUrlWhitelistedLocation(code = '') {
  if (code && k.SEA_COUNTRY_CODES.includes(toLower(code))) {
    return getApiDashboardInSeaBaseUrl();
  }

  return getApiDashboardBaseUrl();
}
