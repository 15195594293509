import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './CreateTaskV2.scss';
import k from 'src/constants/k';
import i18n from 'src/locales';
import TaskProperties from './TaskProperties';
import TaskTitle from './TaskTitle';
import TaskDescription from './TaskDescription';
import AdvancedOptions from './AdvancedOptions';
import ImagesHelper from './task-formats/Images';
import ButtonClick from 'src/components/button-click';
import AppAPI from 'src/app-manager/API';
import ModalAPI from 'src/modal-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import DeleteTask from 'src/modals/delete-task';
import Logger from 'src/lib/Logger';
import Store from 'src/lib/store';
import MessageHover from 'src/components/message-hover';
import Tags from './tags';
import Subscribers from './subscribers';
import ScheduleReminders from './schedule-reminders';
import Spaces from './spaces';
import PriorityIcon from 'src/components/priority-icon';
import CustomButton from 'src/components/button';
import ImagePreviewUploaded from 'src/modals/image-preview-uploaded';
import {
  getHHMMAMPMFormat,
  getStringSizeKB,
  isArray,
  isFunction,
  formatSpaceTags,
  isFileAllowed,
  isMobileView,
  toString,
  timeout,
  size,
  head,
  isEmpty,
  filter,
  isSupportedVideoFileFormat,
  isNil
} from 'src/helpers/utils';
import { isAlphaNumeric, isStringNotEmpty } from 'src/lib/UserInputs';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Redirect, withRouter } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  Select,
  Radio,
  IconButton,
  CrossIcon,
  EditIcon,
  InfoSignIcon,
  Button,
  TextInputField,
  EyeOpenIcon,
  EyeOffIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Spinner
} from 'evergreen-ui';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'src/lib/bodyScrollLock.min';

class CreateTaskV2 extends Component {
  /**
   * Specs:
   * 1. Save user draft
   * 2. Fetch user's last draft
   */
  uniqueCreateTaskInSpaceOption = 'uniqueCreateTaskInSpaceOption';
  titleHiddenDOMRef = null;
  titleShownDOMRef = null;
  propertiesDomRef = null;
  descriptionWrapRef = null;
  wrapperRef = null;
  onGetLastDraftResultSubscriber = null;
  onResizeSubscriber = null;
  onProfileLoadedSubscriber = null;
  onInspectTaskSubscriber = null;
  onTaskDeleteSubscriber = null;
  onSubmitSubscriber = null;
  onSubmitResultSubscriber = null;
  onUserInfoChangeSubscriber = null;
  onDocumentClickSubscriber = null;
  onTaskSubscribersOBS = null;
  savingDraft = false;
  descriptionDeltaReady = false;
  descriptionEditorSaveDraftOBS = null;
  descriptionEditorSaveEditedOBS = null;
  description_delta = { ops: [] };
  created = 0;
  lastUpdated = 0;
  payload = {};
  QuillEditor = null;
  exposure = k.USER_TASK_PRIVACY_PRIVATE;
  exposurePasscode = '';
  priority = k.TASK_PRIORITIES_OBJ.normal.idx;
  author = '';
  author_id = '';
  subscriberEditing = false;
  title = '';
  timeStarted = 0;
  tags = [];
  task_state = '';
  tries = 0;
  hasAccess = [];
  utcDate = null;
  advancedOptions = {
    allowComments: true,
    allowSubsEdit: false
  };
  owner = true;
  views = 0;
  viewsValid = false;
  uploadImagesQueue = [];
  uploadFileQueue = [];
  removeFileQueue = [];
  spaceTagsId = [];

  state = {
    mounted: false,
    loaded: false,

    /**
     * CreateTaskV2 component can be use in two modes, for new tasks or editing tasks
     */
    created: '',
    updated: '',
    taskID: '',
    universalRefID: '',
    title: '',
    titleInfo: { err: false, msg: '' }, // { err: true, msg: i18n('common_should_not_blank') },
    titleFocus: false,
    mode: k.CREATE_TASK_MODE.CREATE,
    priority: k.TASK_PRIORITIES_OBJ.normal,
    exposure: k.USER_TASK_PRIVACY_PRIVATE,
    exposurePasscode: '',
    exposurePasscodeShow: false,
    exposurePasscodeInfo: { err: false, msg: '' },
    exposureWPasscode: false,
    files: [
      // { fileName: 'test1.jpg', sizeInBytes: 1000, loading: false },
      // { fileName: 'test.jpg', sizeInBytes: 1000, loading: false }
    ],
    draggingFileUpload: false,
    tags: [],
    task_state: { ...k.TASKS_STATES_OBJ['active'], valid: true },
    taskDescriptionInit: false,
    hasAccess: [],
    hasAccessValid: false,
    isMobile: false,
    imagePreview: '',
    deleted: false,
    deletedSuccess: false,
    spaceTagsInfo: {},
    spacesListExpand: false,
    submitted: false,
    subscriberEditing: false,
    editDescription: false,
    editProps: false,
    redirectErr: false,
    redirectSuccess: false,
    uploadingImage: false,
    uploadingImages: [],
    uploadingFiles: [],
    removingFiles: [],
    spaceInfo: null,
    spaceId: '',
    spaces: [],
    connectedUsers: [] // user that can be tagged, be added as subscriber
  };

  constructor(props) {
    super(props);
    TaskProperties.setContext(this);
    this.propertiesDomRef = createRef();
    this.wrapperRef = createRef();
    this.descriptionWrapRef = createRef();
    this.titleHiddenDOMRef = createRef();
    this.titleShownDOMRef = createRef();
    this.onTitleInput = TaskTitle.onInput.bind(this);
    this.onTitleFocus = TaskTitle.onFocus.bind(this);
    this.onTitleBlur = TaskTitle.onBlur.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.profileLoaded = this.profileLoaded.bind(this);
    this.promptError = this.promptError.bind(this);
    this.getTask = this.getTask.bind(this);
    this.onGetTask = this.onGetTask.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
    this.deleteResults = this.deleteResults.bind(this);
    this.submitResults = this.submitResults.bind(this);
    this.blockDraft = this.blockDraft.bind(this);
    this.unblockDraft = this.unblockDraft.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onTaskSubscribersOBS = new Subject();
    this.descriptionEditorSaveDraftOBS = new Subject();
    this.descriptionEditorSaveEditedOBS = new Subject();
    this.onSaveEditedSubscriber = this.descriptionEditorSaveEditedOBS
      .pipe(debounceTime(600))
      .subscribe(this.saveForEditHistory);
    this.onUnload = this.onUnload.bind(this);
    this.modifyAdvancedOptions = this.modifyAdvancedOptions.bind(this);
    this.onExposurePWShowToggle = this.onExposurePWShowToggle.bind(this);
    this.onProfilePlanLoaded = this.onProfilePlanLoaded.bind(this);
    this.utcDate = new Date();
    AppAPI.onAppUnload(this.onUnload);
    ImagesHelper.blockDraft = this.blockDraft;
    ImagesHelper.unblockDraft = this.unblockDraft;
  }

  onExposurePWShowToggle() {
    this.setState({
      exposurePasscodeShow: !this.state.exposurePasscodeShow
    });
  }

  onUnload() {
    this.saveDraft();

    if (this.QuillEditor) {
      delete this.QuillEditor;
    }

    if (window.QuillEditor) {
      delete window.QuillEditor;
    }

    if (window.getQuillEditor) {
      delete window.getQuillEditor;
    }
  }

  profileLoaded(params = { fetching: false }) {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    } else if (
      ProfileAPI.USER_DATA.checkIsLoggedIn() &&
      ProfileAPI.USER_DATA.info() &&
      params &&
      !params.fetching
    ) {
      if (this.onUserInfoChangeSubscriber) {
        this.onUserInfoChangeSubscriber.unsubscribe();
        this.onUserInfoChangeSubscriber = null;
      }

      this.onProfilePlanLoaded({ fetching: false });
      this.onMount();
    } else {
      this.onUserInfoChangeSubscriber =
        ProfileAPI.USER_DATA.onProfileFetchingInfoStatus(this.profileLoaded);
    }
  }

  onProfilePlanLoaded(params = {}) {
    const { mounted, mode } = this.state;

    try {
      if (!mounted || !params) {
        return;
      }

      const { fetching = false } = params || {};

      if (
        !ProfileAPI.USER_DATA.checkIsFetchingPlan() &&
        !fetching &&
        ProfileAPI.USER_DATA.info()
      ) {
        // prompt if expired or reached limit for task create
        // or queue if still fetching and user submit to create task

        if (
          !ProfileAPI.USER_DATA.isUserAbleToCreateTask() &&
          mode === k.CREATE_TASK_MODE.CREATE
        ) {
          this.setState({
            submitted: false
          });
          this.promptError(i18n('user_create_task_status_plan_need_premium'));
        }
      } else {
        this.onProfileLoadedSubscriber =
          ProfileAPI.USER_DATA.onProfileFetchingPlanStatus(
            this.onProfilePlanLoaded
          );
      }
    } catch {}
  }

  terminate() {
    // force terminate if there's an error w data init

    this.setState({
      mounted: false
    });

    AppAPI.appendQueryURL('', {
      action: '',
      id: '',
      t: ''
    });
    ModalAPI.goDeactivate();
  }

  componentDidMount() {
    this.onResizeSubscriber = fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(this.onResize);
    this.timeStarted = Math.ceil(Date.now() / 1000);
    this.onDocumentClickSubscriber = fromEvent(document, 'click').subscribe(
      this.onClick
    );
    ModalAPI.noEscape();
    const isMobile = window.innerWidth < 900;
    AppAPI.noUIOverflow();

    if (
      window.location.href.indexOf('action=edit_task') >
        window.location.origin.length ||
      this.props.mode === k.CREATE_TASK_MODE.EDIT
    ) {
      Store.CONFIGS.getQueryJSON();
      const query = Store.CONFIGS.query;

      if (
        !query ||
        !query.action ||
        (query.action === 'edit_task' &&
          (!isStringNotEmpty(query.id) || !query.t || !isAlphaNumeric(query.t)))
      ) {
        this.terminate();
        return;
      }
      const universalRefID = `${query.t}`.trim().replace(/\n/g, '');
      const taskID = `${query.id}`;
      this.setState(
        {
          isMobile,
          taskID: taskID.toUpperCase(),
          universalRefID,
          mode: k.CREATE_TASK_MODE.EDIT,
          mounted: true
        },
        this.profileLoaded
      );
    } else {
      this.setState(
        {
          isMobile,
          mounted: true
        },
        this.profileLoaded
      );
    }
  }

  componentWillUnmount() {
    this.saveDraft('unmount');
    AppAPI.applyUIOverflow();
    AppAPI.removeOnAppUnload(this.onUnload);

    if (window.innerWidth < 900) {
      try {
        window.scrollTo(0, 0);
      } catch {}
    }

    if (this.propertiesDomRef?.current) {
      enableBodyScroll(this.propertiesDomRef.current);
    }

    if (this.onDocumentClickSubscriber) {
      this.onDocumentClickSubscriber.unsubscribe();
    }

    if (this.onUserInfoChangeSubscriber) {
      this.onUserInfoChangeSubscriber.unsubscribe();
    }

    if (this.onSubmitSubscriber) {
      this.onSubmitSubscriber.unsubscribe();
      this.onSubmitSubscriber = null;
    }

    if (this.descriptionEditorSaveDraftOBS) {
      this.descriptionEditorSaveDraftOBS.unsubscribe();
    }

    if (this.onTaskDeleteSubscriber) {
      this.onTaskDeleteSubscriber.unsubscribe();
    }

    if (this.onGetLastDraftResultSubscriber) {
      this.onGetLastDraftResultSubscriber.unsubscribe();
    }

    if (this.onProfileLoadedSubscriber) {
      this.onProfileLoadedSubscriber.unsubscribe();
    }

    if (this.onResizeSubscriber) {
      this.onResizeSubscriber.unsubscribe();
    }

    if (this.onInspectTaskSubscriber) {
      this.onInspectTaskSubscriber.unsubscribe();
    }

    if (this.onSubmitResultSubscriber) {
      this.onSubmitResultSubscriber.unsubscribe();
    }

    this.setStateAsync({
      mounted: false,
      loaded: false,
      deleted: false,
      submitted: false,
      hasAccess: []
    });

    TaskProperties.setInactive();
    ModalAPI.goDeactivate();
    ModalAPI.hasEscape();

    delete this.QuillEditor;
  }

  promptError(message = '') {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    }

    if (message && typeof message === 'string' && message.length > 0) {
      ModalAPI.toaster('danger', message, 4500);
    }
  }

  onMount = async () => {
    const { mode } = this.state;
    TaskProperties.setActive();

    if (k.CREATE_TASK_MODE.CREATE === mode) {
      /**
       * Retrieve last draft
       */

      await this.getDraft();
    } else if (k.CREATE_TASK_MODE.EDIT === mode) {
      const { taskID, universalRefID } = this.state;

      Logger.log(`Editing task, id: ${taskID}, uref: ${universalRefID}`);
      await this.getTask();

      this.onSpacesListSubscriber = ProfileAPI.SPACES.onSpacesList(
        this.onSpacesList
      );
    } else {
      this.terminate();
    }
  };

  onSpacesList = params => {
    if (params) {
      const { spaces = [] } = params;

      this.setState({
        spaces
      });
    }
  };

  onClick = evt => {
    const { spacesListExpand, mounted } = this.state;
    const { setTeam } = this.props;

    if (!mounted) {
      return;
    } else if (!evt || !evt.target) {
      if (spacesListExpand) {
        this.setState({
          spacesListExpand: false
        });
      }

      return;
    }

    const target = evt.target;

    if (
      target.classList?.length &&
      target.classList.contains &&
      target.classList.contains(this.uniqueCreateTaskInSpaceOption)
    ) {
      const spaceId = target.classList[target.classList.length - 1];

      if (spaceId === this.uniqueCreateTaskInSpaceOption) {
        return;
      } else if (isFunction(setTeam)) {
        const isPersonalSelected = !spaceId || spaceId === 'personal';

        if (isPersonalSelected) {
          ProfileAPI.SPACES.setSpaceSelectedForCreate('');
        }

        setTeam(isPersonalSelected ? '' : spaceId);

        if (spacesListExpand) {
          this.setState({
            spacesListExpand: false
          });
        }
      }
    } else if (spacesListExpand) {
      this.setState({
        spacesListExpand: false
      });
    }
  };

  onResize = () => {
    const { mounted, loaded, isMobile } = this.state;

    if (!mounted || !loaded) {
      return;
    }

    if (!isMobile && window.innerWidth < 900) {
      this.setState({
        isMobile: true
      });

      this.cancel();
      this.props.toggleMaximizeEditor(false);
    } else if (isMobile && window.innerWidth > 900) {
      this.setState({
        isMobile: false,
        editDescription: false
      });
      this.cancel();
      this.props.toggleMaximizeEditor(false);
    }

    if (this.titleShownDOMRef && this.titleShownDOMRef.current) {
      TaskTitle.setTitleHeight.call(this, this.titleShownDOMRef.current);
    }
  };

  onAddTags = (tagId = '', fromCustomSpace = false, newSelected = []) => {
    const { mounted, loaded, tags } = this.state;
    const personal_tags = ProfileAPI.USER_TASKS.personal_tags;

    if (!mounted || !loaded) {
      return;
    } else if (fromCustomSpace) {
      if (tags.filter(t => t && t.id === tagId).length <= 0) {
        if (isArray(newSelected)) {
          this.setState({ tags: newSelected });
        }
      }
    } else {
      for (let i = 0; i < personal_tags.length; i++) {
        if (personal_tags[i].id === tagId) {
          if (tags.filter(t => t && t.id === tagId).length <= 0) {
            if (isArray(newSelected)) {
              this.setState({ tags: newSelected });
            }
          }

          break;
        }
      }
    }
  };

  onRemoveTags = tagId => {
    const { mounted, loaded, tags } = this.state;

    if (!mounted || !loaded || typeof tagId !== 'string') {
      return;
    }

    for (let i = 0; i < tags.length; i++) {
      const t = tags[i];

      if (t && typeof t === 'object' && t.id === tagId) {
        tags.splice(i, 1);
        this.setState({ tags });
        break;
      }
    }
  };

  async onGetTask({ success = false, not_exists = false, requiredPW = false }) {
    const { mounted, taskID, universalRefID, mode } = this.state;
    const { setTeam } = this.props;

    if (!mounted) {
      return;
    } else if (!success || requiredPW || not_exists) {
      this.terminate();
      return;
    }

    if (this.onInspectTaskSubscriber && success) {
      this.onInspectTaskSubscriber.unsubscribe();
      this.onInspectTaskSubscriber = null;
    }

    const respond = Object.assign(
      {},
      ProfileAPI.PUBLIC.VARS.inspectTaskMap[taskID]
    );

    if (respond && respond.success) {
      try {
        delete ProfileAPI.PUBLIC.VARS.inspectTaskMap[taskID];
        ImagesHelper.setUniversalRefID(universalRefID);
        ImagesHelper.setTaskId(taskID);
        const spaceId = respond.space_id || respond.space;
        const spaceInfo = respond.space_info;
        const subscriberEditing = Boolean(respond.subs_editing);
        const title = respond.title;
        const exposure = respond.exposure;
        const exposureWPasscode =
          respond.exposure === k.USER_TASK_PRIVACY_PUBLIC_W_PW;
        const exposurePasscode = respond.pwcode;
        const utcDate = new Date(1000 * respond.created);
        const created = `${getHHMMAMPMFormat(
          utcDate.getHours(),
          utcDate.getMinutes()
        )}  ${
          k.MONTHS_INDEX[utcDate.getMonth()].abv
        } ${utcDate.getDate()}, ${utcDate.getFullYear()}`;
        let updated = '';
        const task_state = {
          ...k.TASKS_STATES_OBJ[respond.task_state],
          valid: !!k.TASKS_STATES_OBJ[respond.task_state]
        };
        const priority = k.TASK_PRIORITIES.filter(p => {
          if (respond.priority === p.idx) {
            return true;
          }

          return false;
        })[0];
        const tags = [];
        const isEditMode = mode === k.CREATE_TASK_MODE.EDIT;

        const files = isArray(respond.files)
          ? respond.files.map(file => {
              return { ...file, loading: false };
            })
          : [];

        if (respond.last_updated && respond.last_updated > 0) {
          const updatedUTCDate = new Date(1000 * respond.last_updated);
          updated = `${getHHMMAMPMFormat(
            updatedUTCDate.getHours(),
            updatedUTCDate.getMinutes()
          )}  ${
            k.MONTHS_INDEX[updatedUTCDate.getMonth()].abv
          } ${updatedUTCDate.getDate()}, ${updatedUTCDate.getFullYear()}`;
        }

        if (
          respond.personal_tags &&
          isArray(respond.personal_tags) &&
          respond.personal_tags.length > 0
        ) {
          for (const t of respond.personal_tags) {
            if (`${t}`.toLowerCase() !== 'none') {
              const idx = ProfileAPI.USER_TASKS.tagExists(t);
              if (idx >= 0) {
                this.tags.push(t);
                tags.push({ ...ProfileAPI.USER_TASKS.personal_tags[idx] });
              } else {
                this.tags.push(t);
                tags.push({
                  id: t,
                  name: ProfileAPI.USER_TASKS.tagIDToName(t)
                });
              }
            }
          }
        }

        if (spaceId) {
          const spaceTagsId = respond.space_tags || [];
          const spaceTagsInfo = respond.space_tags_info;

          if (isFunction(setTeam)) {
            setTeam(spaceId, spaceInfo);
          }

          if (isArray(spaceTagsId)) {
            for (const tagId of spaceTagsId) {
              const tagInfo = { spaceId, ...spaceTagsInfo[tagId] };

              if (tagInfo) {
                tags.push(tagInfo);
              }
            }

            this.spaceTagsId = [...spaceTagsId];
          }
        } else {
          if (isEditMode) {
            setTeam(''); // under no custom space
          }
        }

        this.title = title;
        this.exposure = exposure;
        this.exposurePasscode = exposurePasscode;
        this.priority = priority.idx;
        this.task_state = respond.task_state;
        this.author = respond.author;
        this.author_id = respond.author_id || '';
        this.lastUpdated = respond.last_updated;
        this.created = respond.created;
        this.subscriberEditing = subscriberEditing;
        this.views = respond.views || 0;
        this.viewsValid = respond.views_valid;
        this.owner = respond.isOwner;
        this.setState(
          {
            title,
            exposure,
            exposureWPasscode,
            exposurePasscode,
            created,
            updated,
            task_state,
            tags,
            priority,
            subscriberEditing,
            spaceInfo,
            spaceTagsInfo: {
              ...(spaceId && respond?.space_tags_info
                ? respond.space_tags_info
                : {})
            },
            files
          },
          () => {
            this.onGetDraft({
              title,
              files,
              valid: true,
              description_delta: respond.description_delta
            });
          }
        );
      } catch (err) {
        if (err.message) {
          Logger.log(err.message);
        }

        if (err && err.stack) {
          Logger.log(err.stack);
        }
      }
    } else {
      this.terminate();
    }
  }

  async getTask() {
    const { taskID, universalRefID } = this.state;
    this.onInspectTaskSubscriber = ProfileAPI.PUBLIC.onInspectTaskResult(
      this.onGetTask
    );

    await ProfileAPI.PUBLIC.sendInspectTask(taskID, universalRefID);
  }

  onGetDraft = async ({
    title = '',
    description_delta = '',
    valid = false,
    created = 0,
    u_ref = '',
    task_id = '',
    files = []
  }) => {
    const { mounted, mode, taskID, universalRefID, hasAccess } = this.state;

    if (!mounted) {
      return;
    } else if (!valid || (mode === k.CREATE_TASK_MODE.CREATE && !u_ref)) {
      //@todo fetch task draft until we get valid
      //@just hang up if user is offline
      return;
    }

    if (mode === k.CREATE_TASK_MODE.CREATE) {
      // this.task_u_ref = u_ref;
      ImagesHelper.setUniversalRefID(u_ref);
      await this.setStateAsync({
        universalRefID: u_ref
      });
    }

    if (title.length > 0 && valid) {
      await this.setStateAsync({
        title: title
      });
    }

    if (description_delta.length > 0 && valid) {
      try {
        const description_delta_parsed = JSON.parse(description_delta);

        if (
          description_delta_parsed.ops &&
          isArray(description_delta_parsed.ops)
        ) {
          /**
           * Check if ops are supported
           */
          const sanitizeDescriptionDelta = TaskProperties.sanitizeDescription(
            description_delta_parsed
          );
          // test demo user mention
          // sanitizeDescriptionDelta.ops.push({
          //   insert: {
          //     atinfo: {
          //       text: '@Robert Espina',
          //       name: 'Robert Espina',
          //       description: 'Software Engineer',
          //       userId: 'AVooOKnMjLPSsABFPD5Hlttr8umn1'
          //     }
          //   }
          // });
          this.description_delta = { ops: sanitizeDescriptionDelta.ops };

          if (this.QuillEditor) {
            TaskProperties.setDescriptionProperties(this.description_delta);

            await this.setStateAsync({
              taskDescriptionInit: true
            });
          } else {
            this.descriptionDeltaReady = true;
          }
        }
      } catch (err) {
        if (err && err.stack) {
          Logger.log(err.stack);
        }
      }
    }

    const storedFiles = isArray(files)
      ? files.map(file => {
          return { ...file, loading: false };
        })
      : [];

    await this.setStateAsync({
      files: storedFiles,
      loaded: true
    });

    if (this.titleShownDOMRef && this.titleShownDOMRef.current) {
      this.titleShownDOMRef.current.focus();
      TaskTitle.setTitleHeight.call(this, this.titleShownDOMRef.current);
    }

    if (mode === k.CREATE_TASK_MODE.CREATE) {
      this.created = created;
      this.onTaskSubscribersOBS.next({
        valid: true
      });
      this.setState({
        hasAccess: [],
        hasAccessValid: true,
        taskID: task_id || ''
      });
    } else if (mode === k.CREATE_TASK_MODE.EDIT) {
      const subscribers = await ProfileAPI.USER_TASKS.getSubscribers(
        taskID,
        universalRefID
      );

      if (!this.state.mounted) {
        return;
      } else if (isArray(subscribers)) {
        for (const profileId of subscribers) {
          if (
            profileId &&
            typeof profileId === 'string' &&
            profileId.length > 0
          ) {
            const wAccessSub = {
              id: profileId,
              loading: true,
              type: k.USER_TASK_ACCESS_SUBSCRIBER,
              has_image: false,
              image: '',
              initials: '',
              firstName: '',
              lastName: ''
            };
            hasAccess.push(wAccessSub);
            this.hasAccess.push({ ...wAccessSub });
          }
        }
      }

      if (this.state.mounted) {
        await this.setStateAsync({
          hasAccess,
          hasAccessValid: true
        });

        this.onTaskSubscribersOBS.next({
          valid: true
        });
      }
    }
  };

  async getDraft() {
    const { mounted } = this.state;
    if (!mounted) {
      return;
    }

    try {
      const storedLastDraft =
        ProfileAPI && ProfileAPI.USER_PROFILE
          ? ProfileAPI.USER_PROFILE.getLocalCopyDraft()
          : null;

      this.onGetLastDraftResultSubscriber =
        ProfileAPI.USER_PROFILE.onGetLastDraftResult(this.onGetDraft);

      Logger.log(`Checking user's last draft`, storedLastDraft);

      if (
        storedLastDraft &&
        typeof storedLastDraft === 'object' &&
        storedLastDraft.valid &&
        storedLastDraft.taskId &&
        storedLastDraft.uRef
      ) {
        const {
          valid = false,
          title = '',
          description = '',
          created = 0,
          taskId = '',
          uRef = '',
          files = []
        } = storedLastDraft;

        this.onGetDraft({
          created,
          valid,
          title,
          files,
          description_delta: description,
          task_id: taskId,
          u_ref: uRef
        });
      } else {
        await ProfileAPI.USER_PROFILE.getLastDraft(true);
      }
    } catch {}
  }

  blockDraft() {
    this.savingDraft = true;
  }

  unblockDraft() {
    this.savingDraft = false;
  }

  saveDraft = async (type = '') => {
    try {
      let {
        mode,
        title,
        redirectSuccess,
        submitted,
        taskID,
        universalRefID,
        files,
        uploadingImage
      } = this.state;
      let description_delta = { ...this.description_delta };
      delete this.description_delta;

      if (
        uploadingImage ||
        mode !== k.CREATE_TASK_MODE.CREATE ||
        this.savingDraft ||
        submitted
      ) {
        return;
      } else if (type === 'unmount') {
        this.savingDraft = true;
      }

      if (
        !redirectSuccess &&
        this.QuillEditor &&
        typeof this.QuillEditor.getContents === 'function'
      ) {
        description_delta = this.QuillEditor.getContents();
      } else if (redirectSuccess) {
        title = '';
        description_delta = TaskProperties.cleanDescription;
      }

      if (!AppAPI.isOnline()) {
        // save in localStorage for later draft
        //const created = ProfileAPI.USER_PROFILE.getDraftTimestamp();
      }

      Logger.log(`Saving user latest task draft`);
      const description_delta_parsed =
        TaskProperties.sanitizeDescription(description_delta);
      const description_deltaSTR = JSON.stringify(description_delta_parsed);
      const titleSanitized = title.trim().replace(/\n/g, ' ');

      if (!redirectSuccess) {
        // no need to clean manually on server
        if (!type) {
          await ProfileAPI.USER_PROFILE.saveDraft(
            titleSanitized,
            description_deltaSTR
          );
        } else {
          ProfileAPI.USER_PROFILE.saveDraft(
            titleSanitized,
            description_deltaSTR
          );
        }
      }

      ProfileAPI.USER_PROFILE.updateLocalCopyDraft({
        files,
        valid: true,
        created: Math.ceil(Date.now() / (10 * 1000)),
        title: titleSanitized,
        description: description_deltaSTR,
        taskId: taskID,
        uRef: universalRefID
      });
    } catch (err) {
      if (err && err.stack) {
        Logger.log(err.stack);
      }
    }
  };

  saveForEditHistory = async () => {
    const {
      mode,
      loaded,
      mounted,
      uploadingImage,
      uploadingImages,
      universalRefID,
      taskID
    } = this.state;
    const isEditMode = mode === k.CREATE_TASK_MODE.EDIT;
    const QuillEditor = this.QuillEditor;
    const isCurrentlyUploadingImage =
      uploadingImage || size(uploadingImages) > 0;

    if (!isEditMode || isCurrentlyUploadingImage || !loaded || !mounted) {
      return;
    }

    try {
      const descriptionParsed = QuillEditor.getContents();
      const description = JSON.stringify(descriptionParsed);
      Logger.log(`Saving for edit history`);
      await ProfileAPI.USER_TASKS.editRealtime(universalRefID, taskID, {
        description
      });
    } catch (err) {
      Logger.log(`err:saveForEditHistory():${err?.message}`);
    }
  };

  async submitResults({
    success = false,
    try_again = false,
    universalID = '',
    taskID = '',
    editingBlocked = false,
    not_allowed = false,
    not_allowed_plan = false
  }) {
    const { mounted, loaded, mode, universalRefID } = this.state;

    if (!mounted || !loaded) {
      return;
    }

    if (
      (not_allowed_plan || not_allowed) &&
      mode === k.CREATE_TASK_MODE.CREATE
    ) {
      await this.setStateAsync({
        submitted: false
      });

      this.promptError(i18n('user_create_task_status_plan_need_premium'));
      return;
    }

    if (
      try_again &&
      this.payload &&
      this.payload.valid &&
      mode === k.CREATE_TASK_MODE.CREATE &&
      this.tries < 5
    ) {
      this.tries += 1;
      await ProfileAPI.USER_TASKS.create(
        this.payload.title,
        this.payload.description_delta,
        this.payload.tags,
        this.payload.spaceTags,
        this.payload.priority,
        this.payload.exposure,
        this.payload.exposurePasscode,
        this.payload.subscribers,
        universalRefID,
        this.payload.spaceId
      );
    } else if (success && mode === k.CREATE_TASK_MODE.CREATE) {
      if (
        this.payload &&
        this.payload.subscribers &&
        Array.isArray(this.payload.subscribers)
      ) {
        this.payload.subscribers.length = 0;
      }

      ProfileAPI.USER_TASKS.applyAdvancedOptions(
        taskID,
        universalID,
        this.advancedOptions.allowComments,
        this.advancedOptions.allowSubsEdit
      );

      this.setState({
        redirectSuccess: true,
        hasAccess: [],
        mounted: false,
        taskID: taskID.toUpperCase(),
        universalRefID: universalID
      });
      this.tries = 0;
      this.payload = null;

      return;
    } else if (success && mode === k.CREATE_TASK_MODE.EDIT) {
      await Promise.all([
        this.setStateAsync({
          mounted: false
        })
      ]);

      ProfileAPI.USER_TASKS.get();
      if (this.onSubmitResultSubscriber) {
        this.onSubmitResultSubscriber.unsubscribe();
      }
      this.setState({
        redirectSuccess: true,
        hasAccess: []
      });
      this.tries = 0;

      delete this.payload;
      delete this.onSubmitResultSubscriber;

      return;
    } else if (mode === k.CREATE_TASK_MODE.EDIT) {
      // cache
      // and try again later if user is back online
    }

    this.setState({
      submitted: false
    });

    if (
      this.payload &&
      this.payload.valid &&
      this.payload.description_delta &&
      getStringSizeKB(this.payload.description_delta) >
        k.USER_TASK_DESCRIPTION_PROPERTIES.maxSizeKB
    ) {
      return this.promptError(i18n('user_create_task_status_max_desc'));
    }

    if (mode === k.CREATE_TASK_MODE.EDIT && editingBlocked) {
      // @todo reflect previous task if editing was just blocked
      return this.promptError(i18n('user_edit_task_result_editing_blocked'));
    }

    return this.promptError(i18n('common_something_wrong_w_try'));
  }

  submit = async () => {
    const {
      mounted,
      mode,
      loaded,
      deleted,
      submitted,
      title,
      titleInfo,
      exposure,
      exposurePasscodeInfo,
      exposureWPasscode,
      exposurePasscode,
      priority,
      tags,
      hasAccess,
      task_state,
      taskID,
      universalRefID,
      files,
      spaceTagsInfo
    } = this.state;
    const { isTeam = false, team = [] } = this.props;

    if (
      !mounted ||
      !loaded ||
      submitted ||
      titleInfo.err ||
      exposurePasscodeInfo.err
    ) {
      return;
    } else if (deleted) {
      titleInfo.err = false;
      titleInfo.msg = '';

      this.setState({ submitted: false, titleInfo });
      return;
    } else if (title.length <= 0) {
      titleInfo.err = true;
      titleInfo.msg = i18n('common_should_not_blank');

      this.setState({
        submitted: false,
        titleInfo
      });

      return;
    } else if (
      exposure === k.USER_TASK_PRIVACY_PUBLIC_W_PW &&
      exposureWPasscode &&
      exposurePasscode.length < k.USER_TASK_PRIVACY_PW_PROPERTIES.minLength
    ) {
      exposurePasscodeInfo.err = true;
      exposurePasscodeInfo.msg = i18n('user_create_task_status_min_privacy_pw');

      this.setState({ submitted: false, exposurePasscodeInfo });

      return;
    } else if (this.QuillEditor) {
      try {
        await this.setStateAsync({
          submitted: true
        });

        let description_delta_parsed = this.QuillEditor.getContents();

        if (
          description_delta_parsed &&
          Array.isArray(description_delta_parsed.ops) &&
          description_delta_parsed.ops.length > 0
        ) {
          description_delta_parsed = TaskProperties.sanitizeDescription(
            description_delta_parsed
          );
        }

        const description_deltaSTR = JSON.stringify(description_delta_parsed);

        if (
          getStringSizeKB(description_deltaSTR) >
          k.USER_TASK_DESCRIPTION_PROPERTIES.maxSizeKB
        ) {
          await this.setStateAsync({
            submitted: false
          });

          return this.promptError(i18n('user_create_task_status_max_desc'));
        } else if (!window.navigator.onLine) {
          await this.setStateAsync({
            submitted: false
          });

          this.promptError(i18n('common_check_network'));
          return;
        }

        const isUserPremiumWithSpace =
          ProfileAPI.SPACES.isUserPremiumWithSpace();
        const spaces =
          isUserPremiumWithSpace || isTeam ? ProfileAPI.SPACES.get() : [];
        const spaceId =
          team && team.length && team[0] ? team[0].id || team[0].space_id : '';
        const { space: targetSpaceInfo } =
          ProfileAPI.SPACES.getSpaceById(spaceId) || {};
        const spaceInfo = targetSpaceInfo || this.state.spaceInfo;
        const personalTagsApplied = tags
          .filter(tagInfo => tagInfo && !tagInfo.spaceId && tagInfo.id)
          .map(tagInfo => tagInfo.id);
        const spaceTagsApplied = tags
          .filter(tagInfo => tagInfo && tagInfo.spaceId && tagInfo.id)
          .map(tagInfo => {
            const tagId = tagInfo.id;
            spaceTagsInfo[tagId] = { ...tagInfo };
            return tagId;
          });

        if (mode === k.CREATE_TASK_MODE.CREATE) {
          if (!this.onSubmitResultSubscriber) {
            this.onSubmitResultSubscriber =
              ProfileAPI.USER_TASKS.onCreateResults(this.submitResults);
          }

          const subscribers = hasAccess
            .filter(
              h =>
                h &&
                (h.type === k.USER_TASK_ACCESS_SUBSCRIBER ||
                  h.type === k.USER_TASK_ACCESS_SUBSCRIBER_AUTO)
            )
            .map(h => h.id);

          this.payload = {
            title,
            description_delta: description_deltaSTR,
            exposure,
            exposurePasscode,
            subscribers,
            files,
            spaceId: isUserPremiumWithSpace || !isEmpty(spaces) ? spaceId : '',
            priority: priority.idx,
            tags: personalTagsApplied,
            spaceTags: spaceTagsApplied,
            valid: true
          };

          const timestampSeconds = Math.ceil(Date.now() / 1000 + 5);
          const user = ProfileAPI.USER_DATA.info();
          const start = timestampSeconds;

          if (!ProfileAPI.USER_DATA.isUserAbleToCreateTask()) {
            await this.setStateAsync({
              submitted: false
            });

            return this.promptError(
              i18n('user_create_task_status_plan_need_premium')
            );
          }

          const { firstName, lastName, profileID } = user;
          // for create mode
          const lastEdited = {
            title,
            exposure,
            exposurePasscode,
            spaceId,
            files,
            spaceTagsInfo,
            description: description_deltaSTR,
            taskId: taskID,
            uRef: universalRefID,
            priority: priority.idx,
            tags: [...personalTagsApplied],
            spaceTags: [...spaceTagsApplied],
            spaceInfo: null,
            ...(spaceId &&
              spaceInfo && {
                spaceInfo
              }),
            created: timestampSeconds,
            lastUpdated: timestampSeconds,
            author: `${firstName} ${lastName}`,
            author_id: profileID,
            taskState: `${k.TASKS_STATES_OBJ.active.key}`.toLowerCase(),
            valid: true,
            views: 0,
            viewsValid: true,
            owner: true,
            subscribers: [...subscribers],
            subscriberEditing: false,
            source: 'create'
          };

          if (this.onSubmitResultSubscriber) {
            this.onSubmitResultSubscriber.unsubscribe();
            this.onSubmitResultSubscriber = null;
          }

          const allowComments = !!this.advancedOptions.allowComments;
          const allowSubsEdit = !!this.advancedOptions.allowSubsEdit;
          const isValidForFailsafe =
            !!universalRefID &&
            typeof this.payload === 'object' &&
            !!this.payload;

          await AppAPI.setGlobalConfig('lastEdited', lastEdited);

          if (isValidForFailsafe) {
            // we use this as a fail-safe, see /pages/task on how we handle creating task with 'try_again' flag
            // we'll try again in the background if it'll fail the first time
            await AppAPI.setLocalConfigs('lastCreatedTask', {
              ...(this.payload || {}),
              valid: true,
              uRef: universalRefID,
              userId: ProfileAPI.USER_DATA.getUserId() || ''
            });
          }

          if (
            taskID &&
            taskID.length > 1 &&
            universalRefID &&
            universalRefID.length > 1
          ) {
            await this.setStateAsync({
              submitted: true
            });

            await this.setStateAsync({
              redirectSuccess: true,
              hasAccess: [],
              mounted: false,
              taskID: taskID.toUpperCase(),
              universalRefID: universalRefID
            });

            await ProfileAPI.USER_TASKS.create(
              this.payload.title,
              this.payload.description_delta,
              this.payload.tags,
              this.payload.spaceTags,
              this.payload.priority,
              this.payload.exposure,
              this.payload.exposurePasscode,
              this.payload.subscribers,
              this.state.universalRefID,
              this.payload.spaceId
            );

            await ProfileAPI.USER_TASKS.applyAdvancedOptions(
              taskID,
              universalRefID,
              allowComments,
              allowSubsEdit
            );

            // we clear the copy of the local draft for us to fetch next time
            ProfileAPI.USER_PROFILE.updateLocalCopyDraft({
              valid: false,
              created: -1,
              title: '',
              description: JSON.stringify({ ops: [] }),
              taskId: '',
              uRef: '',
              files: []
            });
          } else {
            await ProfileAPI.USER_TASKS.create(
              this.payload.title,
              this.payload.description_delta,
              this.payload.tags,
              this.payload.spaceTags,
              this.payload.priority,
              this.payload.exposure,
              this.payload.exposurePasscode,
              this.payload.subscribers,
              this.state.universalRefID,
              this.payload.spaceId
            );
          }

          Logger.log(
            'Process took',
            Math.ceil(Date.now() / 1000) - start,
            'seconds'
          );
        } else if (mode === k.CREATE_TASK_MODE.EDIT) {
          const newSubs = [];
          const removeSubs = [];
          const newSpaceTags = isArray(spaceTagsApplied)
            ? spaceTagsApplied.filter(
                tagId =>
                  !this.spaceTagsId ||
                  !this.spaceTagsId.length ||
                  !this.spaceTagsId.includes(tagId)
              )
            : [];
          const removeSpaceTags = isArray(this.spaceTagsId)
            ? this.spaceTagsId.filter(
                tagId => spaceTagsApplied && !spaceTagsApplied.includes(tagId)
              )
            : [];
          const ogHasAccess = this.hasAccess;
          const updatedHasAccess = hasAccess;
          const subscribers = [];

          if (Array.isArray(ogHasAccess) && Array.isArray(updatedHasAccess)) {
            for (const ogWAccess of ogHasAccess) {
              let stillExists = false;

              for (const wAccess of updatedHasAccess) {
                if (
                  wAccess.id === ogWAccess.id &&
                  (ogWAccess.type === k.USER_TASK_ACCESS_SUBSCRIBER ||
                    ogWAccess.type === k.USER_TASK_ACCESS_SUBSCRIBER_AUTO)
                ) {
                  stillExists = true;
                  break;
                }
              }

              if (!stillExists) {
                removeSubs.push(ogWAccess.id);
              }
            }

            for (const wAccess of updatedHasAccess) {
              let isNew = true;

              for (const ogWAccess of ogHasAccess) {
                if (
                  ogWAccess &&
                  ogWAccess.id &&
                  !subscribers.includes(ogWAccess.id) &&
                  !removeSubs.includes(ogWAccess.id)
                ) {
                  subscribers.push(ogWAccess.id);
                }

                if (
                  ogWAccess.id === wAccess.id &&
                  (ogWAccess.type === k.USER_TASK_ACCESS_SUBSCRIBER ||
                    ogWAccess.type === k.USER_TASK_ACCESS_SUBSCRIBER_AUTO)
                ) {
                  isNew = false;
                  break;
                }
              }

              if (isNew) {
                if (wAccess && !subscribers.includes(wAccess.id)) {
                  subscribers.push(wAccess.id);
                }

                newSubs.push(wAccess.id);
              }
            }
          }

          // for edit payload
          this.payload = {
            title,
            description_delta: description_deltaSTR,
            files,
            exposure,
            exposurePasscode,
            newSpaceTags,
            removeSpaceTags,
            priority: priority.idx,
            tags: [...personalTagsApplied],
            spaceTags: [...spaceTagsApplied],
            task_state: `${task_state.key}`.toLowerCase(),
            subscribers: newSubs,
            subscribers_remove: removeSubs,
            valid: true
          };

          // for edit mode
          const lastEdited = {
            title,
            exposure,
            exposurePasscode,
            files,
            subscribers,
            spaceId,
            spaceTagsInfo,
            description: description_deltaSTR,
            taskId: taskID,
            uRef: universalRefID,
            priority: priority.idx,
            tags: [...personalTagsApplied],
            spaceTags: [...spaceTagsApplied],
            spaceInfo: null,
            ...(spaceId &&
              spaceInfo && {
                spaceInfo
              }),
            created: this.created,
            lastUpdated: this.lastUpdated,
            author: this.author,
            taskState: this.payload.task_state,
            valid: true,
            views: this.views,
            viewsValid: this.viewsValid,
            owner: this.owner,
            subscriberEditing: this.subscriberEditing,
            noApiRequest: false,
            author_id: this.author_id
          };

          try {
            let noChange = false;

            if (
              this.title === this.payload.title &&
              this.payload.description_delta ===
                JSON.stringify(this.description_delta) &&
              this.exposure === this.payload.exposure &&
              this.exposurePasscode === this.payload.exposurePasscode &&
              this.priority === this.payload.priority &&
              this.task_state === this.payload.task_state &&
              newSubs.length <= 0 &&
              removeSubs.length <= 0
            ) {
              noChange = true;
            }

            if (noChange) {
              for (const t of tags) {
                if (t && t.id && this.tags.indexOf(t.id) < 0) {
                  if (noChange) {
                    noChange = false;
                  }
                }
              }

              if (noChange && tags.length !== this.tags.length) {
                noChange = false;
              }

              if (newSpaceTags.length > 0 || removeSpaceTags.length > 0) {
                noChange = false;
              }
            }

            if (noChange) {
              lastEdited.noApiRequest = true;

              Logger.log('Nothing changed, not sending task update on cloud');
              await AppAPI.setGlobalConfig('lastEdited', lastEdited);

              this.setState({
                redirectSuccess: true,
                mounted: false,
                submitted: false
              });

              return;
            }
          } catch (err) {
            await this.setStateAsync({
              submitted: false
            });

            Logger.log(
              `Failed in checking if task has a property that's updated: ${err.message}`
            );
          }

          await AppAPI.setGlobalConfig('lastEdited', lastEdited);

          const isValid =
            universalRefID &&
            taskID &&
            typeof this.payload === 'object' &&
            this.payload;
          const lastEditedTaskProps = {
            ...(this.payload || {}),
            valid: true,
            uRef: universalRefID,
            taskId: taskID,
            newSubscribers: newSubs,
            removeSubscribers: removeSubs,
            taskState: this.payload.task_state,
            subscribers: undefined,
            subscribers_remove: undefined,
            userId: ProfileAPI.USER_DATA.getUserId() || ''
          };

          if (isValid) {
            // we use this as a fail-safe, see /pages/task on how we handle editing/creating task with 'try_again' flag
            // we'll try again in the background if it'll fail the first time
            await AppAPI.setLocalConfigs('lastEditedTask', lastEditedTaskProps);
          }

          this.tries = 0;
          await this.setStateAsync({
            submitted: true
          });

          ProfileAPI.USER_TASKS.edit(
            taskID,
            universalRefID,
            this.payload.title,
            this.payload.description_delta,
            this.payload.tags,
            this.payload.newSpaceTags,
            this.payload.removeSpaceTags,
            this.payload.priority,
            this.payload.exposure,
            this.payload.exposurePasscode,
            this.payload.task_state,
            this.payload.subscribers,
            this.payload.subscribers_remove
          );

          await timeout(500); // emulate load
          await this.setStateAsync({
            submitted: false,
            mounted: false,
            redirectSuccess: true,
            hasAccess: []
          });
          await ProfileAPI.USER_TASKS.get();
          delete this.onSubmitResultSubscriber;
          return;
        }
      } catch (err) {
        if (err.stack) {
          Logger.log(err.stack);
        }

        Logger.log(err.message);

        await this.setStateAsync({
          submitted: false
        });
      }
    } else {
      this.promptError(i18n('common_something_wrong_w_try'));
    }
  };

  cancel() {
    const { close } = this.props;

    if (typeof close === 'function') {
      close();
    }
  }

  confirmTaskDescription = QuillEditor => {
    Logger.log(`Confirmation for task description component ${Date.now()}`);
    TaskProperties.setActive();
    this.QuillEditor = QuillEditor;

    if (this.descriptionDeltaReady) {
      TaskProperties.setDescriptionProperties(this.description_delta);
      this.setState({
        taskDescriptionInit: true
      });
    }

    this.setupForDragAndDropFile();

    if (isFunction(isMobileView) && isMobileView()) {
      window.scrollTo(0, 0);
    }
  };

  /**
   * Setup events for drag and drop files
   */
  setupForDragAndDropFile() {
    if (this.descriptionWrapRef && this.descriptionWrapRef.current) {
      const dom = this.descriptionWrapRef.current;
      let counter = 0;

      if (dom) {
        for (const typeEvent of k.DRAG_EVENTS) {
          if (typeEvent) {
            dom.addEventListener(typeEvent, function (evt) {
              if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
              }
            });
          }
        }

        ['dragenter', 'dragover'].forEach(event => {
          dom.addEventListener(
            event,
            () => {
              counter++;
              this.setState({
                draggingFileUpload: true
              });
            },
            false
          );
        });

        dom.addEventListener(
          'drop',
          evt => {
            const { draggingFileUpload } = this.state;

            if (draggingFileUpload) {
              this.setState({
                draggingFileUpload: false
              });
            }

            if (evt) {
              const dt = evt.dataTransfer;
              const files = dt.files;

              if (files) {
                this.handleFileUpload(files);
              }
            }
          },
          false
        );

        dom.addEventListener(
          'dragleave',
          () => {
            const { draggingFileUpload, mounted } = this.state;

            if (!mounted) {
              return;
            }
            counter--;
            if (counter === 0 && draggingFileUpload) {
              this.setState({
                draggingFileUpload: false
              });
            }
          },
          false
        );

        dom.addEventListener(
          'click',
          () => {
            const { draggingFileUpload, mounted } = this.state;

            if (!mounted) {
              return;
            }
            if (draggingFileUpload) {
              this.setState({
                draggingFileUpload: false
              });
            }
          },
          false
        );
      }
    }
  }

  handleFileUpload = async (files = []) => {
    const {
      files: storedFiles,
      universalRefID: uRef,
      uploadingFiles = [],
      uploadingImage,
      uploadingImages = [],
      taskID: taskId
    } = this.state;
    const imageFiles = filter(files, file =>
      toString(file?.type).includes('image')
    );
    const notImageFiles = filter(
      files,
      file => !toString(file?.type).includes('image')
    );
    const fromQueue =
      notImageFiles?.length < 1 ? this.uploadFileQueue.shift() : null;
    const file = fromQueue ? fromQueue.file : head(notImageFiles); // 1 file at a time
    const fromUploadImageQueue = !isEmpty(this.uploadImagesQueue);
    const userIsPremium = ProfileAPI.SPACES.isUserPremiumWithSpace();
    const hasImageFiles = !isEmpty(imageFiles) ? imageFiles[0] : null;
    const singleImageFile = size(imageFiles) < 2;
    const isCurrentlyUploadingImage =
      uploadingImage || !isEmpty(uploadingImages);
    const Quill = window.Quill;
    const Delta = Quill.import('delta');
    const QuillEditor = this.QuillEditor;
    const loadAndInsertImage = (currentFile, insertSpace = false, range = 0) =>
      new Promise(resolve => {
        try {
          const fr = new FileReader();
          fr.onload = async function () {
            await ImagesHelper.insertImageBase64(
              this.result,
              null,
              null,
              insertSpace
            );
            resolve();
          };
          fr.readAsDataURL(currentFile);
        } catch {
          resolve();
        }
      });

    if (isCurrentlyUploadingImage) {
      // queue
      this.uploadImagesQueue = imageFiles;
      return;
    }

    if (hasImageFiles) {
      try {
        const range = QuillEditor.getSelection(true);
        await this.setStateAsync({ uploadingImage: true });

        for (let i = 0; i < imageFiles.length; i++) {
          const currentImageFile = imageFiles[i];
          const sizeInBytes = currentImageFile.size;

          if (
            (!userIsPremium && sizeInBytes > 25e6) ||
            (userIsPremium && sizeInBytes > 150e6)
          ) {
            this.promptError(
              userIsPremium
                ? `${i18n('user_create_task_status_exceed_image_premium')}${
                    currentImageFile?.name ? `: ${currentImageFile.name}` : ''
                  }`
                : `${i18n('user_create_task_status_exceed_image_upgrade')}`
            );

            break;
          }

          await loadAndInsertImage(
            currentImageFile,
            singleImageFile || i === size(imageFiles) - 1,
            singleImageFile < 2 ? 0 : range?.index || 0 * 2
          );
        }
      } finally {
        this.unmarkIsUploadingImage(true);
      }

      if (fromUploadImageQueue) {
        this.uploadImagesQueue = [];
      }

      return;
    }

    if (file) {
      // @todo handle queue for multiple files
      const recentlyUploadedFile = head(uploadingFiles);
      const sizeInBytes = file.size;
      const fileType = file.type;
      const isVideoFile = isSupportedVideoFileFormat(toString(file.type));
      const inline = true; // inline mode
      const uploadViaBlot = inline && !isVideoFile;
      const fileId = fromQueue ? fromQueue.id : v4();
      let newFiles = storedFiles;
      let fileName = `${file.name}`.trim().replace(/\s/g, '');
      let fileExtension = `${fileName.substring(
        fileName.lastIndexOf('.') + 1
      )}`;
      let uploadRes = null;

      if (
        recentlyUploadedFile &&
        (recentlyUploadedFile.sizeInBytes > 124e6 || uploadingFiles?.length > 4)
      ) {
        this.promptError(i18n('user_create_task_file_attached_please_wait'));
        return;
      } else if (!isFileAllowed(fileName)) {
        this.promptError(
          i18n('user_create_task_file_upload_status_not_allowed')
        );
        return;
      } else if (!userIsPremium && sizeInBytes > 25e6) {
        this.promptError(
          i18n('user_create_task_file_attached_max_size_reached_upgrade')
        );
        return;
      } else if (sizeInBytes > 2540e6) {
        this.promptError(
          i18n('user_create_task_file_attached_max_size_reached_premium')
        );
        return;
      } else {
        if (!fileType && !['rar'].includes(fileExtension)) {
          this.promptError(i18n('user_create_task_file_attached_unsupported'));
          return;
        }

        if (!fromQueue) {
          const findFileName = storedFiles.filter(
            file =>
              (file?.metadata && file.metadata.fileName === fileName) ||
              file.fileName === fileName
          );

          if (findFileName.length > 0) {
            fileName = `(${findFileName.length + 1})${fileName}`;
          }

          if (!uploadViaBlot) {
            const newFileMetadata = {
              sizeInBytes,
              metadata: { fileName },
              inline: true,
              loading: true,
              id: fileId
            };
            newFiles = [newFileMetadata, ...storedFiles];
            uploadingFiles.push(newFileMetadata);
            await this.setStateAsync({ uploadingFiles, files: newFiles });

            if (uploadingFiles.length >= 2) {
              this.uploadFileQueue.push({ file, id: fileId });
              return;
            }
          }
        }

        const range = QuillEditor.getSelection(true);
        const newRange = range.index + 2;
        const update = new Delta().retain(range.index).delete(range.length);

        try {
          if (uploadViaBlot) {
            QuillEditor.updateContents(
              update.insert('\n'),
              update.insert({
                file: {
                  taskId,
                  uRef,
                  file,
                  contentType: fileType || fileExtension,
                  name: fileName,
                  url: 'upload',
                  refId: '',
                  edit: true,
                  size: sizeInBytes
                }
              }),
              Quill.sources.USER
            );
            QuillEditor.setSelection(newRange, Quill.sources.SILENT);
            return;
          } else {
            if (isVideoFile) {
              // video placeholder
              QuillEditor.updateContents(
                update.insert('\n'),
                update.insert({
                  placeholderVideo: { id: fileId }
                }),
                Quill.sources.USER
              );
              QuillEditor.setSelection(newRange, Quill.sources.SILENT);
            }

            if (userIsPremium) {
              // post request
              uploadRes = await ProfileAPI.TASKS.uploadFile(
                file,
                fileName,
                fileType,
                taskId,
                uRef,
                inline
              );
            } else {
              // for non premium users
              // post request
              uploadRes = await ProfileAPI.USER_TASKS.uploadFile(
                uRef,
                file,
                fileId,
                fileName,
                inline
              );
            }
          }

          if (!this.state.mounted) {
            return;
          }

          if (uploadRes && !uploadRes.err) {
            await this.removeFromUploading(fileId);
            const refId = uploadRes?.file?.ref_id;

            if (isVideoFile && !isEmpty(uploadRes.url)) {
              const descriptionDelta = QuillEditor.getContents();
              const ops = descriptionDelta.ops;
              let hasChange = false;

              for (let i = 0; i < ops.length; i++) {
                const op = ops[i];
                if (
                  op &&
                  op.insert &&
                  op.insert.placeholderVideo?.id === fileId
                ) {
                  // replace video placeholder
                  ops[i] = { insert: { video: uploadRes.url } };
                  hasChange = true;
                  break;
                }
              }

              if (hasChange) {
                descriptionDelta.ops = ops;
                TaskProperties.setDescriptionProperties(descriptionDelta);
                QuillEditor.setSelection(newRange, Quill.sources.SILENT);
              } else {
                QuillEditor.updateContents(
                  update.insert('\n'),
                  update.insert({
                    video: uploadRes.url
                  }),
                  Quill.sources.USER
                );
                QuillEditor.setSelection(newRange, Quill.sources.SILENT);
              }
            } else if (inline) {
              QuillEditor.updateContents(
                update.insert('\n'),
                update.insert({
                  file: {
                    refId,
                    name: fileName,
                    url: uploadRes.url || 'error',
                    size: sizeInBytes
                  }
                }),
                Quill.sources.USER
              );
              QuillEditor.setSelection(newRange, Quill.sources.SILENT);
            }

            if (!isVideoFile && uploadRes.file) {
              await this.updateFileField(
                { ...uploadRes.file, loading: false },
                fileId
              );
            }

            if (this.uploadFileQueue.length > 0) {
              this.handleFileUpload();
              return;
            }
          } else {
            const range = QuillEditor.getSelection(true);
            const newRange = range.index + 2;

            if (isVideoFile) {
              const descriptionDelta = QuillEditor.getContents();
              const ops = descriptionDelta.ops;
              let hasChange = false;

              for (let i = 0; i < ops.length; i++) {
                const op = ops[i];
                if (
                  op &&
                  op.insert &&
                  op.insert.placeholderVideo?.id === fileId
                ) {
                  // replace video placeholder on error
                  ops[i] = '\n';
                  hasChange = true;
                  break;
                }
              }

              if (hasChange) {
                descriptionDelta.ops = ops;
                TaskProperties.setDescriptionProperties(descriptionDelta);
                QuillEditor.setSelection(newRange, Quill.sources.SILENT);
              }
            }

            // remove from list
            if (uploadRes?.notAllowedFile) {
              this.promptError(
                i18n('user_create_task_file_upload_status_not_allowed')
              );
            } else if (uploadRes?.notAllowedPlan) {
              this.promptError(
                i18n('user_create_task_file_attached_max_size_reached_upgrade')
              );
            } else if (uploadRes?.maxSize) {
              this.promptError(
                i18n('user_create_task_file_attached_max_size_reached')
              );
            } else {
              this.promptError(i18n('common_something_wrong_w_try'));
            }

            await this.removeFromFile({ fileId });
            await this.removeFromUploading(fileId);
          }
        } catch (err) {
          Logger.log(err?.message);
          await this.removeFromFile({ fileId });
          await this.removeFromUploading(fileId);
        }
      }
    }
  };

  markIsUploadingImage = () => {
    this.setState({
      uploadingImage: true
    });
  };

  unmarkIsUploadingImage = (saveDraft = false) => {
    this.setState(
      {
        uploadingImage: false
      },
      () => {
        if (saveDraft && this.descriptionEditorSaveDraftOBS) {
          this.descriptionEditorSaveDraftOBS.next();
        }

        if (!isEmpty(this.uploadImagesQueue)) {
          this.handleFileUpload(this.uploadImagesQueue);
        } else if (saveDraft && this.descriptionEditorSaveEditedOBS?.next) {
          this.descriptionEditorSaveEditedOBS.next();
        }
      }
    );
  };

  handleRemoveFileUpload = async (fileId = '', refId = '') => {
    // can be cancel or remove
    await this.removeFromFile({ fileId, refId }, true);
  };

  removeFromFile = async (params = {}, request = false) => {
    const {
      files: storedFiles,
      removingFiles,
      universalRefID: uRef
    } = this.state;
    const { fileId = '', refId = '' } = params || {};
    const removeFile = { fileId, refId };

    for (let i = 0; i < storedFiles.length; i++) {
      const file = storedFiles[i];
      if (file && file.id === fileId) {
        storedFiles.splice(i, 1);
        break;
      }
    }

    if (request) {
      removingFiles.push(fileId);
    }

    await this.setStateAsync({
      removingFiles,
      files: storedFiles
    });

    if (request) {
      if (this.removeFileQueue.length > 0) {
        this.removeFileQueue.push(removeFile);
        return;
      }

      try {
        await ProfileAPI.USER_TASKS.removeUploadedFiles(uRef, [refId]);

        if (!this.state.mounted) {
          return;
        }

        await this.removeFromRemoving(fileId);

        if (this.removeFileQueue.length > 0) {
          const qRemoveFile = this.removeFileQueue.shift();
          return this.removeFromFile(qRemoveFile, true);
        }
      } catch {}
    }
  };

  removeFromUploading = async (fileId = '') => {
    if (!fileId) {
      return;
    }

    const { uploadingFiles } = this.state;
    for (let i = 0; i < uploadingFiles.length; i++) {
      const file = uploadingFiles[i];
      if (file && file.id === fileId) {
        uploadingFiles.splice(i, 1);
        break;
      }
    }

    await this.setStateAsync({
      uploadingFiles
    });
  };

  removeFromRemoving = async (fileId = '') => {
    if (!fileId) {
      return;
    }

    const { removingFiles } = this.state;

    for (let i = 0; i < removingFiles.length; i++) {
      const file = removingFiles[i];

      if (file === fileId) {
        removingFiles.splice(i, 1);
        break;
      }
    }

    await this.setStateAsync({
      removingFiles
    });
  };

  updateFileField = async (params = {}, id = '') => {
    const { files: storedFiles, mounted } = this.state;

    if (!mounted) {
      return;
    }

    for (let i = 0; i < storedFiles.length; i++) {
      const file = storedFiles[i];

      if (file && (file.id === id || file.fileId === id)) {
        storedFiles[i] = { ...file, ...params };

        this.setState({
          files: storedFiles
        });
        break;
      }
    }
  };

  promptDelete() {
    const { mounted, loaded, mode, submitted } = this.state;

    if (
      !mounted ||
      !loaded ||
      mode === k.CREATE_TASK_MODE.CREATE ||
      submitted
    ) {
      return;
    } else {
      ModalAPI.setDOM2(
        <DeleteTask confirm={this.confirmDelete} cancel={ModalAPI.hideDOM2} />
      );
    }
  }

  confirmDelete = async () => {
    const { mounted, loaded, submitted, deleted, taskID, universalRefID } =
      this.state;
    if (!mounted || !loaded || submitted || deleted) {
      ModalAPI.goDeactivate();
      return;
    }

    this.onTaskDeleteSubscriber = ProfileAPI.USER_TASKS.onDeleteResults(
      this.deleteResults
    );

    await this.setStateAsync({
      deleted: true,
      submitted: true,
      mounted: false,
      deletedSuccess: true
    });
    ModalAPI.toaster('success', i18n('common_status_delete_success'), 4500);
    ModalAPI.goDeactivate();

    if (this.onTaskDeleteSubscriber) {
      this.onTaskDeleteSubscriber.unsubscribe();
    }

    await ProfileAPI.USER_TASKS.delete(taskID, universalRefID, true, false);
    ProfileAPI.SPACES.signalForceFetchTaskList();
    await ProfileAPI.USER_TASKS.get();
  };

  async deleteResults({ success = false }) {
    const { mounted, loaded, deleted } = this.state;

    if (!mounted || !deleted || !loaded) {
      return;
    } else if (success) {
      ProfileAPI.USER_TASKS.get();

      await this.setStateAsync({
        mounted: false,
        deletedSuccess: true
      });

      ModalAPI.goDeactivate();
    } else {
      this.setState({
        deletedSuccess: false,
        deleted: false,
        submitted: false
      });
      this.promptError(i18n('common_something_wrong_w_try'));
      ModalAPI.hideDOM2();
    }
  }

  /**
   * Modify subscribers list
   * @param {*} hasAccess
   * @returns
   */
  modifyHasAccess = (hasAccess = []) => {
    const { loaded, mounted } = this.state;

    if (!loaded || !mounted) {
      return;
    }

    if (isArray(hasAccess)) {
      const unique = [];
      const ids = [];

      for (let i = 0; i < hasAccess.length; i++) {
        const user = hasAccess[i];
        const userId = user?.id || user?.profile_id;

        if (userId && !ids.includes(userId)) {
          unique.push(user);
          ids.push(userId);
        }
      }

      this.setState({
        hasAccess: unique
      });
    }
  };

  addSubscriber = (userIds = [], type = '') => {
    const { loaded, mounted, hasAccess, connectedUsers } = this.state;

    if (loaded && mounted) {
      const subscriberIds = filter(
        hasAccess.map(
          currentSubscriber =>
            currentSubscriber?.id || currentSubscriber?.profile_id
        ),
        id => !isEmpty(id)
      );

      for (let i = 0; i < userIds.length; i++) {
        const userId = userIds[i];
        const user = head(
          filter(
            connectedUsers,
            user => user && (user?.id === userId || user?.profile_id === userId)
          )
        );

        if (isNil(user) || (userId && subscriberIds.includes(userId))) {
          continue;
        }

        if (!isEmpty(type)) {
          user.type = type;
        }

        hasAccess.push(user);
      }

      this.setState({ hasAccess });
    }
  };

  removeSubscriber = async (userIds = []) => {
    const { loaded, mounted, hasAccess } = this.state;

    if (loaded && mounted && !isEmpty(userIds)) {
      for (let y = 0; y < userIds.length; y++) {
        const userId = userIds[y];

        for (let i = 0; i < hasAccess.length; i++) {
          const subscriber = hasAccess[i];
          const subscriberId = subscriber?.id || subscriber?.profile_id;

          if (!isEmpty(userId) && subscriberId === userId) {
            hasAccess.splice(i, 1);
            await this.setStateAsync({ hasAccess });
            break;
          }
        }
      }
    }
  };

  modifyAdvancedOptions(name, value) {
    /**
     * - For create mode we apply advanced options on submitting the task payload.
     * - For edit mode, we modify them asynchronously, please see AdvancedOptions.js component
     */
    if (typeof this.advancedOptions[name] !== 'undefined') {
      this.advancedOptions[name] = value;
    }
  }

  toggleEditProps = flag => {
    const { loaded, mounted, editProps } = this.state;
    if (!mounted || !loaded) {
      return;
    }

    if (isMobileView()) {
      if (this.propertiesDomRef?.current) {
        if (flag) {
          disableBodyScroll(this.propertiesDomRef.current);
        } else {
          enableBodyScroll(this.propertiesDomRef.current);
        }
      }
    }

    this.setState({
      editProps: typeof flag === 'boolean' ? flag : !editProps
    });
  };

  toggleExpandSpacesList = () => {
    const { spacesListExpand, mode } = this.state;
    const isUserPremiumWithSpace = ProfileAPI.SPACES.isUserPremiumWithSpace();
    const isEditMode = mode === k.CREATE_TASK_MODE.EDIT;

    if (isEditMode) {
      return;
    }

    if (!isUserPremiumWithSpace && spacesListExpand) {
      this.setState({
        spacesListExpand: false
      });
    } else {
      this.setState({
        spacesListExpand: !spacesListExpand
      });
    }
  };

  updateConnectedUsers = (users = []) => {
    if (isArray(users)) {
      this.setState({
        connectedUsers: users.filter(user => user?.firstName || user?.lastName)
      });
    }
  };

  setImagePreviewUploaded = (url = '') => {
    const { hideCloseButton, showCloseButton } = this.props;
    this.setState({
      imagePreview: url
    });

    if (url) {
      if (isFunction(hideCloseButton)) {
        hideCloseButton();
      }
    } else {
      if (isFunction(showCloseButton)) {
        showCloseButton();
      }
    }
  };

  render() {
    const {
      priority,
      exposure,
      exposurePasscodeShow,
      exposurePasscode,
      exposureWPasscode,
      exposurePasscodeInfo,
      taskID,
      tags,
      title,
      titleInfo,
      loaded,
      editDescription,
      editProps,
      mode,
      updated,
      created,
      task_state,
      taskDescriptionInit,
      titleFocus,
      universalRefID,
      redirectErr,
      redirectSuccess,
      deletedSuccess,
      hasAccess,
      hasAccessValid,
      submitted,
      deleted,
      spacesListExpand,
      subscriberEditing,
      draggingFileUpload,
      uploadingFiles,
      uploadingImage,
      files,
      isMobile,
      connectedUsers,
      spaceInfo,
      imagePreview
    } = this.state;
    // when subscriberEditing flag is true, we don't let them edit the advanced options-
    // and the subscribers list
    // that's for them not to unexpectedly remove subs by their will(could be with bad intention)
    // only the main author can do so
    const {
      maximizeEditor = false,
      showProps = true,
      toggleMaximizeEditor = function () {},
      toggleProps = function () {},
      team = []
    } = this.props;
    const description_delta = this.description_delta;
    const utcDate = this.utcDate;
    const currentDate = `${getHHMMAMPMFormat(
      utcDate.getHours(),
      utcDate.getMinutes()
    )}  ${
      k.MONTHS_INDEX[utcDate.getMonth()].abv
    } ${utcDate.getDate()}, ${utcDate.getFullYear()}`;
    const isThemeDarkMode = AppAPI.isDarkMode();

    if (redirectErr || deletedSuccess) {
      return <Redirect to={'/user'} />;
    }

    if (redirectSuccess) {
      return <Redirect to={`/view/${taskID}?t=${universalRefID}`} />;
    }

    const isEditMode = mode === k.CREATE_TASK_MODE.EDIT;
    const isUserPremiumWithSpace = ProfileAPI.SPACES.isUserPremiumWithSpace();
    const spaceId =
      team && team.length && team[0] ? team[0].id || team[0].space_id : '';
    const { space } = ProfileAPI.SPACES.getSpaceById(spaceId) || {};
    const targetSpaceInfo = space || spaceInfo;
    const spaceTags = targetSpaceInfo ? formatSpaceTags(targetSpaceInfo) : [];
    const spaceName = spaceInfo?.name;

    return (
      <>
        <div
          className={cx(styles.create_task_v2, {
            [styles.create_task_v2_dark]: isThemeDarkMode,
            [styles.create_task_v2_blur]: imagePreview
          })}
          ref={this.wrapperRef}
        >
          <div className={styles.create_task_v2_content}>
            <div
              className={cx(styles.second, {
                [styles.hide_element]: !showProps,
                [styles.second_show]: editProps,
                [styles.second_hide]: !editProps,
                [styles.second_dark]: isThemeDarkMode
              })}
              ref={this.propertiesDomRef}
            >
              <div
                className={cx(styles.property_placeholder, {
                  [styles.property_placeholder_dark]: isThemeDarkMode,
                  [styles.hide_element]: loaded
                })}
              >
                <div></div>
              </div>
              <div className={styles.second_mobile_actions}>
                <div
                  className={cx(styles.hide, {
                    [styles.hide_dark]: isThemeDarkMode
                  })}
                >
                  <Button appearance="minimal" onClick={this.toggleEditProps}>
                    <div>
                      <ChevronLeftIcon />
                    </div>
                  </Button>
                </div>
              </div>

              {loaded && isMobile && (
                <Spaces
                  isUserPremiumWithSpace={isUserPremiumWithSpace}
                  spacesListExpand={spacesListExpand}
                  selected={spaceId}
                  expand={spacesListExpand}
                  onExpand={this.toggleExpandSpacesList}
                />
              )}

              <div
                className={cx(styles.property, {
                  [styles.hide_element]: !loaded
                })}
              >
                <div
                  className={cx(styles.property, styles.state_wrap, {
                    [styles.hide_element]:
                      mode === k.CREATE_TASK_MODE.CREATE ||
                      !loaded ||
                      !task_state.valid
                  })}
                >
                  <div
                    className={cx(styles.title, {
                      [styles.title_dark]: isThemeDarkMode
                    })}
                  >
                    <p>{i18n('user_create_task_user_state')}</p>
                  </div>
                  <div className={styles.value}>
                    <Select
                      value={task_state.key}
                      className={cx(styles.state_opts, {
                        [styles.state_opts_dark]: isThemeDarkMode
                      })}
                      onChange={TaskProperties.onStateSelect}
                    >
                      {k.TASKS_STATES.map(state => {
                        return (
                          <option
                            key={`${TaskProperties.taskPriorityUnique}-${state.idx}`}
                            value={state.key}
                          >
                            {i18n(state.i18n)}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div
                  className={cx(styles.title, {
                    [styles.title_dark]: isThemeDarkMode
                  })}
                >
                  <p>{i18n('user_create_task_user_priority_title')}</p>
                </div>
                <div className={cx(styles.value, styles.property_priority)}>
                  <div className={styles.priority_icon}>
                    <PriorityIcon priority_key={priority.key} />{' '}
                  </div>
                  <Select
                    value={priority.key}
                    className={cx(styles.property_priority_opts, {
                      [styles.property_priority_opts_dark]: isThemeDarkMode
                    })}
                    onChange={TaskProperties.onPrioritySelect}
                  >
                    {k.TASK_PRIORITIES.map(prio => {
                      return (
                        <option
                          key={`${TaskProperties.taskPriorityUnique}-${prio.idx}`}
                          value={prio.key}
                        >
                          {i18n(prio.i18n)}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div
                className={cx(styles.property, styles.property_privacy, {
                  [styles.hide_element]: !loaded
                })}
              >
                <div
                  className={cx(styles.title, {
                    [styles.title_dark]: isThemeDarkMode
                  })}
                >
                  <p>{i18n('user_create_task_user_privacy')}</p>
                </div>
                <div
                  className={cx(styles.value, styles.property_privacy_value)}
                >
                  <div
                    className={cx(styles.property_privacy_tip, {
                      [styles.property_privacy_tip_dark]: isThemeDarkMode,
                      [styles.hide_element]:
                        k.USER_TASK_PRIVACY_ARR.indexOf(exposure) < 0
                    })}
                  >
                    <p>
                      {i18n(
                        exposure === k.USER_TASK_PRIVACY_PUBLIC_NO_PW
                          ? 'user_create_task_user_privacy_public_tip'
                          : exposure === k.USER_TASK_PRIVACY_PUBLIC_W_PW
                          ? 'user_create_task_user_privacy_public_w_passcode_tip'
                          : 'user_create_task_user_privacy_private_tip'
                      )}
                    </p>
                  </div>
                  <div
                    className={cx(styles.choices, {
                      [styles.choices_dark]: isThemeDarkMode
                    })}
                  >
                    <ul onClick={TaskProperties.onTogglePrivacy}>
                      {k.USER_TASK_DESCRIPTION_PRIVACY_OPTS.map((p, idx) => {
                        return (
                          <li
                            key={`${TaskProperties.taskPrivacyUnique}-${p.key}${idx}`}
                          >
                            <Radio
                              className={cx(
                                TaskProperties.taskPrivacyUnique,
                                `${TaskProperties.taskPrivacyUnique}-${p.key}`
                              )}
                              size={12}
                              value={p.key}
                              name={'privacy-task-create'}
                              label={i18n(p.i18n)}
                              checked={p.idx.indexOf(exposure) >= 0}
                            />{' '}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className={styles.passcode}>
                    <div
                      className={cx(styles.passcode_label, {
                        [styles.passcode_label_dark]: isThemeDarkMode
                      })}
                      onClick={TaskProperties.onSecureWithPWToggle}
                    >
                      <Radio
                        size={12}
                        name={'privacy-task-create'}
                        value={'secure'}
                        label={i18n(
                          'user_create_task_user_privacy_with_passcode'
                        )}
                        checked={exposureWPasscode}
                      />
                    </div>
                    <div
                      className={cx(styles.passcode_title, {
                        [styles.hide_element]: !exposureWPasscode,
                        [styles.passcode_title_dark]: isThemeDarkMode
                      })}
                    >
                      <h5>{i18n('task_view_actions_passcode_title')}</h5>
                      <div>
                        <InfoSignIcon height={10} width={10} />
                        <MessageHover
                          className={styles.passcode_title_tip}
                          message={i18n(
                            'user_create_task_user_privacy_with_passcode_tip'
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className={cx(styles.passcode_input, {
                        [styles.hide_element]: !exposureWPasscode
                      })}
                    >
                      <div
                        className={cx(styles.passcode_input_raw, {
                          [styles.passcode_input_raw_dark]: isThemeDarkMode
                        })}
                      >
                        <TextInputField
                          label={i18n('task_view_actions_passcode_title')}
                          placeholder={i18n(
                            'user_create_task_user_privacy_with_passcode_placeholder'
                          )}
                          value={exposurePasscode}
                          onChange={TaskProperties.onExposurePWChange}
                          autoComplete="false"
                          spellCheck={false}
                          type={exposurePasscodeShow ? 'text' : 'password'}
                          maxLength={
                            k.USER_TASK_PRIVACY_PW_PROPERTIES.maxLength
                          }
                          validationMessage={
                            exposurePasscodeInfo.err && exposurePasscodeInfo.msg
                          }
                        />
                        <div
                          className={cx(styles.passcode_input_show, {
                            [styles.passcode_input_show_dark]: isThemeDarkMode
                          })}
                        >
                          <ButtonClick onClick={this.onExposurePWShowToggle}>
                            {!exposurePasscodeShow && <EyeOpenIcon />}
                            {exposurePasscodeShow && <EyeOffIcon />}
                          </ButtonClick>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loaded && taskID && (
                <ScheduleReminders taskId={taskID} uRef={universalRefID} />
              )}

              <div className={cx({ [styles.hide_element]: !loaded })}>
                {((!loaded && mode === k.CREATE_TASK_MODE.CREATE) ||
                  (loaded && k.CREATE_TASK_MODE.EDIT)) && (
                  <Tags
                    onAddTags={this.onAddTags}
                    onRemoveTags={this.onRemoveTags}
                    tags={tags}
                    spaceName={spaceName}
                    spaceId={spaceId}
                    spaceTags={spaceTags}
                  />
                )}
              </div>
              <div
                className={cx({
                  [styles.hide_element]: !loaded || subscriberEditing
                })}
              >
                {hasAccessValid && loaded && (
                  <Subscribers
                    loaded={loaded}
                    hasAccess={hasAccess}
                    connectedUsers={connectedUsers}
                    mode={mode}
                    spaceId={spaceId}
                    modify={this.modifyHasAccess}
                    updateConnectedUsers={this.updateConnectedUsers}
                    fetchInfo={true}
                  />
                )}
              </div>

              {loaded && !subscriberEditing && (
                <AdvancedOptions
                  mode={mode}
                  advancedOptions={this.advancedOptions}
                  modifyAdvancedOptions={this.modifyAdvancedOptions}
                  taskID={taskID}
                  universalRefID={universalRefID}
                />
              )}
            </div>
            <div
              className={cx(styles.expand, {
                [styles.hide_element]: maximizeEditor,
                [styles.expand_dark]: isThemeDarkMode
              })}
            >
              {loaded && (
                <ButtonClick
                  className={styles.expand_button}
                  animate={true}
                  onClick={toggleProps}
                >
                  <div
                    className={cx({
                      [styles.expand_button_w_prop]: showProps,
                      [styles.expand_button_no_prop]: !showProps
                    })}
                  >
                    <ChevronDownIcon />
                  </div>
                  {showProps && (
                    <MessageHover
                      message={i18n('user_create_hide_properties')}
                      className={styles.expand_tip}
                    />
                  )}
                </ButtonClick>
              )}
            </div>
            <div
              className={cx(styles.first, {
                [styles.first_w_prop]: showProps && !maximizeEditor,
                [styles.first_no_prop]: !showProps && !maximizeEditor,
                [styles.first_maximize]: maximizeEditor,
                [styles.first_dark]: isThemeDarkMode
              })}
            >
              <div
                className={cx(styles.task_title_placeholder, {
                  [styles.task_title_placeholder_dark]: isThemeDarkMode,
                  [styles.hide_element]: loaded
                })}
              ></div>
              <div
                className={cx(styles.info, {
                  [styles.hide_element]: !loaded
                })}
              >
                <div>
                  <div className={styles.spaces_desktop}>
                    {loaded && !isMobile && (
                      <Spaces
                        spaceInfo={spaceInfo}
                        isUserPremiumWithSpace={isUserPremiumWithSpace}
                        spacesListExpand={spacesListExpand}
                        selected={spaceId}
                        expand={spacesListExpand}
                        onExpand={this.toggleExpandSpacesList}
                      />
                    )}
                  </div>
                  <div
                    className={cx(styles.info_arrow, {
                      [styles.info_arrow_dark]: isThemeDarkMode
                    })}
                  >
                    <ChevronRightIcon />
                  </div>
                  <div
                    className={cx(styles.current_state, {
                      [styles.current_state_dark]: isThemeDarkMode
                    })}
                  >
                    <h5>
                      {task_state && task_state.i18n && i18n(task_state.i18n)}
                    </h5>
                  </div>
                  {isEditMode && (
                    <div
                      className={cx(styles.task_id, styles.flex_row_xy, {
                        [styles.task_id_dark]: isThemeDarkMode
                      })}
                    >
                      {' '}
                      <div
                        className={cx(styles.info_arrow, {
                          [styles.info_arrow_dark]: isThemeDarkMode
                        })}
                      >
                        {' '}
                        <ChevronRightIcon />{' '}
                      </div>
                      <h5>{taskID}</h5>
                    </div>
                  )}
                </div>
                <div
                  className={cx(styles.date, {
                    [styles.date_dark]: isThemeDarkMode
                  })}
                >
                  <p>{`${
                    (created || currentDate) && isEditMode
                      ? `${i18n('common_created_title')}`
                      : ''
                  } ${created || currentDate}`}</p>
                  {updated && isStringNotEmpty(updated) && (
                    <p>{`, ${i18n(
                      'user_create_task_last_updated_title'
                    )} ${updated}`}</p>
                  )}
                </div>
              </div>
              <div className={styles.task_title}>
                <div
                  className={cx(styles.task_title_input, {
                    [styles.task_title_input_active]: titleFocus,
                    [styles.task_title_input_dark]:
                      !titleFocus && isThemeDarkMode,
                    [styles.task_title_input_active_dark]:
                      titleFocus && isThemeDarkMode,
                    [styles.hide_element]: !loaded
                  })}
                >
                  <textarea
                    value={title}
                    onChange={this.onTitleInput}
                    ref={this.titleShownDOMRef}
                    placeholder={i18n('user_create_task_user_title')}
                    onFocus={this.onTitleFocus}
                    onBlur={this.onTitleBlur}
                  ></textarea>
                  <textarea
                    ref={this.titleHiddenDOMRef}
                    className={styles.task_title_input_hidden}
                  ></textarea>
                  <div
                    className={cx(styles.error_input, {
                      [styles.hide_element]:
                        !titleInfo.err ||
                        typeof titleInfo.msg !== 'string' ||
                        titleInfo.msg.length <= 0
                    })}
                  >
                    <span></span>
                    <p className={styles.msg}>{titleInfo.msg}</p>
                  </div>
                </div>{' '}
                {loaded && (
                  <div
                    className={cx(styles.to_edit, {
                      [styles.hide_element]: titleFocus
                    })}
                  >
                    <ButtonClick
                      className={styles.to_edit_button}
                      onClick={() => {
                        if (
                          this.titleShownDOMRef &&
                          this.titleShownDOMRef.current
                        ) {
                          this.titleShownDOMRef.current.focus();
                        }
                      }}
                    >
                      {' '}
                      <EditIcon />{' '}
                    </ButtonClick>
                  </div>
                )}
              </div>
              <div
                className={cx(styles.task_description_wrap, {
                  [styles.task_description_wrap_maximize]: maximizeEditor,
                  [styles.hide_element]: !loaded
                })}
                ref={this.descriptionWrapRef}
              >
                <TaskDescription
                  editProps={editProps}
                  connectedUsers={connectedUsers}
                  submitted={submitted}
                  taskDescriptionInit={taskDescriptionInit}
                  loaded={loaded}
                  showMobile={editDescription}
                  universalRefID={universalRefID}
                  description_delta={description_delta}
                  hasAccess={hasAccess}
                  descriptionEditorSaveDraftOBS={
                    this.descriptionEditorSaveDraftOBS
                  }
                  descriptionEditorSaveEditedOBS={
                    this.descriptionEditorSaveEditedOBS
                  }
                  updated={updated}
                  blockDraft={this.blockDraft}
                  unblockDraft={this.unblockDraft}
                  saveDraft={this.saveDraft}
                  confirmTaskDescription={this.confirmTaskDescription}
                  promptError={this.promptError}
                  removeSubscriber={this.removeSubscriber}
                  addSubscriber={this.addSubscriber}
                  toggleMaximizeEditor={toggleMaximizeEditor}
                  maximizeEditor={maximizeEditor}
                  draggingFileUpload={
                    draggingFileUpload && uploadingFiles.length < 4
                  }
                  files={files}
                  uploadingFiles={uploadingFiles}
                  handleRemoveFileUpload={this.handleRemoveFileUpload}
                  handleFileUpload={this.handleFileUpload}
                  markIsUploadingImage={this.markIsUploadingImage}
                  unmarkIsUploadingImage={this.unmarkIsUploadingImage}
                  setImagePreviewUploaded={this.setImagePreviewUploaded}
                  isEditMode={mode === k.CREATE_TASK_MODE.EDIT}
                />
              </div>
              <div
                className={cx(styles.actions, {
                  [styles.actions_on_expand]: maximizeEditor,
                  [styles.hide_element]: !loaded
                })}
              >
                <div
                  className={cx(styles.cancel, {
                    [styles.hide_element]:
                      mode === k.CREATE_TASK_MODE.CREATE ||
                      submitted ||
                      uploadingImage ||
                      uploadingFiles.length > 0,
                    [styles.cancel_dark]: isThemeDarkMode
                  })}
                >
                  <Button appearance="minimal" onClick={this.cancel}>
                    <h4>{i18n('user_create_task_discard_title')}</h4>
                  </Button>
                </div>
                {isEditMode && !subscriberEditing && (
                  <div
                    className={cx(styles.delete, {
                      [styles.hide_element]:
                        mode === k.CREATE_TASK_MODE.CREATE ||
                        submitted ||
                        deleted ||
                        uploadingImage ||
                        uploadingFiles.length > 0,
                      [styles.delete_dark]: isThemeDarkMode
                    })}
                  >
                    <Button appearance="minimal" onClick={this.promptDelete}>
                      <h4>{i18n('user_edit_task_delete_title')}</h4>
                    </Button>
                  </div>
                )}
                <div
                  className={cx(styles.confirm, {
                    [styles.hide_element]: submitted
                  })}
                >
                  <CustomButton
                    label={
                      mode === k.CREATE_TASK_MODE.CREATE
                        ? i18n('user_create_task_confirm_title')
                        : i18n('common_save_changes')
                    }
                    variant={'primaryPurple'}
                    appearance="minimal"
                    onClick={this.submit}
                    loading={uploadingImage || uploadingFiles.length > 0}
                    disabled={
                      title.length < 1 ||
                      uploadingFiles.length > 0 ||
                      uploadingImage
                    }
                  />
                </div>

                <div
                  className={cx(styles.spinner_wrap, {
                    [styles.hide_element]: !submitted,
                    [styles.spinner_wrap_dark]: isThemeDarkMode
                  })}
                >
                  <div className={cx(styles.flex_row_xy, styles.raw)}>
                    <Spinner size={18} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.actions_mobile}>
              <div
                className={cx(styles.close, {
                  [styles.hide_element]: !loaded,
                  [styles.close_dark]: isThemeDarkMode
                })}
              >
                <IconButton
                  className={styles.close_button}
                  appearance="minimal"
                  icon={CrossIcon}
                  onClick={this.cancel}
                />
              </div>
              <div
                className={cx(styles.buttons, {
                  [styles.hide_element]: !loaded
                })}
              >
                <div
                  className={cx(styles.show_props, {
                    [styles.hide_element]: submitted,
                    [styles.show_props_dark]: isThemeDarkMode
                  })}
                >
                  <Button onClick={this.toggleEditProps}>
                    <div
                      className={cx(styles.show_props_ic, {
                        [styles.show_props_ic_dark]: isThemeDarkMode
                      })}
                    >
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </Button>
                </div>
                <div
                  className={cx(styles.confirm, {
                    [styles.hide_element]: submitted
                  })}
                >
                  <CustomButton
                    label={
                      mode === k.CREATE_TASK_MODE.CREATE
                        ? i18n('user_create_task_confirm_title')
                        : i18n('common_save_changes')
                    }
                    variant={'primaryPurple'}
                    appearance="minimal"
                    onClick={this.submit}
                    loading={
                      !loaded || uploadingImage || uploadingFiles.length > 0
                    }
                    disabled={
                      title.length < 1 ||
                      uploadingFiles.length > 0 ||
                      uploadingImage
                    }
                  />
                </div>
                <div
                  className={cx(styles.spinner_wrap, {
                    [styles.hide_element]: !submitted,
                    [styles.spinner_wrap_dark]: isThemeDarkMode
                  })}
                >
                  <div className={cx(styles.flex_row_xy, styles.raw)}>
                    <Spinner size={18} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {imagePreview && loaded && (
          <ImagePreviewUploaded
            url={imagePreview}
            close={() => this.setImagePreviewUploaded('')}
          />
        )}
      </>
    );
  }
}

export default withRouter(CreateTaskV2);
