import React from 'react';
import cx from 'classnames';
import styles from './LoadSpinner.scss';
import AppAPI from 'src/app-manager/API';

const LoadSpinner = props => {
  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      className={cx(
        { [props.className]: !!props.className },
        styles.load_spinner,
        { [styles.load_spinner_dark]: isThemeDarkMode }
      )}
    >
      <div
        className={cx(styles.ring_parent, {
          [props.ringClassName]:
            props.ringClassName && typeof props.ringClassName === 'string'
        })}
      >
        <div className={styles.ring}></div>
      </div>
    </div>
  );
};

export default LoadSpinner;
