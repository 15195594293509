import Logger from 'src/lib/Logger';
import axios from 'axios';
import k from 'src/constants/k';
import Store from 'src/lib/store';
import { v4 } from 'uuid';
import { isEmpty } from 'src/helpers/utils';
import { Subject } from 'rxjs';

class Search {
  profileContext = null;
  searching = false;
  searches = {};
  onSearchResultsObs = null;
  onSearchQueryObs = null;

  constructor() {
    this.onSearchResultsObs = new Subject();
    this.onSearchQueryObs = new Subject();
  }

  setContext(profileContext) {
    this.profileContext = profileContext;
  }

  /**
   * Listen to search results
   * @param {function} fn
   * @returns
   */
  onSearchResults(fn) {
    if (this.onSearchResultsObs) {
      return this.onSearchResultsObs.subscribe({ next: fn });
    }

    return null;
  }

  /**
   * Listen if helper is currently searching or not
   * @param {function} fn
   * @returns
   */
  onSearchQuery(fn) {
    if (this.onSearchQueryObs) {
      return this.onSearchQueryObs.subscribe({ next: fn });
    }

    return null;
  }

  checkSearching() {
    return this.searching;
  }

  async start(input = '', spaces = []) {
    // spaces  = spaceId[]
    this.searching = true;
    this.onSearchQueryObs.next({ searching: true });

    try {
      const foundURef = [];
      const found = [];
      const consume = (tasks = []) => {
        for (const task of tasks) {
          const { universal_ref_map = '' } = task || {};

          if (!foundURef.includes(universal_ref_map)) {
            foundURef.push(universal_ref_map);
            found.push(task);
          }
        }
      };

      const { found: step2Found, spaces: spaces2Found } = await this.getTasks(
        input,
        '',
        { step: 2 }
      );

      consume(step2Found);
      this.onSearchResultsObs.next({
        found,
        spaces: { ...spaces2Found }
      });
      this.searching = false;
      this.onSearchQueryObs.next({ searching: false });

      const { found: step1Found, spaces: spaces1Found } = await this.getTasks(
        input,
        '',
        { step: 1 }
      );
      consume(step1Found);
      this.onSearchResultsObs.next({
        found,
        spaces: { ...spaces1Found, ...spaces2Found }
      });
    } finally {
      this.searching = false;
    }
  }

  async getTasks(search = '', spaceId = '', params = {}) {
    const uniqueKey = v4();
    this.searches[uniqueKey] = {
      found: []
    };

    try {
      if (!search || isEmpty(search)) {
        throw new Error('Empty search');
      }

      if (!this.profileContext) {
        throw new Error('Blank profile context');
      }

      const { step = 1, subscribed = false, priority = '' } = params;
      const { isLoggedIn, user } = this.profileContext.state;

      if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            search,
            spaceId,
            step,
            priority,
            subscribed
          },
          url: `/v1/task/search/v2?auth_token_id=${id}&lb=${lb}&n=${n}&p=1`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.valid) {
          const result = {
            spaces: res.data.spaces,
            found: res.data.value,
            key: uniqueKey
          };
          this.searches[uniqueKey] = result;
          return result;
        } else {
          throw new Error(`${res && res.data ? res.data.message : res.status}`);
        }
      }
    } catch (err) {
      Logger.log(`err:searchV2:${err.message}`);

      return {
        spaces: null,
        key: uniqueKey,
        found: []
      };
    }
  }
}

export default Search;
