import React, { useEffect, useRef, useState } from 'react';
import ProfileAPI from 'src/profile-manager/API';
import AppAPI from 'src/app-manager/API';
import cx from 'classnames';
import styles from './Spaces.scss';
import i18n from 'src/locales';
import { isEmpty, isString, isFunction, isArray } from 'src/helpers/utils';
import { Button } from 'evergreen-ui';

const Spaces = props => {
  const mountedRef = useRef(false);
  const spacesListExpand = props.spacesListExpand;
  const isUserPremiumWithSpace = props.isUserPremiumWithSpace;
  const spaceId = `${props.selected}`;
  const { space } = ProfileAPI.SPACES.getSpaceById(spaceId) || {};
  const spaceInfo = space || props.spaceInfo;
  const [availableSpaces, setAvailableSpaces] = useState([]);
  const isPersonalSelected =
    !isUserPremiumWithSpace || !spaceId || spaceId === 'personal' || !spaceInfo;
  const isThemeDarkMode = AppAPI.isDarkMode();
  const uniqueCreateTaskInSpaceOption = 'uniqueCreateTaskInSpaceOption';

  useEffect(() => {
    mountedRef.current = true;

    return () => (mountedRef.current = false);
  }, []);

  // eslint-disable-next-line
  const onSpacesList = params => {
    if (!mountedRef?.current) {
      return;
    }

    if (params) {
      const { spaces = [] } = params;
      setAvailableSpaces(spaces);
    }
  };

  useEffect(() => {
    const onSpacesListSubscriber = ProfileAPI.SPACES.onSpacesList(onSpacesList);

    return () => {
      if (onSpacesListSubscriber) {
        onSpacesListSubscriber.unsubscribe();
      }
    };
  }, [props.expand]);

  useEffect(() => {
    const spaces = isUserPremiumWithSpace ? ProfileAPI.SPACES.get() : [];
    setAvailableSpaces(spaces);
  }, [isUserPremiumWithSpace]);

  const expandList = () => {
    if (isFunction(props.onExpand)) {
      props.onExpand();
    }
  };

  return (
    <div
      className={cx(styles.space_list, uniqueCreateTaskInSpaceOption, {
        [props.className]:
          props && isString(props.className) && !isEmpty(props.className)
      })}
    >
      <div
        className={cx(
          styles.selected,
          { [styles.selected_dark]: isThemeDarkMode },
          uniqueCreateTaskInSpaceOption
        )}
      >
        <Button
          appearance="minimal"
          onClick={() => expandList()}
          className={cx(styles.selected_button, uniqueCreateTaskInSpaceOption)}
        >
          <h5 className={cx(uniqueCreateTaskInSpaceOption)}>
            {isPersonalSelected
              ? i18n('user_dashboard_chamu_personal_space')
              : spaceInfo?.name || spaceInfo?.title}
          </h5>{' '}
        </Button>
      </div>
      {spacesListExpand && (
        <div
          className={cx(
            styles.list,
            { [styles.list_dark]: isThemeDarkMode },
            uniqueCreateTaskInSpaceOption,
            {
              [styles.hide_element]: !isUserPremiumWithSpace
            }
          )}
        >
          <ul className={cx(uniqueCreateTaskInSpaceOption)}>
            <li className={cx(uniqueCreateTaskInSpaceOption, 'personal')}>
              <h5 className={cx(uniqueCreateTaskInSpaceOption, 'personal')}>
                {i18n('user_dashboard_chamu_personal_space')}
              </h5>
            </li>
            {(isArray(availableSpaces) ? availableSpaces : [])
              .filter(space => !!space && (space.id || space.space_id))
              .map(space => {
                const currentSpaceId = `${space.id || space.space_id}`;
                const currentSpaceName = `${space.name || space.title}`;

                return (
                  <li
                    key={`create-task-space-opt-${currentSpaceId}`}
                    className={cx(
                      uniqueCreateTaskInSpaceOption,
                      currentSpaceId
                    )}
                  >
                    <h5
                      className={cx(
                        uniqueCreateTaskInSpaceOption,
                        currentSpaceId
                      )}
                    >
                      {currentSpaceName}
                    </h5>
                  </li>
                );
              })}
          </ul>{' '}
        </div>
      )}
    </div>
  );
};

export default Spaces;
