import axios from 'axios';
import Logger from 'src/lib/Logger';
import k from 'src/constants/k';
import { isDev, getAuthHeaders } from 'src/helpers/utils';

class Payment {
  setContext(context) {
    this.context = context;
  }

  /**
   * Fetch user invoices
   */
  async getBillingHistory() {
    const result = { err: true, invoices: [] };
    try {
      if (!this.context) {
        throw new Error('Context empty');
      }
      const { user } = this.context.state;
      const { token, id, lb, n, username_ref } = user?.auth;

      if (!token) {
        throw new Error('Not logged in');
      }
      const isDevMode = isDev();
      const res = await axios({
        method: 'GET',
        baseURL: k.API_DASHBOARD_URLS[isDevMode ? 'development' : 'production'],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        url: `/v1/user/billing-history?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data?.valid) {
        result.invoices = res.data.invoices;
        result.err = false;
      } else {
        if (res?.data?.message) {
          throw new Error(`${res?.data?.message}`);
        } else {
          throw new Error(`${res.status} ${res.statusText}`);
        }
      }
    } catch (err) {
      Logger.log(`err:getBillingHistory:${err && err.message}`);
    }

    return result;
  }

  async checkoutFailed(refId = '') {
    const result = { err: '' };

    try {
      if (!this.context) {
        throw new Error('Context empty');
      } else {
        const isDevMode = isDev();
        const { user } = this.context.state;

        if (!user) {
          throw new Error('Not logged in');
        } else {
          const { id, lb, n } = user?.auth;
          const headers = getAuthHeaders(user);
          const res = await axios({
            headers,
            method: 'POST',
            baseURL:
              k.API_DASHBOARD_URLS[isDevMode ? 'development' : 'production'],
            data: {
              refId
            },
            url: `/v1/payment/checkout-failed?auth_token_id=${id}&lb=${lb}&n=${n}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data?.done) {
            result.err = '';
          } else {
            if (res?.data?.message) {
              throw new Error(res?.data?.message);
            } else {
              throw new Error(`${res.status} ${res.statusText}`);
            }
          }
        }
      }
    } catch (err) {
      Logger.log(`err:checkoutFailed:${err && err.message}`);

      result.err = err?.message;
    }

    return result;
  }

  async checkoutSuccess(refId = '', sessionId = '') {
    const result = { err: '' };

    try {
      if (!this.context) {
        throw new Error('Context empty');
      } else {
        const isDevMode = isDev();
        const { user } = this.context.state;

        if (!user) {
          throw new Error('Not logged in');
        } else {
          const { id, lb, n } = user?.auth;
          const headers = getAuthHeaders(user);
          const res = await axios({
            headers,
            method: 'POST',
            baseURL:
              k.API_DASHBOARD_URLS[isDevMode ? 'development' : 'production'],
            data: {
              refId,
              sessionId
            },
            url: `/v1/payment/checkout-success?auth_token_id=${id}&lb=${lb}&n=${n}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data?.done) {
            result.err = '';
          } else {
            if (res?.data?.message) {
              throw new Error(res?.data?.message);
            } else {
              throw new Error(`${res.status} ${res.statusText}`);
            }
          }
        }
      }
    } catch (err) {
      Logger.log(`err:checkoutSuccess:${err && err.message}`);

      result.err = err?.message;
    }

    return result;
  }

  async createCheckoutSession() {
    const result = { err: '' };

    try {
      if (!this.context) {
        throw new Error('Context empty');
      } else {
        const isDevMode = isDev();
        const product =
          k.PRICES[isDevMode ? 'development' : 'production'].spacesMonthly?.key;
        const hostOrigin = `${window?.location?.host}`;
        const { user } = this.context.state;

        if (!product) {
          throw new Error(`Cannot find product`);
        } else if (!hostOrigin) {
          throw new Error('Cannot find ');
        } else if (!user) {
          throw new Error('Not logged in');
        } else {
          const { id, lb, n } = user?.auth;
          const headers = getAuthHeaders(user);
          const res = await axios({
            headers,
            method: 'POST',
            baseURL:
              k.API_DASHBOARD_URLS[isDevMode ? 'development' : 'production'],
            data: {
              origin: hostOrigin,
              product
            },
            url: `/v1/payment/create-session?auth_token_id=${id}&lb=${lb}&n=${n}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data?.success) {
            const { id, url } = res.data;
            // navigate to url

            if (url && id) {
              window.location.replace(url);
              result.err = '';
            } else {
              result.err = 'Missing url and id';
            }
          } else {
            if (res?.data?.message) {
              throw new Error(res?.data?.message);
            } else {
              throw new Error(`${res.status} ${res.statusText}`);
            }
          }
        }
      }
    } catch (err) {
      Logger.log(`err:createCheckoutSession:${err && err.message}`);

      result.err = err?.message;
    }

    return result;
  }
}

export default Payment;
