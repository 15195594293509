import React from 'react';
import cx from 'classnames';
import styles from './Reminders.scss';
import i18n from 'src/locales';

const Reminders = () => {
  return (
    <div className={styles.docs_reminders}>
      <div className={styles.label}>
        <h2>{i18n('docs_task_reminders_label')}</h2>{' '}
      </div>
      <div className={styles.message}>
        <p>{i18n('docs_task_reminders_desc')}</p>
      </div>
      <div className={cx(styles.message, styles.instructions)}>
        <p>{i18n('docs_task_reminders_desc_two')}</p>
      </div>
    </div>
  );
};

export default Reminders;
