import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './CommentsOrActivities.scss';
import i18n from 'src/locales';
import Comments from '../comments';
import Activities from '../activities';
import AppAPI from 'src/app-manager/API';
import { Button } from 'evergreen-ui';

class CommentsOrActivities extends Component {
  labelDom = null;

  constructor() {
    super();

    this.labelDom = createRef();
  }

  state = {
    selected: 'comment'
  };

  selectActivities = () => {
    this.setState(
      {
        selected: 'activities'
      },
      this.blurButtons
    );
  };

  blurButtons = () => {
    if (this.labelDom && this.labelDom.current) {
      const buttons = this.labelDom.current.querySelectorAll('button');

      if (buttons?.length) {
        for (let i = 0; i < buttons.length; i++) {
          const button = buttons[i];
          if (button) {
            button.blur();
          }
        }
      }
    }
  };

  selectComments = () => {
    this.setState(
      {
        selected: 'comment'
      },
      this.blurButtons
    );
  };

  render() {
    const {
      loaded = false,
      taskId,
      universalREF,
      isLoggedIn,
      authorProfileId,
      hasAccess,
      showWithAccess,
      taskInfoFromLocal,
      comment,
      allowCommentWrite,
      updateCommentMode,
      blockCommentWrite,
      blockCommentView
    } = this.props;
    const { selected } = this.state;
    const isComment = selected === 'comment';
    const isActivities = selected === 'activities';
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div className={styles.comments_or_activities}>
        {loaded && (
          <div
            className={cx(styles.label, {
              [styles.label_dark]: isThemeDarkMode
            })}
            ref={this.labelDom}
          >
            <div
              className={cx(styles.select_comments, {
                [styles.select_comments_dark]: isThemeDarkMode,
                [styles.selected]: isComment,
                [styles.not_selected]: !isComment,
                [styles.select_comments_active]: !isThemeDarkMode && isComment,
                [styles.select_comments_active_dark]:
                  isThemeDarkMode && isComment
              })}
            >
              <Button appearance="minimal" onClick={this.selectComments}>
                <h5>{i18n('task_view_comments_title')}</h5>
              </Button>
            </div>
            <div
              className={cx(styles.select_activities, {
                [styles.selected]: isActivities,
                [styles.not_selected]: !isActivities,
                [styles.select_activities_dark]: isThemeDarkMode,
                [styles.select_activities_active]:
                  !isThemeDarkMode && isActivities,
                [styles.select_activities_active_dark]:
                  isThemeDarkMode && isActivities
              })}
            >
              <Button appearance="minimal" onClick={this.selectActivities}>
                <h5>{i18n('task_view_activities_title')}</h5>
              </Button>
            </div>{' '}
          </div>
        )}
        <div
          className={cx(styles.comments, {
            [styles.hide_element]: !isComment
          })}
        >
          <Comments
            comment={comment}
            loaded={loaded}
            taskID={taskId}
            universalREF={universalREF}
            isLoggedIn={isLoggedIn}
            authorProfileId={authorProfileId}
            hasAccess={hasAccess}
            showWithAccess={showWithAccess}
            taskInfoFromLocal={taskInfoFromLocal}
            updateCommentMode={updateCommentMode}
            blockCommentView={blockCommentView}
            blockCommentWrite={blockCommentWrite}
            allowCommentWrite={allowCommentWrite}
          />
        </div>
        <div
          className={cx(styles.activities, {
            [styles.hide_element]: !isActivities
          })}
        >
          <Activities
            isLoggedIn={isLoggedIn}
            loaded={loaded}
            taskId={taskId}
            uRef={universalREF}
          />
        </div>
      </div>
    );
  }
}

export default CommentsOrActivities;
