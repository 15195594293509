import cx from 'classnames';
import styles from './LinkMigrate.scss';
import { Button } from 'evergreen-ui';

const LinkMigrate = () => {
  const navigateToNewLink = () => {
    const pathname = window.location?.pathname;
    const search = window.location?.search;

    window.location.href = `https://getchamu.com${pathname}${search}`;
  };

  return (
    <div className={cx(styles.link_migrate, styles.flex_row_xy)}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img
            src={
              'https://storage.googleapis.com/chamu-store-s1/images/app-static/chamu-icon-colored.png'
            }
            alt={'chamu logo'}
          />
        </div>
        <div className={styles.texts}>
          <h2>
            Sorry, we've recently moved this page to a new link. You may proceed
            by clicking the button below.
          </h2>
        </div>
        <div className={cx(styles.flex_row_xy, styles.cta)}>
          <Button onClick={navigateToNewLink} appearance="minimal">
            <p>Navigate to the new link</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LinkMigrate;
