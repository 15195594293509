import React from 'react';
import cx from 'classnames';
import styles from './InitUser.scss';
import AppLogo from 'src/components/app-logo';
import AppAPI from 'src/app-manager/API';

const InitUserLoading = () => {
  const isThemeDarkMode = AppAPI.isDarkMode();
  return (
    <div
      className={cx(styles.flex_column_xy, styles.init_user_wrap, {
        [styles.init_user_wrap_dark]: isThemeDarkMode
      })}
    >
      <div className={styles.logo}>
        <AppLogo />
      </div>
    </div>
  );
};

export default InitUserLoading;
