import React, { Component } from 'react';
import cx from 'classnames';
import styles from './ScheduleReminders.scss';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import TimePicker from 'react-time-picker';
import Calendar from 'react-calendar';
import i18n from 'src/locales';
import k from 'src/constants/k';
import {
  head,
  isString,
  isNumber,
  hoursToHHMMAFormat,
  correctHHMMAFormat,
  getHoursAndMinutesFromHHMMA
} from 'src/helpers/utils';
import { Subject } from 'rxjs';
import {
  Button,
  Checkbox,
  IconButton,
  CrossIcon,
  CalendarIcon
} from 'evergreen-ui';
import { debounceTime } from 'rxjs/operators';

class ScheduleReminders extends Component {
  maxDate = new Date('2050-12-30T16:00:00.000Z');
  minDate = new Date('2021-12-30T16:00:00.000Z');
  calendarInputUniqueClassname = 'calendarInputUniqueClassname';
  currentDate = new Date();
  updateReminderObs = null;
  updateEnableObs = null;
  updateEnableSubscriber = null;
  updateReminderSubscriber = null;
  state = {
    fetching: true,
    mounted: false,
    enabled: false,
    calendar: false,
    hoursInStr: '00:00',
    currentDate: this.currentDate,
    currentDateUtc: this.currentDate.toISOString(),
    reminders: []
  };

  constructor() {
    super();

    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.updateReminderObs = new Subject();
    this.updateEnableObs = new Subject();

    document.addEventListener('click', this.onDocumentClick, false);

    this.updateEnableSubscriber = this.updateEnableObs
      .pipe(debounceTime(300))
      .subscribe(this.updateEnableReminder);

    this.updateReminderSubscriber = this.updateReminderObs
      .pipe(debounceTime(300))
      .subscribe(this.updateReminder);

    this.setState(
      {
        mounted: true
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    if (this.updateEnableSubscriber) {
      this.updateEnableSubscriber.unsubscribe();
    }

    if (this.updateReminderSubscriber) {
      this.updateReminderSubscriber.unsubscribe();
    }

    if (this.updateEnableObs && this.updateEnableObs.unsubscribe) {
      this.updateEnableObs.unsubscribe();
    }

    if (this.updateReminderObs && this.updateReminderObs.unsubscribe) {
      this.updateReminderObs.unsubscribe();
    }

    this.setState({
      mounted: false
    });
    document.removeEventListener('click', this.onDocumentClick, false);
  }

  onMount = async () => {
    const { taskId = '', uRef = '' } = this.props;
    const taskIdLowerCase = `${taskId}`.toLowerCase();
    await this.setStateAsync({
      fetching: true
    });
    const remindersRes = await ProfileAPI.USER_TASKS.getReminders(
      taskIdLowerCase,
      uRef
    );

    if (remindersRes && !remindersRes.err) {
      const reminder = head(remindersRes.reminders);

      if (reminder) {
        const due = reminder.dueString;
        const hours = reminder.hours;
        const hoursInStr =
          isString(hours) && correctHHMMAFormat(hours)
            ? hours
            : isNumber(hours) && hours < 24
            ? hoursToHHMMAFormat(hours)
            : '00:00';
        await this.setStateAsync({
          hoursInStr,
          currentDate: new Date(due),
          currentDateUtc: due,
          reminders: remindersRes.reminders
        });
      }

      await this.setStateAsync({
        fetching: false,
        enabled: remindersRes.enabled
      });
    }
  };

  onDocumentClick = evt => {
    const { mounted } = this.state;
    if (!evt || !mounted) {
      return;
    }
  };

  openCalendar = () => {
    this.setState({
      calendar: true
    });
  };

  closeCalendar = () => {
    this.setState({
      calendar: false
    });
  };

  enableReminder = () => {
    const { enabled } = this.state;
    this.setState(
      {
        enabled: !enabled
      },
      () => {
        if (this.updateEnableObs) {
          this.updateEnableObs.next();
        }
      }
    );
  };

  onDateChange = utcInString => {
    if (!utcInString) {
      return;
    }

    const { hoursInStr } = this.state;
    const valueDate = new Date(utcInString);
    const [hh, mm] = getHoursAndMinutesFromHHMMA(hoursInStr);

    if (isNumber(hh) && isNumber(mm)) {
      valueDate.setHours(hh);
      valueDate.setMinutes(mm);
    }

    const currentDateUtc = valueDate.toISOString();

    this.setState(
      {
        currentDateUtc,
        calendar: false,
        currentDate: valueDate
      },
      () => {
        if (this.updateReminderObs) {
          this.updateReminderObs.next();
        }
      }
    );
  };

  onTimeChange = value => {
    const { currentDate } = this.state;
    const updateCurrentDate = currentDate;
    const [hh, mm] = getHoursAndMinutesFromHHMMA(value);

    if (isNumber(hh) && isNumber(mm)) {
      updateCurrentDate.setHours(hh);
      updateCurrentDate.setMinutes(mm);
    }

    const currentDateUtc = updateCurrentDate.toISOString();

    this.setState(
      { currentDateUtc, currentDate: updateCurrentDate, hoursInStr: value },
      () => {
        if (this.updateReminderObs) {
          this.updateReminderObs.next();
        }
      }
    );
  };

  updateEnableReminder = async () => {
    const { taskId = '', uRef = '' } = this.props;
    const { enabled, mounted, currentDateUtc } = this.state;

    if (!mounted) {
      return;
    }
    const taskIdLowerCase = `${taskId}`.toLowerCase();

    await ProfileAPI.USER_TASKS.enableOrDisableReminder(
      !!enabled,
      taskIdLowerCase,
      uRef
    );
    if (enabled && currentDateUtc) {
      await this.updateReminder();
    }
  };

  updateReminder = async () => {
    const { taskId = '', uRef = '' } = this.props;
    const { currentDateUtc, enabled, mounted, hoursInStr } = this.state;
    const reminder = {
      hours: hoursInStr,
      due: currentDateUtc,
      minutesBefore: [30, 60],
      remindSubscribers: true
    };

    if (!mounted) {
      return;
    }

    const taskIdLowerCase = `${taskId}`.toLowerCase();

    await ProfileAPI.USER_TASKS.updateReminder(
      !!enabled,
      [reminder],
      taskIdLowerCase,
      uRef
    );
  };

  toggleCalendarInput = () => {
    const { calendar } = this.state;
    if (!calendar) {
      this.openCalendar();
    } else {
      this.closeCalendar();
    }
  };

  render() {
    const { currentDate, enabled, calendar, fetching, hoursInStr } = this.state;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const dateInString = `${
      k.MONTHS_INDEX[currentDate.getMonth()].abv
    } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

    return (
      <div
        className={cx(styles.schedule_reminders, {
          [styles.hide_element]: fetching
        })}
      >
        <div className={styles.label}>
          <Checkbox
            checked={enabled}
            onChange={this.enableReminder}
            className={cx(styles.cb, {
              [styles.cb_dark]: isThemeDarkMode,
              [styles.cb_active_dark]: enabled && isThemeDarkMode
            })}
            label={i18n('common_reminder')}
          />
        </div>
        <div
          className={cx(styles.calendar, { [styles.hide_element]: !enabled })}
        >
          <div className={styles.calendar_input}>
            <div className={styles.calendar_input_value}>
              <Button appearance="minimal" onClick={this.toggleCalendarInput}>
                <h5>{dateInString}</h5>
              </Button>
            </div>
            <div className={styles.calendar_input_time}>
              <TimePicker
                onChange={this.onTimeChange}
                isOpen={false}
                value={hoursInStr}
                format={'hh:mm a'}
                disableClock
                clearIcon={<></>}
              />
            </div>
            <div className={styles.calendar_input_icon}>
              <IconButton
                onClick={this.toggleCalendarInput}
                className={styles.flex_row_xy}
                appearance="minimal"
                icon={CalendarIcon}
              />
            </div>
          </div>
          <div
            className={cx(
              styles.calendar_drop,
              this.calendarInputUniqueClassname,
              {
                [styles.hide_element]: !calendar
              }
            )}
          >
            <div
              className={cx(
                styles.close_calendar,
                styles.flex_row_xy,
                this.calendarInputUniqueClassname
              )}
            >
              <IconButton
                className={cx(
                  styles.close_calendar_button,
                  styles.flex_row_xy,
                  this.calendarInputUniqueClassname
                )}
                appearance="minimal"
                icon={CrossIcon}
                onClick={this.closeCalendar}
              />
            </div>
            <Calendar
              className={cx(this.calendarInputUniqueClassname)}
              maxDate={this.maxDate}
              minDate={this.minDate}
              onChange={this.onDateChange}
              value={currentDate}
              tileDisabled={({ activeStartDate, date, view }) => {
                if (view === 'decade') {
                  return this.currentDate.getFullYear() >= 2022
                    ? date.getFullYear() < this.currentDate.getFullYear()
                    : date.getFullYear() < 2022;
                } else if (view === 'month') {
                  return date.getTime() - this.currentDate.getTime() < -8.6e7; // 24 hours
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleReminders;
