import React from 'react';
import cx from 'classnames';
import styles from './AppLogoIcon.scss';
import AppAPI from 'src/app-manager/API';

const AppLogoIcon = props => {
  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      className={cx(styles.flex_row_xy, styles.app_logo_icon, {
        [props.className]: !!props.className
      })}
    >
      <div
        className={cx(styles.flex_row_xy, styles.fav_icon, {
          [styles.fav_icon_dark]: isThemeDarkMode
        })}
      >
        <div></div>
      </div>
      <div
        className={cx(styles.beta_title, {
          [styles.hide_element]: !props.showBeta || true
        })}
      >
        <h5>{'Beta'}</h5>
      </div>
    </div>
  );
};

export default AppLogoIcon;
