import React, { Component } from 'react';
import cx from 'classnames';
import styles from './Activities.scss';
import ProfileAPI from 'src/profile-manager/API';
import ActivityItem from './activity-item';
import UserAvatar from 'src/components/user-avatar';
import i18n from 'src/locales';

class Activities extends Component {
  state = {
    mounted: false,
    initialFetch: false,
    activities: [],
    page: 1 // current page
  };

  constructor() {
    super();

    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    const { loaded = false } = this.props;

    this.setState(
      {
        mounted: true
      },
      () => {
        if (loaded) {
          this.onLoad();
        }
      }
    );
  }

  componentWillUnmount() {
    this.setState({
      mounted: false
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.state.mounted && this.props.loaded) {
      this.onLoad();
    }
  }

  onLoad = async () => {
    const { initialFetch, mounted } = this.state;
    const { loaded = false, taskId = '', uRef = '' } = this.props;

    if (!mounted) {
      return;
    }

    if (initialFetch || !loaded) {
      return;
    }

    await this.setStateAsync({
      initialFetch: true
    });

    try {
      const activitiesFetchRes = await ProfileAPI.USER_TASKS.getActivities(
        1,
        taskId,
        uRef
      );

      if (!this.state.mounted) {
        return;
      }

      if (activitiesFetchRes && !activitiesFetchRes.err) {
        const { activities = [] } = activitiesFetchRes;

        this.storeActivities(activities);
      }
    } catch {}
  };

  storeActivities(activities = []) {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    }

    this.setState({
      activities
    });
  }

  render() {
    const { isLoggedIn } = this.props;
    const { activities } = this.state;

    return (
      <div className={styles.activities}>
        <div
          className={cx(styles.activities_need_login, styles.flex_row_xy, {
            [styles.hide_element]: isLoggedIn
          })}
        >
          <h5>{i18n('task_view_activities_not_logged_in')}</h5>
        </div>
        <ul className={cx({ [styles.hide_element]: !isLoggedIn })}>
          {activities
            .filter(activity => !!activity)
            .map((activity, idx) => {
              return (
                <li key={`activity-${idx}-${activity.ref_id}`}>
                  <div className={styles.item}>
                    <ActivityItem activity={activity} />
                  </div>
                  {activity.author && (
                    <UserAvatar
                      toFetch
                      source={'activity'}
                      profile_id={activity.author}
                      className={styles.author}
                    />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

export default Activities;
