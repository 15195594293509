import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './Header.scss';
import AppLogoIcon from 'src/components/app-logo-icon';
import ButtonClick from 'src/components/button-click';
import ProfileAPI from 'src/profile-manager/API';
import MessageHover from 'src/components/message-hover';
import MoreActions from './more';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import { withUserProfileSettings } from 'src/profile-manager';
import { Link } from 'react-router-dom';
import { Button, LinkIcon, Badge } from 'evergreen-ui';
import { Capacitor } from '@capacitor/core';

class Header extends Component {
  userDPDOMRef = null;
  localUserInfoStatusChangeSubscriber = null;

  state = {
    mounted: false,
    userInfoDone: false
  };

  constructor() {
    super();
    this.userDPDOMRef = createRef();
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    if (this.localUserInfoStatusChangeSubscriber) {
      this.localUserInfoStatusChangeSubscriber.unsubscribe();
    }

    this.setState({
      mounted: false
    });
  }

  onMount = () => {
    if (
      ProfileAPI.USER_DATA.checkIsFetchingInfo() ||
      (ProfileAPI.USER_DATA.info() && !ProfileAPI.USER_DATA.info().firstName)
    ) {
      this.localUserInfoStatusChangeSubscriber =
        ProfileAPI.USER_DATA.onProfileFetchingInfoStatus(
          this.onUserInfoStatusChange
        );
    } else {
      this.onUserInfoStatusChange();
    }
  };

  onUserInfoStatusChange = () => {
    const user = ProfileAPI.USER_DATA.info();

    if (user && user.firstName) {
      this.setState({
        userInfoDone: true
      });

      if (this.localUserInfoStatusChangeSubscriber) {
        this.localUserInfoStatusChangeSubscriber.unsubscribe();
        this.localUserInfoStatusChangeSubscriber = null;
      }
    }
  };

  render() {
    const {
      isLoggedIn,
      toggleTaskMore,
      exposure,
      loaded,
      copiedLink,
      taskMore,
      isOwner,
      isEditor,
      isShared,
      profileLoaded,
      onCopyLink,
      taskState,
      onUpdateState,
      updatingState,
      spaceInfo,
      readOnly
    } = this.props;
    const isIosMobile = Capacitor.getPlatform() === 'ios';
    const isThemeDarkMode = AppAPI.isDarkMode();
    const spaceImage = spaceInfo ? spaceInfo.image : '';
    const spaceName = spaceInfo ? spaceInfo.name : '';
    const underspace = !!spaceName || !!spaceImage;
    let hasDP = false;
    let initials = '...';

    if (isLoggedIn) {
      const user = ProfileAPI.USER_DATA.info();
      const { dp, firstName, lastName } = user;

      if (firstName && lastName) {
        initials = user.initials;
      }

      hasDP =
        dp &&
        typeof dp === 'string' &&
        dp.length > 0 &&
        dp.indexOf('data:') === 0;

      if (
        hasDP &&
        this.userDPDOMRef &&
        this.userDPDOMRef.current &&
        !this.userDPDOMRef.current.style.background
      ) {
        this.userDPDOMRef.current.style.background = `url(${dp})`;
        this.userDPDOMRef.current.style.backgroundRepeat = 'no-repeat';
        this.userDPDOMRef.current.style.backgroundPosition = 'center';
        this.userDPDOMRef.current.style.backgroundSize = 'cover';
      }
    }

    return (
      <div
        className={cx(styles.task_header, {
          [styles.task_header_dark]: isThemeDarkMode,
          [styles.task_header_ios]: isIosMobile
        })}
      >
        <div>
          <div className={styles.logo_wrap}>
            {!underspace && (
              <div className={cx(styles.chamu_logo, styles.flex_row_xy)}>
                <AppLogoIcon />
              </div>
            )}
            {underspace && (
              <div
                className={cx(styles.space_logo, styles.flex_row_xy, {
                  [styles.space_logo_dark]: isThemeDarkMode
                })}
              >
                {spaceImage && <img src={spaceImage} alt={'Space avatar'} />}
                <div className={styles.space_name}>
                  <h5>{`${spaceName}`}</h5>
                </div>
              </div>
            )}
            <div className={styles.cover}>
              <ButtonClick>
                <Link to={'/user'}></Link>
              </ButtonClick>
            </div>
          </div>
          <div
            className={cx(styles.redirect, {
              [styles.redirect_w_user]: isLoggedIn,
              [styles.redirect_no_user]: !isLoggedIn
            })}
          >
            <div
              className={cx(styles.copy_link, {
                [styles.copy_link_dark]: isThemeDarkMode
              })}
            >
              <Button
                className={styles.copy_link_button}
                appearance="minimal"
                onClick={() => {
                  if (typeof onCopyLink === 'function') {
                    return onCopyLink();
                  }
                }}
              >
                <div className={styles.ic}>
                  <LinkIcon />
                </div>
                <h5>{i18n('common_copy_link_clipboard')}</h5>
              </Button>
              {!copiedLink && !taskMore && (
                <MessageHover
                  message={i18n('task_view_copy_link_to_share')}
                  className={styles.copy_link_tip}
                />
              )}
            </div>
            {readOnly && (
              <div className={styles.read_only}>
                {' '}
                <Badge color="orange">Read only</Badge>{' '}
              </div>
            )}
            {!readOnly && (
              <MoreActions
                toggleTaskMore={toggleTaskMore}
                exposure={exposure}
                loaded={loaded}
                copiedLink={copiedLink}
                taskMore={taskMore}
                isLoggedIn={isLoggedIn}
                isOwner={isOwner}
                isEditor={isEditor}
                isShared={isShared}
                profileLoaded={profileLoaded}
                taskState={taskState}
                onUpdateState={onUpdateState}
                updatingState={updatingState}
              />
            )}
            <div
              className={cx(styles.login_signup, {
                [styles.hide_element]: isLoggedIn
              })}
            >
              <div
                className={cx(styles.login, {
                  [styles.login_dark]: isThemeDarkMode
                })}
              >
                <Button appearance="minimal" className={styles.login_button}>
                  <h5>{i18n('common_login_verb')}</h5>
                  <Link to={'/login'} />
                </Button>
              </div>{' '}
              <div
                className={cx(styles.signup, {
                  [styles.signup_dark]: isThemeDarkMode
                })}
              >
                <Button appearance="minimal" className={styles.signup_button}>
                  <h5>{i18n('common_signup_verb')}</h5>
                  <Link to={'/sign-up'} />
                </Button>
              </div>
            </div>
            <div
              className={cx(styles.redirect_avatar, {
                [styles.hide_element]: !isLoggedIn
              })}
            >
              <div
                ref={this.userDPDOMRef}
                className={cx({ [styles.hide_element]: !hasDP })}
              ></div>
              <h5 className={cx({ [styles.hide_element]: hasDP })}>
                {initials}
              </h5>
              <MessageHover
                className={styles.redirect_avatar_hover_msg}
                message={i18n('task_view_redirect_account_settings')}
              />
              <div className={styles.cover}>
                <Link to={'/user/account'}></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUserProfileSettings(Header);
