import React, { Component } from 'react';
import cx from 'classnames';
import styles from './ForgotPassword.scss';
import InitUserLoading from 'src/components/init-user';
import AppLogo from 'src/components/app-logo';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import i18n, { URLMarkdown } from 'src/locales';
import CustomButton from 'src/components/button';
import Store from 'src/lib/store';
import { withUserProfileSettings } from 'src/profile-manager';
import { AdjustableAbsolutWrap } from 'src/layout/commons';
import { Redirect } from 'react-router-dom';
import { isCorrectEmailFormat } from 'src/lib/UserInputs';
import { fromEvent } from 'rxjs';
import { TextInputField } from 'evergreen-ui';

class ForgotPassword extends Component {
  /**
   * To send link on email
   */

  localDocumentOnKeyDownSubscriber = null;
  resultsFromSubmitSubscriber = null;

  state = {
    mounted: false,
    submitted: false,
    status: '',
    email: '',
    emailInfo: {
      err: false,
      msg: ''
    },
    success: false
  };

  constructor() {
    super();
    this.onEmailInput = this.onEmailInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.resultsFromSubmitSubscriber = ProfileAPI.PUBLIC.onSubmitEmailForgotPW(
      this.onSubmitResults
    );
  }

  componentDidMount() {
    this.localDocumentOnKeyDownSubscriber = fromEvent(
      document ? document : window,
      'keydown'
    ).subscribe(this.onKeyDown);

    this.setState({
      mounted: true
    });
  }

  componentWillUnmount() {
    this.setState({
      mounted: false,
      success: false,
      submitted: false,
      status: ''
    });

    if (this.resultsFromSubmitSubscriber) {
      this.resultsFromSubmitSubscriber.unsubscribe();
    }

    if (this.localDocumentOnKeyDownSubscriber) {
      this.localDocumentOnKeyDownSubscriber.unsubscribe();
    }
  }

  onEmailInput(evt) {
    if (evt) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (target) {
        const { value = '' } = target;
        const { emailInfo, submitted, status } = this.state;

        this.setState({ email: value });

        if (!submitted && emailInfo.err) {
          emailInfo.err = false;
          emailInfo.msg = '';

          this.setState({ emailInfo });
        }

        if (status.length > 0 && !submitted) {
          this.setState({
            status: ''
          });
        }
      }
    }
  }

  onKeyDown(evt) {
    const { mounted, submitted } = this.state;

    if (submitted || !mounted) {
      return;
    } else if (evt && evt.keyCode === 13) {
      this.onSubmit();
    }
  }

  async onSubmit() {
    const { submitted, mounted, email, emailInfo } = this.state;
    if (submitted || !mounted || emailInfo.err) {
      this.setState({ status: '' });
    } else if (`${email}`.length <= 0 || !isCorrectEmailFormat(`${email}`)) {
      emailInfo.err = true;
      emailInfo.msg = i18n('user_forgot_password_invalid_email');

      this.setState({ emailInfo, status: '', submitted: false });
    } else if (!Store.OS.USER_ONLINE) {
      emailInfo.err = false;
      emailInfo.msg = '';

      this.setState({
        emailInfo,
        submitted: false,
        status: i18n('common_check_network')
      });
    } else {
      emailInfo.err = false;
      emailInfo.msg = '';

      await this.setStateAsync({
        emailInfo,
        submitted: true,
        status: i18n('user_forgot_password_submitted_status')
      });

      await ProfileAPI.PUBLIC.submitForgotPW(email);
    }
  }

  onSubmitResults = () => {
    const { mounted, submitted, emailInfo } = this.state;

    if (!mounted || !submitted) {
      return;
    } else {
      /**
       * Success to true
       */
      emailInfo.err = false;
      emailInfo.msg = '';

      this.setState({
        email: '',
        submitted: false,
        emailInfo,
        success: true
      });
    }
  };

  render() {
    const { emailInfo, email, success, submitted } = this.state;
    const { profileLoaded, isLoggedIn } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();

    if (!profileLoaded) {
      return <InitUserLoading />;
    } else if (isLoggedIn) {
      return <Redirect to={'/user'} />;
    }

    return (
      <AdjustableAbsolutWrap paddingBottom={0}>
        <div
          className={cx(styles.forgot_password_wrap, {
            [styles.forgot_password_wrap_dark]: isThemeDarkMode
          })}
        >
          <div className={styles.header}>
            <div className={styles.header_logo}>
              <AppLogo />
            </div>
            <div className={cx(styles.return, styles.flex_row_xy)}>
              <h4>
                {URLMarkdown(
                  'common_signup_or_login',
                  {
                    url1: {
                      link: '/sign-up'
                    },
                    url2: {
                      link: '/login'
                    }
                  },
                  '',
                  false
                )}
              </h4>
            </div>
          </div>
          <div
            className={cx(styles.flex_column_x, styles.on_success, {
              [styles.hide_element]: !success
            })}
          >
            <div className={styles.on_success_msg}>
              <h4>{i18n('user_forgot_password_success_check')}</h4>
            </div>
          </div>
          <div
            className={cx(styles.content, styles.flex_column_x, {
              [styles.hide_element]: success
            })}
          >
            <div className={styles.title}>
              <h2>{i18n('user_forgot_password_title')}</h2>
            </div>
            <div
              className={cx(styles.msg, { [styles.msg_dark]: isThemeDarkMode })}
            >
              <p>{i18n('user_forgot_password_message')}</p>
            </div>
            <div className={styles.email}>
              <div
                className={cx(styles.email_input, {
                  [styles.email_input_dark]: isThemeDarkMode
                })}
              >
                <TextInputField
                  label={i18n('common_email_address')}
                  value={email}
                  onChange={this.onEmailInput}
                  placeholder={i18n('user_forgot_password_input_placeholder')}
                  validationMessage={emailInfo.err && emailInfo.msg}
                />
              </div>
            </div>
            <div className={cx(styles.actions, styles.flex_row_xy)}>
              <CustomButton
                loading={submitted}
                disabled={submitted}
                label={i18n('user_forgot_password_confirm')}
                variant={'primaryPurple'}
                className={styles.confirm}
                onClick={this.onSubmit}
              />
            </div>
          </div>
        </div>
      </AdjustableAbsolutWrap>
    );
  }
}

export default withUserProfileSettings(ForgotPassword);
