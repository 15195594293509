import React, { useEffect } from 'react';
import cx from 'classnames';
import styles from './Emojis.scss';
import AppAPI from 'src/app-manager/API';
import { isString, isEmpty, isFunction, last, head } from 'src/helpers/utils';

const available = [
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f44d.png',
    character: `👍`,
    alt: 'emoji thumbs up',
    key: '1f44d'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f602.png',
    character: `😂`,
    alt: 'emoji laughing',
    key: '1f602'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f631.png',
    character: `😱`,
    alt: 'emoji 1f631',
    key: '1f631'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f923.png',
    character: `🤣`,
    alt: 'emoji 1f923',
    key: '1f923'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f62d.png',
    character: `😭`,
    alt: 'emoji 1f62d',
    key: '1f62d'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f60a.png',
    character: `😊`,
    alt: 'emoji 1f60a',
    key: '1f60a'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f601.png',
    character: `😁`,
    alt: 'emoji 1f601',
    key: '1f601'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f4af.png',
    character: `💯`,
    alt: 'emoji 1f4af',
    key: '1f4af'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f44f.png',
    character: `👏`,
    alt: 'emoji 1f44f',
    key: '1f44f'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f4aa.png',
    character: `💪`,
    alt: 'emoji 1f4aa',
    key: '1f4aa'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f44b.png',
    character: `👋`,
    alt: 'emoji 1f44b',
    key: '1f44b'
  },

  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f44c.png',
    character: `👌`,
    alt: 'emoji 1f44c',
    key: '1f44c'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/2705.png',
    character: `✅`,
    alt: 'emoji f2705',
    key: 'f2705'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f680.png',
    character: `🚀`,
    alt: 'emoji 1f680',
    key: '1f680'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/2764.png',
    character: `❤️`,
    alt: 'emoji 2764',
    key: '2764'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f382.png',
    character: `🎂`,
    alt: 'emoji 1f382',
    key: '1f382'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f440.png',
    character: `👀`,
    alt: 'emoji 1f440',
    key: '1f440'
  },
  {
    link: 'https://twemoji.maxcdn.com/v/13.0.1/72x72/1f525.png',
    character: `🔥`,
    alt: 'emoji 1f525',
    key: '1f525'
  }
];

const Emojis = ({ className = '', Quill, v4Id = '', close = null }) => {
  const isThemeDarkMode = AppAPI.isDarkMode();
  const uniqueClassname = `emoji-comment-${v4Id}`;

  useEffect(() => {
    const onDocumentClick = evt => {
      const target = evt?.target || evt?.srcElement;
      if (target) {
        if (
          !(
            target &&
            target.classList &&
            target.classList?.length > 0 &&
            target.classList?.contains &&
            target.classList.contains(uniqueClassname)
          )
        ) {
          if (isFunction(close)) {
            close();
          }
        }
      } else {
        if (isFunction(close)) {
          close();
        }
      }
    };

    document.addEventListener('click', onDocumentClick, false);

    return () => {
      document.removeEventListener('click', onDocumentClick, false);
    };
  }, [uniqueClassname, close]);

  const insert = evt => {
    try {
      if (Quill) {
        const target = evt?.target || evt?.srcElement;

        if (
          target &&
          target.classList &&
          target.classList?.contains &&
          target.classList.contains('emoji-pick')
        ) {
          const selected = last(target.classList);
          const range = Quill.get().getSelection(true);
          const Delta = window.Quill.import('delta');
          const update = new Delta().retain(range.index).delete(range.length);
          const newRange = range.index + 3;
          const chars = available.filter(emoji => emoji.key === selected);
          const char = head(chars);
          const QuillJs = window.Quill;

          if (char && char.character) {
            Quill.get().updateContents(
              update.insert(` ${window.decodeURI(char.character)} `),
              QuillJs.sources.USER
            );
            Quill.get().setSelection(newRange);
            // Quill.get().setSelection(newRange, QuillJs.sources.SILENT); 
          }

          if (isFunction(close)) {
            close();
          }
        }
      }
    } catch {}
  };

  return (
    <div
      className={cx(styles.emojis, uniqueClassname, {
        [styles.emojis_dark]: isThemeDarkMode,
        [className]: isString(className) && !isEmpty(className)
      })}
    >
      {' '}
      <ul onClick={insert} className={cx(uniqueClassname)}>
        {available
          .filter(emoji => emoji?.key && emoji?.alt && emoji?.character)
          .map(emoji => {
            return (
              <li
                key={`emoji-${emoji?.key}`}
                className={cx(uniqueClassname, 'emoji-pick', emoji.key)}
              >
                <img
                  className={cx(uniqueClassname, 'emoji-pick', emoji.key)}
                  src={emoji.link}
                  alt={emoji.alt}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Emojis;
