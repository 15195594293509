import React, { Component, forwardRef, createContext } from 'react';
import cx from 'classnames';
import styles from './ErrorManager.scss';
import { isString } from 'src/helpers/utils';
import { CrossIcon, IconButton } from 'evergreen-ui';

const { Consumer, Provider } = createContext();

export function withErrorManagerSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {value => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class ErrorManager extends Component {
  // @todo show error message for dev reference
  state = { errorMessage: [], enabled: true };

  setErrorMessage = (message = '', stack = '') => {
    const { errorMessage } = this.state;

    if (isString(message) && message) {
      this.setState({
        enabled: true,
        errorMessage: [{ message, stack }, ...errorMessage]
      });
    }
  };

  clearError = () => {
    this.setState({
      errorMessage: [],
      enabled: false
    });
  };

  render() {
    const { errorMessage, enabled } = this.state;
    const { children } = this.props;
    const showError = errorMessage.length > 0;

    return (
      <Provider
        value={{
          errorMessage,
          clearError: this.clearError,
          setErrorMessage: this.setErrorMessage,
          errorMessageEnabled: enabled
        }}
      >
        {children}
        {enabled && (
          <div
            className={cx(styles.error_manager, {
              [styles.hide_element]: !showError
            })}
          >
            <div className={cx(styles.error_message, styles.flex_column_x)}>
              <ul>
                {errorMessage
                  .filter(p => p && (p.stack || p.message))
                  .map(({ message, stack }, index) => {
                    return (
                      <li
                        key={`err-msg-${index}-${`${message}`.substring(0, 4)}`}
                      >
                        {stack && <h5>{stack}</h5>}
                        <p>{message}</p>
                      </li>
                    );
                  })}
              </ul>
              <div className={styles.close}>
                <IconButton
                  appearance="minimal"
                  className={styles.close_button}
                  icon={CrossIcon}
                  onClick={() => this.clearError()}
                />
              </div>
            </div>
          </div>
        )}
      </Provider>
    );
  }
}

export default ErrorManager;
