import React, { Component } from 'react';
import cx from 'classnames';
import styles from './AccessTaskView.scss';
import ModalAPI from 'src/modal-manager/API';
import ProfileAPI from 'src/profile-manager/API'; 
import LoadSpinner from 'src/components/load-spinner';
import i18n, { URLMarkdown } from 'src/locales';
import { IconButton, CrossIcon } from 'evergreen-ui';

class AccessTaskView extends Component {
  onIDInfoSubscriber = null;

  state = {
    complete: false,
    mounted: false,
    taskID: '',
    hasAccess: [],
    universalRef: ''
  };

  constructor(props) {
    super(props);

    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.cancel = this.cancel.bind(this);
    this.onAccessFromEmpty = this.onAccessFromEmpty.bind(this);
    this.onIDInfo = this.onIDInfo.bind(this);
    this.state.complete = !!props.complete;
    this.state.taskID = props.taskID;
    this.state.universalRef = props.universalRef;
  }

  componentDidMount() {
    this.onIDInfoSubscriber = ProfileAPI.USER_PROFILE.onIDInfo(this.onIDInfo);
    this.setState(
      {
        mounted: true
      },
      this.onMount.bind(this)
    );
  }

  componentWillUnmount() {
    if (this.onIDInfoSubscriber) {
      this.onIDInfoSubscriber.unsubscribe();
    }

    this.setState({
      mounted: false
    });
  }

  async onMount() {
    const { complete } = this.state;

    if (!complete) {
      /**
       * Fetch from taskID + universalRef
       */
    } else {
      await this.setStateAsync({
        hasAccess: this.props.hasAccess
      });
    }
  }

  cancel() {
    if (typeof this.props.close === 'function') {
      this.props.close();
    } else {
      ModalAPI.goDeactivate();
    }
  }

  onAccessFromEmpty(evt) {
    if (evt) {
      evt.preventDefault();
    }
  }

  onIDInfo({
    id: profileID,
    valid = false,
    has_image = false,
    image = '',
    firstName = '',
    lastName = ''
  }) {
    const { mounted, hasAccess } = this.state;

    if (!mounted || hasAccess.length < 1) {
      return;
    } else if (valid) {
      let hasChange = false;

      for (let i = 0; i < hasAccess.length; i++) {
        const wAccess = hasAccess[i];

        if (wAccess && wAccess.loading && wAccess.id === profileID) {
          wAccess.has_image = has_image;
          wAccess.image = has_image ? image : '';
          wAccess.initials = `${firstName.charAt(0)}${lastName.charAt(
            0
          )}`.toLowerCase();

          wAccess.firstName = firstName;
          wAccess.lastName = lastName;
          wAccess.loading = false;

          if (!hasChange) {
            hasChange = true;
          }
        }
      }

      if (hasChange) {
        this.setState({
          hasAccess
        });
      }

      if (typeof this.props.modifyHasAccess === 'function') {
        this.props.modifyHasAccess(hasAccess);
      }
    }
  }

  render() {
    const { complete, hasAccess } = this.state;
    const isEmpty = hasAccess.length < 1 && complete;

    return (
      <div className={cx(styles.access_task_view, styles.flex_row_y)}>
        <div className={styles.actual}>
          <div className={styles.title}>
            <h4>{i18n('user_view_task_access_title')}</h4>
          </div>
          <div
            className={cx(styles.empty, { [styles.hide_element]: !isEmpty })}
          >
            <h5>
              {URLMarkdown('user_view_task_access_empty', {
                url1: { onClick: this.onAccessFromEmpty }
              })}
            </h5>
          </div>
          <div className={cx(styles.list, { [styles.hide_element]: isEmpty })}>
            <ul>
              {hasAccess.map((h, idx) => {
                return (
                  <li key={`access-task-${idx}-${h.id}`}>
                    <div className={styles.avatar}>
                      <div
                        {...(h.has_image && {
                          style: {
                            background: `url(${h.image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }
                        })}
                        className={cx({ [styles.hide_element]: h.loading })}
                      >
                        {!h.has_image && (
                          <h5>{`${h.initials}`.toUpperCase()}</h5>
                        )}
                      </div>
                      <div
                        className={cx(styles.avatar_spinner, {
                          [styles.hide_element]: !h.loading
                        })}
                      >
                        <div className={styles.avatar_spinner_raw}>
                          <LoadSpinner
                            className={styles.avatar_spinner_raw_spin}
                          />
                        </div>
                      </div>
                    </div>
                    <p className={styles.name}>
                      {h.loading || !h.firstName
                        ? `${h.id}`
                        : `${h.firstName} ${h.lastName}`}
                    </p>
                    <p className={styles.list_access_type}>
                      {i18n('user_modify_task_access_subscriber_title')}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className={cx(styles.list_placeholder, {
              [styles.hide_element]: complete
            })}
          >
            <ul>
              <li></li>
              <li></li>
            </ul>
          </div>

          <div className={styles.close}>
            <IconButton
              className={styles.close_button}
              appearance="minimal"
              icon={CrossIcon}
              onClick={this.cancel}
            />
          </div> 
        </div>
      </div>
    );
  }
}

export default AccessTaskView;
