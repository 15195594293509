import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './MarksH2.scss';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import { isEmpty } from 'src/helpers/utils';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class MarksH2 extends Component {
  marksH2WrapRef = null;
  onWindowScrollSubscriber = null;
  marksRef = [];
  marksListUnique = 'marks-list-unique-item';
  marksListRef = null;
  marksListClickSubscriber = null;
  scrollEvtBlock = false;
  scrollFromClick = false;

  state = {
    mounted: false,
    texts: [],
    selected: 0
  };

  constructor() {
    super();

    this.marksH2WrapRef = createRef(null);
    this.marksListRef = createRef(null);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.onWindowScrollSubscriber = fromEvent(window, 'scroll')
      .pipe(debounceTime(200))
      .subscribe(this.onScroll);

    if (this.marksListRef && this.marksListRef.current) {
      this.marksListClickSubscriber = fromEvent(
        this.marksListRef.current,
        'click'
      ).subscribe(this.onMarkClick);
    }
    this.setState(
      {
        mounted: true
      },
      this.onLoad
    );
  }

  componentWillUnmount() {
    if (this.marksListClickSubscriber) {
      this.marksListClickSubscriber.unsubscribe();
    }

    if (this.onWindowScrollSubscriber) {
      this.onWindowScrollSubscriber.unsubscribe();
    }

    this.setState({
      mounted: false
    });

    this.marksRef.length = 0;
  }

  onScroll = () => {
    const { mounted, texts, selected } = this.state;

    if (!mounted || window.innerWidth < 900) {
      return;
    } else if (this.scrollEvtBlock) {
      if (this.scrollFromClick) {
        this.scrollFromClick = false;
      }

      return;
    } else if (texts.length > 0 && this.marksRef.length > 0) {
      if (this.scrollFromClick) {
        this.scrollFromClick = false;
        return;
      }
      const y = window.scrollY;
      const offsets = this.marksRef
        .filter(r => r && r.current)
        .map(r => {
          return r.current.offsetTop;
        });

      if (
        y * 2 >=
        document.documentElement.scrollHeight -
          window.innerHeight +
          window.pageYOffset
      ) {
        this.setState({
          selected: 0
        });
        return;
      }

      for (let i = offsets.length - 1; i >= 0; i--) {
        const cset = offsets[i];

        if (y < offsets[0]) {
          this.setState({
            selected: 0
          });
          return;
        }

        if (y > offsets[offsets.length - 1] && y - cset >= 250) {
          this.setState({
            selected: 0
          });

          return;
        }

        if (cset + 10 <= y) {
          if (selected !== i + 1) {
            this.setState({ selected: i + 1 });
          }

          return;
        }
      }

      this.setState({
        selected: 0
      });
    }
  };

  onLoad = async () => {
    const target = document.querySelector('#cha-task-view-des-editor');
    const texts = [];

    if (target) {
      const h2s = target.querySelectorAll('h2');
      this.marksRef.length = 0;

      if (!isEmpty(h2s)) {
        for (const h2c of h2s) {
          if (h2c) {
            const nRef = createRef();
            nRef.current = h2c;

            if (!isEmpty(h2c.textContent || '')) {
              texts.push(`${h2c.textContent}`);
              this.marksRef.push(nRef);
            }
          }
        }
      }

      await this.setStateAsync({
        texts
      });

      if (texts.length) {
        const { innerHeight } = window;
        const estH = texts.length * 35; // height per mark + margin + padding
        const marksH2WrapRef = this.marksH2WrapRef;

        if (
          innerHeight - 280 < estH &&
          marksH2WrapRef &&
          marksH2WrapRef.current
        ) {
          marksH2WrapRef.current.style.overflowY = 'scroll';
          marksH2WrapRef.current.style.paddingBottom = '25px';
          marksH2WrapRef.current.style.maxHeight = `${innerHeight - 280}px`;
        }
      }
    }
  };

  onMarkClick = evt => {
    const { mounted } = this.state;

    if (evt && mounted) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (
        target &&
        target.classList &&
        target.classList.length &&
        target.classList.contains &&
        target.classList.contains(this.marksListUnique)
      ) {
        const cn = Number(target.classList[target.classList.length - 1]);

        if (typeof cn === 'number' && cn > -1 && this.marksRef) {
          try {
            this.scrollEvtBlock = true;

            if (cn === 0) {
              this.setState({
                selected: 0
              });
              this.scrollFromClick = true;
              if (window && window.scrollTo) {
                window.scrollTo(0, 0);
              }

              this.scrollEvtBlock = false;
              return;
            }

            const cref = this.marksRef[cn - 1];

            if (cref && cref.current) {
              const toOffset = cref.current.offsetTop;
              this.scrollFromClick = true;

              if (window && window.scrollTo) {
                window.scrollTo(0, toOffset + 20);
              }

              this.setState({
                selected: cn
              });
            }
          } catch (err) {
            console.error(err);
          }

          this.scrollEvtBlock = false;
        }
      }
    }
  };

  render() {
    const { texts, selected } = this.state;
    const now = Math.ceil(Date.now() / 1000);
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div
        className={cx(styles.marks_h2, {
          [styles.marks_h2_dark]: isThemeDarkMode
        })}
        ref={this.marksH2WrapRef}
      >
        <ul
          ref={this.marksListRef}
          className={cx({ [styles.hide_element]: texts.length < 1 })}
        >
          <li
            className={cx(styles.hide_element, {
              [styles.is_active]: selected === 0
            })}
          >
            <h5 className={cx(this.marksListUnique, '0')}>
              {i18n('user_task_view_mark_description_title')}
            </h5>
          </li>
          {texts.map((text, idx) => {
            const trueIdx = idx + 1;

            return (
              <li
                className={cx({ [styles.is_active]: selected === trueIdx })}
                key={`task-view-mark-h2-${now}-${idx}`}
              >
                <h5 className={cx(this.marksListUnique, `${trueIdx}`)}>
                  {text}
                </h5>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default MarksH2;
