import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './TaskDescription.scss';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import TaskFormats from './task-formats';
import Logger from 'src/lib/Logger';
import TaskProperties from './TaskProperties';
import UserDOM from 'src/lib/UserDOM';
import QuillTemplate from 'src/lib/quill-template';
import MarksHeading2 from './marks-heading2';
import Files from './files';
import Mention from './mention';
import k from 'src/constants/k';
// import ModalAPI from 'src/modal-manager/API';
// import ProfileAPI from 'src/profile-manager/API';
import {
  head,
  isNil,
  isYTURL,
  isTwitterStatusURL,
  isWebURL,
  isString,
  isFunction,
  isWhitespace,
  toString,
  includes,
  isValidFileLink,
  isNumber,
  isMobileView,
  isArray,
  isValidObject,
  descriptionTextContents,
  filter,
  isEmpty,
  toLower,
  isIOSMobile
} from 'src/helpers/utils';
import { Capacitor } from '@capacitor/core';
import { getMatchWhitelistUrls } from 'src/helpers/urls';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UploadIcon } from 'evergreen-ui';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'src/lib/bodyScrollLock.min';

/**
 * Custom blot specific
 */
import 'src/lib/formats/YoutubeBlot.css';

class TaskDescription extends Component {
  descriptionEditableRef = null;
  descriptionEditableClickSubscriber = null;
  descriptionEditorChangeOBS = null;
  descriptionEditorClickOBS = null;
  descriptionEditorSaveDraftOBS = null;
  descriptionCursorSaveOBS = null;
  descriptionEditorSaveDraftSubscriber = null;
  descriptionCursorSaveSubscriber = null;
  onDescriptionKeydownSubscriber = null;
  descriptionScrollTopMeta = {
    valid: false,
    value: 0
  };
  QuillEditor = null;

  state = {
    ready: false,
    preview: false,
    inputFocus: false,
    mounted: false,
    showMention: false,
    showMentionRange: null,
    Quill: null,
    bodyScrollDisabled: false,
    cursorBounds: null
  };

  constructor(props) {
    super(props);

    this.descriptionEditorChangeOBS = new Subject();
    this.descriptionEditorClickOBS = new Subject();
    this.descriptionCursorSaveOBS = new Subject();
    this.descriptionEditorSaveDraftOBS = props.descriptionEditorSaveDraftOBS;
    this.descriptionEditableRef = createRef();
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onEditableClicked = this.onEditableClicked.bind(this);
    this.getDescriptionDOM = this.getDescriptionDOM.bind(this);
    this.removeAll = this.removeAll.bind(this);
    this.onInputPaste = this.onInputPaste.bind(this);

    if (this.descriptionEditorSaveDraftOBS) {
      this.descriptionEditorSaveDraftSubscriber =
        this.descriptionEditorSaveDraftOBS
          .pipe(debounceTime(500))
          .subscribe(this.onSavingDraft);
    }

    if (!window.getQuillEditor) {
      window.getQuillEditor = this.getQuillEditor;
    }
  }

  componentDidMount() {
    this.setState({
      mounted: true
    });

    this.descriptionCursorSaveSubscriber = this.descriptionCursorSaveOBS
      .pipe(debounceTime(200))
      .subscribe(this.storeCursorBounds);

    if (this.descriptionEditableRef.current) {
      this.onDescriptionKeydownSubscriber = fromEvent(
        this.descriptionEditableRef.current,
        'keydown'
      ).subscribe(this.onDescriptionInputKeydown);
    }
  }

  componentDidUpdate() {
    const { editProps } = this.props;
    const { mounted, ready, bodyScrollDisabled } = this.state;

    if (
      !ready ||
      !mounted ||
      !isMobileView() ||
      !this.descriptionEditableRef?.current?.firstElementChild
    ) {
      return;
    }

    const parent = this.descriptionEditableRef.current;
    const child = parent?.firstElementChild;

    if (bodyScrollDisabled && editProps) {
      this.setState(
        {
          bodyScrollDisabled: false
        },
        () => {
          enableBodyScroll(child);
          enableBodyScroll(parent);
        }
      );
    } else if (!bodyScrollDisabled && !editProps) {
      this.setState(
        {
          bodyScrollDisabled: true
        },
        () => {
          disableBodyScroll(child, {
            allowTouchMove: el =>
              el &&
              (el?.id === 'editor-task-formats' ||
                el?.id === 'create-task-mention-wrap')
          });
          disableBodyScroll(parent, {
            allowTouchMove: el =>
              el &&
              (el?.id === 'editor-task-formats' ||
                el?.id === 'create-task-mention-wrap')
          });
        }
      );
    }
  }

  componentWillUnmount() {
    this.setState({
      ready: false,
      mounted: false
    });

    if (this.descriptionEditableRef?.current) {
      const parent = this.descriptionEditableRef.current;
      const child = parent?.firstElementChild;
      enableBodyScroll(child);
      enableBodyScroll(parent);
    }

    if (this.descriptionCursorSaveSubscriber) {
      this.descriptionCursorSaveSubscriber.unsubscribe();
    }

    if (this.onDescriptionKeydownSubscriber) {
      this.onDescriptionKeydownSubscriber.unsubscribe();
    }

    if (this.descriptionEditorSaveDraftSubscriber) {
      this.descriptionEditorSaveDraftSubscriber.unsubscribe();
    }

    if (this.state.Quill) {
      this.state.Quill.destroy();

      delete this.state.Quill;
    }

    if (this.descriptionEditableClickSubscriber) {
      this.descriptionEditableClickSubscriber.unsubscribe();
    }

    this.QuillEditor = null;
    this.descriptionEditableRef = null;

    delete this.descriptionEditorChangeOBS;
    delete this.descriptionEditorClickOBS;
    delete window.getQuillEditor;
  }

  onDescriptionInputKeydown = evt => {
    if (!evt) {
      return;
    }

    const { showMention } = this.state;

    if (
      (evt.shiftKey && evt.key === '@') ||
      (isMobileView() && evt.keyCode === 50)
    ) {
      this.promptMentions();
    } else if (evt.keyCode === 27 && showMention) {
      // esc character
      this.hideMentions();
    }
  };

  promptMentions = (range = null) => {
    const { Quill } = this.state;

    if (!Quill) {
      return;
    }

    const QuillEditor = Quill.get();
    const currentRange = QuillEditor.getSelection(true);
    this.setState({
      showMention: true,
      showMentionRange: range || currentRange
    });
  };

  hideMentions = () => {
    this.setState({
      showMention: false,
      showMentionRange: null
    });
  };

  getQuillEditor = () => {
    const { Quill } = this.state;

    try {
      if (Quill && Quill.get()) {
        return Quill.get();
      }

      return null;
    } catch {
      return null;
    }
  };

  getDescriptionDOM() {
    return this.descriptionEditableRef.current;
  }

  onInputPaste() {
    Logger.log('Something pasted');
  }

  onSelectionChange = range => {
    const {
      cursorBounds: currentCursorBounds,
      showMention,
      showMentionRange
    } = this.state;
    if (!range || range === null) {
      this.onBlur();

      if (currentCursorBounds || showMention || showMentionRange) {
        this.setState(
          {
            cursorBounds: null
          },
          this.hideMentions
        );
      }
    } else {
      this.onFocus();
      const {
        cursorBounds: prevCursorBounds,
        showMentionRange,
        showMention
      } = this.state;
      const QuillEditor = this.getQuillEditor();

      if (!QuillEditor) {
        return;
      }

      const descriptionContents = QuillEditor.getContents();
      const fullTextOnly = toString(
        descriptionTextContents(descriptionContents)
      );
      const cursorBounds = QuillEditor.getBounds(range?.index);
      const lastIndexOfAtKey = fullTextOnly.lastIndexOf('@');
      const substringCheckForAtMention =
        fullTextOnly && lastIndexOfAtKey < range.index
          ? fullTextOnly.substring(lastIndexOfAtKey, range.index)
          : '';

      if (isNumber(range?.index)) {
        // bounds for @mention

        if (!isNil(cursorBounds)) {
          this.setState({ cursorBounds });
        }
      }

      if (
        ((isValidObject(showMentionRange) &&
          showMentionRange.index >= range.index) ||
          prevCursorBounds?.bottom !== cursorBounds?.bottom ||
          prevCursorBounds?.top !== cursorBounds?.top) &&
        showMention
      ) {
        this.hideMentions();
      } else if (
        isNumber(lastIndexOfAtKey) &&
        lastIndexOfAtKey > -1 &&
        !showMention &&
        !showMentionRange &&
        substringCheckForAtMention &&
        !substringCheckForAtMention.includes(' ') &&
        substringCheckForAtMention.length < 16
      ) {
        this.promptMentions({ index: lastIndexOfAtKey });
      }
    }
  };

  onFocus() {
    const { ready, inputFocus, Quill } = this.state;

    if (!ready) {
      return;
    }

    if (!inputFocus && Quill.get().hasFocus()) {
      this.setState({
        inputFocus: true
      });
    }
  }

  onBlur() {
    const { ready, inputFocus, Quill } = this.state;

    if (!ready) {
      return;
    }

    if (inputFocus && !Quill.get().hasFocus()) {
      this.setState(
        {
          inputFocus: false,
          cursorBounds: null
        },
        this.hideMentions
      );
    }
  }

  checkForWebPreview = (url = '') => {
    if (!url || !window) {
      return;
    }

    const Quill = window.Quill;
    const QuillEditor = this.getQuillEditor();

    if (!QuillEditor || !Quill) {
      return;
    }

    const Delta = Quill.import('delta');
    const range = QuillEditor.getSelection(true);
    const update = new Delta().retain(range.index).delete(range.length);
    const firstidx = url.indexOf('https://');
    const lastidx = url.lastIndexOf('https://');
    // const _Delta = new Delta();
    const possibleLastURL = url.substring(lastidx, url.length);
    const finalURL =
      lastidx !== firstidx &&
      lastidx > 0 &&
      url.length > lastidx &&
      isWebURL(possibleLastURL)
        ? possibleLastURL
        : url;
    url = `${url || finalURL}`;
    // const newRange = range.index + (url && url.length ? url.length : 1);

    if (
      lastidx !== firstidx &&
      lastidx > 0 &&
      url.length > lastidx &&
      isWebURL(possibleLastURL)
    ) {
      // _Delta.insert(url.substring(0, lastidx));
      // _Delta.insert(possibleLastURL, {
      //   link: url
      // });
      QuillEditor.updateContents(
        update.insert(url.substring(0, lastidx)),
        update.insert(possibleLastURL, {
          link: url || finalURL
        }),
        Quill.sources.USER
      );
    } else {
      // _Delta.insert(url, { link: url });
      QuillEditor.updateContents(
        update.insert(url, {
          link: url || finalURL
        }),
        Quill.sources.USER
      );
    }

    // was experimental
    // const res = await ProfileAPI.PUBLIC.getSiteInfo(finalURL);
    // if (res && !res.err && res.ogs && res.ogs.title && res.ogs.description) {
    //   // use web-preview blot
    //   const range = QuillEditor.getSelection(true);
    //   const newRange = range.index + 2;
    //   const update = new Delta().retain(range.index).delete(range.length);

    //   QuillEditor.updateContents(
    //     // update.insert('\n'),
    //     update.insert({
    //       webpreview: {
    //         url: finalURL,
    //         title: res.ogs.title,
    //         description: res.ogs.description
    //       }
    //     }),
    //     Quill.sources.USER
    //   );

    //   QuillEditor.setSelection(newRange, Quill.sources.SILENT);
    // }
  };

  confirmTaskFormats = (
    imageHandler = null,
    ImagesHelper = null,
    cb = function () {}
  ) => {
    const { unmarkIsUploadingImage, markIsUploadingImage } = this.props;
    const domId =
      this.descriptionEditableRef.current.id ||
      this.descriptionEditableRef.current.getAttribute('id');
    const toolbarId = TaskProperties.taskDescriptionToolbarID;
    const Quill = new QuillTemplate(domId);
    // const checkForWebPreview = this.checkForWebPreview;
    if (ImagesHelper) {
      ImagesHelper.setMarkIsUploadingImage(markIsUploadingImage);
      ImagesHelper.setUnmarkIsUploadingImage(unmarkIsUploadingImage);
    }

    if (Quill) {
      Quill.create('', toolbarId, {
        modules: { syntax: true },
        placeholder: i18n('user_create_task_description_placeholder'),
        handlers: {
          ...(isFunction(imageHandler) && { image: imageHandler })
        }
      });

      Quill.setName('CreateOrEdit');
      Quill.setNotAllowedHTMLs(TaskProperties.NOT_ALLOWED_HTMLS);

      if (this.descriptionEditableRef && this.descriptionEditableRef.current) {
        if (this.descriptionEditableRef.current) {
          Logger.log(`Setting task description input ondragstart`);

          this.descriptionEditableRef.current.ondragstart = function (evt) {
            if (!evt) {
              return;
            }

            const target = evt.target
              ? evt.target
              : evt.srcElement
              ? evt.srcElement
              : null;

            if (target && target.nodeName.toLowerCase()) {
              evt.preventDefault();
              return false;
            }
          };
        }
      }

      Quill.get().clipboard.addMatcher('IMG', function (node, delta) {
        if (delta && delta.ops && delta.ops[0] && delta.ops[0].insert) {
          if (delta.ops[0].insert.image) {
            if (isValidFileLink(delta.ops[0].insert.image)) {
              const Delta = window.Quill.import('delta');
              return new Delta([
                { insert: { image: delta.ops[0].insert.image } },
                { insert: '' }
              ]);
            }

            if (ImagesHelper && ImagesHelper.insertImageBase64) {
              ImagesHelper.insertImageBase64(
                delta.ops[0].insert.image,
                markIsUploadingImage,
                unmarkIsUploadingImage
              );
            }
          }

          delete delta.ops[0].insert;
        }

        const Delta = window.Quill.import('delta');
        return new Delta([{ insert: '' }]);
      });

      Quill.get().clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
        try {
          const Delta = window.Quill.import('delta');
          const text = toString(node?.textContent);
          const regex = /https?:\/\/[^\s]+/g;
          const matches = text.match(regex);
          const whitelistUrlMatches = getMatchWhitelistUrls(text);

          if (isYTURL(text)) {
            const currentDelta = new Delta();
            currentDelta.insert({ youtube: node.textContent });
            return currentDelta;
          } else if (isTwitterStatusURL(text)) {
            const currentDelta = new Delta();

            currentDelta.insert('\n');
            currentDelta.insert({ twitter: { url: node.textContent } });

            return currentDelta;
          } else if (
            (matches && matches.length) ||
            whitelistUrlMatches?.length
          ) {
            const ops = [];
            const currentMatches = matches?.length
              ? matches
              : whitelistUrlMatches;
            let str = text;

            for (let i = 0; i < currentMatches.length; i++) {
              const match = currentMatches[i];
              const split = str.split(match);
              const beforeLink = split.shift();
              ops.push({ insert: beforeLink });
              ops.push({ insert: match, attributes: { link: match } });
              str = split.join(match);
            }

            ops.push({ insert: str });
            const currentDelta = new Delta(ops);
            return currentDelta;
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/pagehide_event
            // checkForWebPreview(`${node.textContent}`);  was experimental just don't remove yet
          }
        } catch (err) {
          if (err.stack) {
            Logger.log(err.stack);
          }
        }

        return delta;
      });

      Quill.get().on('text-change', function (delta) {
        // params delta, prevDelta, source

        if (
          delta?.ops?.length &&
          delta.ops.length === 2 &&
          delta.ops[0].retain &&
          isWhitespace(delta.ops[1].insert)
        ) {
          // check if user is typing in a valid url
          const regex = /https?:\/\/[^\s]+$/g;
          const endRetain = delta.ops[0].retain;
          const text = Quill.get().getText().substr(0, endRetain);
          const match = text.match(regex);
          const whitelistUrlMatches = getMatchWhitelistUrls(text);

          if (match !== null || whitelistUrlMatches?.length) {
            const url =
              match !== null
                ? toString(head(match))
                : toString(head(whitelistUrlMatches));
            const ops = [];

            if (endRetain > url.length) {
              ops.push({ retain: endRetain - url.length });
            }

            Quill.get().updateContents({
              ops: ops.concat([
                { delete: url.length },
                {
                  insert: url,
                  attributes: {
                    link:
                      includes(url, 'https') || includes(url, 'http')
                        ? url
                        : `https://${url}`
                  }
                }
              ])
            });
          }
        }
      });

      Quill.onSelectionChange(this.onSelectionChange);
      Quill.onEditorChange(this.editorChange);

      this.QuillEditor = Quill.get();
      this.setState(
        {
          Quill
        },
        () => {
          const { Quill } = this.state;
          const { confirmTaskDescription } = this.props;

          if (isFunction(cb)) {
            cb();
          }

          if (isFunction(confirmTaskDescription)) {
            this.descriptionEditableClickSubscriber = fromEvent(
              this.descriptionEditableRef.current,
              'click'
            ).subscribe(this.editableClicked);
            this.setState(
              {
                ready: true
              },
              () => {
                window.scrollTo(0, 0);
                confirmTaskDescription(Quill.get());
                this.storeCursorBounds();

                if (this.descriptionEditableRef?.current) {
                  const child =
                    this.descriptionEditableRef.current?.firstElementChild;

                  if (isMobileView()) {
                    this.setState(
                      {
                        bodyScrollDisabled: true
                      },
                      () => {
                        disableBodyScroll(child, {
                          allowTouchMove: el =>
                            el &&
                            (el?.id === 'editor-task-formats' ||
                              el?.id === 'create-task-mention-wrap')
                        });
                      }
                    );
                  }
                }
              }
            );
          }
        }
      );
    }
  };

  storeCursorBounds = () => {
    const { Quill } = this.state;
    if (Quill && Quill.get()) {
      const QuillEditor = Quill.get();
      const range = QuillEditor.getSelection(true);

      if (!range) {
        return;
      }

      const cursorBounds = QuillEditor.getBounds(range?.index);

      this.setState({
        cursorBounds
      });
    }
  };

  clearDescription = () => {
    const { unmarkIsUploadingImage } = this.props;
    const { Quill, ready } = this.state;
    if (!ready) {
      return;
    }

    if (this.QuillEditor && Quill.getDelta()) {
      TaskProperties.setDescriptionProperties({ ops: [] });
      this.props.unblockDraft();

      if (this.QuillEditor && !Quill.get().hasFocus()) {
        Quill.get().focus();
      }

      if (this.descriptionEditorSaveDraftOBS) {
        this.descriptionEditorSaveDraftOBS.next();
      }
    }

    if (isFunction(unmarkIsUploadingImage)) {
      unmarkIsUploadingImage();
    }

    this.hideMentions();
    this.storeCursorBounds();
  };

  editableClicked = evt => {
    const { Quill } = this.state;

    if (this.QuillEditor && !Quill.get().hasFocus()) {
      Quill.get().focus();
    }

    this.watchForImageClicked(evt);
    this.descriptionEditorClickOBS.next(evt);
  };

  onEditableClicked(fn) {
    if (this.descriptionEditorClickOBS) {
      return this.descriptionEditorClickOBS.subscribe({
        next: fn
      });
    }

    return null;
  }

  watchForImageClicked = evt => {
    if (!evt) {
      return;
    }

    const { setImagePreviewUploaded } = this.props;
    const { Quill } = this.state;
    const target = evt.target ? evt.target : evt.srcElement;
    // image from task description
    if (
      target &&
      target?.dataset?.taskimage &&
      toLower(target?.nodeName) === 'img' &&
      target.src
    ) {
      if (Quill) {
        Quill.get().blur();

        if (
          this.descriptionEditableRef &&
          this.descriptionEditableRef.current?.blur
        ) {
          this.descriptionEditableRef.current.blur();
        }

        const isIOSPlatform = Capacitor.getPlatform() === 'ios';
        const isIosPhoneDevice = isIOSMobile();

        if (isMobileView()) {
          const timeoutId = setTimeout(
            () => {
              if (isFunction(setImagePreviewUploaded)) {
                setImagePreviewUploaded(target.src);
              }

              clearTimeout(timeoutId);
            },
            isIOSPlatform || isIosPhoneDevice ? 500 : 200
          );
        } else {
          if (isFunction(setImagePreviewUploaded)) {
            setImagePreviewUploaded(target.src);
          }
        }
      }
    }
  };

  editorChange = (name, ...args) => {
    const { ready, showMention } = this.state;
    const { hasAccess, removeSubscriber } = this.props;

    if (name === 'text-change') {
      this.descriptionScrollTopMeta.valid = true;
      this.descriptionScrollTopMeta.value =
        document.querySelector('.ql-editor').scrollTop;

      if (ready && this.descriptionCursorSaveOBS) {
        this.descriptionCursorSaveOBS.next();
      }
    }

    if (!ready) {
      return;
    } else if (
      this.descriptionEditableRef &&
      this.descriptionEditableRef.current
    ) {
      const target = this.descriptionEditableRef.current;
      this.rmStyles(target);
      this.clean(target);
      this.descriptionEditorChangeOBS.next({ name, args: [...args] });
    }

    if (name === 'text-change') {
      const { taskDescriptionInit = false, loaded = false } = this.props;
      const QuillEditor = this.getQuillEditor();

      if (this.descriptionEditorSaveDraftOBS) {
        if (loaded && taskDescriptionInit) {
          this.descriptionEditorSaveDraftOBS.next();
        }
      }

      if (loaded) {
        this.saveForEditHistory();
      }

      if (QuillEditor && args && args.length && args[0]) {
        const descriptionContents = QuillEditor.getContents();
        const range = QuillEditor.getSelection(true);
        const fullTextOnly = descriptionTextContents(descriptionContents);
        const userIdsMentioned = isArray(descriptionContents?.ops)
          ? filter(
              descriptionContents.ops.map(currentOp =>
                !isEmpty(currentOp?.insert?.atinfo?.userId)
                  ? currentOp?.insert?.atinfo?.userId
                  : ''
              ),
              userId => userId && !isEmpty(userId)
            )
          : [];

        if (
          isMobileView() &&
          range &&
          !range.length &&
          range.index > 0 &&
          fullTextOnly &&
          !showMention &&
          toString(fullTextOnly).substring(range.index - 1, 1) === '@'
        ) {
          this.promptMentions();
        }

        if (
          isArray(hasAccess) &&
          !isEmpty(hasAccess) &&
          isFunction(removeSubscriber)
        ) {
          // check for mentioned and added sub if it still exists in the body/description
          const usersToBeRemoved = [];

          for (let i = 0; i < hasAccess.length; i++) {
            const currentSubscriber = hasAccess[i];
            const currentSubscriberId =
              currentSubscriber?.id || currentSubscriber?.profile_id;

            if (
              !isEmpty(currentSubscriberId) &&
              !userIdsMentioned.includes(currentSubscriberId) &&
              currentSubscriber.type &&
              currentSubscriber.type === k.USER_TASK_ACCESS_SUBSCRIBER_AUTO
            ) {
              usersToBeRemoved.push(currentSubscriberId);
              // remove
            }
          }

          if (!isEmpty(usersToBeRemoved)) {
            removeSubscriber(usersToBeRemoved);
          }
        }
      }
    }

    // check for marks heading2
  };

  onSavingDraft = () => {
    const { mounted } = this.state;
    const { saveDraft } = this.props;

    if (!mounted) {
      return;
    } else if (isFunction(saveDraft)) {
      Logger.log(`Saving user draft...`);
      saveDraft();
    }
  };

  saveForEditHistory = () => {
    const { descriptionEditorSaveEditedOBS, isEditMode } = this.props;
    if (descriptionEditorSaveEditedOBS?.next && isEditMode) {
      descriptionEditorSaveEditedOBS.next();
    }
  };

  onEditorChange = fn => {
    if (this.descriptionEditorChangeOBS) {
      return this.descriptionEditorChangeOBS.subscribe({ next: fn });
    }

    return null;
  };

  rmStyles = (target = null) => {
    if (UserDOM.isElement(target)) {
      const pArray = target.querySelectorAll('p');
      let i = 0;

      while (pArray.length > i) {
        const p = pArray[i];
        if (p && p.hasChildNodes && p.hasChildNodes()) {
          const spans = document.querySelectorAll('span');
          const anchors = document.querySelectorAll('a');

          for (const child of spans) {
            if (
              child &&
              (child.style ||
                (child.hasAttribute && child.hasAttribute('style'))) &&
              !!child.removeAttribute
            ) {
              child.removeAttribute('style');
            } else if (child && child.style) {
              child.style = '';
            }
          }

          for (const child of anchors) {
            if (
              child &&
              (child.style ||
                (child.hasAttribute && child.hasAttribute('style'))) &&
              !!child.removeAttribute
            ) {
              child.removeAttribute('style');
            } else if (child && child.style) {
              child.style = '';
            }
          }
        }

        i += 1;
      }
    }
  };

  clean = target => {
    /**
     * Remove unnecessary DOMs
     */

    if (target) {
      // experimental
      // const iframes = target.querySelectorAll('iframe');
      // const links = target.querySelectorAll('link');
      // const styles = target.querySelectorAll('style');
      // this.removeAll(iframes);
      // this.removeAll(links);
      // this.removeAll(styles);

      const videos = target.querySelectorAll('video');
      const objects = target.querySelectorAll('object');
      const h3s = target.querySelectorAll('h3');
      const h4s = target.querySelectorAll('h4');
      const h5s = target.querySelectorAll('h5');

      this.removeBlackListedVideos(videos);
      this.removeAll(objects);
      this.removeAll(h3s);
      this.removeAll(h4s);
      this.removeAll(h5s);

      if (this.descriptionScrollTopMeta.valid) {
        this.descriptionScrollTopMeta.valid = false;
        const dom = document.querySelector('.ql-editor');

        if (dom) {
          dom.scrollTo(0, this.descriptionScrollTopMeta.value);
        }
      }

      return;
    }
  };

  removeBlackListedVideos = (videos = []) => {
    if (videos?.length) {
      for (let i = 0; i < videos.length; i++) {
        const vidElement = videos[i];

        if (
          vidElement &&
          isString(vidElement.src) &&
          isValidFileLink(vidElement.src)
        ) {
          continue;
        } else if (vidElement) {
          vidElement.remove();
        }
      }
    }
  };

  removeAll(doms) {
    if (doms.length > 0) {
      let i = 0;

      while (doms.length > i) {
        const dom = doms[i];
        dom.remove();

        if (i === doms.length - 1) {
          break;
        }

        i += 1;
      }
    }
  }

  render() {
    const {
      promptError,
      toggleMaximizeEditor,
      maximizeEditor = false,
      submitted = false,
      draggingFileUpload = false,
      loaded = false,
      files = [],
      uploadingFiles = [],
      handleRemoveFileUpload,
      handleFileUpload,
      markIsUploadingImage,
      unmarkIsUploadingImage,
      isEditMode = false,
      connectedUsers = [],
      addSubscriber = () => {},
      hasAccess = []
    } = this.props;
    const {
      inputFocus,
      cursorBounds,
      preview,
      ready,
      showMention,
      showMentionRange
    } = this.state;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div className={styles.task_description_raw}>
        <div
          className={cx(styles.task_description_raw_input, {
            [styles.task_description_raw_input_dark]: isThemeDarkMode,
            [styles.task_description_raw_input_active]: inputFocus,
            [styles.task_description_raw_input_active_dark]:
              inputFocus && isThemeDarkMode
          })}
        >
          <div className={styles.scrolling_container} id="scrolling-container">
            <div
              ref={this.descriptionEditableRef}
              id="create-task-description-editable"
              className={cx(styles.editable, {
                [styles.editable_default]: !preview,
                [styles.editable_dark]: isThemeDarkMode
              })}
            ></div>
          </div>

          {draggingFileUpload && (
            <div className={cx(styles.flex_column_xy, styles.drag_file)}>
              <div className={cx(styles.flex_row_xy, styles.icon)}>
                <UploadIcon />
              </div>
              <h1>{i18n('user_create_task_drag_file_here')}</h1>
            </div>
          )}
          <div
            className={cx(styles.format, {
              [styles.format_active]: inputFocus,
              [styles.format_dark]: isThemeDarkMode,
              [styles.format_dark_active]: inputFocus && isThemeDarkMode
            })}
          >
            <TaskFormats
              submitted={submitted}
              toggleMaximizeEditor={toggleMaximizeEditor}
              maximizeEditor={maximizeEditor}
              inputFocus={inputFocus}
              ready={ready}
              clearDescription={this.clearDescription}
              confirmTaskFormats={this.confirmTaskFormats}
              onEditorChange={this.onEditorChange}
              onEditableClicked={this.onEditableClicked}
              getQuillEditor={this.getQuillEditor}
              getDescriptionDOM={this.getDescriptionDOM}
              QuillEditor={this.QuillEditor}
              handleFileUpload={handleFileUpload}
              markIsUploadingImage={markIsUploadingImage}
              unmarkIsUploadingImage={unmarkIsUploadingImage}
              promptError={promptError}
            />
          </div>
        </div>
        {loaded && window.innerWidth > 900 && (
          <div className={styles.files_wrapper}>
            <Files
              files={files}
              uploadingFiles={uploadingFiles}
              handleRemoveFileUpload={handleRemoveFileUpload}
              isEditMode={isEditMode}
            />
          </div>
        )}
        {ready && (
          <MarksHeading2
            getQuillEditor={this.getQuillEditor}
            onEditorChange={this.onEditorChange}
          />
        )}

        {ready && loaded && this.QuillEditor && (
          <Mention
            getDescriptionDom={() =>
              this.descriptionEditableRef && this.descriptionEditableRef.current
            }
            close={this.hideMentions}
            open={this.promptMentions}
            getQuillEditor={this.getQuillEditor}
            hasAccess={hasAccess}
            addSubscriber={addSubscriber}
            show={showMention}
            startRange={showMentionRange}
            users={connectedUsers}
            cursorBounds={cursorBounds}
          />
        )}
      </div>
    );
  }
}

export default TaskDescription;
