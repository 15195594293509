import React, { useEffect } from 'react';
import cx from 'classnames';
import styles from './ImagePreviewUploaded.scss';
import { isFunction, toLower } from 'src/helpers/utils';
import { CrossIcon, IconButton } from 'evergreen-ui';

const ImagePreviewUploaded = props => {
  const imageUrl = props.url;
  const close = props.close;
  const uniqueClassnameImage = 'uniqueClassnameImage';

  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    const onClick = evt => {
      if (evt) {
        const target = evt.target ? evt.target : evt.srcElement;

        if (target?.dataset?.taskimage || toLower(target?.nodeName) === 'img') {
          return;
        }

        if (
          target &&
          target?.classList?.length > 0 &&
          target.classList.contains(uniqueClassnameImage)
        ) {
          return;
        } else if (isFunction(close)) {
          close();
        }
      }
    };

    document.addEventListener('click', onClick, false);

    return () => {
      document.removeEventListener('click', onClick, false);
    };
  }, [imageUrl, close]);

  return (
    <div className={cx(styles.flex_row_xy, styles.image_preview_uploaded)}>
      <img
        className={uniqueClassnameImage}
        src={imageUrl}
        alt={'Preview for uploaded'}
      />
      <div
        className={cx(styles.close, styles.flex_row_xy, uniqueClassnameImage)}
      >
        <IconButton
          className={cx(uniqueClassnameImage)}
          icon={CrossIcon}
          appearance={'minimal'}
          onClick={() => {
            if (isFunction(close)) {
              close();
            }
          }}
        />
      </div>
    </div>
  );
};

export default ImagePreviewUploaded;
