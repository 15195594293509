import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './Tags.scss';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import LoadSpinner from 'src/components/load-spinner';
import k from 'src/constants/k';
import { isFunction, isValidObject, isMobileView } from 'src/helpers/utils';
import { fromEvent, Subject } from 'rxjs';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'src/lib/bodyScrollLock.min';

class Tags extends Component {
  listSelectedRef = null;
  listWrapRef = null;
  listRef = null;
  inputRef = null;
  searchOBS = null;
  searchOBSSubscriber = null;
  listRefClickSubscriber = null;
  documentClickSubscriber = null;
  windowBlurSubscriber = null;
  tagsInKeys = {};

  state = {
    tagInput: '',
    tags: [], // object
    results: [], // object, same with tags[]
    selected: [], // tags selected
    loading: true,
    mounted: false,
    focused: false
  };

  constructor() {
    super();

    this.listSelectedRef = createRef(null);
    this.listWrapRef = createRef(null);
    this.listRef = createRef(null);
    this.inputRef = createRef(null);
    this.searchOBS = new Subject();
    this.select = this.select.bind(this);
    this.copyTags = this.copyTags.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onWindowBlur = this.onWindowBlur.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    this.setState({
      mounted: false,
      results: [],
      tags: [],
      selected: []
    });

    this.enableOrdisableBodyScrollListSelected(true);
    this.enableOrdisableBodyScrollListTags(true);

    if (this.searchOBSSubscriber) {
      this.searchOBSSubscriber.unsubscribe();
    }

    if (this.localUserTagsResultsChangeSubscriber) {
      this.localUserTagsResultsChangeSubscriber.unsubscribe();
    }

    if (this.listRefClickSubscriber) {
      this.listRefClickSubscriber.unsubscribe();
    }

    if (this.documentClickSubscriber) {
      this.documentClickSubscriber.unsubscribe();
    }

    if (this.windowBlurSubscriber) {
      this.windowBlurSubscriber.unsubscribe();
    }
  }

  onMount = () => {
    const { tags = [] } = this.props;

    if (ProfileAPI.USER_TASKS.VARS.firstTags) {
      this.copyTags({ personal_tags: ProfileAPI.USER_TASKS.personal_tags });
    }

    this.localUserTagsResultsChangeSubscriber =
      ProfileAPI.USER_TASKS.onPersonalTagsResults(this.copyTags);

    this.searchOBSSubscriber = this.searchOBS.subscribe(this.search);

    this.documentClickSubscriber = fromEvent(
      window || document,
      'click'
    ).subscribe(this.onDocumentClick);

    this.windowBlurSubscriber = fromEvent(window, 'blur').subscribe(
      this.onWindowBlur
    );

    if (this.listRef && this.listRef.current) {
      this.listRefClickSubscriber = fromEvent(
        this.listRef.current,
        'click'
      ).subscribe(this.select);
    }

    if (tags && tags.length) {
      this.setState(
        {
          selected: [...tags]
        },
        () => this.enableOrdisableBodyScrollListSelected(false)
      );
    }
  };

  enableOrdisableBodyScrollListSelected = (enable = true) => {
    if (!isMobileView() || !this.listSelectedRef?.current) {
      return;
    }

    if (!enable) {
      disableBodyScroll(this.listSelectedRef.current);
    } else {
      enableBodyScroll(this.listSelectedRef.current);
    }
  };

  enableOrdisableBodyScrollListTags = (enable = true) => {
    if (!isMobileView() || !this.listWrapRef?.current) {
      return;
    } else if (!enable) {
      disableBodyScroll(this.listWrapRef.current);
    } else {
      enableBodyScroll(this.listWrapRef.current);
    }
  };

  onWindowBlur() {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    } else if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.blur();

      this.onInputBlur();
    }
  }

  remove(tag = {}) {
    const { selected, mounted } = this.state;
    const { onRemoveTags } = this.props;

    if (!tag || !mounted || !selected || !selected.length) {
      return;
    }
    const { id: tagId } = tag;

    for (let i = 0; i < selected.length; i++) {
      const tag = selected[i];
      if (tag && tag.id === tagId) {
        selected.splice(i, 1);
        this.setState({
          selected
        });

        if (selected?.length < 1) {
          this.enableOrdisableBodyScrollListSelected(true);
        }

        if (isFunction(onRemoveTags)) {
          onRemoveTags(tagId);
        }

        break;
      }
    }
  }

  attachNew = (tag = {}, fromCustomSpace = false) => {
    const { selected, mounted } = this.state;
    const { onAddTags, spaceId } = this.props;
    let alreadyAttached = false;

    if (!tag || !mounted) {
      return false;
    }

    const { id: tagId } = tag;

    if (selected && selected.length) {
      for (const tag of selected) {
        if (tag && tag.id === tagId) {
          alreadyAttached = true;

          break;
        }
      }
    }

    if (!alreadyAttached && this.state.mounted) {
      const personalTagsCount = selected.filter(
        tagInfo => tagInfo && !tagInfo.spaceId
      ).length;
      const spaceTagsCount = selected.length - personalTagsCount;

      if (!fromCustomSpace && personalTagsCount >= k.USER_TASK_MAX_TAGS) {
        // pop from personal tags
        for (let i = selected.length - 1; i >= 0; i--) {
          const tagInfo = selected[i];
          if (tagInfo && !tagInfo.spaceId) {
            selected.splice(i, 1);
            break;
          }
        }
      } else if (fromCustomSpace && spaceTagsCount >= k.USER_TASK_MAX_TAGS) {
        // pop from space tags
        for (let i = selected.length - 1; i >= 0; i--) {
          const tagInfo = selected[i];
          if (tagInfo && tagInfo.spaceId) {
            selected.splice(i, 1);
            break;
          }
        }
      }

      const newSelected = [
        { ...tag, spaceId: fromCustomSpace ? spaceId : '' },
        ...selected
      ];

      this.setState(
        {
          selected: newSelected
        },
        () => {
          this.enableOrdisableBodyScrollListSelected(false);
        }
      );

      if (isFunction(onAddTags)) {
        onAddTags(tagId, fromCustomSpace, newSelected);
      }
    }
  };

  async copyTags({ personal_tags }) {
    const { mounted, loading } = this.state;

    if (!mounted) {
      return;
    }

    if (personal_tags) {
      await this.setStateAsync({
        tags: [...personal_tags],
        ...(loading &&
          ProfileAPI.USER_TASKS.VARS.firstTags && {
            loading: false
          })
      });

      for (const tag of this.state.tags) {
        if (tag && tag.id) {
          this.tagsInKeys[tag.id] = tag;
        }
      }
    }
  }

  onInput = evt => {
    if (!evt) {
      return;
    }

    const target = evt.target
      ? evt.target
      : evt.srcElement
      ? evt.srcElement
      : null;

    if (target) {
      const { value = '' } = target;

      this.enableOrdisableBodyScrollListTags(false);
      this.setState(
        {
          focused: true,
          tagInput: value
        },
        () => {
          if (this.searchOBS) {
            this.searchOBS.next();
          }

          this.enableOrdisableBodyScrollListTags(false);
        }
      );
    }
  };

  onInputFocus() {
    const { mounted } = this.state;

    if (mounted) {
      this.enableOrdisableBodyScrollListTags(false);
      this.setState(
        {
          focused: true
        },
        () => this.enableOrdisableBodyScrollListTags(false)
      );
    }
  }

  onInputBlur() {
    const { mounted } = this.state;

    if (mounted) {
      this.setState(
        {
          focused: false
        },
        () => {
          this.enableOrdisableBodyScrollListTags(true);
        }
      );
    }
  }

  onDocumentClick(evt) {
    const { mounted } = this.state;

    if (evt && mounted) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (target) {
        if (
          target.classList &&
          (target.classList.contains('attach-task-tags') ||
            target.classList.contains(styles.tags_input_raw))
        ) {
          return;
        } else if (
          target.classList &&
          target.classList.contains(styles.remove_selected) &&
          target.classList.length
        ) {
          // remove a selected tag
          const tagId = `${target.classList[target.classList.length - 1]}`;
          const fromCustomSpace =
            target.classList.length > 2 &&
            target.classList[target.classList.length - 2] === 'space';

          if (fromCustomSpace) {
            this.remove({ id: tagId });
            return;
          } else if (tagId && this.tagsInKeys[tagId]) {
            this.remove(this.tagsInKeys[tagId]);
            return;
          }
        }
      }
    }

    this.onInputBlur();
  }

  search = () => {
    const { loading, mounted, tagInput, tags } = this.state;
    const { spaceTags, spaceId, spaceName } = this.props;
    const results = [];

    if (!mounted || loading) {
      return;
    } else if (!tagInput.length) {
      this.setState({
        results: []
      });
    } else {
      const notSensitiveInput = tagInput.toLowerCase();

      for (const tag of tags) {
        const str = `${tag.id} ${tag.name} ${tag.id}${tag.name}`.toLowerCase();

        if (str.includes(notSensitiveInput)) {
          results.push(tag);
        } else {
          const kws = notSensitiveInput.split(' ');

          for (let i = 0; i < kws.length; i++) {
            const keyWord = kws[i];
            if (str.includes(keyWord)) {
              results.push(tag);
              break;
            }
          }
        }
      }

      if (spaceId) {
        for (const spaceTag of spaceTags) {
          if (isValidObject(spaceTag)) {
            const str =
              `${spaceTag.tag} ${spaceTag.id} ${spaceName}`.toLowerCase();

            if (str.includes(notSensitiveInput)) {
              results.push({ ...spaceTag, spaceId });
            }
          }
        }
      }

      this.setState(
        {
          focused: true,
          results
        },
        () => this.enableOrdisableBodyScrollListTags(false)
      );
    }
  };

  select(evt) {
    const { mounted, loading, focused } = this.state;
    if (!evt || !mounted || loading) {
      return;
    }

    const target = evt.target
      ? evt.target
      : evt.srcElement
      ? evt.srcElement
      : null;

    if (
      target &&
      target.classList &&
      target.classList.contains &&
      target.classList.contains('attach-task-tags')
    ) {
      const { spaceTags = [] } = this.props;
      const cn = `${target.classList[target.classList.length - 1]}`;
      const fromCustomSpace =
        target.classList.length > 2
          ? target.classList[target.classList.length - 2] === 'space'
          : false;

      if (fromCustomSpace) {
        const find = spaceTags.filter(t => t.id === cn);

        if (find && find.length) {
          this.attachNew(find[0], true);
        }
      } else if (cn && this.tagsInKeys[cn]) {
        this.attachNew(this.tagsInKeys[cn]);
      }

      if (focused) {
        this.setState(
          {
            focused: false,
            tagInput: ''
          },
          () => this.enableOrdisableBodyScrollListTags(true)
        );
      }
    }
  }

  render() {
    const { results, tags, focused, loading, tagInput, selected } = this.state;
    const { spaceName = '', spaceId = '', spaceTags = [] } = this.props;
    const isSearching = Boolean(tagInput.length > 0);
    const emptySearch = Boolean(results.length < 1 && tagInput.length);
    const emptyTagList = Boolean(
      tags.length < 1 &&
        (!spaceId || !spaceTags || !spaceTags.length) &&
        !tagInput.length
    );
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div className={styles.tags}>
        <div
          className={cx(styles.title, { [styles.title_dark]: isThemeDarkMode })}
        >
          <p>{i18n('user_create_task_user_attached_label')}</p>
        </div>
        <div
          className={cx(styles.tags_input, {
            [styles.tags_input_dark]: isThemeDarkMode,
            [styles.tags_input_w_selected]: selected.length > 0
          })}
        >
          <input
            ref={this.inputRef}
            type="text"
            className={cx(styles.tags_input_raw, {
              [styles.tags_input_raw_dark]: isThemeDarkMode
            })}
            onFocus={this.onInputFocus}
            onChange={this.onInput}
            value={tagInput}
            placeholder={i18n('user_create_task_attach_tag_input_placeholder')}
          />

          <div
            className={cx(styles.selected_list, {
              [styles.hide_element]: !selected.length
            })}
            ref={this.listSelectedRef}
          >
            <ul>
              {selected.map((s, idx) => {
                if (!s.spaceId) {
                  return (
                    <li key={`task-edit-attach-tag-selected-${idx}-${s.id}`}>
                      <h5> {s.name} </h5>
                      <div
                        className={cx(styles.remove_selected, `${s.id}`)}
                      ></div>
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={`task-edit-attach-space-tag-selected-${idx}-${s.id}`}
                    >
                      <h5>
                        {s.tag}
                        <span>{`(${spaceName || ' ... '})`}</span>
                      </h5>
                      <div
                        className={cx(
                          styles.remove_selected,
                          'space',
                          `${s.id}`
                        )}
                      ></div>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div
            className={cx(styles.tags_drop, {
              [styles.tags_drop_hide]: !focused
            })}
          >
            <div
              className={cx(styles.spinner_wrap, styles.flex_row_xy, {
                [styles.hide_element]: !loading
              })}
            >
              <div className={styles.raw}>
                <LoadSpinner className={styles.raw_spin} />
              </div>
            </div>

            {(emptySearch || emptyTagList) && !loading ? (
              <div className={styles.empty}>
                {emptyTagList && (
                  <h5>{i18n('user_create_task_attach_tag_empty_list')}</h5>
                )}
                {emptySearch && (
                  <h5>{i18n('user_create_task_attach_tag_empty_search')}</h5>
                )}
              </div>
            ) : (
              <></>
            )}

            <div
              className={cx(styles.list, {
                [styles.hide_element]:
                  loading ||
                  (emptySearch && isSearching) ||
                  (!isSearching && emptyTagList)
              })}
              ref={this.listWrapRef}
            >
              <ul ref={this.listRef}>
                {!isSearching ? (
                  <>
                    {spaceId &&
                      spaceTags.map((t, idx) => {
                        const cn = t.id;
                        const tagId = t.id;

                        return (
                          <li
                            key={`task-edit-attach-space-tag-${idx}-${tagId}`}
                            className={cx('attach-task-tags', 'space', cn)}
                          >
                            <h5 className={cx('attach-task-tags', 'space', cn)}>
                              {`${t.tag || t.name}`}
                              <span
                                className={cx('attach-task-tags', 'space', cn)}
                              >{`(${spaceName || '....'})`}</span>
                            </h5>
                          </li>
                        );
                      })}{' '}
                    {tags.map((t, idx) => {
                      const cn = `${t.id}`.replace(/['"]+/g, '');

                      return (
                        <li
                          key={`task-edit-attach-tag-${idx}-${t.id}`}
                          className={cx('attach-task-tags', cn)}
                        >
                          <h5 className={cx('attach-task-tags', cn)}>
                            {t.name}
                          </h5>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  results.map((t, idx) => {
                    const cn = `${t.id}`.replace(/['"]+/g, '');

                    if (t && t.spaceId) {
                      const spaceTagId = t.id;
                      return (
                        <li
                          key={`task-edit-attach-space-tag-${idx}-${spaceTagId}`}
                          className={cx(
                            'attach-task-tags',
                            'space',
                            spaceTagId
                          )}
                        >
                          <h5
                            className={cx(
                              'attach-task-tags',
                              'space',
                              spaceTagId
                            )}
                          >
                            {`${t.tag || t.name}`}
                            <span
                              className={cx(
                                'attach-task-tags',
                                'space',
                                spaceTagId
                              )}
                            >{`(${spaceName || '....'})`}</span>
                          </h5>
                        </li>
                      );
                    }

                    return (
                      <li
                        key={`task-edit-attach-tag-results-${idx}-${t.id}`}
                        className={cx('attach-task-tags', cn)}
                      >
                        <h5 className={cx('attach-task-tags', cn)}>{t.name}</h5>
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tags;
