import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './theme/task.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';

const renderReactDom = () => {
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root')
  );
};

if (window.cordova) {
  document.addEventListener(
    'deviceready',
    () => {
      renderReactDom();
    },
    false
  );
} else {
  renderReactDom();
}
