import React from 'react';
import cx from 'classnames';
import styles from './FilePreview.scss';
import k from 'src/constants/k';
import { toString } from 'src/helpers/utils';

const filePreviewImages = k.FILE_PREVIEW_IMAGES;
// Cache-Control == max-age=31536000

const FilePreview = params => {
  const {
    type = '',
    name = '',
    sizeInKb = '',
    url = '',
    sizeInBytes = ''
  } = params || {};

  const typeInString = toString(type);
  const nameInString = toString(name);
  const canbeMb = sizeInKb >= 1000;
  const sizeInMb = canbeMb ? Number((sizeInKb / 1000).toFixed(2)) : 0;
  const isMusic =
    typeInString.includes('audio') || typeInString.includes('audio/mpeg');
  const isDocsOrWordFile =
    nameInString.indexOf('.docx') + '.docx'.length >= nameInString.length ||
    typeInString ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    typeInString.includes('.docx') ||
    typeInString.includes('.docs') ||
    typeInString.includes('document');
  const isPdfFile =
    nameInString.indexOf('.pdf') > 1 ||
    typeInString === 'application/pdf' ||
    typeInString.includes('pdf');
  const isImage =
    typeInString.includes('image/') ||
    typeInString === 'image/jpeg' ||
    typeInString === 'image/png';
  const isPptFile =
    nameInString.indexOf('.ppt') + '.ppt'.length >= nameInString.length;
  const isVideo =
    typeInString.includes('video') || typeInString.includes('mp4');
  const isZipArchive =
    typeInString === 'application/zip' ||
    nameInString.includes('.zip') ||
    typeInString.includes('.zip') ||
    typeInString.includes('compressed');

  if (!name) {
    return <></>;
  }

  return (
    <div className={styles.file_preview}>
      <div className={styles.raw}>
        <div className={styles.type}>
          <img
            src={
              isZipArchive
                ? filePreviewImages.zip
                : isPptFile
                ? filePreviewImages.presentation
                : isVideo
                ? filePreviewImages.video
                : isImage
                ? filePreviewImages.image
                : isPdfFile
                ? filePreviewImages.pdf
                : isMusic
                ? filePreviewImages.music
                : isDocsOrWordFile
                ? filePreviewImages.word
                : filePreviewImages.general
            }
            alt={'file preview'}
          />
        </div>
        <div className={styles.texts}>
          <h3>{name}</h3>
          {sizeInKb || sizeInBytes ? (
            <h5>{`${
              canbeMb
                ? sizeInMb
                : sizeInBytes > 999 && sizeInKb
                ? sizeInKb
                : sizeInBytes
            } ${
              canbeMb ? 'MB' : sizeInBytes > 999 && sizeInKb ? 'KB' : 'B'
            }`}</h5>
          ) : (
            <></>
          )}
        </div>
        {url && (
          <div className={cx(styles.cover, styles.link)}>
            <a href={url} target="_blank" rel="noreferrer">
              {' '}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
export default FilePreview;
