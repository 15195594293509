import k from 'src/constants/k';
import i18n from 'src/locales';
import Logger from 'src/lib/Logger';
import ModalAPI from 'src/modal-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import ImageFile from 'src/lib/ImageFile';
import TaskProperties from 'src/pages/user/create-task2/TaskProperties';
import {
  isImageURL,
  toString,
  isFunction,
  isDev,
  isNumber
} from 'src/helpers/utils';
import { v4 } from 'uuid';
import BetterImageStyles from '../../../../lib/formats/better-image/BetterImage.scss';

class Images {
  quality = 1;
  blockFormat = false;
  QuillEditor = null;
  descriptionWrapDOM = null;
  markIsUploadingImage = null;
  triggerSaveDraft = null;
  unmarkIsUploadingImage = null;
  uRef = '';
  taskId = '';

  constructor() {
    this.scaleImageJPEG = this.scaleImageJPEG.bind(this);
    this.block = this.block.bind(this);
    this.unblock = this.unblock.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.setQuillEditor = this.setQuillEditor.bind(this);
    this.insertImageBase64 = this.insertImageBase64.bind(this);
  }

  onError(msg) {
    if (msg && typeof msg === 'string') {
      ModalAPI.toaster('danger', msg, 6000);
    }
  }

  destroy() {
    delete this.QuillEditor;
  }

  setTaskId(taskId = '') {
    if (taskId) {
      this.taskId = taskId;
    }
  }

  setUniversalRefID(uRef = '') {
    if (uRef) {
      this.uRef = uRef;
    }
  }

  setQuillEditor(QuillEditor) {
    this.QuillEditor = QuillEditor;
  }

  setDescriptionDOM(descriptionWrapDOM) {
    this.descriptionWrapDOM = descriptionWrapDOM;
  }

  scaleImageJPEG(src, type = 'jpeg', quality = 0.5, maxW = 0, maxH = 0) {
    return new Promise(resolve => {
      const img = new Image();

      img.onload = function () {
        try {
          let h =
            typeof this.naturalHeight === 'number' && this.naturalHeight > 0
              ? this.naturalHeight
              : this.height;
          let w =
            typeof this.naturalWidth === 'number' && this.naturalWidth > 0
              ? this.naturalWidth
              : this.width;

          if (w > maxW && maxW > 0) {
            const ratio = maxW / w;

            w = Math.ceil(ratio * w);

            if (maxH <= 0) {
              h = Math.ceil(ratio * h);
            }
          }

          if (h > maxH && maxH > 0) {
            const ratio = maxH / h;

            h = Math.ceil(ratio * h);
          }

          const fileType =
            type.indexOf('jpeg') > -1 ? 'image/jpeg' : 'image/png';
          const canvas = document.createElement('canvas');
          canvas.width = Math.ceil(w);
          canvas.height = Math.ceil(h);
          canvas
            .getContext('2d')
            .drawImage(img, 0, 0, canvas.width, canvas.height);
          const value = canvas.toDataURL(fileType, quality);
          resolve({ value, err: false });
        } catch (err) {
          if (err.message) {
            Logger.log(err.message);
          }

          if (err.stack) {
            Logger.log(err.stack);
          }

          resolve({ err: true });
        }

        img.remove();
      };

      if (isDev()) {
        img.crossOrigin = 'Anonymous';
      }

      img.onerror = function (err) {
        if (err.message) {
          Logger.log(err.message);
        }

        if (err.stack) {
          Logger.log(err.stack ? err.stack : '');
        }

        resolve({ err: true });

        img.remove();
      };

      img.src = src;
    });
  }

  imageHandler() {
    const input = document.createElement('input');

    try {
      if (
        !window.FileReader ||
        !window.Blob ||
        !this.QuillEditor ||
        !this.QuillEditor.getSelection
      ) {
        this.onError(i18n('common_action_not_allowed'));
        return;
      }

      const block = this.block;
      const unblock = this.unblock;
      const onError = this.onError;
      const insertImageBase64 = this.insertImageBase64;
      const markIsUploadingImage = this.markIsUploadingImage;
      const unmarkIsUploadingImage = this.unmarkIsUploadingImage;
      const fileInputDOMID = 'FILE_INPUT_DOM_ID';
      const QuillEditor = this.QuillEditor;
      const range = QuillEditor.getSelection(true);

      if (document && document.querySelector(`#${fileInputDOMID}`)) {
        const input = document.querySelector(`#${fileInputDOMID}`);

        if (input) {
          input.remove();
        }
      }

      input.style.visibility = 'hidden';
      input.style.position = 'fix';
      input.style.top = '-20px';
      input.style.right = '-20px';
      input.setAttribute('id', toString(fileInputDOMID));
      input.setAttribute('type', 'file');
      input.setAttribute(
        'accept',
        'image/png, image/gif, image/jpeg, image/jpg, image/bmp, image/x-icon'
      );
      input.setAttribute('multiple', true);

      document.body.appendChild(input);

      input.onchange = async function () {
        if (input.files.length > 0) {
          const files = input.files;
          const singleFile = files.length < 2;
          const userIsPremium = ProfileAPI.SPACES.isUserPremiumWithSpace();

          if (isFunction(markIsUploadingImage)) {
            markIsUploadingImage();
          }

          for (let i = 0; i < files.length; i++) {
            const file = input.files[i];
            const validImage = file ? /^image\//.test(file.type) : false;

            if (file && file.size) {
              if (!validImage) {
                onError(
                  `${i18n('user_create_task_status_invalid_image')}${
                    file.name ? `: ${file.name}` : ''
                  }`
                );
                unblock();
                break;
              }

              console.log(file.size);
              const doLoadPromisify = () =>
                new Promise(resolve => {
                  const fr = new FileReader();
                  try {
                    fr.onload = async function () {
                      await insertImageBase64(
                        this.result,
                        null,
                        null,
                        !singleFile && i === files.length - 1
                          ? true
                          : singleFile,
                        singleFile ? 0 : (range?.index || 0) + i * 2
                      );
                      unblock();
                      resolve();
                    };
                    fr.readAsDataURL(file);
                  } catch {
                    resolve();
                  }
                });

              try {
                if (
                  (!userIsPremium && file.size > 25e6) ||
                  (userIsPremium && file.size > 124e6)
                ) {
                  if (userIsPremium) {
                    onError(
                      `${i18n('user_create_task_status_exceed_image_premium')}${
                        file?.name ? `: ${file.name}` : ''
                      }`
                    );
                  } else {
                    onError(
                      `${i18n('user_create_task_status_exceed_image_upgrade')}${
                        file?.name ? `: ${file.name}` : ''
                      }`
                    );
                  }

                  unblock();
                  break;
                } else {
                  block();
                }

                await doLoadPromisify();
              } finally {
                unblock();
              }
            } else {
              onError(i18n('user_create_task_status_invalid_image'));
              unblock();
            }
          }

          if (isFunction(unmarkIsUploadingImage)) {
            unmarkIsUploadingImage();
          }

          if (input) {
            input.remove();
          }
        }
      };

      input.onerror = function () {
        onError(i18n('user_create_task_status_invalid_image'));
        unblock();

        this.remove();
      };

      input.click();
    } catch (err) {
      if (err.stack) {
        Logger.log(err.stack);
      }

      input.remove();
    }
  }

  async insertImageBase64(
    b64 = '',
    blockCallback = function () {},
    callback = function () {},
    insertSpace = false,
    startIndex = 0,
    fileName = ''
  ) {
    if (!window.Blob || !window.FormData || !this.uRef) {
      this.onError(i18n('common_action_not_allowed'));

      if (isFunction(callback)) {
        callback();
      }

      return;
    } else if (b64.indexOf('data:image/') !== 0 && b64.indexOf('http') === 0) {
      const { value, err = false } = await ImageFile.srcToBase64JPEG(b64);

      if (err) {
        const { value, err = false } = await ImageFile.srcToBase64JPEG(
          b64,
          false
        );

        if (err) {
          this.onError(
            i18n('user_dashboard_account_update_display_image_err_private')
          );

          if (isFunction(callback)) {
            callback();
          }

          return;
        } else {
          b64 = toString(value);
          window.URL.revokeObjectURL(value);
        }
      } else {
        b64 = toString(value);
        window.URL.revokeObjectURL(value);
      }
    }

    if ((b64.indexOf('data:image/') !== 0 && !isImageURL(b64)) || !b64) {
      this.onError(i18n('user_create_task_status_invalid_image'));

      if (isFunction(callback)) {
        callback();
      }

      return;
    }

    const quality = this.quality;
    const uRef = this.uRef;
    const taskId = this.taskId;
    const Quill = window.Quill;
    const Delta = Quill.import('delta');
    const QuillEditor = this.QuillEditor;
    const unblockDraft = this.unblockDraft;
    const onError = this.onError;
    const range = QuillEditor.getSelection(true);

    if (isFunction(blockCallback)) {
      blockCallback();
    }

    const { value: newImageBase64, err = false } = await this.scaleImageJPEG(
      b64,
      'image/jpeg',
      quality,
      k.USER_TASK_DESCRIPTION_PROPERTIES.maxImageWidth
    );

    if (!err) {
      const finalImageBase64 =
        toString(newImageBase64).length < b64.length ? newImageBase64 : b64;
      const imageBlob = ImageFile.base64ToBlob(finalImageBase64);
      const userIsPremium = ProfileAPI.SPACES.isUserPremiumWithSpace();

      if (!imageBlob) {
        this.onError(i18n('user_create_task_status_invalid_image'));
      } else {
        this.blockDraft();
        const update = new Delta().retain(range.index).delete(range.length);
        const newRange =
          (isNumber(startIndex) && startIndex > 0 ? startIndex : range.index) +
          2;

        QuillEditor.updateContents(
          update.insert('\n'),
          update.insert({ image: { src: finalImageBase64 } }),
          insertSpace ? update.insert('\n\n') : update.insert(''),
          Quill.sources.USER
        );
        QuillEditor.setSelection(newRange, Quill.sources.SILENT);

        let uploadImageRES = null;

        if (
          !fileName ||
          fileName.indexOf('.jpg') + '.jpg'.length < fileName.length
        ) {
          fileName = `${v4()}-${Date.now()}-${uRef}.jpg`;
        } else {
          fileName = `${Date.now()}-${uRef}-${fileName}`;
        }

        if (!userIsPremium) {
          uploadImageRES = await ProfileAPI.USER_TASKS.uploadImage(
            uRef,
            imageBlob
          );
        } else {
          // @todo clear unused image files on backend from edit-task and save draft endpoint
          uploadImageRES = await ProfileAPI.TASKS.uploadFile(
            imageBlob,
            fileName,
            'image/jpeg',
            taskId,
            uRef,
            true
          );
        }

        const failed =
          !uploadImageRES ||
          uploadImageRES.err ||
          uploadImageRES.notAllowedPlan ||
          uploadImageRES.notAllowedFile;
        const maxSize = uploadImageRES && uploadImageRES.maxSize;
        const descriptionDelta = QuillEditor.getContents();
        const ops = descriptionDelta.ops;
        const newImageUrl = uploadImageRES ? uploadImageRES.url : '';
        const isError = !newImageUrl || failed || maxSize;

        if (maxSize) {
          onError(i18n('user_create_task_status_max_file'));
        } else if (uploadImageRES?.notAllowedPlan) {
          onError(i18n('user_create_task_status_exceed_image_premium'));
        } else if (uploadImageRES?.notAllowedFile) {
          onError(i18n('user_create_task_status_invalid_image'));
        }

        unblockDraft();
        const latestRange = QuillEditor.getSelection(true);
        const latestRangeUpdated = latestRange.index + 1;

        if (!isError) {
          if (this.descriptionWrapDOM) {
            const imgs = this.descriptionWrapDOM.querySelectorAll('img');

            for (let i = 0; i < imgs.length; i++) {
              const img = imgs[i];

              if (
                img.hasAttribute('src') &&
                img.getAttribute('src') === finalImageBase64
              ) {
                img.setAttribute('src', uploadImageRES.url);
                img.classList.remove(BetterImageStyles.uploading_image);
                QuillEditor.setSelection(
                  latestRangeUpdated,
                  Quill.sources.SILENT
                );
                break;
              }
            }
          }
        } else if (ops && ops.length) {
          let i = 0;
          let hasChange = false;

          while (i < ops.length) {
            const op = ops[i];
            if (op && op.insert && op.insert.image === finalImageBase64) {
              if (isError) {
                ops.splice(i, 1);
              } else {
                op.insert.image = newImageUrl;
              }

              hasChange = true;

              break;
            }

            i += 1;
          }

          if (hasChange) {
            descriptionDelta.ops = ops;
            TaskProperties.setDescriptionProperties(descriptionDelta);
            QuillEditor.setSelection(latestRangeUpdated, Quill.sources.SILENT);
          }
        }

        window.URL.revokeObjectURL(newImageBase64);
      }
    } else {
      this.onError(i18n('user_create_task_status_invalid_image'));
    }

    if (b64) {
      window.URL.revokeObjectURL(b64);
    }

    if (isFunction(callback)) {
      callback();
    }
  }

  block() {
    this.blockFormat = true;
  }

  unblock() {
    this.blockFormat = false;
  }

  blockDraft() {}

  unblockDraft() {}

  setMarkIsUploadingImage(fn) {
    this.markIsUploadingImage = fn;
  }

  setUnmarkIsUploadingImage(fn) {
    this.unmarkIsUploadingImage = fn;
  }
}

export default new Images();
