import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './More.scss';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import MessageHover from 'src/components/message-hover';
import { getEventTarget } from 'src/helpers/dom';
import {
  AlignLeftIcon,
  AlignRightIcon,
  AlignJustifyIcon,
  AlignCenterIcon
} from 'evergreen-ui';

export const H2BlockFormat = ({
  className = '',
  active = false,
  inFocus = false,
  onClick = function () {}
}) => {
  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      className={cx(styles.ft_h2_block, 'ql-header-2', {
        [`${className}`]: className && typeof className === 'string',
        [styles.ft_active]: inFocus,
        [styles.ft_inactive]: !inFocus,
        [styles.ft_active_focus]: active && inFocus,
        [styles.ft_active_nofocus]: active && !inFocus,
        [styles.ft_inactive_dark]: !inFocus && isThemeDarkMode,
        [styles.ft_active_dark]: inFocus && isThemeDarkMode
      })}
    >
      <button
        type="button"
        {...(onClick && typeof onClick === 'function' && { onClick })}
        className="ql-header"
        value="2"
      >
        <p>
          <span>{'H'}</span>
          <span>{'2'} </span>
        </p>
      </button>
      <MessageHover className={styles.tip} message={i18n('common_heading_2')} />
    </div>
  );
};

export const CodeBlockFormat = ({
  className = '',
  active = false,
  inFocus = false,
  onClick = function () {
    return true;
  }
}) => {
  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      className={cx(styles.ft_code_block, 'ql-code-block', {
        [`${className}`]: className && typeof className === 'string',
        [styles.ft_active]: inFocus,
        [styles.ft_inactive]: !inFocus,
        [styles.ft_active_focus]: active && inFocus,
        [styles.ft_active_nofocus]: active && !inFocus,
        [styles.ft_inactive_dark]: !inFocus && isThemeDarkMode,
        [styles.ft_active_dark]: inFocus && isThemeDarkMode
      })}
    >
      <button
        type="button"
        {...(onClick && typeof onClick === 'function' && { onClick })}
        className="ql-code-block"
      >
        <p>
          <span>{'<'}</span>
          <span>{' /'}</span>
          <span>{'>'}</span>
        </p>
      </button>
      <MessageHover
        className={styles.tip}
        message={i18n('common_code_block')}
      />
    </div>
  );
};

export const JustifyTextFormat = ({
  className = '',
  active = false,
  inFocus = false,
  onClick = function () {
    return true;
  }
}) => {
  const isThemeDarkMode = AppAPI.isDarkMode();
  const [expand, setExpand] = useState(false);
  const justifyTextContentFormatUnique = 'justifyTextContentFormatUnique';

  useEffect(() => {
    const onDocumentClick = evt => {
      const target = getEventTarget(evt);
      if (!target) {
        return;
      } else if (
        target &&
        target.classList &&
        target.classList.contains(justifyTextContentFormatUnique)
      ) {
        if (!expand) {
          setExpand(true);
        } else {
          setExpand(false);
        }
      } else {
        setExpand(false);
      }
    };

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [expand, justifyTextContentFormatUnique]);

  return (
    <div
      className={cx(styles.ft_justify, 'ql-align', {
        [className]: typeof className === 'string' && className,
        [styles.ft_justify_active]: !isThemeDarkMode && (inFocus || active),
        [styles.ft_justify_dark_drop]: isThemeDarkMode,
        [styles.ft_justify_dark]: isThemeDarkMode && !(inFocus || active),
        [styles.ft_justify_dark_active]: isThemeDarkMode
      })}
    >
      <div className={cx(styles.selected)}>
        <button
          type="button"
          onClick={onClick}
          className={cx(justifyTextContentFormatUnique)}
        >
          <AlignLeftIcon />
          <div
            className={cx(styles.cover, justifyTextContentFormatUnique)}
          ></div>
          <MessageHover
            className={styles.selected_tip}
            message={i18n('common_align')}
          />
        </button>
      </div>
      <div
        className={cx('ql-picker-options', { [styles.hide_element]: !expand })}
      >
        <button className="ql-align" data-value="" value="">
          <AlignLeftIcon />
        </button>
        <button className="ql-align" data-value="center" value="center">
          <AlignCenterIcon />
        </button>
        <button className="ql-align" data-value="right" value="right">
          <AlignRightIcon />
        </button>
        <button className="ql-align" data-value="justify" value="justify">
          <AlignJustifyIcon />
        </button>{' '}
      </div>
    </div>
  );
};
