import React from 'react';
import cx from 'classnames';
import styles from './Footer.scss';
import AppAPI from 'src/app-manager/API';

const Footer = props => {
  const readOnly = props.readOnly;
  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      className={cx(styles.flex_row_xy, styles.footer, {
        [styles.footer_dark]: isThemeDarkMode,
        [styles.footer_read_only]: readOnly
      })}
    >
      <div
        className={cx(styles.chamu, { [styles.chamu_dark]: isThemeDarkMode })}
      >
        <h5>{`Chamu © 2022`}</h5>
      </div>
    </div>
  );
};

export default Footer;
