import axios from 'axios';
import k from 'src/constants/k';
import Store from 'src/lib/store';

class Admin {
  profile_context = null;

  setContext(profile_context = null) {
    if (!profile_context) {
      return;
    }

    this.profile_context = profile_context;
  }

  log(...args) {
    console.log('ADMIN:', ...args);
  }

  async searchUserByUsernameOrEmail(emailOrUsername = '') {
    const result = { err: '', user: null };

    try {
      if (!this.profile_context) {
        throw new Error('Empty profile context');
      }
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      }

      const { auth } = user;
      const {
        token = '',
        id = '',
        lb = '',
        n = -1,
        username_ref = ''
      } = auth || {};
      const res = await axios({
        method: 'GET',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        url: `/v1/admin/user?auth_token_id=${id}&lb=${lb}&n=${n}&emailOrUsername=${emailOrUsername}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && res.data.valid) {
        result.user = res.data.user;
        result.err = '';
      } else {
        result.err = `${res?.data?.message}`;

        throw new Error(`${res?.data?.message} ${res?.statusText}`);
      }
    } catch (err) {
      this.log(
        `Something went wrong in searchUserByUsernameOrEmail due to error: ${err?.message}`
      );
    }

    return result;
  }

  async awardUserPremiumInMonths(teams = false, months = 0, userId = '') {
    const result = { err: 'Something went wrong', start: 0, end: 0 };
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile context');
      }

      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (!userId) {
        throw new Error('Invalid user id');
      } else if (typeof months !== 'number' || months > 99) {
        throw new Error(`Invalid months value: ${months}`);
      }

      const { auth } = user;
      const {
        token = '',
        id = '',
        lb = '',
        n = -1,
        username_ref = ''
      } = auth || {};
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          teams,
          userId,
          months
        },
        url: `/v1/admin/award/premium?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && res.data.success) {
        result.err = '';
        result.start = res.data.start;
        result.end = res.data.end;
      } else {
        result.err =
          `${res && res.data && res.data.message}` ||
          `${res.status} ${res.statusText}`;

        throw new Error(result.err || 'Something went wrong');
      }
    } catch (err) {
      this.log(
        `Something went wrong in awardUserPremiumInMonths due to error: ${err?.message}`
      );
    }

    return result;
  }

  async revokeUserPremium(userId = '') {
    const result = { err: 'Something went wrong', start: 0, end: 0 };
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile context');
      }

      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (!userId) {
        throw new Error('Invalid user id');
      }

      const { auth } = user;
      const {
        token = '',
        id = '',
        lb = '',
        n = -1,
        username_ref = ''
      } = auth || {};

      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          userId
        },
        url: `/v1/admin/revoke/premium?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && res.data.success) {
        result.err = '';
        result.start = res.data.start;
        result.end = res.data.end;
      } else {
        result.err =
          `${res && res.data && res.data.message}` ||
          `${res.status} ${res.statusText}`;

        throw new Error(result.err || 'Something went wrong');
      }
    } catch (err) {
      this.log(
        `Something went wrong in revokeUserPremium due to error: ${err?.message}`
      );
    }

    return result;
  }

  async activateUser(userId = '') {
    const result = { err: 'Something went wrong' };
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile context');
      }

      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (!userId) {
        throw new Error('Invalid user id');
      }

      const { auth } = user;
      const {
        token = '',
        id = '',
        lb = '',
        n = -1,
        username_ref = ''
      } = auth || {};
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          userId
        },
        url: `/v1/admin/user/activate?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && res.data.success) {
        result.err = '';
      } else {
        result.err =
          `${res && res.data && res.data.message}` ||
          `${res.status} ${res.statusText}`;

        throw new Error(result.err);
      }
    } catch (err) {
      this.log(
        `Something went wrong in activateUser due to error: ${err?.message}`
      );
    }

    return result;
  }
}

export default Admin;
