import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './CustomToaster.scss';
import AppAPI from 'src/app-manager/API';
import ModalAPI from 'src/modal-manager/API';
import { isString, isNil } from 'src/helpers/utils';
import { fromEvent } from 'rxjs';
import {
  TickCircleIcon,
  WarningSignIcon,
  BanCircleIcon,
  InfoSignIcon,
  CrossIcon,
  IconButton
} from 'evergreen-ui';

const CustomToaster = props => {
  const domWrapRef = useRef();
  const onResize = useCallback(() => {
    if (domWrapRef && domWrapRef.current) {
      const w = domWrapRef.current.clientWidth;
      const left = Math.ceil((window.innerWidth - w) / 2);

      domWrapRef.current.style.left = `${left}px`;
    }
  }, [domWrapRef]);

  useEffect(() => {
    onResize();

    const onResizeSubscriber = fromEvent(window, 'resize').subscribe(onResize);

    return () => {
      if (onResizeSubscriber) {
        onResizeSubscriber.unsubscribe();
      }
    };
  }, [onResize]);

  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div
      ref={domWrapRef}
      className={cx(styles.custom_toaster, {
        [styles.custom_toaster_default]:
          !props.position || props.position === 'top',
        [styles.custom_toaster_bottom]: props.position === 'bottom',
        [styles.custom_toaster_dark]: isThemeDarkMode
      })}
    >
      <div className={styles.ic}>
        {props.type === 'success' && (
          <div className={styles.success}>
            <TickCircleIcon color="success" />
          </div>
        )}
        {props.type === 'danger' && (
          <div className={styles.danger}>
            <BanCircleIcon color="danger" />
          </div>
        )}
        {props.type === 'warning' && (
          <div className={styles.warning}>
            <WarningSignIcon color="warning" />
          </div>
        )}

        {props.type === 'info' && (
          <div className={styles.info}>
            <InfoSignIcon color="info" />
          </div>
        )}
      </div>
      {isString(props.message) && <h5>{props.message}</h5>}
      {!isNil(props.message) && !isString(props.message) && props.message}
      <div className={styles.close}>
        <IconButton
          className={styles.close_button}
          appearance="minimal"
          icon={CrossIcon}
          onClick={() => {
            ModalAPI.closeToaster();
          }}
        />
      </div>
    </div>
  );
};

export default CustomToaster;
