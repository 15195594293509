import React, { Component } from 'react';
import AppManager from 'src/app-manager';
import ErrorManager from 'src/error-manager';
import ProfileManager from 'src/profile-manager';
import ModalManager from 'src/modal-manager';
import BottomPromptManager from 'src/bottom-prompt-manager';
import ErrorBound from './error-bound';
import Pages from 'src/pages';
import './App.scss';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class App extends Component {
  fontResizeOBS = null;

  state = {
    lastW: 0,
    mounted: false
  };

  constructor() {
    super();

    this.state.lastW = window.innerWidth;
    this.handleFontSize = this.handleFontSize.bind(this);
    this.fontResizeOBS = fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(this.onResize.bind(this));
  }

  onResize() {
    const { mounted, lastW } = this.state;
    if (window.innerWidth !== lastW && mounted) {
      this.setState(
        {
          lastW: window.innerWidth
        },
        this.handleFontSize
      );
    }
  }

  handleFontSize() {
    try {
      const { innerWidth } = window;
      const BASE_FONT_SIZE = 14.5;
      const BASE_WIDTH_FONT_SIZE = 1536;
      const r = document.getElementById('root');
      const cal = Math.floor(
        innerWidth * (BASE_FONT_SIZE / BASE_WIDTH_FONT_SIZE)
      );
      let fontSizeVal = `${
        cal > 16 ? (innerWidth > 1900 ? 15.7 : 15.5) : cal
      }px`;

      if (!r) return;
      else if (innerWidth <= 900) {
        fontSizeVal = '13px';
      } else if (innerWidth <= 1480) {
        fontSizeVal = '14.4px';
      } else if (innerWidth <= 1250) {
        fontSizeVal = '14px';
      }

      if (r) {
        r.style.fontSize = fontSizeVal;
      }

      if (document && document.body) {
        document.body.style.fontSize = fontSizeVal;
      }
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    this.handleFontSize();
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    if (this.fontResizeOBS) {
      this.fontResizeOBS.unsubscribe();
    }
  }

  render() {
    return (
      <ErrorBound>
        <ErrorManager>
          <AppManager>
            <ProfileManager>
              <ModalManager>
                <BottomPromptManager>
                  <Pages />
                </BottomPromptManager>
              </ModalManager>
            </ProfileManager>
          </AppManager>
        </ErrorManager>
      </ErrorBound>
    );
  }
}

export default App;
