import React from 'react';
import cx from 'classnames';
import i18n from 'src/locales';
import styles from './CopiedPrompt.scss';

const CopiedPrompt = props => {
  return (
    <div
      className={cx(styles.copied_prompt, {
        [`${props.className}`]:
          typeof props.className === 'string' && !!props.className
      })}
    >
      <div>
        <h5>{i18n('common_copied_title')}</h5>
      </div>
    </div>
  );
};

export default CopiedPrompt;
