import React, { useEffect, useRef, useState, useMemo } from 'react';
import cx from 'classnames';
import styles from './NotFound.scss';
import AppLogo from 'src/components/app-logo';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import Store from 'src/lib/store';
import i18n, { URLMarkdown } from 'src/locales';
import {
  isFunction,
  isDev,
  isMobileApp,
  isEmpty,
  toUpper,
  toString
} from 'src/helpers/utils';
import { withUserProfileSettings } from 'src/profile-manager';
import { withErrorManagerSettings } from 'src/error-manager';
import { Redirect } from 'react-router-dom';

const NotFound = props => {
  const isThemeDarkMode = AppAPI.isDarkMode();
  const [redirectCheck, setRedirectCheck] = useState(false);
  const [newPath, setNewPath] = useState('');
  const redirecting = useRef(false);
  const { setErrorMessage } = props;
  const currentPath = `${window.location.pathname}`;
  const user = ProfileAPI.USER_DATA.info();
  const userId = user ? user.profileID : '';
  const isFetchingPlan = ProfileAPI.USER_DATA.checkIsFetchingPlan();
  const isLoggedIn = useMemo(
    () =>
      Boolean(
        !isFetchingPlan &&
          user &&
          userId &&
          ProfileAPI.USER_DATA.checkIsLoggedIn()
      ),
    [user, userId, isFetchingPlan]
  );

  useEffect(() => {
    try {
      const canPrompt = isDev() || isMobileApp();

      if (canPrompt && isFunction(setErrorMessage)) {
        setErrorMessage(
          `Not found path: '${
            window.location ? window.location.pathname : '/'
          }'`
        );
      }
    } catch {}

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!redirectCheck) {
      try {
        setRedirectCheck(true);
        Store.CONFIGS.getQueryJSON();
        const query = Store.CONFIGS.getQuery();

        if (!isEmpty(query?.taskViewRedirect)) {
          const [taskId, uRef] = toString(query.taskViewRedirect)
            .trim()
            .split('-ref-');

          if (
            taskId &&
            !isEmpty(taskId) &&
            !isEmpty(uRef) &&
            ProfileAPI.USER_DATA.checkIsLoggedIn()
          ) {
            setNewPath(`/view/${toUpper(taskId)}?t=${uRef}`);
          }
        }
      } catch {}
    }
  }, [redirectCheck]);

  if (!redirecting.current) {
    if (newPath) {
      redirecting.current = true;
      return <Redirect to={newPath} />;
    } else if (currentPath === '/index.html' && !isLoggedIn) {
      redirecting.current = true;
      return <Redirect to={'/login'} />;
    }
  }

  // @todo show login creds input if not logged in
  return (
    <div
      className={cx(styles.flex_row_y, styles.not_found, {
        [styles.not_found_dark]: isThemeDarkMode
      })}
    >
      <div className={cx(styles.flex_column_xy, styles.not_found_wrap)}>
        <div className={styles.app_logo}>
          <AppLogo />
        </div>
        <div className={styles.zzz}>
          <h1>{'Z'}</h1>
          <h1>{'Z'}</h1>
          <h1>{'Z'}</h1>
        </div>
        <div className={styles.logo}>
          <div></div>
        </div>
        <div className={cx(styles.msg, { [styles.msg_dark]: isThemeDarkMode })}>
          <h2>{i18n('page_not_found_title')}</h2>
          <h4>
            {URLMarkdown('page_not_found_msg', {
              url1: {
                link:
                  props && props.profileLoaded && props.isLoggedIn
                    ? '/user'
                    : '/login',
                notRedirect: true
              }
            })}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default withUserProfileSettings(withErrorManagerSettings(NotFound));
