import styles from './VideoPlaceholder.scss';

const Quill = window.Quill;
const OldVideo = Quill.import('formats/video');

export default function RegisterVideoPlaceholder() {
  class VideoPlaceholder extends OldVideo {
    static create(value) {
      const { id } = value || {};
      super.create(value);
      const div = document.createElement('div');
      const domPlaceholder = document.createElement('div');
      const domPlayButton = document.createElement('div');

      div.classList.add(styles.video_placeholder);
      div.classList.add(styles.flex_row_xy);
      div.classList.add('ql-placeholder-video');
      domPlaceholder.classList.add(styles.flex_row_xy);
      domPlayButton.classList.add(styles.flex_row_xy);
      domPlayButton.classList.add(styles.video_placeholder_play);
      domPlaceholder.append(domPlayButton);
      div.append(domPlaceholder);
      div.setAttribute('id', id);

      return div;
    }

    static value(domNode) {
      if (domNode) {
        return {
          id: domNode?.id
        };
      }

      return {};
    }
  }

  VideoPlaceholder.blotName = 'placeholderVideo';
  VideoPlaceholder.className = 'ql-placeholder-video';
  VideoPlaceholder.tagName = 'div';

  Quill.register('formats/placeholderVideo', VideoPlaceholder, true);
}
