import React from 'react';
import cx from 'classnames';
import MessageHover from 'src/components/message-hover';
import i18n from 'src/locales';
import styles from './PriorityIcon.scss';
import k from 'src/constants/k';

const PriorityIcon = props => {
  const { priority_key = '' } = props || {};
  const isLowPrio = Boolean(priority_key === 'low');
  const isMediumPrio = Boolean(priority_key === 'normal');
  const isHighPrio = Boolean(priority_key === 'high');

  return (
    <div className={styles.prio_icon}>
      <ul>
        <li
          className={cx({
            [styles.low_prio]: isLowPrio,
            [styles.medium_prio]: isMediumPrio,
            [styles.high_prio]: isHighPrio,
            [styles.is_default]: !isLowPrio && !isHighPrio && !isMediumPrio
          })}
        ></li>
        <li
          className={cx({
            [styles.medium_prio]: isMediumPrio,
            [styles.high_prio]: isHighPrio,
            [styles.is_default]: !isHighPrio && !isMediumPrio
          })}
        ></li>
        <li
          className={cx({
            [styles.high_prio]: isHighPrio,
            [styles.is_default]: !isHighPrio
          })}
        ></li>
      </ul>
      {priority_key && (
        <MessageHover
          className={styles.priority_box_tip}
          message={i18n(k.TASK_PRIORITIES_OBJ[priority_key].i18n)}
        />
      )}
    </div>
  );
};

export default PriorityIcon;
