import React, { useEffect, useMemo, useRef } from 'react';
import styles from './Mention.scss';
import cx from 'classnames';
import UserAvatar from 'src/components/user-avatar';
import k from 'src/constants/k';
import {
  isFunction,
  isNumber,
  isArray,
  toLower,
  descriptionTextContents,
  head,
  isEmpty,
  filter
} from 'src/helpers/utils';
import { Spinner } from 'evergreen-ui';
import { fromEvent } from 'rxjs';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'src/lib/bodyScrollLock.min';

const Mention = props => {
  const listRef = useRef();
  const bodyScrollDisabled = useRef(false);
  const {
    hasAccess = [],
    users = [],
    show = false,
    cursorBounds = null,
    getQuillEditor,
    startRange = null,
    getDescriptionDom
  } = props;

  const addingSub = useRef(false);
  const startRangeOnClick = useRef(startRange || null);
  const hide = useMemo(() => !show || !cursorBounds, [show, cursorBounds]);
  const mentionListUniqueClassname = 'mentionListUniqueClassname';
  const QuillEditor = useMemo(() => {
    return getQuillEditor ? getQuillEditor() : null;

    // eslint-disable-next-line
  }, [getQuillEditor, hide, hasAccess]);
  const descriptionContents = QuillEditor.getContents();
  const searchInput = useMemo(() => {
    if (
      !hide &&
      startRange &&
      isNumber(startRange.index) &&
      descriptionContents
    ) {
      const range = QuillEditor.getSelection(true);
      const fullTextOnly = descriptionTextContents(descriptionContents);

      if (range && isNumber(range.index)) {
        return fullTextOnly.substring(startRange.index + 1, range.index);
      }
    }

    return '';
  }, [QuillEditor, hide, startRange, descriptionContents]);
  const addSubscriber = props.addSubscriber;

  const descriptionDom = useMemo(() => {
    if (isFunction(getDescriptionDom)) {
      return getDescriptionDom();
    }

    return null;

    // eslint-disable-next-line
  }, [getDescriptionDom, users, QuillEditor]);

  const filtered = useMemo(
    () =>
      !searchInput
        ? users.filter(user => user?.firstName || user?.lastName)
        : isArray(users)
        ? users.filter(user => {
            return toLower(`${user?.firstName} ${user.lastName}`).includes(
              toLower(searchInput)
            );
          })
        : [],

    // eslint-disable-next-line
    [users, startRange, searchInput]
  );
  const stillLoading =
    users && !isEmpty(users.length) && head(users) && !head(users)?.firstName;

  useEffect(() => {
    if (startRange) {
      startRangeOnClick.current = startRange;
    } else if (QuillEditor && !startRangeOnClick.current) {
      startRangeOnClick.current = QuillEditor.getSelection(true);
    }
  }, [QuillEditor, startRange, users]);

  useEffect(() => {
    let subscriber = null;

    if (!listRef.current) {
      return;
    }

    subscriber = fromEvent(listRef.current, 'click').subscribe(evt => {
      const target = evt?.target;
      const Quill = window.Quill;
      const Delta = Quill.import('delta');
      const range = QuillEditor.getSelection(true);

      if (
        target &&
        target.classList &&
        target.classList.length > 1 &&
        target.classList.contains(mentionListUniqueClassname) &&
        isArray(users)
      ) {
        const userId = `${
          target.classList[target.classList.length - 1] || ''
        }`.trim();
        const find = userId
          ? users.filter(u => u && (u.id === userId || u.profile_id === userId))
          : [];
        const targetUser = head(find);
        if (targetUser) {
          const startIndex = startRangeOnClick?.current
            ? startRangeOnClick.current.index
            : range?.index;
          const newRange = startIndex + 2;
          const update = new Delta()
            .retain(startIndex)
            .delete(
              (isNumber(range?.index) ? range.index : startIndex) - startIndex
            );
          const text = `@${targetUser.firstName} ${targetUser.lastName}`.trim();
          const name = `${targetUser.firstName} ${targetUser.lastName}`.trim();

          if (!name) {
            return;
          }

          const op = {
            atinfo: {
              userId,
              text,
              name,
              description: ''
            }
          };

          if (isFunction(addSubscriber)) {
            addingSub.current = true;
            addSubscriber([userId], k.USER_TASK_ACCESS_SUBSCRIBER_AUTO);
          }

          QuillEditor.updateContents(
            update.insert(' '),
            update.insert(op),
            Quill.sources.USER
          );
          QuillEditor.setSelection(newRange, Quill.sources.SILENT);
          addingSub.current = false;
        }
      }

      startRangeOnClick.current = null;
    });

    return () => {
      subscriber.unsubscribe();
    };
  }, [
    users,
    QuillEditor,
    hide,
    startRange,
    filtered,
    searchInput,
    addSubscriber
  ]);

  const positionStyle = {
    right: cursorBounds
      ? cursorBounds.left > descriptionDom.clientWidth - 250
        ? '8px'
        : `calc(100% - ${
            (cursorBounds.left > 0 ? cursorBounds.left : 0) + 250
          }px)`
      : '0px',
    bottom: cursorBounds
      ? filtered.length > 0 && cursorBounds.top < 70
        ? 'calc(100% - 65px)' // 50px formats bar
        : `calc(100% - ${cursorBounds.top + 40}px)`
      : '100%'
  };

  useEffect(() => {
    if (QuillEditor) {
      if (isArray(hasAccess)) {
        const userIdsMentioned = isArray(descriptionContents?.ops)
          ? filter(
              descriptionContents.ops.map(currentOp =>
                !isEmpty(currentOp?.insert?.atinfo?.userId)
                  ? currentOp?.insert?.atinfo?.userId
                  : ''
              ),
              userId => userId && !isEmpty(userId)
            )
          : [];
        const subscriberIds = filter(
          hasAccess.map(
            currentSubscriber =>
              currentSubscriber?.id || currentSubscriber?.profile_id
          ),
          id => id && !isEmpty(id)
        );
        const newSubscriberIds = [];

        if (addingSub.current) {
          return;
        }

        if (
          isArray(filtered) &&
          !isEmpty(filtered) &&
          !isEmpty(userIdsMentioned) &&
          !isEmpty(head(filtered)?.firstName)
        ) {
          for (let i = 0; i < filtered.length; i++) {
            const user = filtered[i];
            const userId = user?.id || user?.profile_id;

            if (
              userId &&
              !isEmpty(userId) &&
              userIdsMentioned.includes(userId) &&
              !subscriberIds.includes(userId)
            ) {
              addingSub.current = true;
              newSubscriberIds.push(userId);
            }
          }

          if (!isEmpty(newSubscriberIds)) {
            addSubscriber(newSubscriberIds, k.USER_TASK_ACCESS_SUBSCRIBER_AUTO);
            addingSub.current = false;
          }
        }
      }
    }
  }, [
    QuillEditor,
    hasAccess,
    addSubscriber,
    filtered,
    descriptionContents?.ops
  ]);

  useEffect(() => {
    const dom = listRef.current;

    return () => {
      if (dom) {
        enableBodyScroll(dom);
      }
    };
  }, []);

  useEffect(() => {
    if (!listRef?.current) {
      return;
    }

    const dom = listRef.current;
    const noMention =
      (!filtered?.length && !stillLoading) ||
      !users?.length ||
      hide ||
      stillLoading;

    if (noMention && bodyScrollDisabled.current) {
      enableBodyScroll(dom);
      bodyScrollDisabled.current = false;
    } else if (!bodyScrollDisabled.current) {
      disableBodyScroll(dom);
      bodyScrollDisabled.current = true;
    }
  }, [hide, searchInput, filtered, users, stillLoading]);

  return (
    <div
      id="create-task-mention-wrap"
      className={cx(styles.mention, {
        [styles.mention_hide]: hide,
        [styles.mention_show]: !hide,
        [styles.hide_element]:
          (searchInput && !filtered?.length && !stillLoading) ||
          (!searchInput && !users?.length)
      })}
      style={positionStyle}
    >
      <div
        className={cx(styles.mention_loading, {
          [styles.hide_element]: !stillLoading
        })}
      >
        <div className={styles.flex_row_xy}>
          <Spinner height={20} width={20} />
        </div>
      </div>
      <ul ref={listRef} className={cx({ [styles.hide_element]: stillLoading })}>
        {filtered.map(user => {
          const userId = user?.id || user?.profile_id;
          const firstName = user?.firstName;
          const lastName = user?.lastName;
          const displayName = `${firstName} ${lastName}`;
          const hasImage = user?.image?.length > 1;
          const image = user?.image;

          return (
            <li key={userId}>
              <div className={styles.avatar}>
                <UserAvatar
                  noHover
                  firstName={firstName}
                  lastName={lastName}
                  has_image={hasImage}
                  image={hasImage ? image : ''}
                  initials={`${firstName}`.charAt(0) + `${lastName}`.charAt(0)}
                />
              </div>
              <p>{displayName}</p>
              <div
                className={cx(styles.cover, mentionListUniqueClassname, userId)}
              ></div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Mention;
