import React, { Component } from 'react';
import cx from 'classnames';
import ProfileAPI from 'src/profile-manager/API';
import styles from './CommentMobile.scss';
import AppAPI from 'src/app-manager/API';
import CommentInput from '../comment-input';
import k from 'src/constants/k';
import i18n from 'src/locales';
import { Capacitor } from '@capacitor/core';
import {
  isEmpty,
  isIOSMobile,
  isValidObject,
  isFunction
} from 'src/helpers/utils';
import { Button, IconButton, CrossIcon } from 'evergreen-ui';

class CommentMobile extends Component {
  newComment = () => {
    try {
      const { updateCommentMode } = this.props;

      if (isFunction(updateCommentMode)) {
        updateCommentMode(k.TASK_VIEW_COMMENT_MODE.new.key, null);
      }

      window.scrollTo(0, 0);
    } catch {}
  };

  closeOut = () => {
    try {
      const { updateCommentMode } = this.props;

      if (isFunction(updateCommentMode)) {
        updateCommentMode('', null);
      }

      window.scrollTo(0, 0);
    } catch {}
  };

  render() {
    const currentUser = ProfileAPI.USER_DATA.info();
    const { uRef = '', taskId = '', comment } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const { properties } = comment;
    const isLoading = comment?.loading;
    const isEditing =
      isValidObject(properties) &&
      !isEmpty(properties?.ref_id) &&
      !isEmpty(properties?.editingId) &&
      comment?.mode === k.TASK_VIEW_COMMENT_MODE.edit.key;
    const writing = comment?.writing;
    const allowedWrite = comment?.allowedWrite;
    const newComment = !isEditing && writing;
    const isIOSPlatform = Capacitor.getPlatform() === 'ios';
    const isIosPhoneDevice = isIOSMobile();

    if (!currentUser || isLoading || !allowedWrite) {
      return <></>;
    }

    return (
      <div
        className={cx(styles.comment_mobile, {
          [styles.comment_mobile_ios]: isIosPhoneDevice || isIOSPlatform
        })}
      >
        <div
          className={cx(styles.comment_mobile_raw, {
            [styles.comment_mobile_raw_dark]: isThemeDarkMode,
            [styles.comment_mobile_raw_editing]: isEditing
          })}
        >
          <div
            className={cx(styles.island, {
              [styles.island_dark]: isThemeDarkMode,
              [styles.island_editing]: newComment
            })}
          >
            <div></div>
          </div>

          {newComment && (
            <div
              className={cx(styles.close, {
                [styles.close_dark]: isThemeDarkMode
              })}
            >
              <IconButton
                appearance="minimal"
                icon={CrossIcon}
                onClick={this.closeOut}
              />
            </div>
          )}
          <div className={styles.content}>
            {isEditing && (
              <CommentInput
                mobileView
                showEdit
                autoEdit
                text={properties?.text}
                version={properties?.isV1 ? 'v1' : 'v2'}
                delta={properties?.gif ? '' : properties?.delta}
                mode={'edit'}
                taskId={taskId}
                uRef={uRef}
                refId={properties?.ref_id}
                id={properties?.editingId}
                editingId={properties?.editingId}
                gif={properties?.gif}
                setEditingId={properties?.setEditingId}
              />
            )}
            {newComment && (
              <CommentInput
                forceEdit={true}
                placeholder={i18n('task_view_comments_placeholder_for_author')}
                mode={'create'}
                taskId={taskId}
                uRef={uRef}
              />
            )}
            {!isEditing && !writing && (
              <div
                className={cx(styles.cta_write, {
                  [styles.cta_write_dark]: isThemeDarkMode
                })}
              >
                <Button appearance="minimal" onClick={this.newComment}>
                  <h4>{i18n('task_view_comments_placeholder_for_author')}</h4>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CommentMobile;
