import axios from 'axios';
import k from 'src/constants/k';
import Logger from 'src/lib/Logger';
import Store from 'src/lib/store';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import { isArray, isNumber, isString, getAuthHeaders } from 'src/helpers/utils';
import {
  getApiOpenBaseUrl,
  getApiDashboardUrlWhitelistedLocation
} from 'src/helpers/urls';
import { Subject } from 'rxjs';
import { v4 } from 'uuid';

class Spaces {
  profile_context = null;
  spacesPage = 1;
  spaces = [];
  spacesId = [];
  spacesInKeys = {};
  spaceSelected = '';
  spaceTagSelected = [];
  spaceSelectedForCreate = ''; // space id, if blank then user wants to create it under their personal space
  accumulatedSpacesInPage = {};
  totalSpaces = 0;
  totalPerPage = 20;
  initFetch = false;
  onSpacesListOBS = null;
  onSpaceMembersListOBS = null;
  onSpaceSelectOBS = null;
  onSpaceTagSelectOBS = null;
  onSpaceTagModifyOBS = null;
  forceFetchTaskListObs = null;

  constructor() {
    this.onSpacesListOBS = new Subject();
    this.onSpaceTagModifyOBS = new Subject();
    this.onSpaceMembersListOBS = new Subject();
    this.onSpaceSelectOBS = new Subject();
    this.onSpaceTagSelectOBS = new Subject();
    this.forceFetchTaskListObs = new Subject();
  }

  getContextState() {
    return this.profile_context?.state;
  }

  resetVars() {
    this.spacesPage = 1;
    this.spaces = [];
    this.spacesId = [];
    this.spacesInKeys = {};
    this.spaceSelected = '';
    this.spaceTagSelected = [];
    this.spaceSelectedForCreate = ''; // space id, if blank then user wants to create it under their personal space
    this.accumulatedSpacesInPage = {};
    this.totalSpaces = 0;
    this.totalPerPage = 20;
    this.initFetch = false;
  }

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  doneWithInitialFetch() {
    return this.initFetch;
  }

  reset() {
    this.clearSpaceSelected();

    this.spacesPage = 1;
    this.spaces = [];
    this.spacesId = [];
    this.spacesInKeys = {};
    this.spaceSelected = '';
    this.spaceTagSelected = [];
    this.spaceSelectedForCreate = '';
    this.accumulatedSpacesInPage = {};
    this.totalSpaces = 0;
    this.totalPerPage = 20;
    this.initFetch = false;
  }

  isUserPremiumWithSpace() {
    return Boolean(
      this.profile_context &&
        this.profile_context.state &&
        this.profile_context.state.user &&
        this.profile_context.state.user.plan &&
        this.profile_context.state.user.plan.isPremiumTeams &&
        !this.profile_context.state.user.plan.isExpired
    );
  }

  signalForceFetchTaskList() {
    if (this.forceFetchTaskListObs) {
      this.forceFetchTaskListObs.next();
    }
  }

  onforceFetchTaskList(fn) {
    if (this.forceFetchTaskListObs) {
      return this.forceFetchTaskListObs.subscribe({ next: fn });
    }

    return null;
  }

  onSpacesList(fn) {
    if (this.onSpacesListOBS && typeof fn === 'function') {
      return this.onSpacesListOBS.subscribe({ next: fn });
    }

    return null;
  }

  onSpaceTagModify(fn) {
    if (this.onSpaceTagModifyOBS && typeof fn === 'function') {
      return this.onSpaceTagModifyOBS.subscribe({ next: fn });
    }

    return null;
  }

  onSpaceSelectedUpdate(fn) {
    if (this.onSpaceSelectOBS && typeof fn === 'function') {
      return this.onSpaceSelectOBS.subscribe({ next: fn });
    }

    return null;
  }

  onSpaceTagSelectUpdate(fn) {
    if (this.onSpaceTagSelectOBS && typeof fn === 'function') {
      return this.onSpaceTagSelectOBS.subscribe({ next: fn });
    }

    return null;
  }

  onSpaceMembersList(fn) {
    if (this.onSpaceMembersListOBS && typeof fn === 'function') {
      return this.onSpaceMembersListOBS.subscribe({ next: fn });
    }

    return null;
  }

  idExists(id = '') {
    return this.spacesId.includes(id);
  }

  getSpaceById(id = '') {
    try {
      for (let i = 0; i < this.spaces.length; i++) {
        const space = this.spaces[i];

        if (space && id && (space.id === id || space.space_id === id)) {
          return { space: this.spaces[i], index: i };
        }
      }

      return null;
    } catch {
      return null;
    }
  }

  getMemberById(userId = '', spaceId = '') {
    const { space } = this.getSpaceById(spaceId);

    if (space) {
      const members = space.members;

      if (members && typeof members === 'object') {
        const pages = Object.keys(members);

        for (const page of pages) {
          const membersInPage = space.members[`${page}`];

          if (membersInPage && isArray(membersInPage)) {
            for (let i = 0; i < membersInPage.length; i++) {
              const member = space.members[`${page}`][i];

              if (
                member &&
                typeof member === 'object' &&
                (member.profile_id === userId || member.id === userId)
              ) {
                return {
                  page,
                  index: i,
                  user: member
                };
              }
            }
          }
        }
      }
    }

    return null;
  }

  async getJoinedSpaces() {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.getContextState();

        if (!isLoggedIn || !this.isUserPremiumWithSpace()) {
          Logger.log(
            `Tried fetching space but not logged in or subscription plan is incorrect`
          );

          return [];
        }

        if (!this.initFetch) {
          this.initFetch = true;
        }

        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          baseURL,
          headers,
          method: 'GET',
          url: `/v1/spaces/joined-list?auth_token_id=${id}&lb=${lb}&n=${n}&p=${this.spacesPage}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res) {
          if (res.data && res.data.valid) {
            this.accumulatedSpacesInPage[`${this.spacesPage}`] =
              res.data.spaces;

            if (!this.totalSpaces) {
              this.totalSpaces = res.data.total || 0;
            }

            if (res.data.perPage) {
              this.totalPerPage = res.data.perPage;
            }

            for (let i = 0; i < res.data.spaces.length; i++) {
              const space = res.data.spaces[i];

              if (space) {
                const existsInSpacesId = this.spacesId.indexOf(space.space_id);

                if (
                  existsInSpacesId < 0 ||
                  (existsInSpacesId >= 0 &&
                    this.spacesInKeys[space.space_id] &&
                    (this.spacesInKeys[space.space_id].name !== space.name ||
                      this.spacesInKeys[space.space_id].bio !== space.bio ||
                      (space.avatar &&
                        this.spacesInKeys[space.space_id].avatar !==
                          space.avatar)))
                ) {
                  if (existsInSpacesId < 0) {
                    this.spaces.push(space);
                  } else {
                    // just update information
                    const { index } = this.getSpaceById(space.space_id);

                    if (index > -1) {
                      this.spaces[index] = { ...space, ...this.spaces[index] };
                    }
                  }

                  if (existsInSpacesId < 0) {
                    this.spacesId.push(space.space_id);
                  }

                  this.spacesInKeys[space.space_id] = space;
                }
              }
            }

            if (
              this.totalSpaces === this.spacesId.length ||
              (this.totalSpaces < k.MAX_USER_SPACES_FETCH_BATCH &&
                this.spacesPage === 1)
            ) {
              this.onSpacesListOBS.next({
                spaces: this.spaces
              });
              await this.getJoinedSpacesInfo();
            } else {
              this.spacesPage += 1;
              this.getJoinedSpaces();
            }

            return this.spaces;
          } else if (res.data.message) {
            throw new Error(res.data.message);
          } else {
            throw new Error(
              `Wrong with status: ${res.status} and statusText: ${res.statusText}`
            );
          }
        } else return [];
      } catch (err) {
        Logger.log(
          `Something went wrong when fetching user's joined spaces list: ${err.message}`
        );

        return [];
      }
    } else {
      return [];
    }
  }

  async create(name = '') {
    const result = {
      err: true,
      try_again: false,
      taken: false,
      message: '',
      not_allowed_plan: false
    };

    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { isLoggedIn, user } = this.getContextState();

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        // @todo finish endpoint for creating a new space

        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          baseURL,
          headers,
          method: 'POST',
          data: {
            name
          },
          url: `/v1/spaces/create?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          result.err = false;
          this.totalSpaces += 1;
          this.spacesPage = 1;
          await this.getJoinedSpaces();
        } else if (res) {
          result.try_again = res.data && res.data.try_again;
          result.taken = res.data && res.data.name_taken;
          result.not_allowed_plan = res.data && res.data.not_allowed_plan;
        }
      }
    } catch (err) {
      result.err = true;
      if (err) {
        result.message = err.message;

        Logger.log(
          `Something went wrong when creating new space: ${name} due error: ${err.message}`
        );
      }
    }

    return result;
  }

  async edit(oldProps = {}, props = {}, space_id = '', ref_id = '') {
    const result = {
      err: true,
      try_again: false,
      message: '',
      not_allowed_plan: false
    };

    try {
      if (!props) {
        throw new Error('Invalid props');
      }

      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { isLoggedIn, user } = this.getContextState();

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        // @todo finish endpoint for creating a new space
        const editingName = typeof props.name === 'string';
        const editingBio = typeof props.bio === 'string';
        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          headers,
          baseURL,
          method: 'PUT',
          data: {
            ref_id,
            id: space_id,
            ...(editingName && { name: props.name }),
            ...(editingBio && { bio: props.bio })
          },

          url: `/v1/spaces/edit?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        const { space: spaceInfo } = this.getSpaceById(space_id);

        if (res && res.data && res.data.success) {
          if (spaceInfo) {
            if (editingName) {
              spaceInfo.name = props.name;
            }

            if (editingBio) {
              spaceInfo.bio = props.bio;
            }
          }

          if (editingBio || editingName) {
            this.onSpacesListOBS.next({
              spaces: this.spaces
            });
          }

          result.err = false;
        } else if (res && res.data && res.data.message) {
          if (spaceInfo) {
            if (editingName) {
              spaceInfo.name = oldProps.name;
            }

            if (editingBio) {
              spaceInfo.bio = oldProps.bio;
            }
          }

          if (editingBio || editingName) {
            this.onSpacesListOBS.next({
              spaces: this.spaces
            });
          }

          throw new Error(res.data.message);
        }
      }
    } catch (err) {
      result.err = true;
      if (err) {
        result.message = err.message;

        Logger.log(
          `Something went wrong when editing space: ${space_id} due error: ${err.message}`
        );
      }
    }

    return result;
  }

  get() {
    return this.spaces;
  }

  async getJoinedSpacesInfo() {
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      } else {
        Logger.log(`Getting joined spaces info`);
      }

      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        const spacesInfoAsyncArr = [];
        const spacesSettingsAsyncArr = [];
        const spacesMembersAsyncArr = [];

        for (let i = 0; i < this.spaces.length; i++) {
          const space = this.spaces[i];

          if (space) {
            spacesInfoAsyncArr.push(this.getInfo(space.space_id));
            spacesSettingsAsyncArr.push(
              this.getSettings(space.space_id, space.ref_id)
            );
          }
        }

        if (spacesInfoAsyncArr?.length > 0) {
          const spaceInfoResults = await Promise.all(spacesInfoAsyncArr);
          const spaceSettingsResults = await Promise.all(
            spacesSettingsAsyncArr
          );

          for (let i = 0; i < spaceInfoResults.length; i++) {
            const res = spaceInfoResults[i];
            const resSettings = spaceSettingsResults[i];
            const { space_id = '' } = res;

            if (res && !res.err && res.info) {
              const { space, index } = this.getSpaceById(`${space_id}`) || {};

              if (typeof index === 'number' && this.spaces[index]) {
                const tagsTotal = res.info.tagsTotal;

                this.spaces[index] = {
                  ...space,
                  tasks: {
                    active: {},
                    inactive: {},
                    created: {},
                    archived: {}
                  },
                  bio: res.info.bio,
                  id: res.info.id,
                  name: res.info.name,
                  creator: res.info.creator,
                  created_str: res.info.created_str,
                  created_mins: res.info.created_mins,
                  tags: res.info.tags || {}, // space tags
                  tagsTotal: res.info.tagsTotal || 0,
                  tagsPageFetched: [],
                  ref_id: space.ref_id,
                  members: {},
                  membersTotal: 0,
                  membersDoneFetch: false,
                  membersPageFetched: [],
                  membersCurrentPage: 1,
                  ...(resSettings &&
                    !resSettings.err && { settings: resSettings.settings }),
                  ...(space &&
                    !space.image && {
                      image: res.info.image,
                      imageSizeKB: res.info.imageSizeKB
                    })
                };

                this.onSpacesListOBS.next({
                  spaces: this.spaces
                });

                spacesMembersAsyncArr.push(
                  this.getMembers(1, space.space_id, space.ref_id, true)
                );

                if (tagsTotal > 0 || true) {
                  // fetch tags initial list
                  spacesMembersAsyncArr.push(
                    this.getTagsList(1, space.space_id, true)
                  );
                }
              }
            }
          }

          if (spacesMembersAsyncArr?.length > 0) {
            await Promise.all(spacesMembersAsyncArr);
          }

          this.onSpacesListOBS.next({
            spaces: this.spaces
          });
        }
      }
    } catch (err) {
      if (err) {
        Logger.log(
          `Failed to fetch all space info in list error:`,
          err.message
        );
      }
    }
  }

  async getSettings(space_id = '', member_ref_id = '') {
    const result = { err: true, settings: null };
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }
      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        const { token, id, lb, n, username_ref } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const res = await axios({
          baseURL,
          method: 'POST',
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            member_ref_id,
            id: space_id
          },
          url: `/v1/spaces/settings?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.valid && res.data.settings) {
          result.err = false;
          result.settings = res.data.settings;
        } else if (res) {
          throw new Error(
            `status: ${res.status} statusText: ${res.statusText} err: ${
              res.data.message || 'n/a'
            }`
          );
        }
      } else {
        throw new Error('Invalid user object');
      }
    } catch (err) {
      result.err = true;
      Logger.log(`Failed to get settings for space with id: ${space_id}`);
    }

    return result;
  }

  async getInfo(space_id = '') {
    const result = { space_id, err: true, info: null };
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        const { token, id, lb, n, username_ref } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const res = await axios({
          baseURL,
          method: 'POST',
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            id: space_id
          },
          url: `/v1/spaces/info?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.valid && res.data.space) {
          result.err = false;
          result.info = res.data.space;
        } else if (res) {
          throw new Error(
            `status: ${res.status} statusText: ${res.statusText} err: ${
              res.data.message || 'n/a'
            }`
          );
        }
      }
    } catch (err) {
      Logger.log(`Failed to get info for space with id: ${space_id}`);
    }

    return result;
  }

  async getMembers(
    page = 0,
    space_id = '',
    member_ref_id = '',
    fetchNext = false
  ) {
    try {
      member_ref_id = `${member_ref_id}`;
      space_id = `${space_id}`;

      if (!this.profile_context) {
        throw new Error('No profile_context loaded');
      } else if (!space_id) {
        throw new Error('No id provided');
      } else if (!page) {
        throw new Error('Invalid page');
      } else if (!member_ref_id) {
        throw new Error('No member_ref_id provided');
      }

      const { isLoggedIn, user } = this.profile_context.state;
      const spaceId = space_id;

      if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const countryCode = AppAPI.getGlobalConfig('country');
      const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
      const res = await axios({
        baseURL,
        method: 'POST',
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          page,
          member_ref_id,
          id: spaceId
        },
        url: `/v1/spaces/members?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && !res.data.err && res.data.valid && res.data.value) {
        // store members
        const { space } = this.getSpaceById(space_id);
        const total = res.data.total;

        if (space) {
          // update field
          space.members[`${page}`] = res.data.value;
          this.updateSpaceProperties(space_id, {
            members: space.members,
            membersTotal: total,
            membersDoneFetch: !!(
              res.data.value && total === res.data.value.length
            ),
            membersCurrentPage: page
          });

          if (res.data.has_next && fetchNext) {
            // if it's initial fetch then we fetch the next pages if it has it-
            await this.getMembers(page + 1, space_id, member_ref_id, false);
            // @todo handle lots of members
            // for now we fetch upto 200 count members
          }

          if (this.onSpaceMembersListOBS && this.onSpaceMembersListOBS.next) {
            this.onSpaceMembersListOBS.next({
              spaceId: space_id,
              page,
              members: res.data.value
            });
          }
        }
      }
    } catch (err) {
      Logger.log(
        `Failed to fetch space's ${space_id} members due to error: ${err?.message}`
      );
    }
  }

  isMemberOnly(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    return !!(
      this.spaces.filter(space => {
        if (
          space &&
          (space.id === spaceId || space.space_id === spaceId) &&
          role &&
          isArray(role) &&
          !role.includes(k.SPACES_ROLES_ENUM.creator) &&
          !role.includes(k.SPACES_ROLES_ENUM.admin) &&
          role.includes(k.SPACES_ROLES_ENUM.member)
        ) {
          return true;
        }

        return false;
      })?.length > 0
    );
  }

  isMemberAdmin(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    return !!(
      this.spaces.filter(space => {
        if (
          space &&
          (space.id === spaceId || space.space_id === spaceId) &&
          role &&
          isArray(role) &&
          !role.includes(k.SPACES_ROLES_ENUM.creator) &&
          role.includes(k.SPACES_ROLES_ENUM.admin)
        ) {
          return true;
        }

        return false;
      })?.length > 0
    );
  }

  isUserCreator(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    return !!(
      this.spaces.filter(space => {
        if (
          space &&
          (space.id === spaceId || space.space_id === spaceId) &&
          role &&
          isArray(role) &&
          role.includes(k.SPACES_ROLES_ENUM.creator)
        ) {
          return true;
        }

        return false;
      })?.length > 0
    );
  }

  isAllowedToEditSpaceInfo(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    return !!(
      this.spaces.filter(space => {
        if (
          space &&
          (space.id === spaceId || space.space_id === spaceId) &&
          role &&
          isArray(role) &&
          (role.includes(k.SPACES_ROLES_ENUM.editSpace) ||
            role.includes(k.SPACES_ROLES_ENUM.creator) ||
            role.includes(k.SPACES_ROLES_ENUM.admin))
        ) {
          return true;
        }

        return false;
      })?.length > 0
    );
  }

  isAllowedToRemoveMembers(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    return !!(
      this.spaces.filter(space => {
        if (
          space &&
          (space.id === spaceId || space.space_id === spaceId) &&
          role &&
          Array.isArray(role) &&
          (role.includes(k.SPACES_ROLES_ENUM.removeMembers) ||
            role.includes(k.SPACES_ROLES_ENUM.creator) ||
            role.includes(k.SPACES_ROLES_ENUM.admin))
        ) {
          return true;
        }

        return false;
      })?.length > 0
    );
  }

  isAllowedToAddMembers(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    return !!(
      this.spaces.filter(space => {
        if (
          space &&
          (space.id === spaceId || space.space_id === spaceId) &&
          Array.isArray(role) &&
          (role.includes(k.SPACES_ROLES_ENUM.addMembers) ||
            role.includes(k.SPACES_ROLES_ENUM.creator) ||
            role.includes(k.SPACES_ROLES_ENUM.admin))
        ) {
          return true;
        }

        return false;
      })?.length > 0
    );
  }

  isAllowedToCreateTags(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    const find = this.spaces.filter(space => {
      if (
        space &&
        (space.id === spaceId || space.space_id === spaceId) &&
        Array.isArray(role) &&
        (role.includes(k.SPACES_ROLES_ENUM.createTag) ||
          role.includes(k.SPACES_ROLES_ENUM.editTag) ||
          role.includes(k.SPACES_ROLES_ENUM.creator) ||
          role.includes(k.SPACES_ROLES_ENUM.admin))
      ) {
        return true;
      }

      return false;
    });

    return !!(find && find.length > 0);
  }

  isAllowedToEditTags(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    const find = this.spaces.filter(space => {
      if (
        space &&
        (space.id === spaceId || space.space_id === spaceId) &&
        isArray(role) &&
        (role.includes(k.SPACES_ROLES_ENUM.editTag) ||
          role.includes(k.SPACES_ROLES_ENUM.creator) ||
          role.includes(k.SPACES_ROLES_ENUM.admin))
      ) {
        return true;
      }

      return false;
    });

    return !!(find && find.length > 0);
  }

  isAllowedToAssignRoles(role = [], spaceId = '') {
    if (!spaceId) {
      return false;
    }

    const find = this.spaces.filter(space => {
      if (
        space &&
        (space.id === spaceId || space.space_id === spaceId) &&
        isArray(role) &&
        (role.includes(k.SPACES_ROLES_ENUM.creator) ||
          role.includes(k.SPACES_ROLES_ENUM.admin) ||
          role.includes(k.SPACES_ROLES_ENUM.assignRoles) ||
          role.includes(k.SPACES_ROLES_ENUM.createRoles))
      ) {
        return true;
      }

      return false;
    });

    return !!(find && find.length > 0);
  }

  async updateDisplayImage(
    spaceId = '',
    spaceRefId = '',
    key = '',
    blob = Blob
  ) {
    const result = { err: true, url: '', maxSize: false };

    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (isLoggedIn && user && user.auth) {
          const formData = new FormData();
          const { token, id, lb, n, username_ref } = user.auth;
          formData.append('file', blob, `${v4()}${key ? `_${key}` : ''}`);
          const baseURL = getApiOpenBaseUrl();
          const res = await axios({
            baseURL,
            method: 'POST',
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`,
              'content-type': 'multipart/form-data'
            },
            data: formData,
            url: `/v1/file/upload/image/space?auth_token_id=${id}&lb=${lb}&n=${n}&ref_id=${spaceRefId}&space_id=${spaceId}&key=${key}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data && res.data.success) {
            result.err = false;
          } else {
            result.maxSize = res && res.data && res.data.max_size_reached;
          }
        }
      } catch (err) {
        Logger.log(`Failed to upload avatar for space: ${spaceRefId}`);
      }
    }

    return result;
  }

  updateSpaceProperties(id = '', props = {}, callSpacesListOBS = true) {
    if (typeof props !== 'object' || !props || !id || typeof id !== 'string') {
      return null;
    }

    const { space } = this.getSpaceById(id);

    if (!space) {
      return;
    }

    for (const prop in props) {
      space[`${prop}`] = props[prop];
    }

    if (callSpacesListOBS) {
      this.onSpacesListOBS.next({
        spaces: this.spaces
      });
    }

    return space;
  }

  async selectPersonalSpace() {
    try {
      const space = AppAPI.getGlobalConfig('space');
      space.selected = 'personal';
      await AppAPI.setGlobalConfig('space', space);
    } catch (err) {
      Logger.log(
        `Failed selectPersonalSpace due to error: ${err && err.message}`
      );
    }
  }

  getSpaceTagSelected() {
    if (isArray(this.spaceTagSelected)) {
      return this.spaceTagSelected;
    }

    return [];
  }

  setSpaceTagSelected(tags = []) {
    if (isArray(tags)) {
      this.spaceTagSelected = tags;

      if (this.onSpaceTagSelectOBS) {
        this.onSpaceTagSelectOBS.next({ tags });
      }
    }
  }

  clearSpaceTagSelected() {
    if (this.onSpaceTagSelectOBS) {
      this.spaceTagSelected = [];
      this.onSpaceTagSelectOBS.next({ tags: [] });
    }
  }

  setSpaceSelected(spaceId = '') {
    if (!spaceId) {
      return;
    }

    const { space } = this.getSpaceById(spaceId);

    if (space) {
      this.spaceSelected = spaceId;
    }

    if (this.onSpaceSelectOBS) {
      this.onSpaceSelectOBS.next({ spaceId });
    }
  }

  clearSpaceSelected() {
    this.spaceSelected = '';

    if (this.onSpaceSelectOBS) {
      this.onSpaceSelectOBS.next({ spaceId: '' });
    }
  }

  getSpaceSelected() {
    if (!this.spaceSelected) {
      return null;
    }

    const info = this.getSpaceById(this.spaceSelected);

    if (info) {
      return info.space;
    }

    return null;
  }

  /**
   * @param {string} spaceId
   * @returns {string} role from a joined space in translated string
   */
  getRoleInName(role = []) {
    if (role && Array.isArray(role) && role.length > 0) {
      if (role.includes(k.SPACES_ROLES_ENUM.creator)) {
        return i18n('space_role_creator');
      } else if (role.includes(k.SPACES_ROLES_ENUM.admin)) {
        return i18n('space_role_admin');
      } else if (role.includes(k.SPACES_ROLES_ENUM.member)) {
        return i18n('space_role_member');
      } else if (role.includes(k.SPACES_ROLES_ENUM.viewOnly)) {
        return i18n('space_role_view_only');
      }
    }

    if (!role) {
      return i18n('space_role_member');
    }

    return '';
  }

  // @todo
  checkIfExpired(data = null) {
    try {
      Logger.log(`Checking if user's chamu space plan has expired`);

      if (!data) {
        throw new Error('Empty data');
      }
    } catch (err) {
      Logger.log(
        `Failed checking user spaces plan has expired due to error: ${err?.message}`
      );
    }
  }

  async leaveAsMember(spaceId = '') {
    const result = { err: true, try_again: false };

    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { isLoggedIn, user } = this.profile_context.state;
      const { space } = this.getSpaceById(spaceId) || {};

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (!user || typeof user !== 'object') {
        throw new Error('Invalid user object');
      } else if (!space) {
        throw new Error('Cannot find space');
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          id: spaceId
        },
        url: `/v1/spaces/leave?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && res.data.success) {
        result.err = false;
        this.removeSpaceFromList(spaceId);
      } else {
        if (res && res.data && res.data.expired) {
          throw new Error('Plan expired');
        }

        result.try_again = res && res.data && res.data.try_again;
      }
    } catch (err) {
      Logger.log(`Failed to leaveAsMember due to error: ${err?.message}`);
      result.err = true;
    }

    return result;
  }

  async addMembers(userIds = [], spaceId = '') {
    const result = { err: true, newMembers: [], alreadyMembers: [] };
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      } else if (!userIds || !Array.isArray(userIds)) {
        throw new Error('Invalid user ids');
      }

      const { isLoggedIn, user } = this.profile_context.state;
      const { space } = this.getSpaceById(spaceId) || {};

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (!user || typeof user !== 'object') {
        throw new Error('Invalid user object');
      } else if (!space) {
        throw new Error('Cannot find space');
      }

      if (space) {
        if (userIds.length <= 1) {
          const userId = userIds[0];

          const { user = null, page = 0 } =
            this.getMemberById(userId, spaceId) || {};

          if (!!user && page >= 1) {
            // already a member

            result.err = false;
            result.alreadyMembers.push(userId);
            return result;
          }
        }
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          membersId: userIds,
          id: spaceId
        },
        url: `/v1/spaces/members/new?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data) {
        const {
          success = false,
          newMembers = [],
          alreadyMembers = []
        } = res.data;

        if (success) {
          result.err = false;
          result.newMembers = newMembers;
          result.alreadyMembers = alreadyMembers;
          const members = space.members;
          let membersTotal = space.membersTotal;
          let hasChange = false;

          if (newMembers && Array.isArray(newMembers)) {
            for (const newMember of newMembers) {
              if (newMember && newMember.profile_id) {
                for (const userId of userIds) {
                  if (userId === newMember.profile_id) {
                    // update field

                    if (members && typeof members === 'object') {
                      const membersFirstPage = members['1'];

                      if (membersFirstPage && Array.isArray(membersFirstPage)) {
                        const newMembersFirstPage = [
                          { ...newMember },
                          ...membersFirstPage
                        ];
                        members['1'] = newMembersFirstPage;
                        membersTotal += 1;

                        this.updateSpaceProperties(spaceId, {
                          membersTotal,
                          members: members
                        });

                        if (!hasChange) {
                          hasChange = true;
                        }
                      }
                    }
                  }
                }
              }
            }

            if (
              hasChange &&
              this.onSpaceMembersListOBS &&
              this.onSpaceMembersListOBS.next
            ) {
              this.onSpaceMembersListOBS.next({
                members,
                spaceId: spaceId,
                page: 1
              });
            }
          }
        }
      } else {
        throw new Error(
          `${(res && res.data && res.data.message) || ''} ${res.status} ${
            res.statusText
          }`
        );
      }
    } catch (err) {
      Logger.log(
        `Failed to add new members`,
        userIds,
        `due to error: ${err?.message}`
      );
    }

    return result;
  }

  async removeMembers(userIds = [], spaceId = '') {
    const result = {
      err: true,
      removedMembers: [],
      notMembers: [],
      toTryAgain: []
    };
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      } else if (!userIds || !Array.isArray(userIds)) {
        throw new Error('Invalid user ids');
      } else if (!spaceId) {
        throw new Error('Invalid space id');
      }

      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (!user || typeof user !== 'object') {
        throw new Error('Invalid user object');
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          membersId: userIds,
          id: spaceId
        },
        url: `/v1/spaces/members/remove?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data) {
        const {
          success = false,
          notMembers = [],
          removedMembers = [],
          toTryAgain = []
        } = res.data;
        const { space = null } = this.getSpaceById(spaceId);

        if (success) {
          // update list
          result.err = false;
          result.notMembers = notMembers;
          result.removedMembers = removedMembers;
          result.toTryAgain = toTryAgain;

          if (space) {
            const members = space.members;
            let membersTotal = space.membersTotal;
            let hasChange = false;

            if (members && typeof members === 'object') {
              if (removedMembers && Array.isArray(removedMembers)) {
                for (const removedUserId of removedMembers) {
                  const {
                    user = null,
                    index = -1,
                    page = 0
                  } = this.getMemberById(removedUserId, spaceId) || {};

                  if (user && index >= 0 && page >= 1) {
                    // remove

                    const membersInPage = members[`${page}`];

                    if (membersInPage && Array.isArray(membersInPage)) {
                      membersInPage.splice(index, 1);

                      if (typeof membersTotal === 'number') {
                        membersTotal -= 1;
                      }

                      this.updateSpaceProperties(spaceId, {
                        members: members,
                        ...(typeof membersTotal === 'number' && {
                          membersTotal
                        })
                      });

                      if (!hasChange) {
                        hasChange = true;
                      }
                    }
                  }
                }
              }
            }

            if (
              hasChange &&
              this.onSpaceMembersListOBS &&
              this.onSpaceMembersListOBS.next
            ) {
              this.onSpaceMembersListOBS.next({
                members,
                spaceId: spaceId
              });
            }
          }
        }
      } else {
        throw new Error(
          `${(res && res.data && res.data.message) || ''} ${res.status} ${
            res.statusText
          }`
        );
      }
    } catch (err) {
      result.err = true;

      Logger.log(
        `Failed to remove members`,
        userIds,
        `due to error: ${err?.message}`
      );
    }

    return result;
  }

  async archiveCreatedSpace(spaceId = '') {
    const result = { err: true };

    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { space } = this.getSpaceById(spaceId);

      if (!space) {
        throw new Error(`Cannot find space: ${spaceId}`);
      } else {
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn || !user) {
          throw new Error('Not logged in');
        }
        const payload = {
          member_ref_id: space.ref_id,
          id: space.space_id
        };

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: payload,
          url: `/v1/spaces/created/archive?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          result.err = false;
          this.removeSpaceFromList(spaceId);
        } else {
          throw new Error(`${res && res.status} ${res && res.statusText}`);
        }
      }
    } catch (err) {
      Logger.log(
        `archiveCreatedSpace:space: ${spaceId} error: ${err?.message}`
      );
      result.err = true;
    }

    return result;
  }

  async deleteCreatedSpace(spaceId = '') {
    const result = { err: true };
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { space } = this.getSpaceById(spaceId);

      if (!space) {
        throw new Error(`Cannot find space: ${spaceId}`);
      } else {
        // send api request
        // if successful
        // set personal space or nearest space as selected
        // call to update task-views
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn || !user) {
          throw new Error('Not logged in');
        }

        const payload = {
          member_ref_id: space.ref_id,
          id: space.space_id
        };
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'DELETE',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: payload,
          url: `/v1/spaces/created?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          // successfully deleted
          // remove space from list
          result.err = false;
          this.removeSpaceFromList(spaceId);
        } else {
          throw new Error(
            `${res && res.data && res.data.message} ${res.status} ${
              res.statusText
            }`
          );
        }
      }
    } catch (err) {
      Logger.log(
        `Failed to delete created space: ${spaceId} due to err: ${err?.message}`
      );
    }

    return result;
  }

  async removeSpaceFromList(spaceId = '') {
    try {
      if (!spaceId) {
        return;
      }

      const { index } = this.getSpaceById(spaceId);
      const spaceIdIndex = this.spacesId.indexOf(spaceId);

      await this.selectPersonalSpace();

      this.spaces.splice(index, 1);
      this.spacesId.splice(spaceIdIndex, 1);
      this.onSpacesListOBS.next({
        spaces: this.spaces
      });

      delete this.spacesInKeys[spaceId];
    } catch {}
  }

  setSpaceSelectedForCreate(spaceId = '') {
    if (spaceId !== 'personal') {
      this.spaceSelectedForCreate = spaceId;
    } else {
      this.spaceSelectedForCreate = '';
    }
  }

  getSpaceSelectedForCreate() {
    return this.spaceSelectedForCreate;
  }

  clearTaskList(page = 1, state = '', spaceId = '', tags = []) {
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (Number.isNaN(page) || page < 1) {
        throw new Error('Invalid page');
      }

      const { space, index } = this.getSpaceById(spaceId);
      const { isLoggedIn } = this.profile_context.state;

      if (!space) {
        throw new Error('Invalid space id');
      } else if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else {
        const tasks = this.spaces[index].tasks;
        const tagsInString = '';
        const pageKey = `page${page}${tagsInString}`;

        if (tasks && tasks[state][pageKey]) {
          this.spaces[index].tasks[state][pageKey] = null;
        }
      }
    } catch (err) {
      Logger.log(`Failed clearTaskList error: ${err && err.message}`);
    }
  }

  async getTagsFromPage(page = 1, spaceId = '') {
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!isNumber(page) || page < 1) {
        throw new Error('Invalid page');
      } else if (!spaceId) {
        throw new Error('Invalid space id');
      } else {
        const { space, index } = this.getSpaceById(spaceId);
        const { user } = this.profile_context.state;

        if (!space || index < 0) {
          throw new Error('space not found');
        } else if (!user) {
          throw new Error('Not logged in');
        }
      }
    } catch (err) {
      Logger.log(
        `Failed in getTagsFromPage due to error: ${err && err.message}`
      );
      return [];
    }
  }

  async getTagsList(page = 1, spaceId = '', fetchNext = true) {
    const result = {
      err: true,
      value: [],
      total: 0,
      hasNext: false,
      perBatch: 30,
      notAllowedPlan: false
    };

    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!isNumber(page) || page < 1) {
        throw new Error('Invalid page');
      } else if (!spaceId) {
        throw new Error('Invalid space id');
      } else {
        const { space, index } = this.getSpaceById(spaceId);
        const { user } = this.profile_context.state;

        if (!space) {
          throw new Error('space not found');
        } else if (!user) {
          throw new Error('Not logged in');
        }

        Logger.log(
          `Fetching tags list from space: ${space.name} ${spaceId} on page: ${page}`
        );
        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          baseURL,
          headers,
          method: 'GET',
          url: `/v1/spaces/tags?auth_token_id=${id}&lb=${lb}&n=${n}&p=${page}&spaceId=${spaceId}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.valid) {
          const {
            total = 0,
            perBatch = 30,
            hasNext = false,
            tags = []
          } = res.data;

          result.total = total;
          result.perBatch = perBatch;
          result.hasNext = hasNext;
          result.value = tags;
          result.err = false;

          if (index > -1) {
            if (!space.tags) {
              space['tags'] = {};
            }

            space.tags[`page${page}`] = tags;
            space.tagsTotal = total;

            if (
              isArray(space.tagsPageFetched) &&
              !space.tagsPageFetched.includes(page)
            ) {
              space.tagsPageFetched.push(page);
            }
          }

          if (fetchNext && hasNext) {
            this.getTagsList(page + 1, spaceId, true);
          } else if (!hasNext) {
            this.onSpacesListOBS.next({
              spaces: this.spaces
            });
          }
        } else {
          if (res && res.data && isString(res.data.message)) {
            throw new Error(res.data.message);
          } else {
            throw new Error(`status: ${res.status} ${res.statusText}`);
          }
        }
      }
    } catch (err) {
      result.err = true;
      Logger.log(`Failed in getTagsList due to error: ${err && err.message}`);
    }

    return result;
  }

  async getTasksList(
    page = 1,
    state = '',
    spaceId = '',
    tags = [],
    prio = '',
    forceRefresh = false
  ) {
    const result = {
      err: true,
      value: [],
      total: 0,
      currentActiveCount: 0,
      currentInactiveCount: 0,
      hasNext: false
    };
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (Number.isNaN(page) || page < 1) {
        throw new Error('Invalid page');
      }

      const { space, index } = this.getSpaceById(spaceId);
      const { isLoggedIn, user } = this.profile_context.state;
      const tagsInString = '';
      const pageKey = `page${page}${tagsInString}`;

      if (!space) {
        throw new Error('Invalid space id');
      } else if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else if (space) {
        const tasks = this.spaces[index].tasks;
        if (tasks && tasks[state][pageKey] && !forceRefresh) {
          // cached
          if (tasks[state][pageKey] && tasks[state][pageKey].list.length > 2) {
            const {
              list = [],
              total = 0,
              hasNext = false,
              currentActiveCount = 0,
              currentInactiveCount = 0
            } = tasks[state][pageKey];

            result.err = false;
            result.value = list;
            result.total = total;
            result.hasNext = hasNext;
            result.currentActiveCount = currentActiveCount;
            result.currentInactiveCount = currentInactiveCount;
            return result;
          }
        }
      }

      Logger.log(
        `Fetching task list from space: ${space.name} ${spaceId} on page: ${page} task state: ${state}`
      );
      const { id, lb, n } = user.auth;
      const countryCode = AppAPI.getGlobalConfig('country');
      const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
      const headers = getAuthHeaders(user);
      const res = await axios({
        headers,
        baseURL,
        method: 'GET',
        url: `/v1/spaces/tasks/list?auth_token_id=${id}&lb=${lb}&n=${n}&kind=${state}&p=${page}&spaceId=${spaceId}&tags=${JSON.stringify(
          tags
        )}${prio ? `&prio=${prio}` : ''}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && res.data.valid) {
        const { space, index } = this.getSpaceById(spaceId);
        const tasks = this.spaces[index].tasks;

        const {
          currentActiveCount,
          currentInactiveCount,
          hasNext,
          value = [],
          total
        } = res.data;

        if (this.spaces[index] && space && space.id === spaceId) {
          if (!tasks) {
            this.spaces[index].tasks = {
              active: {},
              inactive: {},
              created: {},
              archived: {}
            };
          }

          if (tasks[state]) {
            this.spaces[index].tasks[state][pageKey] = {
              total,
              hasNext,
              currentActiveCount,
              currentInactiveCount,
              list: value
            };
          }
        }

        result.err = false;
        result.total = res.data.total;
        result.value = res.data.value;
        result.hasNext = hasNext;
        result.currentActiveCount = currentActiveCount;
        result.currentInactiveCount = currentInactiveCount;
      } else {
        throw new Error(
          `${res && res.data && res.data.message} ${res.status} ${
            res.statusText
          }`
        );
      }
    } catch (err) {
      result.err = true;
      Logger.log(
        `Failed in getSpaceTasksList due to error: ${err && err.message}`
      );
    }

    return result;
  }

  async removeTag(tags = [], spaceId = '') {
    const result = { err: true, toTryAgain: [] };

    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      } else if (tags.length > 1) {
        throw new Error('Only one tag allowed for now');
      } else if (!isString(tags[0])) {
        throw new Error('Invalid tag id');
      }

      const { space } = this.getSpaceById(spaceId);
      const { isLoggedIn, user } = this.profile_context.state;

      if (!space) {
        throw new Error('space not found');
      } else if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          baseURL,
          headers,
          method: 'POST',
          data: {
            tags,
            id: spaceId
          },
          url: `/v1/spaces/tags/remove?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          if (isArray(res.data.toTryAgain) && res.data.toTryAgain.length > 0) {
            result.toTryAgain = res.data.toTryAgain;
          } else {
            result.err = false;
            // @todo address for tags > 100  or more than 2 two pages
            await this.getTagsList(1, spaceId, true);
            this.onSpaceTagModifyOBS.next({
              type: 'remove'
            });
          }
        } else if (res && res.data) {
          result.toTryAgain = res.data.toTryAgain || [];

          if (isString(res.data.message)) {
            throw new Error(res.data.message);
          }
        }
      }
    } catch (err) {
      Logger.log(`Failed in removeTag due to error: ${err && err.message}`);
      result.err = true;
    }

    return result;
  }

  async editTag(tags = [], spaceId = '') {
    const result = { err: true, toTryAgain: [] };

    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      } else if (tags.length > 1) {
        throw new Error('Only one tag allowed for now');
      }

      const { space } = this.getSpaceById(spaceId);
      const { isLoggedIn, user } = this.profile_context.state;

      if (!space) {
        throw new Error('space not found');
      } else if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          baseURL,
          headers,
          method: 'POST',
          data: {
            tags,
            id: spaceId
          },
          url: `/v1/spaces/tags/edit?auth_token_id=${id}&lb=${lb}&n=${n}&spaceId=${spaceId}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          result.err = false;
          const { index } = this.getSpaceById(spaceId) || {};
          const tagId = tags[0].id;
          const newName = tags[0].tagName;

          if (index > -1) {
            const space = this.spaces[index];
            if (space && space.id === spaceId) {
              const spaceTags = space.tags;
              try {
                for (const page in spaceTags) {
                  const currentTags = spaceTags[page];

                  if (isArray(currentTags)) {
                    for (let i = 0; i < currentTags.length; i++) {
                      const tagInfo = currentTags[i];

                      if (tagInfo && tagInfo.id === tagId) {
                        tagInfo.tag = newName;
                        break;
                      }
                    }
                  }
                }
              } catch {}
            }

            this.onSpaceTagModifyOBS.next({
              type: 'edit'
            });
          }
        } else if (res && res.data) {
          result.toTryAgain = res.data.toTryAgain || [];

          if (res.data.message) {
            throw new Error(res.data.message);
          }
        }
      }
    } catch (err) {
      Logger.log(`Failed in editTag due to error: ${err && err.message}`);
      result.err = true;
    }

    return result;
  }

  async createTag(tags = [], spaceId = '') {
    const result = { err: true, toTryAgain: [] };

    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      }

      const { space } = this.getSpaceById(spaceId);
      const { isLoggedIn, user } = this.profile_context.state;

      if (!space) {
        throw new Error('space not found');
      } else if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { id, lb, n } = user.auth;
        const countryCode = AppAPI.getGlobalConfig('country');
        const baseURL = getApiDashboardUrlWhitelistedLocation(countryCode);
        const headers = getAuthHeaders(user);
        const res = await axios({
          baseURL,
          method: 'POST',
          headers,
          data: {
            tags,
            id: spaceId
          },
          url: `/v1/spaces/tags/new?auth_token_id=${id}&lb=${lb}&n=${n}&spaceId=${spaceId}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          const { toTryAgain = [] } = res.data;
          const oneTagOnly = tags.length < 2;

          if (isArray(toTryAgain)) {
            if (
              oneTagOnly &&
              toTryAgain.length > 0 &&
              toTryAgain.includes(tags[0])
            ) {
              result.err = true;
            } else {
              result.err = false;
            }

            result.toTryAgain = toTryAgain;
          } else {
            result.err = false;
          }

          if (!result.err) {
            await this.getTagsList(1, spaceId, true);
          }
        } else {
          if (res && res.data && res.data.message) {
            throw new Error(res.data.message);
          }
        }
      }
    } catch (err) {
      result.err = true;
      Logger.log(`Failed in createTag due to error: ${err && err.message}`);
    }

    return result;
  }

  async archiveTask(taskId = '', uRef = '', spaceId = '') {
    const result = { success: false };
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      } else if (!taskId) {
        throw new Error('Invalid task id');
      } else if (!uRef) {
        throw new Error('Invalid uRef');
      }

      const { space } = this.getSpaceById(spaceId);
      const { isLoggedIn, user } = this.profile_context.state;

      if (!space) {
        throw new Error('space not found');
      } else if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            taskId,
            uRef,
            spaceId
          },
          url: `/v1/spaces/tasks/archive?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          // refresh task list
          result.success = true;
        } else {
          if (res && res.data && res.data.message) {
            throw new Error(res.data.message);
          } else {
            throw new Error(
              `status: ${res.status}, statusText: ${res.statusText}`
            );
          }
        }
      }
    } catch (err) {
      result.err = true;
      Logger.log(`Failed in archiveTask due to error: ${err && err.message}`);
    }

    return result;
  }

  async unarchiveTask(taskId = '', uRef = '', spaceId = '') {
    const result = { success: false };
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      } else if (!taskId) {
        throw new Error('Invalid task id');
      } else if (!uRef) {
        throw new Error('Invalid uRef');
      }

      const { space } = this.getSpaceById(spaceId);
      const { isLoggedIn, user } = this.profile_context.state;

      if (!space) {
        throw new Error('space not found');
      } else if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            taskId,
            uRef,
            spaceId
          },
          url: `/v1/spaces/tasks/unarchive?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          // refresh task list
          result.success = true;
        } else {
          if (res && res.data && res.data.message) {
            throw new Error(res.data.message);
          } else {
            throw new Error(
              `status: ${res.status}, statusText: ${res.statusText}`
            );
          }
        }
      }
    } catch (err) {
      result.err = true;
      Logger.log(`Failed in unarchiveTask due to error: ${err && err.message}`);
    }

    return result;
  }

  async updateMembersRole(membersIdWithRole = [], spaceId = '') {
    const result = { err: true, toTryAgain: [] };
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      } else if (!isArray(membersIdWithRole)) {
        throw new Error('Invalid membersIdWithRole data type');
      }

      const { space } = this.getSpaceById(spaceId);
      const { isLoggedIn, user } = this.profile_context.state;

      if (!space) {
        throw new Error('space not found');
      } else if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { membersIdWithRole, spaceId },
          url: `/v1/spaces/update/member-role?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          if (res.data.err) {
            throw new Error(res.data.message);
          } else {
            result.err = false;
            // update local copy

            if (space) {
              const settings = space ? space.settings : null;
              const availableRoles =
                space && settings ? settings.availableRoles : [];
              const { roleId, userId } = membersIdWithRole[0] || {};

              // @todo for multiple roles and users
              for (let i = 0; i < availableRoles.length; i++) {
                const roleInfoSelected = availableRoles[i];
                if (
                  roleInfoSelected &&
                  isArray(roleInfoSelected.role) &&
                  roleInfoSelected.id === roleId
                ) {
                  const newRole = [...roleInfoSelected.role];

                  for (const page in space.members) {
                    const currentPageMembers = space.members[`${page}`];

                    if (currentPageMembers && currentPageMembers.length) {
                      for (const member of currentPageMembers) {
                        if (
                          member &&
                          (member.id === userId || member.profile_id === userId)
                        ) {
                          member.role = newRole;

                          if (this.onSpaceMembersListOBS) {
                            this.onSpaceMembersListOBS.next({
                              spaceId,
                              members: space.members
                            });
                          }
                        }
                      }

                      break;
                    }
                  }

                  break;
                }
              }
            }
          }
        } else {
          throw new Error(`${res.status} ${res.statusText}`);
        }
      }
    } catch (err) {
      result.err = true;
      Logger.log(`Failed:updateMembersRole:error: ${err && err.message}`);
    }

    return result;
  }

  // tags: tagId[]
  async attachTagsToTask(
    tags = [],
    spaceId = '',
    taskId = '',
    uRef = '',
    opts = { fetchTaskList: true }
  ) {
    const result = { err: true };
    const { fetchTaskList = true } = opts;
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      }
      const { isLoggedIn, user } = this.getContextState();

      if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { id, lb, n } = user.auth;
        const headers = getAuthHeaders(user);
        const baseURL = getApiOpenBaseUrl();
        const res = await axios({
          headers,
          baseURL,
          method: 'POST',
          data: { tags, taskId, uRef, spaceId },
          url: `/v1/task/space/attach-tags?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          result.err = false;

          if (fetchTaskList) {
            this.signalForceFetchTaskList();
          }
        } else if (res && res.data && res.data.message) {
          throw new Error(res.data.message);
        } else {
          throw new Error(`${res?.status} ${res?.statusText}`);
        }
      }
    } catch (err) {
      Logger.log(`Failed:attachTagsToTask:${err && err.message}`);
    }

    return result;
  }

  // tags: tagId[]
  async removeTagsFromTask(
    tags = [],
    spaceId = '',
    taskId = '',
    uRef = '',
    opts = { fetchTaskList: true }
  ) {
    const result = { err: true };
    const { fetchTaskList = true } = opts;
    try {
      if (!this.profile_context) {
        throw new Error('Empty profile_context');
      } else if (!spaceId) {
        throw new Error('Invalid space');
      }
      const { isLoggedIn, user } = this.getContextState();

      if (!isLoggedIn || !user) {
        throw new Error('Not logged in');
      } else {
        const { id, lb, n } = user.auth;
        const headers = getAuthHeaders(user);
        const baseURL = getApiOpenBaseUrl();
        const res = await axios({
          headers,
          baseURL,
          method: 'POST',
          data: { tags, taskId, uRef, spaceId },
          url: `/v1/task/space/remove-tags?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          result.err = false;

          if (fetchTaskList) {
            this.signalForceFetchTaskList();
          }
        } else if (res && res.data && res.data.message) {
          throw new Error(res.data.message);
        } else {
          throw new Error(`${res?.status} ${res?.statusText}`);
        }
      }
    } catch (err) {
      Logger.log(`Failed:removeTagsFromTask:${err && err.message}`);
    }

    return result;
  }
}

export default Spaces;
