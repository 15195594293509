import React, { Component, createContext, forwardRef } from 'react';
import AppFlow from './Flow';
import Store from 'src/lib/store';
import AppAPI from './API';
import i18n from 'src/locales';
import UserLog from 'src/lib/UserLog';
import k from 'src/constants/k';
import { withErrorManagerSettings } from 'src/error-manager';
import * as QLTemplate from 'src/lib/quill-template';
import { withRouter } from 'react-router-dom';

const { Consumer, Provider } = createContext();

export function withGlobalSettings(WrappedComponent) {
  return forwardRef((props, ref) => {
    return (
      <Consumer>
        {value => <WrappedComponent {...props} ref={ref} {...value} />}
      </Consumer>
    );
  });
}

class AppManager extends Component {
  APP_FLOW = null;
  APP_CB_UNLOAD = [];

  state = {
    mounted: false,
    loaded: false,
    location: { pathname: '' },
    localConfigs: {
      rememberLogin: true,
      taskDraft: { valid: false, desc: '', title: '', priority: -1 },
      lastCreatedTask: {
        userId: '',
        title: '',
        taskId: '',
        uRef: '',
        spaceId: '',
        description_delta: JSON.stringify({ ops: [] }),
        exposure: k.USER_TASK_PRIVACY_PRIVATE,
        exposurePasscode: '',
        subscribers: [],
        priority: k.TASK_PRIORITIES_OBJ.normal.idx,
        tags: [],
        spaceTags: [],
        valid: false
      },
      lastEditedTask: {
        title: '',
        taskId: '',
        taskState: '',
        uRef: '',
        description_delta: JSON.stringify({ ops: [] }),
        exposure: k.USER_TASK_PRIVACY_PRIVATE,
        exposurePasscode: '',
        newSubscribers: [],
        removeSubscribers: [],
        priority: k.TASK_PRIORITIES_OBJ.normal.idx,
        tags: [],
        spaceTags: [],
        valid: false
      },
      recentSearch: [],
      darkMode: false,
      themePreference: k.APP_THEME.light,
      onboarding: {
        welcome: true,
        free1MonthPremium: false
      },
      tasks: {
        selectedTags: [],
        selectedState: []
      }
    },
    globalConfigs: {
      /**
       * global configs aren't persist as user session, we don't store it in LocalStorage, we have localConfigs for that
       */
      theme: '', // can 'light' or 'dark' this field is now deprecated
      successProbe: false,
      taskViewColumn: 0,
      taskListFilter: { all: true, is_tag: false, tag_id: '' },
      taskInspect: { active: false, title: '', taskID: '' },
      country: 'US',
      region: '',
      IPs: [],
      taskToEditProps: {
        valid: false,
        title: '',
        desc: {},
        tags: [],
        spaceTags: [],
        exposure: 0,
        priority: 0,
        created: -1,
        task_state: ''
      },
      toUserBeta: {
        username: '',
        pw: '',
        valid: false,
        lb: '',
        n: -1
      },
      toLoginUser: {
        username: '',
        pw: '',
        valid: false
      },
      lastEdited: {
        taskId: '',
        spaceId: '',
        uRef: '',
        title: '',
        created: 0,
        lastUpdated: 0,
        description: JSON.stringify({ ops: [] }),
        exposure: -1,
        exposurePasscode: '',
        priority: 0,
        files: [],
        tags: [],
        spaceTags: [],
        spaceInfo: {},
        spaceTagsInfo: {},
        taskState: '',
        author: '',
        author_id: '',
        subscribers: [],
        subscriberEditing: false,
        views: 0,
        viewsValid: false,
        owner: false,
        valid: false,
        noApiRequest: false
      },
      lastScroll: {
        x: 0,
        y: 0
      },
      space: {
        selected: ''
      }
    }
  };

  constructor() {
    super();

    this.setHTMLMeta = this.setHTMLMeta.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    AppAPI.setContext(this);

    if (AppAPI.isDarkMode()) {
      AppAPI.applyDarkMode();
    }

    this.APP_FLOW = new AppFlow(this);
    this.setState(
      { mounted: true, location: { pathname: window.location.pathname } },
      this.start.bind(this)
    );
  }

  componentWillUnmount() {
    this.setState({
      taskToEditProps: {
        valid: false,
        title: '',
        desc: {},
        tags: [],
        spaceTags: [],
        exposure: 0,
        priority: 0,
        created: -1,
        task_state: ''
      }
    });

    if (window.platform) {
      delete window.platform;
    }

    if (window.Quill) {
      delete window.Quill;
    }

    if (window.pako) {
      delete window.pako;
    }
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.location &&
      previousProps.location.pathname !== window.location.pathname &&
      this.state.mounted
    ) {
      /**
       * Adjust meta SEO
       */
      this.setState(
        {
          location: { pathname: window.location.pathname }
        },
        this.setHTMLMeta
      );
    }
  }

  setHTMLMeta() {
    const metaNoReplaceTitle = document.querySelector('.no_replace_title');

    const { location } = this.state;

    if (
      metaNoReplaceTitle &&
      metaNoReplaceTitle.hasAttribute('content') &&
      metaNoReplaceTitle.getAttribute('content') === 'true'
    ) {
      return AppAPI.setMetaDescription(i18n('seo_dashboard_home_desc'));
    }

    if (this.state.location.pathname.indexOf('/login') === 0) {
      AppAPI.setMetaTitle(i18n('seo_dashboard_login_title'));
    } else if (this.state.location.pathname.indexOf('/sign-up') === 0) {
      AppAPI.setMetaTitle(i18n('seo_dashboard_signup_title'));
    } else if (this.state.location.pathname.indexOf('/docs') === 0) {
      AppAPI.setMetaTitle(i18n('seo_dashboard_docs_default'));
    } else if (location.pathname.indexOf('/forgot-password') === 0) {
      AppAPI.setMetaTitle(i18n('seo_dashboard_forgot_password_title'));
    } else if (location.pathname.indexOf('/reset-password') === 0) {
      AppAPI.setMetaTitle(i18n('seo_dashboard_reset_password_title'));
    } else if (location.pathname.indexOf('/user') === 0) {
      AppAPI.setMetaTitle(i18n('seo_dashboard_user'));
    } else {
      AppAPI.setMetaTitle(i18n('seo_dashboard_home_title'));
    }

    AppAPI.setMetaDescription(i18n('seo_dashboard_home_desc'));
  }

  async start() {
    try {
      this.setHTMLMeta();
      if (QLTemplate) {
        QLTemplate.init();
      }

      await this.APP_FLOW.start();

      window.onpagehide = this.appUnload;
    } catch {}
  }

  async confirmLoaded() {
    await this.setStateAsync({
      loaded: true
    });
  }

  appUnload = () => {
    try {
      Store.CONFIGS.storedConfigs();
    } catch {}

    for (let f of this.APP_CB_UNLOAD) {
      if (typeof f === 'function') {
        f();
      }
    }

    if (window.Quill) {
      delete window.Quill;
    }

    UserLog.destroy();
  };

  onAppUnload(fn) {
    if (this.APP_CB_UNLOAD.indexOf(fn) < 0) {
      this.APP_CB_UNLOAD.push(fn);
    }
  }

  render() {
    const { loaded } = this.state;

    return (
      <Provider value={{ appLoaded: loaded }}>
        {loaded && this.props.children}
      </Provider>
    );
  }
}

export default withRouter(withErrorManagerSettings(AppManager));
