import React, { Component, Fragment, createRef } from 'react';
import cx from 'classnames';
import styles from './Comments.scss';
import k from 'src/constants/k';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import Logger from 'src/lib/Logger';
import ButtonClick from 'src/components/button-click';
import CommentEntity from './comment-entity';
import i18n from 'src/locales';
import UserAvatar from 'src/components/user-avatar';
import CommentInput from './comment-input';
import {
  timeout,
  isNil,
  isFunction,
  isArray,
  isEmpty,
  toLower,
  isValidObject,
  isMobileView
} from 'src/helpers/utils';
import { v4 } from 'uuid';
import { fromEvent, timer, interval } from 'rxjs';
import { Button } from 'evergreen-ui';
import { Link } from 'react-router-dom';

class Comments extends Component {
  commentLastMadeTimestamp = 0;
  commentsListSubscriber = null;
  commentsListClickSubscriber = null;
  commentsListREF = null;
  commentOptionsExpandUnique = 'comment-option-expand-unique';
  commentOptionsActionsUnique = 'comment-option-actions-unique';
  documentClickSubscriber = null;
  timerOBSSubscirber = null;
  currentPage = 1;
  currentPages = [1];
  currentPagesWComments = [];
  currentListKey = '';
  commentTries = 0;
  latestPage = 1;
  latestListKey = '';
  q = false;
  intervalSubscriber = null;
  savedUsersCurrentPage = 1;

  state = {
    allowed: true,
    allowedWrite: true,
    actionableCommentID: '',
    fetching: true,
    fetchingBackground: false,
    fetchingMore: false,
    mounted: false,
    comments: [],
    commentToEdit: '',
    commentEdited: '',
    has_next: false,
    has_prev: false,
    status: '',
    minsPassed: 0,
    users: {},
    editingId: ''
  };

  constructor() {
    super();

    this.commentsListREF = createRef();
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    const currentUser = ProfileAPI.USER_DATA.info();
    const { isLoggedIn, blockCommentWrite } = this.props;

    if (currentUser && currentUser.profileID) {
      const { users } = this.state;
      users[`${currentUser.profileID}`] = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        initials: currentUser.initials,
        has_image: currentUser.dp,
        image: currentUser.dp || ''
      };

      this.setState({
        users
      });
    }

    this.timerOBSSubscirber = timer(60000, 60000).subscribe(this.onTimer);

    if (
      ProfileAPI.USER_DATA.checkIsProfileLoaded() &&
      (!ProfileAPI.USER_DATA.checkIsLoggedIn() || !isLoggedIn)
    ) {
      this.setState({
        allowedWrite: false
      });

      if (isFunction(blockCommentWrite)) {
        blockCommentWrite();
      }
    }

    this.commentsListSubscriber = ProfileAPI.USER_TASKS.onCommentsList(
      this.onCommentsList
    );

    if (this.commentsListREF && this.commentsListREF.current) {
      this.commentsListClickSubscriber = fromEvent(
        this.commentsListREF.current,
        'click'
      ).subscribe(this.onCommentsListClick);
    }

    this.documentClickSubscriber = fromEvent(document, 'click').subscribe(
      this.onDocumentClick
    );

    this.setState(
      {
        mounted: true
      },
      this.onMount
    );

    this.intervalSubscriber = interval(3_000).subscribe(
      this.fetchCommentsInterval
    ); // 4.5 seconds interval, sweet spot
  }

  componentWillUnmount() {
    if (this.commentsListSubscriber) {
      this.commentsListSubscriber.unsubscribe();
    }

    if (this.documentClickSubscriber) {
      this.documentClickSubscriber.unsubscribe();
    }

    if (this.commentsListClickSubscriber) {
      this.commentsListClickSubscriber.unsubscribe();
    }

    if (this.timerOBSSubscirber) {
      this.timerOBSSubscirber.unsubscribe();
    }

    if (this.intervalSubscriber) {
      this.intervalSubscriber.unsubscribe();
    }

    this.setState({
      mounted: false,
      users: {}
    });

    this.currentListKey = '';
    this.latestListKey = '';

    delete this.commentsListREF;
  }

  fetchCommentsInterval = async () => {
    const { mounted, fetchingBackground, editingId } = this.state;

    if (!mounted || AppAPI.isBlur() || !isEmpty(editingId)) {
      return;
    } else if (!fetchingBackground) {
      await this.getComments();
    }
  };

  onMount = async () => {
    try {
      const {
        taskID: taskId,
        universalREF = '',
        taskInfoFromLocal = false
      } = this.props;
      const taskIdLowered = toLower(taskId);

      if (taskId && universalREF) {
        if (taskInfoFromLocal) {
          // let's put delay given server may tike time to create the said task
          await timeout(200);
        }

        await ProfileAPI.USER_TASKS.comments(
          taskIdLowered,
          universalREF,
          this.currentPage
        );
      } else {
        throw new Error(`Missing task id or uref`);
      }
    } catch (err) {
      Logger.log(
        `Failed getting initial comments list for task: ${err.message}`
      );

      this.setState({
        allowed: false
      });
    }
  };

  onSavedUsers = async params => {
    // @todo for mentions
    if (!params) {
      return;
    }

    const savedUsersmaxPage = ProfileAPI.USER_TASKS.getSaveUsersMaxPage();
    const savedUsers = ProfileAPI.USER_TASKS.getSavedUsers(1);

    if (savedUsersmaxPage > 1) {
      this.savedUsersCurrentPage += 1;
      await ProfileAPI.USER_TASKS.savedUsers(this.savedUsersCurrentPage);
    }
  };

  onTimer = () => {
    const { minsPassed = 0, mounted } = this.state;

    if (mounted) {
      this.setState({
        minsPassed: minsPassed + 1
      });
    }
  };

  onCommentsList = ({
    valid = false,
    value = [],
    has_prev = false,
    has_next = false,
    not_allowed = false,
    block_write = false,
    limit_reached = false,
    key = '',
    page = 1
  }) => {
    const {
      isLoggedIn = false,
      blockCommentWrite,
      blockCommentView,
      allowCommentWrite
    } = this.props;
    const { mounted, fetchingBackground, fetchingMore } = this.state;
    const allowedView = !not_allowed;
    const allowedWrite =
      (!block_write || not_allowed) && !limit_reached && isLoggedIn;

    if (!mounted) {
      return;
    }

    if (!allowedWrite && isFunction(blockCommentWrite)) {
      blockCommentWrite();
    } else if (allowedWrite && isFunction(allowCommentWrite)) {
      allowCommentWrite();
    }

    if (!allowedView) {
      if (isFunction(blockCommentView)) {
        blockCommentView();
      }

      this.setState({
        allowedWrite: false,
        allowed: false,
        fetching: false
      });
    } else if (valid && fetchingMore && this.latestListKey === key) {
      if (isArray(value)) {
        if (this.currentPages.indexOf(page) < 0) {
          this.currentPages.push(page);
        }

        this.currentPagesWComments[page - 1] = value;
        this.setState({
          allowedWrite,
          comments: this.updateComments(),
          fetchingMore: false,
          has_next,
          has_prev
        });
      }
    } else if (valid && fetchingBackground && this.currentListKey === key) {
      if (this.q) {
        this.q = false;
        return this.getComments();
      }

      if (this.currentPages.indexOf(page) < 0) {
        this.currentPages.push(page);
      }

      if (isArray(value)) {
        this.currentPagesWComments[page - 1] = value;
      }

      this.setState(
        {
          allowedWrite,
          comments: this.updateComments(),
          fetchingBackground: false,
          has_next,
          has_prev
        },
        () => {
          if (value.length === k.TASKS_COMMENT_MAX_BATCH_FETCH && has_next) {
            this.currentPage += 1;
            this.getComments();
          } else if (this.currentPage !== 1) {
            this.currentPage = 1;
          }
        }
      );
    } else if (valid) {
      // first load
      const comments = isArray(value) ? value : [];

      this.setState({
        allowedWrite,
        allowed: true,
        comments,
        has_next,
        has_prev,
        fetching: false
      });
      this.currentPagesWComments[0] = comments;
    } else {
      this.setState({
        allowed: false,
        allowedWrite: false,
        fetching: false
      });

      if (isFunction(blockCommentView)) {
        blockCommentView();
      }
    }
  };

  onUserInfo = (profileID, has_image, image, firstName, lastName) => {
    const { mounted, users } = this.state;

    if (!mounted) {
      return;
    } else if (!users[profileID]) {
      users[profileID] = {
        has_image,
        image,
        firstName,
        lastName
      };

      this.setState({
        users
      });
    }
  };

  onCommentsListClick = async evt => {
    if (evt) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (
        target &&
        target.classList &&
        target.classList.contains(this.commentOptionsExpandUnique) &&
        target.classList.length > 1
      ) {
        const cn = target.classList[target.classList.length - 1];
        const { mounted, actionableCommentID } = this.state;

        if (mounted && cn) {
          if (cn === actionableCommentID) {
            this.setState({
              actionableCommentID: ''
            });
          } else {
            this.setState({
              actionableCommentID: cn
            });
          }
        }
      } else if (
        target &&
        target &&
        target.classList &&
        target.classList.contains(this.commentOptionsActionsUnique) &&
        target.classList.length > 1
      ) {
        const cn = target.classList[target.classList.length - 1];
        const ref_id = target.classList[target.classList.length - 2];
        const { mounted, commentToEdit, commentEdited } = this.state;

        if (!mounted || !ref_id || commentEdited) {
          return;
        } else if (cn === 'edit') {
          if (commentToEdit !== ref_id) {
            await this.setStateAsync({
              commentToEdit: ref_id,
              actionableCommentID: ''
            });
          } else {
            await this.setStateAsync({
              actionableCommentID: ''
            });
          }
        }
      }
    }
  };

  onDocumentClick = evt => {
    if (evt) {
      const { mounted, actionableCommentID } = this.state;
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (
        !mounted ||
        (target &&
          target.classList &&
          (target.classList.contains(this.commentOptionsExpandUnique) ||
            target.classList.contains(this.commentOptionsActionsUnique)))
      ) {
        return;
      } else if (actionableCommentID) {
        this.setState({
          actionableCommentID: ''
        });
      }
    }
  };

  updateComments() {
    return this.currentPagesWComments.reduce((acc, comments) => {
      if (isArray(comments)) {
        acc = [...acc, ...comments];
      }

      return acc;
    }, []);
  }

  getComments = async () => {
    const { mounted } = this.state;
    if (!mounted) {
      return;
    } else {
      this.currentListKey = v4();
    }

    const { taskID = '', universalREF = '' } = this.props;
    const taskId = `${taskID}`.toLowerCase();

    await this.setStateAsync({
      fetchingBackground: true
    });

    await ProfileAPI.USER_TASKS.comments(
      taskId,
      universalREF,
      this.currentPage,
      this.currentListKey
    );
  };

  loadMore = async () => {
    const { fetchingMore, mounted, has_next } = this.state;
    const { taskID = '', universalREF = '' } = this.props;

    if (fetchingMore || !mounted || !has_next) {
      return;
    } else {
      this.latestPage += 1;
      this.latestListKey = v4();
    }

    await this.setStateAsync({
      fetchingMore: true
    });

    await ProfileAPI.USER_TASKS.comments(
      `${taskID}`.toLowerCase(),
      universalREF,
      this.latestPage,
      this.latestListKey
    );
  };

  onEdit = async (
    ref_id = '',
    idx = -1,
    new_comment = '',
    old_comment = ''
  ) => {
    // save comment edited
    const { mounted, commentToEdit, comments } = this.state;
    let success = false;

    if (
      mounted &&
      comments.length &&
      comments[idx] &&
      commentToEdit === ref_id &&
      idx > -1
    ) {
      if (new_comment !== old_comment) {
        if (comments[idx]) {
          comments[idx]['text'] = new_comment;
          comments[idx]['edited'] = true;
        }

        await this.setStateAsync({
          comments,
          commentToEdit: '',
          commentEdited: ref_id,
          actionableCommentID: ''
        });

        const { taskID = '', universalREF = '' } = this.props;
        const res = await ProfileAPI.USER_TASKS.updateComment(
          new_comment,
          ref_id,
          taskID,
          universalREF
        );

        if (res && res.success) {
          if (comments[idx]) {
            comments[idx]['text'] = new_comment;
            comments[idx]['edited'] = true;
            success = true;
          }
        } else {
          comments[idx]['text'] = old_comment;
          comments[idx]['edited'] = false;
        }
      }

      await this.setStateAsync({
        comments,
        commentToEdit: '',
        commentEdited: '',
        actionableCommentID: ''
      });
    }

    return success;
  };

  onEditCancel = async () => {
    const { mounted, actionableCommentID, allowedWrite, commentEdited } =
      this.state;

    if (mounted && allowedWrite && !actionableCommentID && !commentEdited) {
      await this.setStateAsync({
        actionableCommentID: '',
        commentToEdit: ''
      });
    }
  };

  setEditingId = (id = '', props = null) => {
    const { allowedWrite, mounted } = this.state;
    const { updateCommentMode } = this.props;
    const editMode = !isEmpty(id) || isValidObject(props);

    if (!mounted) {
      return;
    }

    this.setState({
      editingId: allowedWrite ? id : ''
    });

    if (allowedWrite && isFunction(updateCommentMode)) {
      updateCommentMode(
        editMode ? k.TASK_VIEW_COMMENT_MODE.edit.key : '',
        editMode ? props : null
      );
    }
  };

  render() {
    const {
      allowed,
      allowedWrite,
      actionableCommentID,
      comments,
      commentEdited,
      commentToEdit,
      fetching,
      fetchingMore,
      status,
      has_next,
      users,
      editingId
    } = this.state;
    const {
      comment,
      isLoggedIn,
      loaded = false,
      authorProfileId = '',
      taskID: taskId,
      universalREF: uRef
    } = this.props;
    const currentUser = ProfileAPI.USER_DATA.info();
    const {
      firstName,
      lastName,
      profileID: currentUserProfileId,
      dp: userLoggedInImage
    } = currentUser || {};
    const isEmpty = !fetching && comments.length < 1;
    const userLoggedInHasDP =
      typeof userLoggedInImage === 'string' &&
      userLoggedInImage.length > 0 &&
      userLoggedInImage.indexOf('data:') === 0;
    const isThemeDarkMode = AppAPI.isDarkMode();
    const isUserAuthor = currentUser.profileID === authorProfileId;
    const commentPlaceholder =
      isLoggedIn && currentUser && authorProfileId && isUserAuthor
        ? i18n('task_view_comments_placeholder_for_author')
        : i18n('task_view_comments_placeholder');
    const commentsBlocked = !allowedWrite;

    return (
      <div
        className={cx(styles.task_comments, styles.task_comments_default, {
          [styles.hide_element]: !loaded
        })}
      >
        <div className={styles.task_comments_raw}>
          {!isLoggedIn && (
            <div className={styles.cta_to_signup}>
              <Button
                appearance="minimal"
                className={cx(styles.signup_button, {
                  [styles.signup_button_dark]: isThemeDarkMode
                })}
              >
                <h4>
                  {i18n('task_view_comments_cta_create_account_to_comment')}
                </h4>
                <div className={styles.cover}>
                  <Link to={'/sign-up'}></Link>
                </div>
              </Button>
            </div>
          )}
          {commentsBlocked && isLoggedIn && (
            <div className={cx(styles.comments_blocked, styles.flex_column_xy)}>
              <h5>{i18n('task_view_comments_blocked')}</h5>
              {isUserAuthor && <p>{i18n('task_view_comments_blocked_msg')}</p>}
            </div>
          )}

          <div
            className={cx(styles.new_comment, {
              [styles.hide_element]: fetching || !allowedWrite || !allowed
            })}
          >
            {isLoggedIn && currentUserProfileId && firstName && lastName && (
              <div className={styles.new_comment_avatar}>
                <UserAvatar
                  noHover={true}
                  toFetch={false}
                  profile_id={currentUserProfileId}
                  firstName={firstName}
                  lastName={lastName}
                  has_image={userLoggedInHasDP}
                  image={userLoggedInImage}
                />
              </div>
            )}

            <div
              className={cx(styles.new_comment_input, {
                [styles.new_comment_input_w_avatar]: isLoggedIn && currentUser,
                [styles.new_comment_input_no_avatar]:
                  !isLoggedIn || !currentUser,
                [styles.new_comment_input_dark]: isThemeDarkMode
              })}
            >
              {allowedWrite && isLoggedIn && currentUser && (
                <CommentInput
                  forceEdit
                  placeholder={commentPlaceholder}
                  mode={'create'}
                  taskId={taskId}
                  uRef={uRef}
                />
              )}
            </div>
          </div>
          {!commentsBlocked && isEmpty && (
            <div className={cx(styles.comments_empty, styles.flex_column_xy)}>
              <h5>{i18n('task_view_comments_empty_list')}</h5>
            </div>
          )}
          <div
            className={cx(styles.status, {
              [styles.hide_element]: status.length < 1 || !allowedWrite
            })}
          >
            <h5>{status}</h5>
          </div>
          <div
            className={cx(styles.list, { [styles.list_dark]: isThemeDarkMode })}
          >
            <ul ref={this.commentsListREF}>
              {comments.map((c, idx) => {
                if (c.user) {
                  const { profile_id } = c.user;
                  const wCommentsOpts = !!(
                    currentUser &&
                    currentUser.profileID === profile_id &&
                    c.ref_id
                  );
                  const hideEditButton = Boolean(
                    !comment?.allowedWrite ||
                      (isMobileView() &&
                        comment?.properties &&
                        comment?.properties.ref_id === c.ref_id)
                  );

                  return (
                    <li key={`${profile_id}-${idx}-${c.ref_id}-comments`}>
                      <CommentEntity
                        idx={idx}
                        hideEditButton={hideEditButton}
                        isAuthor={profile_id === authorProfileId}
                        profile_id={profile_id}
                        users={users}
                        firstName={c.user.firstName}
                        lastName={c.user.lastName}
                        created={c.created}
                        opts={wCommentsOpts}
                        ref_id={c.ref_id}
                        text={c.text}
                        actionableCommentID={actionableCommentID}
                        loadAction={commentEdited === c.ref_id}
                        edited={c.edited}
                        deleted={c.deleted}
                        toEdit={commentToEdit === c.ref_id}
                        spaceMember={!isNil(c.space)}
                        onSave={this.onEdit}
                        onCancel={this.onEditCancel}
                        onUserInfo={this.onUserInfo}
                        delta={c.delta}
                        text_raw={c.text_raw}
                        gif={c.gif}
                        taskId={taskId}
                        uRef={uRef}
                        editingId={editingId}
                        setEditingId={this.setEditingId}
                        showEdit
                      />
                    </li>
                  );
                } else {
                  return <Fragment />;
                }
              })}
            </ul>
          </div>
          <div
            className={cx(styles.comments_load_more, {
              [styles.hide_element]:
                !has_next || isEmpty || fetchingMore || !allowed
            })}
          >
            <ButtonClick onClick={this.loadMore}>
              <div>
                <h4>{i18n('task_view_comments_load_previous')}</h4>
                <div></div>
              </div>
            </ButtonClick>
          </div>
        </div>
      </div>
    );
  }
}

export default Comments;
