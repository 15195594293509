import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './MarksHeading2.scss';
import { isEmpty } from 'src/helpers/utils';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class MarksHeading2 extends Component {
  checking = false;
  scrollEvtBlock = false;
  marksRef = [];
  onEditorChangeSubscriber = null;
  marksListRef = null;
  marksListUnique = 'marks-list-unique-item';
  marksListClickSubscriber = null;
  onEditorScrollSubscriber = null;

  state = {
    mounted: false,
    texts: [],
    selected: 0,
    init: false
  };

  constructor() {
    super();

    this.marksListRef = createRef();
  }

  componentDidMount() {
    if (this.marksListRef && this.marksListRef.current) {
      this.marksListClickSubscriber = fromEvent(
        this.marksListRef.current,
        'click'
      ).subscribe(this.onMarkClick);
    }

    this.setState(
      {
        mounted: true
      },
      this.checkForMarks
    );
  }

  componentDidUpdate() {
    const { init } = this.state;

    if (!init) {
      this.checkForMarks();
    }
  }

  componentWillUnmount() {
    if (this.onEditorScrollSubscriber) {
      this.onEditorScrollSubscriber.unsubscribe();
    }

    if (this.marksListClickSubscriber) {
      this.marksListClickSubscriber.unsubscribe();
    }

    if (this.onEditorChangeSubscriber) {
      this.onEditorChangeSubscriber.unsubscribe();
    }

    this.setState({
      mounted: false,
      init: false
    });
  }

  checkForMarks = () => {
    const { getQuillEditor, onEditorChange } = this.props;
    const { mounted, init } = this.state;
    const texts = [];

    if (!mounted || this.checking || !getQuillEditor) {
      return;
    } else {
      this.checking = true;
    }

    if (!this.onEditorChangeSubscriber && onEditorChange) {
      this.onEditorChangeSubscriber = onEditorChange(this.checkForMarks);
    }

    const QuillEditor = getQuillEditor();

    if (QuillEditor) {
      // check
      const delta = QuillEditor.getContents();

      if (delta && delta.ops) {
        const qlEditor = document.getElementById(
          'create-task-description-editable'
        );

        if (qlEditor && qlEditor.firstChild) {
          const child = qlEditor.firstChild;

          if (child && !init) {
            this.onEditorScrollSubscriber = fromEvent(child, 'scroll')
              .pipe(debounceTime(200))
              .subscribe(this.onScroll);

            this.setState({
              init: true
            });
          }

          if (child && child.querySelectorAll) {
            const h2s = child.querySelectorAll('h2');
            this.marksRef.length = 0;

            if (!isEmpty(h2s)) {
              for (const element of h2s) {
                const txt = `${element.textContent || ''}`;

                if (!isEmpty(txt)) {
                  const nRef = createRef();
                  nRef.current = element;
                  texts.push(txt);
                  this.marksRef.push(nRef);
                }
              }
            }

            this.setState({
              texts
            });
          }
        }
      }
    }

    this.checking = false;
  };

  onScroll = evt => {
    const { mounted, init } = this.state;

    if (
      !evt ||
      window.innerWidth < 900 ||
      !mounted ||
      !init ||
      this.scrollEvtBlock ||
      !this.marksRef ||
      !this.marksRef.length
    ) {
      return;
    }

    // @todo adjust selected
  };

  onMarkClick = evt => {
    const { mounted } = this.state;

    if (evt && mounted) {
      const target = evt.target
        ? evt.target
        : evt.srcElement
        ? evt.srcElement
        : null;

      if (
        target &&
        target.classList &&
        target.classList.length &&
        target.classList.contains &&
        target.classList.contains(this.marksListUnique)
      ) {
        const cn = Number(target.classList[target.classList.length - 1]);

        if (typeof cn === 'number' && cn > -1 && this.marksRef) {
          try {
            const qlEditor = document.getElementById(
              'create-task-description-editable'
            );

            if (qlEditor && qlEditor.firstChild) {
              const child = qlEditor.firstChild;
              if (child) {
                this.scrollEvtBlock = true;

                if (cn === 0) {
                  this.setState({
                    selected: 0
                  });

                  this.scrollEvtBlock = false;

                  return;
                } else {
                  const cref = this.marksRef[cn - 1];

                  if (cref && cref.current) {
                    cref.current.scrollIntoView();
                  }

                  this.setState({
                    selected: cn
                  });
                }

                this.scrollEvtBlock = false;
              }
            }
          } catch {
            this.scrollEvtBlock = false;
          }
        }
      }
    }
  };

  render() {
    const { texts } = this.state;

    return (
      <div
        className={cx(styles.marks_heading2, {
          [styles.hide_element]: !texts || texts.length < 1
        })}
      >
        <div className={styles.marks_heading2_icon}>
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className={styles.list} ref={this.marksListRef}>
          <ul>
            {texts.map((text, idx) => {
              const trueIdx = idx + 1;

              return (
                <li
                  key={`marks-heading2-create-task-${idx}`}
                  className={cx(
                    styles.list_inactive,
                    this.marksListUnique,
                    `${trueIdx}`
                  )}
                >
                  <h5 className={cx(this.marksListUnique, `${trueIdx}`)}>
                    {text}
                  </h5>{' '}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default MarksHeading2;
