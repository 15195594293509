import React, { Component } from 'react';
import cx from 'classnames';
import styles from './Actions.scss';
import MarksH2 from '../marks-h2';
import AppAPI from 'src/app-manager/API';
import Exposure from '../exposure';

class Actions extends Component {
  state = {
    mounted: false
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  render() {
    const {
      loaded = false,
      pwcode = '',
      isOwner = false,
      isEditor = false,
      isShared = false,
      invalid = true,
      exposure = 1
    } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div className={styles.task_actions_wrap}>
        <div
          className={cx(styles.actions_placeholder, {
            [styles.hide_element]: loaded,
            [styles.actions_placeholder_dark]: isThemeDarkMode
          })}
        >
          <ul>
            <li></li>
            <li></li>
          </ul>
        </div>
        {loaded && !invalid && (
          <Exposure
            className={styles.exposure_info}
            pwcode={pwcode}
            exposure={exposure}
            isShared={isShared}
            isOwner={isOwner || isEditor}
          />
        )}
        {loaded && !invalid && <MarksH2 />}
      </div>
    );
  }
}

export default Actions;
