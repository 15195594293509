import React, { Component, createRef } from 'react';
import cx from 'classnames';
import ProfileAPI from 'src/profile-manager/API';
import styles from './UserAvatar.scss';
import { isFunction } from 'src/helpers/utils';

class UserAvatar extends Component {
  imgRef = null;
  wrapRef = null;

  onIDInfoSubscriber = null;
  state = {
    mounted: false,
    user: {
      firstName: '',
      lastName: '',
      has_image: false,
      image: ''
    }
  };

  constructor() {
    super();

    this.imgRef = createRef();
    this.wrapRef = createRef();
    this.onMount = this.onMount.bind(this);
    this.onUserInfo = this.onUserInfo.bind(this);
    this.setStateAsync = obj =>
      new Promise(resolve => this.setState({ ...obj }, resolve));
  }

  componentDidMount() {
    this.setState(
      {
        mounted: true
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    if (this.onIDInfoSubscriber) {
      this.onIDInfoSubscriber.unsubscribe();
    }

    this.setState({
      mounted: false
    });
  }

  componentDidUpdate(prevProps) {
    const { mounted } = this.state;
    const { has_image, image } = this.props;

    if (
      this.wrapRef &&
      this.wrapRef.current &&
      has_image &&
      mounted &&
      image &&
      this.imgRef &&
      (!prevProps.image ||
        (this.imgRef &&
          this.imgRef.current &&
          this.imgRef.current.getAttribute &&
          !this.imgRef.current.getAttribute('src')))
    ) {
      this.setImage(image);
    }
  }

  setImage = (img = '', noStateUpdate = false) => {
    const { image = '' } = this.props;
    const { user, mounted } = this.state;

    if (!img) {
      img = image;
    }

    if (this.imgRef && this.imgRef.current) {
      if (this.wrapRef && this.wrapRef.current) {
        if (this.wrapRef.current.clientHeight) {
          const h = `${this.wrapRef.current.clientHeight}px`;
          const w = h;

          this.imgRef.current.style.height = h;
          this.imgRef.current.style.width = w;
        } else {
          this.imgRef.current.style.height = '36px';
          this.imgRef.current.style.width = '36px';
        }

        this.imgRef.current.setAttribute('src', img);
      } else if (this.wrapRef && !this.wrapRef.current && mounted && img) {
        return this.setImage(img);
      }

      if (!noStateUpdate && user && (!user.has_image || !user.image)) {
        user.has_image = true;
        user.image = img;

        this.setState({
          user,
          has_image: true
        });
      }
    }
  };

  async onMount() {
    const { toFetch = false, profile_id = '', has_image, image } = this.props;
    if (toFetch && profile_id) {
      this.onIDInfoSubscriber = ProfileAPI.USER_PROFILE.onIDInfo(
        this.onUserInfo
      );

      await ProfileAPI.USER_PROFILE.IDInfo(profile_id);
    } else if (has_image) {
      this.setImage(image);
    }
  }

  onUserInfo = async ({
    id: profileID,
    valid = false,
    has_image = false,
    image = '',
    firstName = '',
    lastName = ''
  }) => {
    const { profile_id, onInfo = null, toFetch = false } = this.props;
    const { user } = this.state;

    if (profileID && valid && profile_id === profileID && toFetch) {
      user.firstName = firstName;
      user.lastName = lastName;
      user.has_image = Boolean(has_image || image);
      user.image = image;

      await this.setStateAsync({
        user
      });

      if (isFunction(onInfo)) {
        onInfo(profileID, has_image, image || '', firstName, lastName);
      }

      this.setImage(image);
    }
  };

  render() {
    const {
      firstName = '',
      lastName = '',
      initials = '',
      has_image = false,
      image = '',
      noHover = false
    } = this.props;
    const { user, mounted } = this.state;
    const finalImageValue = image || user?.image;
    const hasDP = finalImageValue && (user?.has_image || has_image);

    if (this.imgRef && mounted && this.imgRef.current && hasDP) {
      const src = this.imgRef.current.getAttribute('src');

      if (!src) {
        this.setImage(finalImageValue, true);
      }
    }

    return (
      <div
        ref={this.wrapRef}
        className={cx(styles.user_avatar, {
          [`${this.props.className}`]:
            typeof this.props.className === 'string' && this.props.className
        })}
      >
        <div>
          {!hasDP && (
            <h5>
              {`${
                initials
                  ? initials
                  : firstName && lastName
                  ? `${firstName.charAt(0)} ${lastName.charAt(0)}`
                  : user.firstName
                  ? `${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`
                  : '. . .'
              }`.toUpperCase()}
            </h5>
          )}
          <img
            ref={this.imgRef}
            alt={'user avatar'}
            src={image}
            className={cx(styles.img, {
              [styles.user_avatar_no_dp]: !hasDP,
              [styles.user_avatar_w_dp]: hasDP
            })}
          />
        </div>
        {!noHover && (
          <div
            className={cx(styles.for_hover, {
              [styles.hide_element]:
                !firstName && !lastName && !user.firstName && !user.lastName
            })}
          >
            <h5>{`${firstName || user.firstName} ${
              lastName || user.lastName
            }`}</h5>
          </div>
        )}
      </div>
    );
  }
}

export default UserAvatar;
