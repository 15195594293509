import React, { Component, createRef } from 'react';
import cx from 'classnames';
import ProfileAPI from 'src/profile-manager/API';
import styles from './WithAccess.scss';
import UserAvatar from 'src/components/user-avatar';
import AccessTaskView from 'src/modals/access-task-view';
import ModalAPI from 'src/modal-manager/API';
import AppAPI from 'src/app-manager/API';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Button } from 'evergreen-ui';

class WithAccess extends Component {
  onIDInfoSubscriber = null;
  onListMouseEnterSubscriber = null;
  onListMouseLeaveSubscriber = null;
  listRef = null;

  state = {
    mounted: false,
    fetching: false,
    hasAccess: [],
    listHover: false
  };

  constructor(props) {
    super(props);

    this.listRef = createRef(null);
    this.onIDInfo = this.onIDInfo.bind(this);
    this.onAccessViewClose = this.onAccessViewClose.bind(this);
    this.modifyHasAccess = this.modifyHasAccess.bind(this);
    this.onListHover = this.onListHover.bind(this);
    this.onListMouseLeave = this.onListMouseLeave.bind(this);
    this.state.hasAccess = props.hasAccess;
  }

  componentDidMount() {
    if (this.state.hasAccess.length > 0) {
      this.onIDInfoSubscriber = ProfileAPI.USER_PROFILE.onIDInfo(this.onIDInfo);
    }

    if (this.listRef && this.listRef.current) {
      this.onListMouseEnterSubscriber = fromEvent(
        this.listRef.current,
        'mouseenter'
      ).subscribe(this.onListHover);
      this.onListMouseLeaveSubscriber = fromEvent(
        this.listRef.current,
        'mouseleave'
      )
        .pipe(debounceTime(100))
        .subscribe(this.onListMouseLeave);
    }

    this.setState(
      {
        mounted: true,
        fetching: true
      },
      this.onMount
    );
  }

  componentWillUnmount() {
    if (this.onIDInfoSubscriber) {
      this.onIDInfoSubscriber.unsubscribe();
    }

    if (this.onListMouseEnterSubscriber) {
      this.onListMouseEnterSubscriber.unsubscribe();
    }

    if (this.onListMouseLeaveSubscriber) {
      this.onListMouseLeaveSubscriber.unsubscribe();
    }

    delete this.listRef;

    this.setState({
      mounted: false
    });
  }

  onMount = async () => {
    const { hasAccess } = this.state;
    const idInfoAsync = [];

    for (const wAccess of hasAccess) {
      if (wAccess && wAccess.id) {
        // @todo check there's a bug in here

        idInfoAsync.push(ProfileAPI.USER_PROFILE.IDInfo(wAccess.id));
      }
    }

    if (idInfoAsync && idInfoAsync.length > 0) {
      await Promise.all(idInfoAsync);
    }
  };

  onIDInfo({
    id: profileID,
    valid = false,
    has_image = false,
    image = '',
    firstName = '',
    lastName = ''
  }) {
    const { mounted, hasAccess } = this.state;
    const { modifyHasAccess } = this.props;

    if (!mounted) {
      return;
    } else if (valid && hasAccess.length > 0) {
      let hasChange = false;
      let hasInfoLacking = false;

      for (let i = 0; i < hasAccess.length; i++) {
        const wAccess = hasAccess[i];

        if (wAccess && wAccess.loading && wAccess.id === profileID) {
          wAccess.has_image = has_image;
          wAccess.image = has_image ? image : '';
          wAccess.initials = `${firstName.charAt(0)}${lastName.charAt(
            0
          )}`.toLowerCase();

          wAccess.firstName = firstName;
          wAccess.lastName = lastName;
          wAccess.loading = false;

          if (!hasChange) {
            hasChange = true;

            if (hasInfoLacking) {
              break;
            }
          }
        }

        if (wAccess && wAccess.loading) {
          hasInfoLacking = true;

          if (hasChange) {
            break;
          }
        }
      }

      if (!hasInfoLacking) {
        this.setState({
          fetching: false
        });
      }

      if (hasChange && typeof modifyHasAccess === 'function') {
        modifyHasAccess(hasAccess);
      }
    }
  }

  onAccessView = async () => {
    const { mounted, hasAccess } = this.state;

    if (!mounted || hasAccess.length < 1) {
      return;
    }

    if (window.scrollY) {
      await AppAPI.setLastScroll(0, window.scrollY);
    }

    if (this.onIDInfoSubscriber) {
      this.onIDInfoSubscriber.unsubscribe();
    }

    ModalAPI.goDeactivate();
    ModalAPI.setDOM(
      <AccessTaskView
        hasAccess={hasAccess}
        modifyHasAccess={this.modifyHasAccess}
        close={this.onAccessViewClose}
        complete={true}
      />
    );
    ModalAPI.goActivate();
  };

  modifyHasAccess(hasAccess) {
    const { mounted } = this.state;

    if (mounted) {
      this.setState({
        hasAccess
      });
    }

    this.props.modifyHasAccess(hasAccess);
  }

  onAccessViewClose() {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    } else {
      this.onIDInfoSubscriber = ProfileAPI.USER_PROFILE.onIDInfo(this.onIDInfo);
      ModalAPI.goDeactivate();
    }

    const { y = 0 } = AppAPI.getGlobalConfig('lastScroll');

    if (y) {
      window.scrollTo(0, y);
    }
  }

  onListHover() {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    }

    this.setState({
      listHover: true
    });
  }

  onListMouseLeave() {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    }

    this.setState({
      listHover: false
    });
  }

  render() {
    const { hasAccess } = this.state;
    const { innerWidth } = window;
    const isMax4 = innerWidth < 700;
    const previewHasAccess = hasAccess.slice(0, isMax4 ? 4 : 6);
    const hasMore = hasAccess.length > previewHasAccess.length;
    const showMore = hasMore;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div className={styles.tasks_with_access}>
        <div className={styles.list}>
          <ul
            ref={this.listRef}
            className={cx({ [styles.hide_element]: hasAccess.length < 1 })}
          >
            {previewHasAccess.map((wAccess, idx) => {
              return (
                <li key={`task-view-with-access-${wAccess.id}-${idx}`}>
                  <UserAvatar
                    has_image={wAccess.has_image}
                    firstName={wAccess.firstName}
                    lastName={wAccess.lastName}
                    image={wAccess.image}
                    initials={wAccess.initials}
                    className={styles.avatar}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={cx(styles.show_more, {
            [styles.show_more_dark]: isThemeDarkMode,
            [styles.hide_element]: !showMore
          })}
        >
          <Button
            appearance="minimal"
            type="button"
            onClick={this.onAccessView}
          >
            <h5>{'. . .'}</h5>
          </Button>
        </div>
      </div>
    );
  }
}

export default WithAccess;
