import React, { Component } from 'react';
import cx from 'classnames';
import styles from './Exposure.scss';
import i18n from 'src/locales';
import k from 'src/constants/k';
import AppAPI from 'src/app-manager/API';
import { Button, KeyIcon, LockIcon, GlobeIcon } from 'evergreen-ui';

class Exposure extends Component {
  state = {
    mounted: false,
    pwcodeShow: false
  };

  constructor() {
    super();

    this.onPWCodeToggle = this.onPWCodeToggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  onPWCodeToggle() {
    const { mounted } = this.state;

    if (!mounted) {
      return;
    } else {
      this.setState({
        pwcodeShow: !this.state.pwcodeShow
      });
    }
  }

  render() {
    const { pwcodeShow } = this.state;
    const {
      pwcode = '',
      exposure = 1,
      noText = false,
      isOwner = false,
      isShared = false
    } = this.props;
    const isThemeDarkMode = AppAPI.isDarkMode();

    return (
      <div
        className={cx(
          {
            [`${this.props.className}`]:
              typeof this.props.className === 'string' && this.props.className,
            [styles.hide_element]: !isOwner && !isShared
          },
          styles.exposure_info
        )}
      >
        <div className={styles.exposure_info_actual}>
          <div
            className={cx(styles.is_private, {
              [styles.hide_element]: k.USER_TASK_PRIVACY_PRIVATE !== exposure
            })}
          >
            <h5 className={cx({ [styles.hide_element]: noText })}>
              {i18n('task_view_actions_private')}
            </h5>
            <div className={styles.is_private_ic}>
              <LockIcon />{' '}
            </div>
          </div>
          <div
            className={cx(styles.is_pub, {
              [styles.hide_element]:
                exposure !== k.USER_TASK_PRIVACY_PUBLIC_NO_PW
            })}
          >
            <h5 className={cx({ [styles.hide_element]: noText })}>
              {i18n('task_view_actions_public_edit')}
            </h5>
            <div>
              <GlobeIcon />
            </div>
          </div>
          <div
            className={cx(styles.is_secure_pw, {
              [styles.hide_element]:
                exposure !== k.USER_TASK_PRIVACY_PUBLIC_W_PW
            })}
          >
            <h5 className={cx({ [styles.hide_element]: noText })}>
              {i18n('task_view_actions_passcode_needed')}
            </h5>
            <div>
              <LockIcon />{' '}
            </div>
            <Button
              onClick={this.onPWCodeToggle}
              className={cx(styles.is_secure_pw_show, {
                [styles.is_secure_pw_show_dark]: isThemeDarkMode,
                [styles.hide_element]: isShared
              })}
            >
              <p>
                {i18n(
                  !pwcodeShow
                    ? 'task_view_actions_passcode_show_title'
                    : 'task_view_actions_passcode_hide_title'
                )}
              </p>
            </Button>
            <div
              className={cx(styles.is_secure_pw_key, {
                [styles.hide_element]:
                  typeof pwcode !== 'string' ||
                  pwcode.length <= 0 ||
                  !pwcodeShow ||
                  isShared
              })}
            >
              <div className={styles.is_secure_pw_key_ic}>
                <KeyIcon />{' '}
              </div>
              <p>
                {typeof pwcode === 'string' && pwcode.length > 0
                  ? `${pwcode}`
                  : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Exposure;
