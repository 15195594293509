import Logger from 'src/lib/Logger';
import axios from 'axios';
import { getAuthHeaders } from 'src/helpers/utils';
import { getApiOpenBaseUrl } from 'src/helpers/urls';

class Tasks {
  /**
   * For caching, search mode, etc
   */

  tasks = {};
  tasksList = [];

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  clearCached() {
    try {
      this.tasks = {};
      this.tasksList.length = 0;
    } catch {}
  }

  cache(taskId = '', uRef = '', payload) {
    taskId = taskId.toLowerCase();
    // Logger.log(`Setting cache for: ${taskId} ${uRef}`);
    if (taskId && uRef && payload && typeof payload === 'object') {
      if (this.tasks[`${taskId}`]) {
        if (payload && payload.deleted) {
          return delete this.tasks[`${taskId}`];
        }

        this.tasks[`${taskId}`] = {
          latest: { ...this.tasks[`${taskId}`].latest, ...payload },
          deleted: payload.deleted || false
        };
      } else {
        this.tasks[`${taskId}`] = {
          taskId,
          uRef,
          latest: { ...payload },
          deleted: payload.deleted || false
        };
      }
    }
  }

  getCachedTaskById(taskId = '') {
    taskId = taskId.toLowerCase().trim();

    if (!taskId) {
      return null;
    }

    return this.tasks[taskId];
  }

  /**
   * Upload file via signed url
   * @param {*} fileName
   * @param {*} contentType
   * @param {*} taskId
   * @param {*} uRef
   * @returns
   */
  async uploadFile(
    file = null,
    fileName = '',
    contentType = '',
    taskId = '',
    uRef = '',
    inline = false
  ) {
    const result = {
      err: true,
      refId: '',
      signedUrl: '',
      url: '',
      file: null,
      notAllowedPlan: false,
      notAllowedFile: false,
      maxSize: false,
      tryAgain: false
    };

    try {
      const { user = {}, isLoggedIn = false } = this.profile_context.state;

      if (!file) {
        throw new Error('Invalid file');
      } else if (!this.profile_context) {
        throw new Error('Empty context');
      } else if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else {
        const { id, lb, n } = user.auth;
        const headers = getAuthHeaders(user);
        const baseURL = getApiOpenBaseUrl();
        const res = await axios({
          method: 'POST',
          baseURL,
          headers,
          data: {
            fileName,
            contentType,
            taskId,
            uRef,
            inline
          },
          url: `/v1/task/file/upload?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res?.data) {
          if (res.data.success) {
            const signedUrl = res.data.url;
            const refId = res.data.refId;
            let uploadFailed = '';
            // upload file
            try {
              await axios({
                method: 'PUT',
                url: signedUrl,
                headers: {
                  'Content-Type': contentType
                },
                data: file
              });
            } catch (err) {
              uploadFailed = err?.message || 'upload failed';
              await this.uploadedFileRemoveIntent(refId, taskId, uRef);
            }

            if (!uploadFailed) {
              for (let i = 0; i < 4; i++) {
                // mark file upload complete after put req
                const completeRes = await this.uploadFileComplete(
                  refId,
                  taskId,
                  uRef
                );

                if (completeRes?.tryAgain) {
                  continue;
                } else if (completeRes && !completeRes.err) {
                  result.file = completeRes.file;
                  result.err = false;
                  result.signedUrl = signedUrl;
                  result.url = completeRes.publicUrl;
                  break;
                } else {
                  result.err = true;
                  result.tryAgain = completeRes.tryAgain;
                  break;
                }
              }
            } else {
              throw new Error(`${uploadFailed || ''} `);
            }
          } else {
            const message = res.data.message;
            result.notAllowedFile = res.data.notAllowedFile;
            result.notAllowedPlan = res.data.notAllowedPlan;
            result.maxSize = res.data.maxSize;

            throw new Error(message);
          }
        } else {
          throw new Error(`${res.status} ${res.statusText}`);
        }
      }
    } catch (err) {
      result.err = true;
      Logger.log(`err:uploadFile():${err?.message}`);
    }

    return result;
  }

  /**
   * Mark file upload complete from signed url
   * @param {*} refId
   * @param {*} taskId
   * @param {*} uRef
   */
  async uploadFileComplete(refId = '', taskId = '', uRef = '') {
    const result = {
      err: true,
      tryAgain: false,
      maxSize: false,
      publicUrl: '',
      file: null
    };
    try {
      const { user = {}, isLoggedIn = false } = this.profile_context.state;
      if (!this.profile_context) {
        throw new Error('Empty context');
      } else if (!refId) {
        throw new Error('Invalid ref id');
      } else if (!isLoggedIn) {
        throw new Error('Not logged in');
      }

      const { id, lb, n } = user.auth;
      const headers = getAuthHeaders(user);
      const baseURL = getApiOpenBaseUrl();
      const res = await axios({
        method: 'POST',
        baseURL,
        headers,
        data: {
          refId,
          taskId,
          uRef
        },
        url: `/v1/task/file/upload-complete?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data) {
        if (res.data.success) {
          result.err = false;
          result.publicUrl = res.data.publicUrl;
          result.file = res.data.file;
        } else {
          result.tryAgain = res.data.tryAgain;
          result.maxSize = res.data.maxSize;
        }
      } else {
        throw new Error(`${res.status} ${res.statusText}`);
      }
    } catch (err) {
      result.err = true;
      Logger.log(`err:uploadFileComplete():${err?.message}`);
    }

    return result;
  }

  async uploadedFileRemoveIntent(
    refId = '',
    taskId = '',
    uRef = '',
    uploaded = false
  ) {
    const result = { err: true };
    try {
      const { user = {}, isLoggedIn = false } = this.profile_context.state;
      if (!this.profile_context) {
        throw new Error('Empty context');
      } else if (!refId) {
        throw new Error('Invalid ref id');
      } else if (!isLoggedIn) {
        throw new Error('Not logged in');
      }

      const { id, lb, n } = user.auth;
      const headers = getAuthHeaders(user);
      const baseURL = getApiOpenBaseUrl();
      const res = await axios({
        method: 'POST',
        baseURL,
        headers,
        data: {
          refId,
          taskId,
          uRef,
          uploaded
        },
        url: `/v1/task/file/upload-remove-intent?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data?.success) {
        result.err = false;
      } else if (res?.data) {
        throw new Error(res.data.message);
      } else {
        throw new Error(`${res.status} ${res.statusText}`);
      }
    } catch (err) {
      result.err = true;
      Logger.log(`err:uploadedFileRemoveIntent():${err?.message}`);
    }

    return result;
  }
}

export default Tasks;
