import React from 'react';
import cx from 'classnames';
import styles from './About.scss';
import AppAPI from 'src/app-manager/API';
import i18n, { Markdown } from 'src/locales';
import { LightningIcon, LayersIcon, LockIcon } from 'evergreen-ui';

const AboutUs = () => {
  const isThemeDarkMode = AppAPI.isDarkMode();

  return (
    <div className={styles.docs_about}>
      <div className={styles.label}>
        <h2>{i18n('docs_about_us_label')}</h2>
      </div>
      <div className={styles.msg_one}>
        {Markdown('docs_about_us_content_message_one', {
          title1: null
        })}
      </div>
      <div className={styles.msg_two}>
        {Markdown('docs_about_us_content_message_two', { emph1: null })}
      </div>
      <div
        className={cx(styles.mission, {
          [styles.mission_dark]: isThemeDarkMode
        })}
      >
        <h3>
          <span>{'\u201C'}</span>
          {i18n('docs_about_us_content_mission')}
          <span>{'”'}</span>
        </h3>
      </div>
      <div className={cx(styles.keys, { [styles.keys_dark]: isThemeDarkMode })}>
        <div>
          <ul>
            <li>
              <div className={styles.ic_fast}>
                <LightningIcon />
              </div>
              <div className={styles.key_desc}>
                <h4>{i18n('docs_about_us_keys_fast_title')}</h4>
                <h5>{i18n('docs_about_us_keys_fast_description')}</h5>
              </div>
            </li>
            <li>
              <div className={styles.ic_accessible}>
                <LayersIcon />
              </div>
              <div className={styles.key_desc}>
                <h4>{i18n('docs_about_us_keys_accessible_title')}</h4>
                <h5>{i18n('docs_about_us_keys_accesssible_description')}</h5>
              </div>
            </li>
            <li>
              <div className={styles.ic_secure}>
                <LockIcon />
              </div>
              <div className={styles.key_desc}>
                <h4>{i18n('docs_about_us_keys_secure_title')}</h4>
                <h5>{i18n('docs_about_us_keys_secure_description')}</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
